export const SET_LOCATION = 'SET_LOCATION';
export const SET_TOKEN = 'SET_TOKEN';
export const CATEGORY_MENU = 'CATEGORY_MENU';
export const MENU_OPTIONS = 'MENU_OPTIONS';
export const GET_CATEGORY_UPDATE = 'GET_CATEGORY_UPDATE';
export const MENU_UPDATE = 'MENU_UPDATE';
export const CREATE_ORDER = 'CREATE_ORDER';
export const LIVE_ORDERS_LIST = 'LIVE_ORDERS_LIST';
export const LIVE_ORDERS_DETAILS = 'LIVE_ORDERS_DETAILS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export const GET_PROMOTIONS = 'GET_PROMOTIONS';
export const SET_ORDER_TYPE_LIST = 'SET_ORDER_TYPE_LIST';
export const SET_CATEGORY_ICON_CLASS_LIST = 'SET_CATEGORY_ICON_CLASS_LIST';
export const SET_SOUND_VOLUME = 'SET_SOUND_VOLUME';
export const SET_SOUND_MODE = 'SET_SOUND_MODE';
export const SET_SOUND_COUNT = 'SET_SOUND_COUNT';
export const SET_SOUND_ONE_CARD = 'SET_SOUND_ONE_CARD';
export const SET_SOUND_PLAY = 'SET_SOUND_PLAY';
