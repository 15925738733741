import {
  SET_SOUND_COUNT,
  SET_SOUND_MODE,
  SET_SOUND_ONE_CARD,
  SET_SOUND_PLAY,
  SET_SOUND_VOLUME
} from '../../constants/notifications';

const initialState = {
  loading: true,
  volume: 'notification_medium.wav',
  mode: 'Single',
  handleOneCard: false,
  howler_sound_play: false,
  count: 1
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SOUND_VOLUME:
      return {
        ...state,
        volume: action.volume
      };
    case SET_SOUND_MODE:
      return {
        ...state,
        mode: action.mode
      };
    case SET_SOUND_COUNT:
      return {
        ...state,
        count: action.count
      };
    case SET_SOUND_ONE_CARD:
      return {
        ...state,
        handleOneCard: action.handleOneCard
      };
    case SET_SOUND_PLAY:
      return {
        ...state,
        howler_sound_play: action.howler_sound_play
      };

    default:
      return state;
  }
};

export default notificationReducer;
