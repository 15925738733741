import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const StyledDesktopMenu = styled.section`
  height: 64px;
  background-color: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  right: 0;
  @media (min-width: 1200px) {
    width: ${(props) =>
      props.sidebarCollapsed === true ? 'calc(100% - 80px)' : 'calc(100% - 260px)'};
  }
  @media (max-width: 768px) {
    z-index: 1000;
    position: fixed;
    .pro-sidebar {
      display: block !important;
      position: fixed;
      width: 224px;
      transition: 0.1s;
      left: ${(props) => (props.showSidebar === true ? '0' : '-100%')};
      top: 64px;

      .pro-sidebar-inner {
        width: 224px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0;
        }

        .pro-menu-item {
          &:first-child {
            display: none;
          }
        }

        .pro-sidebar-layout {
          height: calc(100% + 356px);
        }
      }
    }
  }

  .circular-progress {
    .MuiCircularProgress-root {
      width: 16px !important;
      height: 16px !important;
      color: #c2c6dc;

      .MuiCircularProgress-svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .container {
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-left: 29px;
    box-shadow: ${(props) => props.theme !== 'dark' && '0 3px 15px rgba(0,0,0,0.08)'};
    @media (max-width: 768px) {
      padding-left: 34px;
      padding-right: 23px;
    }
  }

  .menu-icon {
    display: none;
    cursor: pointer;
    position: absolute;
    left: 10px;
    z-index: 1500;
    @media (max-width: 1200px) {
      display: block;
      left: ${(props) => props.isIdExists === true && '12px'};
    }
    @media (max-width: 768px) {
      left: ${(props) => (props.pathname === '/' ? '-5px' : '10px')};
    }

    svg {
      color: #c2c6dc;
    }
  }

  .menu-icon-back {
    cursor: pointer;
    position: absolute;
    left: 10px;
    z-index: 1500;
    @media (max-width: 1200px) {
      display: block;
      left: ${(props) => props.isIdExists === true && '12px'};
    }
    @media (max-width: 768px) {
      left: ${(props) => (props.pathname === '/' ? '-5px' : '0')};
    }

    svg {
      color: #c2c6dc;
    }
  }

  .top-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: row-reverse;
      .logo {
        flex-direction: row-reverse;
        padding-right: 13px;
      }
    }

    &__left-side {
      display: flex;
      align-items: center;
      justify-content: ${(props) =>
        props.pathname === '/locations/pos/cash-checkout' ||
        props.pathname === '/locations/pos/checkout-done'
          ? 'flex-end'
          : 'space-between'};
      width: calc(100% - ${(props) => props.orderDivWidth && `${props.orderDivWidth}px`});
      position: ${(props) => (props.module ? 'static' : 'relative')};

      .order-detail {
        width: 100% !important;
        padding-left: 24px;
        color: #7367f0;
        font-size: 20px;
        font-weight: 300;
        font-family: Larsseit, sans-serif !important;
        line-height: 18px;
        @media (max-width: 768px) {
          // for location with order id
          //font-size: ${(props) => (props.pathname === '/' ? '20px' : '16px')};
          padding-left: ${(props) => (props.pathname === '/' ? '34px' : '44px')};
          position: absolute;
        }
      }

      .select-restaurant-parent {
        display: ${(props) => (props.pathname === '/locations/pos' ? 'block' : 'none')};
        width: 100%;
        @media (max-width: 1200px) {
          padding-left: 49px;
        }
        @media (max-width: 768px) {
          //display: ${(props) =>
            props.pathname === '/locations/pos/cash-checkout' ||
            props.pathname === '/locations/pos/checkout-done'
              ? 'block !important'
              : props.pathname === '/locations/pos'
              ? 'flex'
              : 'none'};
          padding-left: ${(props) =>
            props.pathname === '/locations/pos' ||
            props.pathname === '/locations/pos/cash-checkout' ||
            props.pathname === '/locations/pos/checkout-done'
              ? '46px !important'
              : '15px !important'};
        }

        .select-restaurant {
          height: 100%;
          @media (min-width: 769px) {
            width: 40% !important;
          }
          display: ${(props) =>
            props.pathname === '/locations/pos'
              ? (props) => (props.searchClicked === true ? 'none' : 'block')
              : 'none'};
          @media (max-width: 768px) {
            display: ${(props) =>
              props.pathname === '/locations/pos' ||
              props.pathname === '/locations/pos/cash-checkout' ||
              props.pathname === '/locations/pos/checkout-done'
                ? (props) => (props.searchClicked === true ? 'none' : 'block')
                : 'none'};
          }
          @media (max-width: 426px) {
            div {
              button {
                width: 60%;

                div {
                  justify-content: space-between;
                  align-items: center;
                }
              }
            }
          }

          @media (max-width: 320px) {
            div {
              button {
                width: 80%;
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        width: 100%;
        .logo {
          width: unset;
          justify-content: space-between;
        }

        div {
          &:nth-of-type(2) {
            padding-left: 15px;
          }
        }
      }

      h2 {
        margin: 0;
      }

      .pos-icon {
        position: absolute;
        top: 50%;
        left: 36%;
        transform: translate(-50%, -50%);

        h2 {
          font-size: 20px;
          font-weight: bold;
          color: ${(props) => (props.theme === 'dark' ? '#00cdbe' : '#8176F5')};
          font-family: Larsseit, sans-serif !important;
          line-height: 24px;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }

  .logo {
    align-items: center;
    display: flex;
    width: 50%;
    justify-content: flex-end;
    padding-right: 27px;

    img {
      object-fit: contain;
      width: 48px;
      height: 48px;

      // @media(max-width: 768px){
      //   display: ${(props) => (props.searchClicked === true ? 'none' : 'block')};
      // }
    }

    span {
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      padding-left: 20px;
    }

    .search-input {
      display: ${(props) => (props.pathname === '/locations/pos' ? 'flex' : 'none')};
      align-items: center;

      .search-field {
        .MuiPaper-elevation1 {
          box-shadow: ${(props) => props.theme !== 'dark' && '0px 3px 6px 0 rgba(0, 0, 0, 0.08)'};
        }
        form {
          transition: 0.1s;
          padding: 0;
          width: ${(props) => (props.searchClicked === true ? '360px' : '34px')};
          background: ${(props) => (props.searchClicked === true ? '#FFFFFF' : '#262C49')};
          flex-direction: row-reverse;
          border-top-left-radius: 5px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          // width: ${(props) => (props.searchClicked === true ? '471px' : '34px')};
          @media (max-width: 768px) {
            width: ${(props) => (props.searchClicked === true ? '258px' : '34px')};
            flex-direction: row-reverse;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background: ${(props) => (props.searchClicked === true ? '#FFFFFF' : '#262C49')};
          }

          @media (max-width: 325px) {
            width: ${(props) => (props.searchClicked === true ? '240px' : '34px')};
          }

          @media (max-width: 320px) {
            width: ${(props) => (props.searchClicked === true ? '230px' : '34px')};
          }

          button {
            border-radius: 5px;

            .MuiIconButton-label {
              padding: 0;
            }
          }

          input {
            &::placeholder {
              color: #a5a5a5;
              font-size: 12px;
              font-weight: 300;
              line-height: 14px;
            }
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }
      }

      button {
        transition: 0.1s;
        background: ${(props) =>
          props.theme === 'dark'
            ? props.searchClicked === true
              ? '#FFFFFF'
              : '#262C49'
            : props.searchClicked === true
            ? 'unset'
            : '#F8F8F8'};

        border: unset;
        padding: 8px;
        //margin-left: 32px;
        border-radius: 5px;
        outline: unset;

        img {
          width: 20px;
          height: 20px;
          display: block !important;
        }
      }

      input {
        padding: 8px 52px 8px 5px;
        outline: unset;
      }
    }
  }

  .menu-search {
    position: relative;
    padding-right: 30px;
    align-items: center;
    display: flex;

    form {
      width: 100%;
    }

    .form-control {
      border: none;
      height: 40px;
      border-right: 5px;
      font-size: 14px;
      padding-left: 30px;
      padding-right: 40px;

      &::placeholder {
        color: #a1a1a1 !important;
        font-weight: 400 !important;
      }
    }

    button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: #e2f4ff;
      border-radius: 5px;
      top: 0;
      right: 30px;
      border: none;
      bottom: 0;
      margin: auto;

      img {
        object-fit: contain;
      }
    }

    &__result {
      position: absolute;
      min-height: 150px;
      background-color: #fff;
      width: calc(100% - 45px);
      top: 80px;
      box-shadow: 0 0 20px rgb(0 0 0 / 8%);
      z-index: 9;
      border-radius: 4px;
      display: none;
      opacity: 0;

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 16px solid white;
        top: -15px;
        left: 15px;
      }

      p.not-found {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0px;
        right: 0;
        margin: auto;
        height: fit-content;
        text-align: center;
      }
    }
  }

  .right-menu {
    //width: 50%;
    width: ${(props) => props.orderDivWidth && `${props.orderDivWidth}px`};
    display: ${(props) => (props.pathname === '/locations/pos/checkout-done' ? 'none' : 'flex')};
    // only visible when path is pos,checkout and checkout done
    justify-content: flex-end;
    align-items: center;
    height: 64px;
    position: relative;
    color: #dbdbdb;
    //column-gap: 92px;
    //column-gap: 24px;
    padding: 16px;
    justify-content: space-between;
    transition: 0.1s;
    scroll-behavior: smooth;

    &__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;

      span {
        &:first-child {
          h2 {
            color: ${(props) => (props.theme === 'dark' ? '#DBDBDB' : '#565656')};
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0;
            margin-top: 2px;
            font-family: Larsseit, sans-serif !important;
          }
        }

        &:nth-of-type(2) {
          margin-left: 16px;
        }
      }

      &__order-table {
        text-decoration: unset;
        margin: 0;
        padding: 0;
        list-style-type: none;
        right: 0;
        position: absolute;
        top: 58px;
        background: #fff;
        background: ${(props) => (props.theme === 'dark' ? '#8176F5' : '#5A5A5A')};
        width: 70px;
        transition: 0.5s;
        display: ${(props) => (props.enableDropdown.table === true ? 'block' : 'none')};

        li {
          text-align: right;
          border-bottom: 1px solid #dddddd;
          padding: 12px;
          font-family: Larsseit, sans-serif !important;
          height: fit-content;
          transition: 0.3s;

          a {
            color: #fff;
            font-size: 12px;
            line-height: 15px;
            font-weight: normal;
          }

          &:hover {
            background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#313131')};
          }
        }

        .selected-table {
          background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#313131')};
        }
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      margin: 0 0 5px 0;
      text-transform: capitalize;
      cursor: pointer;
    }

    &__order-type {
      width: ${(props) => props.orderDivWidth && `${props.orderDivWidth}px`};
      z-index: 999;
      background: #fff;
      top: 64px;
      //right: ${(props) => props.positionRightValue && `-${props.positionRightValue}px`};
      right: 0;
      margin: 0;
      list-style: none;
      padding: 0;
      position: absolute;
      display: ${(props) => (props.enableDropdown.orderType === true ? 'block' : 'none')};

      li {
        font-family: Larsseit, sans-serif !important;
        font-weight: normal;
        //color: #F9F9F9;
        cursor: pointer;

        line-height: 20px;
        font-size: 16px;
        padding: 20px;
        transition: 0.3s;
        border-bottom: 1px solid #c2c6dc;

        &:last-child {
          border-bottom: unset;
        }

        a {
          color: #10163a;

          &:hover {
            color: #10163a;
          }
        }

        //&:hover{
        //  background: #A5A5A5;
        //  a{
        //    color: #10163A;
        //  }
        //}
      }

      @media (max-width: 768px) {
        width: 100%;
        left: 0;
        li {
          padding-left: 25px;
        }
      }

      .selected-order-type {
        background: #a5a5a5;
        color: #fff;

        a {
          color: #ffffff !important;
        }
      }
    }

    &__customer {
      width: ${(props) => props.orderDivWidth && `${props.orderDivWidth}px`};
      z-index: 999;
      background: #fff;
      top: 64px;
      //right: ${(props) => props.positionRightValue && `-${props.positionRightValue}px`};
      right: 0;
      margin: 0;
      list-style: none;
      padding: 0;
      position: absolute;
      display: ${(props) => (props.enableDropdown.customer === true ? 'block' : 'none')};

      li {
        padding: 16px;
        border-bottom: 1px solid #a5a5a5;
        display: flex;
        align-items: center;
        column-gap: 16px;
        justify-content: space-between;

        &:nth-of-type(1) {
          padding: 0;

          img {
            padding: 16px 0 16px 16px;

            &:nth-of-type(2) {
              padding: 16px 16px 16px 4px;
            }
          }

          input {
            padding: 16px 16px 16px 0;
          }

          //width: 90%;
          //border-bottom: -moz-default-background-color;
        }

        &:nth-of-type(2) {
          cursor: pointer;
          border-bottom: -moz-default-background-color;
          //width: 10%;
          //justify-content: center;
        }

        img {
          cursor: pointer;
        }

        input {
          outline: unset;
          border: unset;
          color: #313131;
          font-size: 16px;
          font-family: Larsseit, sans-serif !important;
          font-weight: 300;
          line-height: 18px;
          width: 100%;

          &::placeholder {
            color: #c2c6d6;
            font-size: 16px;
            font-family: Larsseit, sans-serif !important;
            font-weight: 300;
            line-height: 18px;
          }
        }

        @media (max-width: 768px) {
          padding-left: 25px;
        }

        .circular-progress {
          padding: 16px;
        }
      }

      &__result {
        text-align: left;
        background: ${(props) =>
          props.theme === 'dark'
            ? props.customerClicked === true
              ? '#7367F0'
              : '#7367F0'
            : '#A5A5A5'};
        color: #ffffff;

        &__contents {
          width: 100%;
          border-bottom: 1px solid rgb(225 228 230 / 40%);
          display: flex;
          flex-direction: column;
          row-gap: 6px;

          &__content {
            display: flex;
            column-gap: 4px;
            align-items: center;

            p {
              font-size: 16px;
              color: #ffffff;
              font-weight: normal;
              font-family: Larsseit, sans-serif !important;
              line-height: 20px;
              margin-bottom: 0;
            }

            h2,
            h3 {
              font-size: 12px;
              font-weight: 300;
              font-family: Larsseit, sans-serif !important;
              line-height: 14px;
              color: #ffffff;
              margin-bottom: 0;
            }

            &:nth-of-type(2) {
              padding-bottom: 16px;
            }
          }
        }
      }

      &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#A5A5A5')};
        cursor: pointer;
      }

      @media (max-width: 768px) {
        width: 100%;
        left: 0;
      }
    }

    @media (max-width: 768px) {
      position: absolute;
      width: 100%;
      background: #262c49;
      right: ${(props) => (props.cartVisibility === true ? '0' : '100%')};
      //top: 0;
      top: 64px;
      padding-left: 23px;
      padding-right: 20px;
      display: ${(props) => (props.pathname === '/locations/pos' ? 'flex' : 'none')};
      z-index: 1000000000;
    }
  }

  @media (max-width: 767px) {
    //opacity: 0;
    //z-index: -999;
    //visibility: hidden;
  }
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));
