import CONSTANTS from '../../../constants';

let dbConnections = {}; // Store each db connection by dbName

const openDatabase = (dbName) => {
  return new Promise((resolve, reject) => {
    if (dbConnections[dbName]) {
      // Handle pending closure
      if (dbConnections[dbName].closePending) {
        console.warn('Reopening database due to pending closure...');
        dbConnections[dbName] = null;
      } else {
        return resolve(dbConnections[dbName]);
      }
    }

    let request = indexedDB.open(dbName);

    request.onsuccess = () => {
      dbConnections[dbName] = request.result;

      // Handle database version changes
      dbConnections[dbName].onversionchange = () => {
        dbConnections[dbName].close();
        dbConnections[dbName] = null; // Reset the connection when it closes
        console.warn('Database version change detected. Reopen connection before using.');
      };

      resolve(dbConnections[dbName]);
    };

    request.onerror = (event) => {
      console.error('Error opening database:', event.target.error);
      reject(event.target.error);
    };
  });
};

// Initialize MainDB
export const initMainDB = (dbName) => {
  return new Promise((resolve, reject) => {
    if (!dbName) return reject('Invalid database name');

    // // Clean the database first if testing
    // indexedDB.deleteDatabase(dbName).onsuccess = () => {
    //   console.log('Previous database deleted successfully.');
    // };

    // Open the database for the first time with version 1
    let request = window.indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      let db = event.target.result;
      console.log('onupgradeneeded: creating object stores');
      try {
        if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.PLUGD.LOCATIONS)) {
          db.createObjectStore(CONSTANTS.INDEXDB.PLUGD.LOCATIONS, {
            keyPath: 'id',
            autoIncrement: true
          });
          console.log('Created LOCATIONS store');
        }

        if (!db.objectStoreNames.contains(CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS)) {
          db.createObjectStore(CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS, {
            keyPath: 'id',
            autoIncrement: true
          });
          console.log('Created LIVE_ORDERS store');
        }
      } catch (error) {
        console.error('Error creating object stores:', error);
      }
    };

    request.onsuccess = (event) => {
      let db = event.target.result;
      console.log('Database opened successfully');
      resolve(db);
    };

    request.onerror = (event) => {
      console.error('Database failed to open:', event.target.errorCode);
      reject('Database failed to open');
    };

    request.onblocked = () => {
      console.warn('Database initialization blocked. Close all tabs using this database.');
      reject('Database initialization blocked');
    };
  });
};

// Initialize RestaurantDB
export const initRestaurantDB = (dbName, loc_id) => {
  return new Promise((resolve, reject) => {
    if (!dbName || !loc_id) return reject('Invalid database or location ID');

    // Open the restaurant-specific database for the given location
    let request = window.indexedDB.open(dbName + loc_id, 1); // Use version 1 for the first time

    request.onupgradeneeded = (event) => {
      let db = event.target.result;

      console.log('onupgradeneeded: creating restaurant-specific object stores');
      try {
        const stores = [
          CONSTANTS.INDEXDB.STORE.USERS,
          CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
          CONSTANTS.INDEXDB.STORE.OUTLETS,
          CONSTANTS.INDEXDB.STORE.MENUS,
          CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
          CONSTANTS.INDEXDB.STORE.PROMOTIONS,
          CONSTANTS.INDEXDB.STORE.TABLES
        ];

        stores.forEach((store) => {
          if (!db.objectStoreNames.contains(store)) {
            db.createObjectStore(store, { keyPath: 'id', autoIncrement: true });
            console.log(`Created object store: ${store}`);
          }
        });
      } catch (error) {
        console.error('Error creating object stores:', error);
      }
    };

    request.onsuccess = (event) => {
      let db = event.target.result;
      console.log('Restaurant database opened successfully:', db);
      resolve(db); // Resolve the open database connection
    };

    request.onerror = (event) => {
      console.error('Restaurant database initialization failed:', event.target.errorCode);
      reject('Restaurant database initialization failed');
    };

    request.onblocked = () => {
      console.warn(
        'Restaurant database initialization blocked. Close all tabs using this database.'
      );
      reject('Restaurant database initialization blocked');
    };
  });
};

// Add data to a store in a specific database
export const addData = (dbName, storeName, data) => {
  return new Promise((resolve) => {
    if (!dbName || !storeName || !data) return resolve('Invalid parameters');

    const attemptTransaction = (retryCount = 0) => {
      openDatabase(dbName)
        .then((db) => {
          const tx = db.transaction([storeName], 'readwrite');

          tx.onabort = () => {
            console.error('Transaction aborted:', tx.error);
            resolve(false);
          };

          const store = tx.objectStore(storeName);
          store.add(data);

          tx.oncomplete = () => {
            resolve(data);
          };

          tx.onerror = (e) => {
            console.error('Error adding data:', e.target.error);
            resolve(false);
          };
        })
        .catch((error) => {
          console.error('Failed to open database for transaction:', error);
          resolve(false);
        });
    };

    attemptTransaction();
  });
};

// Delete data from a store in a specific database
export const deleteData = (dbName, storeName, key) => {
  return new Promise((resolve) => {
    if (!dbName || !storeName || key === undefined || key === null) {
      return resolve('Invalid parameters');
    }
    openDatabase(dbName).then((db) => {
      const tx = db.transaction([storeName], 'readwrite');
      tx.onabort = () => {
        console.error('Transaction aborted:', tx.error);
        resolve(false);
      };

      const store = tx.objectStore(storeName);
      const res = store.get(key);

      res.onsuccess = () => {
        if (!res.result) {
          console.warn('No record found to delete.');
          resolve(null);
        } else {
          store.delete(key);
          tx.oncomplete = () => resolve(true);
        }
      };

      res.onerror = () => {
        console.error('Error deleting data:', res.error);
        resolve(false);
      };
    });
  });
};

// Update data in a store in a specific database
export const updateData = (dbName, storeName, key, data) => {
  return new Promise((resolve) => {
    if (!dbName || !storeName || !key || !data) return resolve('Invalid parameters');

    openDatabase(dbName).then((db) => {
      const tx = db.transaction([storeName], 'readwrite');
      tx.onabort = () => {
        console.error('Transaction aborted:', tx.error);
        resolve(false);
      };

      const store = tx.objectStore(storeName);
      const res = store.get(key);

      res.onsuccess = () => {
        if (!res.result) {
          console.warn('No data found for the given key.');
          resolve(null);
        } else {
          const newData = { ...res.result, ...data };
          store.put(newData);
          tx.oncomplete = () => resolve(newData);
        }
      };

      res.onerror = () => {
        console.error('Error updating data:', res.error);
        resolve(null);
      };
    });
  });
};

// Use Cursor to get data efficiently for large datasets
export const getStoreData = (dbName, storeName) => {
  return new Promise((resolve) => {
    if (!dbName || !storeName) return resolve('Invalid parameters');

    openDatabase(dbName).then((db) => {
      const tx = db.transaction([storeName], 'readonly');
      tx.onabort = () => {
        console.error('Transaction aborted:', tx.error);
        resolve(false);
      };

      const store = tx.objectStore(storeName);
      const data = [];
      const cursorRequest = store.openCursor();

      cursorRequest.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          data.push(cursor.value); // Process the data
          cursor.continue(); // Move to the next record
        } else {
          resolve(data);
        }
      };

      cursorRequest.onerror = (event) => {
        console.error('Error fetching data with cursor:', event.target.error);
        resolve(false);
      };
    });
  });
};

// Delete a specific database
export const deleteDatabase = (dbName, loc_id) => {
  return new Promise((resolve) => {
    if (!dbName || !loc_id) return resolve('Invalid database or location ID');

    let request = window.indexedDB.deleteDatabase(dbName + loc_id);

    request.onsuccess = () => {
      console.log('Database deleted successfully.');
      resolve(true);
    };

    request.onerror = (event) => {
      console.error('Database deletion failed:', event.target.error);
      resolve(false);
    };

    request.onblocked = () => {
      console.warn('Database deletion blocked. Close all tabs using this database and try again.');
      resolve(false);
    };
  });
};
