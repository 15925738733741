import CONSTANTS from '../../../constants';

export function orders(state = { data: null, loading: false }, action) {
  if (action.type === CONSTANTS.ACTIONS.ORDERS.LIST)
    return { data: action.orders, loading: action.loading };
  return state;
}
export function provOrders(state = { data: null, loading: false }, action) {
  if (action.type === CONSTANTS.ACTIONS.ORDERS.GET_PROV_ORDERS)
    return { data: action.orders, loading: action.loading };
  return state;
}

export function currentStatus(state = { currentStatus: null, loading: false }, action) {
  if (action.type === CONSTANTS.ACTIONS.ORDERS.STATE)
    return { currentStatus: action.currentStatus, loading: action.loading };
  return state;
}
