import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setIsMasterLocation } from '../../../redux/actions/locations';
import { getCustomers } from '../../../redux/actions/users';
import { StyledModalOutlet } from '../../../styles/GlobalStyles';
import { setOrderAddress, setOrderCustomer } from '../redux/actions/cart';
import { completeRegistration } from '../redux/actions/users';
import OutletList from './OutletList';

import '../../../assets/css/outletList.css';
import {
  getChildLocations,
  getLocation,
  getOutletsForPOS,
  setLocation,
  setSelectedLocation
} from '../../../redux/actions/locations/index';
import { getFilteredPromotionList } from '../../../redux/actions/promotions';
import LocationService from '../../../services/LocationService';
import { clearCart, clearCheckout } from '../redux/actions/cart';
import { setLocationData, setTokenData } from '../redux/actions/pos';
import CartService from '../utils/CartService';
import { initRestaurantDB } from '../utils/DBHelper';
import { getPromotions } from '../utils/Promotions';
import SyncHelper from '../utils/SyncHelper';
import CONSTANTS from '../utils/constants';

const SelectOutletModal = (props) => {
  const {
    setIsMasterLocation,
    outlets,
    location,
    categoryMenu,
    selectedLocation,
    setCustomerInfoModal
  } = props;
  const [landspaceDevice, setLandspaceDevice] = useState(false);
  const [doneClick, setDoneClick] = useState(false);
  const [selectedOutlet, setSelectedOutlet] = useState(false);
  let theme = props.customizer?.customizer?.theme;

  const updateOrientationStatus = () => {
    const mql = window.matchMedia('(orientation: portrait)');
    if (!mql.matches) {
      setLandspaceDevice(true);
    } else {
      setLandspaceDevice(false);
    }
  };

  async function onDoneClick() {
    localStorage.removeItem('plugd_menu_cart');
    props.setSelectedLocation(selectedOutlet);
    LocationService.setLocation(selectedOutlet);
    initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, selectedOutlet.loc_id);
    localStorage.setItem('current_location', JSON.stringify(selectedOutlet));
    localStorage.setItem('currency_code', selectedOutlet.Currency?.currency_code);
    localStorage.setItem('decimal_place', selectedOutlet.decimal_places);
    localStorage.setItem('tax_percentage', selectedOutlet.tax_percent);
    CartService.setDeliveryCharge(selectedOutlet?.loc_id, 0);
    props.setLocation(selectedOutlet);
    props.setLocationData(selectedOutlet);
    props.getLocation(selectedOutlet?.loc_id);
    props.getChildLocations(selectedOutlet?.slug);
    props.getFilteredPromotionList(
      selectedOutlet?.is_outlet ? selectedOutlet?.parent_id : selectedOutlet?.loc_id
    );
    await SyncHelper.checkForOrderTypes(selectedOutlet);
    setIsMasterLocation(false);
    setCustomerInfoModal({ ['orderType']: true });
  }

  function onOkClick() {
    setIsMasterLocation(false);
  }

  useEffect(() => {
    updateOrientationStatus();
    if ('onorientationchange' in window) {
      window.addEventListener(
        'orientationchange',
        function () {
          updateOrientationStatus();
        },
        false
      );
    }
  }, []);

  const handleClose = () => {
    setIsMasterLocation(false);
  };
  console.log(outlets);
  if (outlets === null) {
    return '';
  }
  return (
    <StyledModalOutlet
      theme={theme}
      visible={true}
      onClose={handleClose}
      isLandspace={landspaceDevice}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      enterAnimation={'slideDown'}
      leaveAnimation={'slideDown'}
      duration={350}
      closeMaskOnClick={false}>
      <div className="outlet-modal">
        <div className="outlet-modal__header">
          <h2>Outlets</h2>
        </div>
        <OutletList
          doneClick={doneClick}
          setDoneClick={setDoneClick}
          setIsMasterLocation={setIsMasterLocation}
          setCustomerInfoModal={setCustomerInfoModal}
          setSelectedOutlet={setSelectedOutlet}
        />
        {outlets && outlets.length > 0 && (
          <button disabled={Object.keys(selectedOutlet).length === 0} onClick={onDoneClick}>
            Done
          </button>
        )}
        {location && !outlets && categoryMenu.length === 0 && (
          <button onClick={onOkClick}>Back</button>
        )}
      </div>
    </StyledModalOutlet>
  );
};

function mapStateToProps(state) {
  return {
    selectedLocation: state.locations && state.locations?.locationType?.selectedLocation,
    categoryMenu: state.posReducer ? state.posReducer.categories : [],
    location: state.posReducer ? state.posReducer.location : null,
    outlets: state.locations && state?.locations?.outlet?.data,
    customizer: state.customizer ? state.customizer : null,
    user: state.userReducer ? state.userReducer.user : null
  };
}

export default connect(mapStateToProps, {
  getCustomers,
  setOrderCustomer,
  completeRegistration,
  setOrderAddress,
  setIsMasterLocation,
  // setIsMasterLocation,
  setSelectedLocation,
  setLocationData,
  setLocation,
  setTokenData,
  getLocation,
  getOutletsForPOS,
  getChildLocations,
  getFilteredPromotionList,
  getPromotions,
  clearCart,
  clearCheckout
})(SelectOutletModal);
