import _ from 'lodash';
import CONSTANTS from '../../../constants';

export function errorCategory(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.CATEGORIES.GET_ERROR_CATEGORY) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}

export function categoryMenu(state = { data: null, loading: false }, action) {
  if (action.type === CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU) {
    const { data, loading } = action;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const cat = data[i];
        if (cat.OutletStockStatuses?.length > 0) {
          cat.status = cat.OutletStockStatuses[0].status_id;
        }
        for (let j = 0; j < cat.Menus?.length; j++) {
          const menu = cat.Menus[j];
          if (menu.OutletStockStatuses?.length > 0) {
            menu.menu_status = menu.OutletStockStatuses[0].status_id;
          }
        }
      }
    }
    return { data, loading };
  }

  if (action.type === CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU_UPDATE) {
    let sortedUpdatedCats = _.sortBy(action.data, 'category_id');
    let sortedCats = _.sortBy(state.data, 'category_id');

    for (let i = 0; i < sortedCats.length; i++)
      if (sortedCats[i].category_id === sortedUpdatedCats[i].category_id)
        sortedCats[i].priority = sortedUpdatedCats[i].priority;

    sortedCats = _.sortBy(sortedCats, 'priority');
    return { data: sortedCats, loading: action.loading };
  }

  if (
    action.type === CONSTANTS.ACTIONS.MENU.BULK_UPDATE &&
    action.loading === false &&
    action.data &&
    action.data.length > 0
  ) {
    let updatedCategory = state.data.filter(
      (cat) => cat.category_id === action.data[0].menu_category_id
    )[0];
    let sortedMenu = _.sortBy(updatedCategory.Menus, 'menu_id');
    let sortedUpdatedMenu = _.sortBy(action.data, 'menu_id');
    for (let i = 0; i < sortedMenu.length; i++)
      if (sortedMenu[i].menu_id === sortedUpdatedMenu[i].menu_id)
        sortedMenu[i].menu_priority = sortedUpdatedMenu[i].menu_priority;
    sortedMenu = _.sortBy(sortedMenu, 'menu_priority');
    updatedCategory.Menus = sortedMenu;
    return { data: state.data.splice(0), loading: action.loading };
  }

  return state;
}
