import CONSTANTS from '../../../constants';

export function allriders(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_HISTORY)
    return { data: action.riders, loading: action.loading };
  return state;
}
export function riders(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS)
    return { data: action.riders, loading: action.loading };
  return state;
}
export function ridersearnings(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_EARNINGS)
    return { data: action.riders, loading: action.loading };
  return state;
}

export function allpayments(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_PAYMENTS)
    return { data: action.payments, loading: action.loading };
  return state;
}
export function payment(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.RIDERS.PAYMENT)
    return { data: action.payments, loading: action.loading };
  return state;
}
