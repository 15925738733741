import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setIsMasterLocation } from '../../../redux/actions/locations/index';
import AddAddress from './AddAddress';
import Outlet from './Outlet';
import SelectOrderType from './SelectOrderType';
import SelectTable from './SelectTable';
import Wizard from './Wizard';

import Icon from '@ant-design/icons';
import { ReactComponent as Customer } from '../../../assets/img/static/customer.svg';
import { ReactComponent as dish } from '../../../assets/img/static/dish.svg';
import { ReactComponent as dish2 } from '../../../assets/img/static/dish2.svg';
import { ReactComponent as Search } from '../../../assets/img/static/search-icon-gray.svg';
import { ReactComponent as table } from '../../../assets/img/static/table.svg';
import { ReactComponent as table2 } from '../../../assets/img/static/table2.svg';
import { ReactComponent as User } from '../../../assets/img/static/user-dark-theme.svg';

const TableIcon = (props) => <Icon component={table} {...props} />;
const TableIcon2 = (props) => <Icon component={table2} {...props} />;

const OrderMenu = (props) => <Icon component={dish} {...props} />;
const OrderMenu2 = (props) => <Icon component={dish2} {...props} />;

const CustomerIcon = (props) => <Icon component={Customer} {...props} />;
const SearchIcon = (props) => <Icon component={Search} {...props} />;
const UserIcon = (props) => <Icon component={User} {...props} />;

const RightMenu = (props) => {
  const { setIsMasterLocation, isMasterLocation, locationType } = props;
  return (
    <div className="right-menu">
      <div
        onClick={() =>
          props.orderInfo?.orderType?.order_type === 'dine_in' &&
          props.handleCustomerInfoOpen('table')
        }
        className="right-menu__item">
        {props.orderInfo?.orderType?.order_type === 'dine_in' && (
          <span>
            {props.theme === 'dark' ? (
              <TableIcon style={{ fontSize: '25px' }} />
            ) : (
              <TableIcon2 style={{ fontSize: '25px' }} />
            )}
            {props.orderInfo?.table !== '' ? (
              <h2>
                {props.orderInfo?.table?.name
                  ? props.orderInfo?.table?.name
                  : props.orderInfo?.table?.table_name}
              </h2>
            ) : (
              <h2>Table</h2>
            )}
          </span>
        )}
      </div>

      <div onClick={() => props.handleCustomerInfoOpen('orderType')} className="right-menu__item">
        <span>
          {props.theme === 'dark' ? (
            <OrderMenu style={{ fontSize: '25px' }} />
          ) : (
            <OrderMenu2 style={{ fontSize: '25px' }} />
          )}
          {props.orderInfo?.orderType?.order_type === 'delivery' ? (
            <h2>Delivery</h2>
          ) : props.orderInfo?.orderType?.order_type === 'pick_up' ? (
            <h2>Pick Up</h2>
          ) : props.orderInfo?.orderType?.order_type === 'dine_in' ? (
            <h2>Dine-In</h2>
          ) : props.orderInfo?.orderType?.order_type === 'curbside_pickup' ? (
            <h2>Curbside</h2>
          ) : (
            <h2>Order Type</h2>
          )}
        </span>
      </div>
      <>
        <div
          ref={props.customerClickRef}
          onClick={() => props.changeEnableDropdownStatus('customer')}
          className="right-menu__item">
          <span>
            <CustomerIcon style={{ fontSize: '25px' }} />
            <h2>Customer</h2>
          </span>
        </div>

        <ul ref={props.dropDownref} className={'right-menu__customer'}>
          <li>
            <SearchIcon style={{ fontSize: '25px' }} />
            <input
              autoComplete={'off'}
              type={'number'}
              name="phone_number"
              onKeyDown={props.searchCustomer}
              onChange={props.changeCustomer}
              value={props.customer?.phone_number}
              className="customer-input"
              placeholder={'Search customer phone'}
            />
            {props.customer?.phone_number.length >= 10 && !props.customerInformation && (
              <span onClick={props.userRegistration} style={{ margin: '5px' }}>
                <UserIcon style={{ fontSize: '25px' }} />
              </span>
            )}
            {props.customer?.phone_number.length >= 1 &&
              props.customer?.phone_number.length < 10 &&
              !props.customerInformation && (
                <div className="circular-progress">
                  <CircularProgress />
                </div>
              )}
          </li>
          {props.customerInformation && (
            <li
              onClick={() => props.changeCustomerClick(props.customerInformation)}
              className={'right-menu__customer__result'}>
              <div className="right-menu__customer__result__contents">
                <div className="right-menu__customer__result__contents__content">
                  <p>
                    {props.customerInformation?.first_name} {props.customerInformation?.last_name}
                  </p>
                </div>
                <div className="right-menu__customer__result__contents__content">
                  <h2>{props.customerInformation?.phone_number}</h2>
                  {props.customerInformation?.email && <h2>.</h2>}
                  <h3>{props.customerInformation?.email}</h3>
                </div>
              </div>
            </li>
          )}
        </ul>
      </>
      {/* {props.connection ? (
        <AddNewCustomer
          open={props.customerInfoModalOpen?.customer}
          handleClose={() => props.handleAddNewCustomerClose('customer')}
          phone_number={props.customer?.phone_number}
          _location={props._location}
        />
      ) : (
        <AddNewCustomer
          open={props.customerInfoModalOpen?.customer}
          handleClose={() => props.handleAddNewCustomerClose('customer')}
          phone_number={props.customer?.phone_number}
          _location={props._location}
        />
      )} */}

      {locationType?.isMasterLoc && <Outlet setCustomerInfoModal={props.setCustomerInfoModal} />}

      <AddAddress
        open={false}
        handleClose={() => props.handleAddNewCustomerClose('address_demo')}
      />
      {!locationType?.isMasterLoc && (
        <SelectOrderType
          open={props.customerInfoModalOpen?.orderType}
          setCustomerInfoModal={props.setCustomerInfoModal}
          handleClose={(orderType = 'orderType', type = '') =>
            props.handleAddNewCustomerClose(orderType, type)
          }
          closeOrderTypeModal={props.closeOrderTypeModal}
          handleCustomerInfoOpen={props.handleCustomerInfoOpen}
        />
      )}
      <SelectTable
        open={props.customerInfoModalOpen?.table}
        handleClose={() => props.handleAddNewCustomerClose('table')}
      />
      {props.customerInfoModalOpen?.address && (
        <Wizard
          open={props.customerInfoModalOpen?.customer}
          handleClose={() => props.handleAddNewCustomerClose('customer')}
          data={props._outlets}
          onChangeOutlet={props.onChangeOutlet}
          selectedOutlet={props._location}
          updateSavedLocations={props.updateSavedLocations}
          deliveryInformation={props.userDeliveryInformation}
          changeDeliveryInformation={props.changeUserDeliveryInformation}
          setUserDeliveryInformation={props.setUserDeliveryInformation}
          handleAddNewCustomerClose={props.handleAddNewCustomerClose}
          connection={props.connection}
          setCustomerInfoModal={props.setCustomerInfoModal}
          handleCustomerInfoOpen={props.handleCustomerInfoOpen}
          changeCustomerClick={props.changeCustomerClick}
          toggleModal={() => props.handleAddNewCustomerClose('address')}
          showModal={props.customerInfoModalOpen?.address}
          name="Add new location"
          itemIndex={-1}
          editLocation={false}
          isTakeAway={false}
        />
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    locationType: state.locations && state.locations.locationType
  };
}
export default connect(mapStateToProps, {
  setIsMasterLocation
})(withRouter(RightMenu));
