import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
export default class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children && this.props.children) {
      if (prevProps.children.type.displayName !== this.props.children.type.displayName) {
        this.setState({ hasError: false });
      }
    }
  }

  onRefresh() {
    this.setState({ hasError: false });
    this.props.history.push({ path: '/' });
    this.props.history.goBack();
  }

  render() {
    if (this.state.hasError || !this.props.children) {
      return (
        <>
          <Card className={'col-md-12'}>
            <CardHeader className="font-large-1">
              {this.props.message ? this.props.message : 'OOPS! Something Unexpected happened'}
            </CardHeader>
            <CardBody className="font-medium-1">
              {this.props.type === 'liveorder'
                ? 'If you would like access to features, please call or mail us at support@plugd.app to upgrade your account.'
                : this.props.message
                ? 'If you would like access to this feature, please call or mail us at support@plugd.app to upgrade your account.'
                : 'Please refresh the page and try again. If problem persists please contact us developer@plugd.app.'}
              <div className="mt-2">
                <Button color="primary" onClick={this.onRefresh.bind(this)}>
                  Reload now
                </Button>
              </div>
            </CardBody>
          </Card>
        </>
      );
    }
    return this.props.children;
  }
}
