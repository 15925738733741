import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAllTablesForPOS } from '../../../redux/actions/tables';
import { StyledModalTable } from '../../../styles/GlobalStyles';
import { setOrderTableNo } from '../redux/actions/cart';

import LocationService from '../../../services/LocationService';

const SelectTable = (props) => {
  const loc = LocationService.getLocation();
  let theme = props.customizer?.customizer?.theme;
  const connection = window.navigator.onLine;

  useEffect(() => {
    props.getAllTablesForPOS(loc?.loc_id);
  }, []);

  let tables = props.tables ? (props.tables.length ? props.tables : []) : [];

  let tableLoc = tables.filter((p) => p.loc_id === props.location?.loc_id);

  // if (!connection) {
  //   tableLoc = JSON.parse(localStorage.getItem('table_list'));
  // } else {
  //   localStorage.setItem('table_list', JSON.stringify(tableLoc));
  // }

  const closeSelectTableModal = () => {
    props.handleClose();
  };

  return (
    <StyledModalTable
      theme={theme}
      visible={props.open}
      onClose={closeSelectTableModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      enterAnimation={'slideDown'}
      leaveAnimation={'slideDown'}
      duration={350}>
      <div className="customer-modal">
        <div className="customer-modal__header">
          <h2>Select Table:</h2>
          <button onClick={closeSelectTableModal}>Cancel</button>
        </div>
        <div className="customer-modal__contents">
          <div className="customer-modal__contents__content-group">
            {tableLoc && tableLoc.length
              ? tableLoc.map((table, key) => {
                  let Orders = table?.Orders || [];
                  if (!connection) {
                    let localOrderListData = JSON.parse(localStorage.getItem('offline_order_list'));
                    if (localOrderListData) {
                      let offlineOrders = [];
                      for (let i = 0; i < localOrderListData.length; i++) {
                        let offlineOrder = localOrderListData[i];
                        if (table.table_id === offlineOrder.table.table_no) {
                          offlineOrders.push(offlineOrder);
                        }
                      }
                      Orders = offlineOrders;
                    }
                  }
                  let indx = 0;
                  let occupiedTables = [];
                  for (; indx < Orders.length; indx++) {
                    if (![15, 19].includes(Orders[indx]?.status_id)) {
                      occupiedTables.push(table.table_id);
                      break;
                    }
                  }
                  let selectTable = Orders && Orders[indx]?.status_id;
                  return (
                    <div
                      key={key}
                      onClick={() => {
                        if (occupiedTables.includes(table.table_id)) {
                          return;
                        }
                        props.setOrderTableNo({
                          id: table.table_id,
                          name: table.table_name
                        });
                        closeSelectTableModal();
                      }}
                      className={
                        props.orderedTable?.id === table.table_id
                          ? 'customer-modal__contents__content-group__content selected'
                          : selectTable
                          ? selectTable === 13
                            ? 'customer-modal__contents__content-group__content occupied'
                            : 'customer-modal__contents__content-group__content'
                          : 'customer-modal__contents__content-group__content'
                      }>
                      <h3>
                        {props.orderedTable?.id === table.table_id
                          ? 'Selected'
                          : selectTable
                          ? ![15, 19].includes(selectTable)
                            ? 'Occupied'
                            : 'Available'
                          : 'Available'}
                      </h3>
                      <h2>{table?.table_name}</h2>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </StyledModalTable>
  );
};

function mapStateToProps(state) {
  return {
    location: state.posReducer ? state.posReducer.location : null,
    customizer: state.customizer ? state.customizer : null,
    orderedTable: state.cartReducer
      ? state.cartReducer.table
        ? state.cartReducer.table
        : null
      : null,
    tables: state.tables.alltables.data
  };
}

export default connect(mapStateToProps, {
  setOrderTableNo,
  getAllTablesForPOS
})(SelectTable);
