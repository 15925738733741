import axios from 'axios';
import CONSTANTS from '../../../../../constants';
import { RESET_USER_DATA, SET_USER_DATA, SET_USER_PHONE_NUMBER } from '../../constants/users';

export function registerUser(userData) {
  return async (dispatch) => {
    try {
      const url = `${CONSTANTS.API_URL}/customers/sessions/login`;
      let response = await axios.post(url, userData);
      if (response.status === 200) {
        dispatch(storeUserData(response.data.data));
        return response;
      }
      return { status: response.status, data: response.data };
    } catch (e) {
      //alert('Operation failed');
      dispatch(storeUserData(''));
      return { status: 500, data: null };
    }
  };
}
export function completeRegistration(userData, token) {
  return async (dispatch) => {
    try {
      const url = `${CONSTANTS.API_URL}/customers/users/profile`;
      let response = await axios.post(url, userData, {
        headers: {
          'X-ACCESS-TOKEN': token
        }
      });
      if (response.status === 200) {
        dispatch(storeUserData(response.data.data));
        return response;
      }
      return;
    } catch (e) {
      alert('Operation failed');
    }
  };
}
export function storeUserData(userData) {
  return {
    type: SET_USER_DATA,
    data: userData
  };
}

export function resetUserData() {
  return {
    type: RESET_USER_DATA,
    data: {}
  };
}

export function storeUserPhoneNumber(userPhoneNumber) {
  return {
    type: SET_USER_PHONE_NUMBER,
    data: userPhoneNumber
  };
}
