import _ from 'lodash';
import CONSTANTS from './constants';

export default class PromotionsHelper {
  static checkIfAnyPromoPresent(promotions) {
    if (!promotions) return false;

    const allItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] ?? [];
    const specificItemKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] ?? {}
    );
    const specificCatKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] ?? {}
    );
    const basketPromos = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET] ?? [];

    return (
      allItems.length > 0 ||
      specificItemKeys.length > 0 ||
      basketPromos.length > 0 ||
      specificCatKeys.length > 0
    );
  }

  static getPromotionsWithNoUserLimit(promotions) {
    const allItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] ?? [];
    const basketPromos = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET] ?? [];
    return {
      ...promotions,
      [CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS]: allItems.filter(
        (item) => item.usage_limit_user === 0
      ),
      [CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET]: basketPromos.filter(
        (item) => item.usage_limit_user === 0
      )
    };
  }

  static getAllApplicablePromoIdsFor(items) {
    if (!items) return [];

    let ids = [];
    for (let i = 0; i < items.length; i++) {
      const applicable = Object.keys(items[i].discounts ? items[i].discounts : {}).filter(
        (i) => !isNaN(i)
      );
      ids = _.union(ids, applicable);
    }
    return _.uniq(_.compact(ids));
  }

  static getApplicableDiscountLevel(amount, levels) {
    levels.sort((a, b) => b.expenditure - a.expenditure);
    for (let i = 0; i < levels.length; i++) if (amount > levels[i].expenditure) return levels[i];
    return null;
  }

  static getDiscountedPriceFor(price, promo) {
    if (!promo) return price;
    if (
      promo.discountAmountType === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.REWARD_TYPE.PERCENTAGE
    ) {
      return price - (promo.discountAmount / 100) * price;
    }
    return price - promo.discountAmount;
  }

  static getMaxDisFor(promoId, promotions) {
    let maxDis = 0;
    if (!promotions) return maxDis;

    const allItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] ?? [];
    const specificItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] ?? {};
    const specificCats =
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] ?? {};
    const specificItemKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] ?? {}
    );
    const specificCatKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] ?? {}
    );

    for (let i = 0; i < allItems.length; i++)
      if (allItems[i].id === promoId) maxDis = allItems[i].max_discount_amount;

    for (let i = 0; i < specificItemKeys.length; i++)
      if (specificItems[specificItemKeys[i]].parent_id === promoId)
        maxDis = specificItems[specificItemKeys[i]].max_discount;

    for (let i = 0; i < specificCatKeys.length; i++)
      if (specificCats[specificCatKeys[i]].parent_id === promoId)
        maxDis = specificCats[specificCatKeys[i]].max_discount;

    maxDis = parseInt(maxDis) || 0;

    return maxDis;
  }

  static getPromotionOrderTypes(promoId, promotions) {
    if (!promotions) return [];

    const allItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] ?? [];
    const specificItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] ?? {};
    const specificCats =
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] ?? {};
    const specificItemKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] ?? {}
    );
    const specificCatKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] ?? {}
    );

    for (let i = 0; i < allItems.length; i++)
      if (allItems[i].id === promoId) {
        return allItems[i].PromotionOrderTypes.map((o) => o.order_type_id);
      }

    for (let i = 0; i < specificItemKeys.length; i++)
      if (specificItems[specificItemKeys[i]].parent_id === promoId)
        return specificItems[specificItemKeys[i]].order_type_ids;

    for (let i = 0; i < specificCatKeys.length; i++)
      if (specificCats[specificCatKeys[i]].parent_id === promoId)
        return specificCats[specificCatKeys[i]].order_type_ids;

    return [];
  }

  static getPromotionOrderTypesObject(promoId, promotions) {
    if (!promotions) return [];

    const allItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] ?? [];
    const specificItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] ?? {};
    const specificCats =
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] ?? {};
    const specificItemKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] ?? {}
    );
    const specificCatKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] ?? {}
    );

    for (let i = 0; i < allItems.length; i++)
      if (allItems[i].id === promoId) {
        return allItems[i].PromotionOrderTypes;
      }
    for (let i = 0; i < specificItemKeys.length; i++)
      if (specificItems[specificItemKeys[i]].parent_id === promoId)
        return specificItems[specificItemKeys[i]].order_types;

    for (let i = 0; i < specificCatKeys.length; i++)
      if (specificCats[specificCatKeys[i]].parent_id === promoId)
        return specificCats[specificCatKeys[i]].order_types;

    return [];
  }

  static getOrderTypesByPromoId(promoId, promotions) {
    if (!promotions) return [];
    const allItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS];
    const baskets = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET];
    const specificItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS];
    const specificCats = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES];
    const specificItemKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS]
    );
    const specificCatKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES]
    );

    for (let i = 0; i < allItems.length; i++)
      if (allItems[i].id === parseInt(promoId)) {
        return allItems[i].PromotionOrderTypes;
      }

    for (let i = 0; i < specificItemKeys.length; i++)
      if (specificItems[specificItemKeys[i]].parent_id === parseInt(promoId))
        return specificItems[specificItemKeys[i]].order_types;

    for (let i = 0; i < specificCatKeys.length; i++)
      if (specificCats[specificCatKeys[i]].parent_id === parseInt(promoId))
        return specificCats[specificCatKeys[i]].order_types;

    for (let i = 0; i < baskets.length; i++)
      if (baskets[i].id === parseInt(promoId)) {
        return baskets[i].PromotionOrderTypes;
      }

    return [];
  }

  static getCodeForPromo(promoId, promotions) {
    if (!promotions) return '';

    const allItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] ?? [];
    const specificItems = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] ?? {};
    const specificCats =
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] ?? {};
    const specificItemKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] ?? {}
    );
    const specificCatKeys = Object.keys(
      promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] ?? {}
    );
    const basketPromos = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET] ?? [];

    for (let i = 0; i < allItems.length; i++)
      if (allItems[i].id === promoId) return allItems[i].code;

    for (let i = 0; i < basketPromos.length; i++)
      if (basketPromos[i].id === promoId) return basketPromos[i].code;

    for (let i = 0; i < specificItemKeys.length; i++)
      if (specificItems[specificItemKeys[i]].parent_id === promoId)
        return specificItems[specificItemKeys[i]].code;

    for (let i = 0; i < specificCatKeys.length; i++)
      if (specificCats[specificCatKeys[i]].parent_id === promoId)
        return specificCats[specificCatKeys[i]].code;

    return '';
  }
}
