import { combineReducers } from 'redux';
import { order } from './OrderReducer';
import { currentStatus, orders, provOrders } from './OrdersReducer';

const ordersReducer = combineReducers({
  order,
  orders,
  currentStatus,
  provOrders
});

export default ordersReducer;
