import React from 'react';
import ViewHelper from '../../../helpers/ViewHelper';

const Item = (props) => {
  let found = props.cart.length
    ? props.cart.filter((i) => i.cartItem?.menu_id === props.item?.menu_id)
    : '';
  let count = 0;
  for (let i = 0; i < found.length; i++) {
    count += found[i].count;
  }
  let promotions;
  // if (_.isEmpty(props.customer)) {
  //   promotions = PromotionsHelper.getPromotionsWithNoUserLimit(props.promotions);
  // } else {
  promotions = props.promotions ? props.promotions : {};
  //}

  if (!promotions) promotions = [];

  const originalPrice = props.item?.Portion
    ? props.item.Portion.PortionSizes[0].price
    : props.item.menu_price;
  props.item.discounts = ViewHelper.getDiscountedItemPricePOS(
    originalPrice,
    props.item?.menu_id,
    props.category.category_id,
    promotions,
    props.orderType,
    props.location
      ? props.location.OrderTypes === undefined
        ? props.location.orderTypes
        : props.location.OrderTypes
      : []
  );
  const discountedPrice = props.item.discounts.discountedPrice;

  return (
    <>
      {props.item?.menu_status === 0 ? null : (
        <div
          tabIndex={props.itemIndex}
          key={props.item.menu_id}
          active={props.itemIndex === props.cursor}
          item={props.item}
          onClick={() => props.selectItem(props.index, props.itemIndex)}
          //className={item?.OutletStockStatuses.length > 0 && item?.OutletStockStatuses[0]?.status_id === 2 ? 'item item-disabled' : menuParentIndex === index && menuChildIndex === itemIndex && currentDiv === "item" ? "item-active" : "item"}
          className={props.item?.menu_status === 2 ? 'item item-disabled' : 'item'}>
          {count > 0 ? <div className="quantity">{count}</div> : null}
          <div className="item-parent__wrapper">
            <div className="menu-name">{props.item?.menu_name}</div>

            <div className="menu-price">
              <p>
                {ViewHelper.toCurrency(discountedPrice, props.currencyCode, props.decimalPlace)}
              </p>
              {Number(originalPrice) !== Number(discountedPrice) && (
                <p style={{ textDecoration: 'line-through' }}>
                  {ViewHelper.toCurrency(originalPrice, props.currencyCode, props.decimalPlace)}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Item;
