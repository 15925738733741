import _ from 'lodash';
import CONSTANTS from '../../../constants';
import SyncHelper from '../../../pos/src/utils/SyncHelper';
import OrderService from '../../../services/OrderService';

const connection = window.navigator.onLine;
export function getLiveOrders(params, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.ORDERS.LIST, orders: null, loading: true });
      let orders = { count: 0, rows: [] };
      if (!window.navigator.onLine) {
        let offlineData = JSON.parse(localStorage.getItem('offline_order_list'));
        offlineData = offlineData !== null ? offlineData : [];
        let savedOrders = await SyncHelper.getSavedLiveOrders();
        console.log(savedOrders);
        if (offlineData.length > 0) {
          for (let i = 0; i < offlineData.length; i++) {
            savedOrders.rows.push(offlineData[i]);
          }
        }
        savedOrders.rows = _.orderBy(savedOrders.rows, ['isOfflineOrder'], ['asc']);
        orders = savedOrders;
      } else {
        orders = await OrderService.getLiveOrders(params);
        await SyncHelper.saveLiveOrders(orders);
      }
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.LIST, orders: orders, loading: false });
    } catch (error) {
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.LIST, orders: null, loading: false });
    }
  };
}

export function setStateValue(state, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.ORDERS.STATE, currentStatus: null, loading: false });

      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.STATE, currentStatus: state, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.STATE, currentStatus: null, loading: false });
    }
  };
}

export function setLiveOrders(orders) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.LIST, orders: orders, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.LIST, orders: null, loading: false });
    }
  };
}

export function getOrder(locationId, orderId, initialize = true) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET, order: null, loading: true });
      let order = await OrderService.getOrder(locationId, orderId);
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET, order: order, loading: false });
    } catch (error) {
      console.log(error);
      alert('Could not get order');
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET, order: null, loading: false });
    }
  };
}

export function getProvOrder(locationId, initialize = true) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET_PROV_ORDERS, orders: [], loading: true });
      let orders = await OrderService.getProvOrders(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET_PROV_ORDERS, orders: orders, loading: false });
    } catch (error) {
      console.log(error);
      alert('Could not get order');
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET_PROV_ORDERS, orders: [], loading: false });
    }
  };
}

export function setOfflineOrder(order, initialize = true) {
  return async (dispatch) => {
    console.log(order);
    try {
      if (initialize) dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET, order: null, loading: true });
      // filter from local storage data
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET, order: order, loading: false });
    } catch (error) {
      console.log(error);
      alert('Could not get order');
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET, order: null, loading: false });
    }
  };
}

export function markAsSeen(order, statuses, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize) dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET, order: null, loading: true });
      let seenStatus = statuses.find(
        (status) => status.status_name === CONSTANTS.VARIABLES.ORDERS.STATUSES.SEEN
      );
      let updatedOrder = await OrderService.updateStatus(
        order.location_id,
        order.order_id,
        seenStatus.status_id
      );
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET, order: updatedOrder, loading: false });
    } catch (error) {
      console.log(error);
      alert('Could not get order');
      dispatch({ type: CONSTANTS.ACTIONS.ORDERS.GET, order: null, loading: false });
    }
  };
}
