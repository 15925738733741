const countryDenominations = [
  {
    country_code: 'INR',
    denominations: [
      {
        type: 'note',
        value: 10
      },
      {
        type: 'note',
        value: 20
      },
      {
        type: 'note',
        value: 50
      },
      {
        type: 'note',
        value: 100
      },
      {
        type: 'note',
        value: 200
      },
      {
        type: 'note',
        value: 500
      },
      {
        type: 'note',
        value: 2000
      },
      {
        type: 'coin',
        value: 10
      },
      {
        type: 'coin',
        value: 25
      },
      {
        type: 'coin',
        value: 50
      }
    ]
  },
  {
    country_code: 'PKR',
    denominations: [
      {
        type: 'note',
        value: 10
      },
      {
        type: 'note',
        value: 20
      },
      {
        type: 'note',
        value: 50
      },
      {
        type: 'note',
        value: 100
      },
      {
        type: 'note',
        value: 500
      },
      {
        type: 'note',
        value: 1000
      },
      {
        type: 'note',
        value: 5000
      }
    ]
  },
  {
    country_code: 'BDT',
    denominations: [
      {
        type: 'note',
        value: 5
      },
      {
        type: 'note',
        value: 10
      },
      {
        type: 'note',
        value: 20
      },
      {
        type: 'note',
        value: 50
      },
      {
        type: 'note',
        value: 100
      },
      {
        type: 'note',
        value: 500
      },
      {
        type: 'note',
        value: 1000
      }
    ]
  },
  {
    country_code: 'USD',
    denominations: [
      {
        type: 'note',
        value: 1
      },
      {
        type: 'note',
        value: 2
      },
      {
        type: 'note',
        value: 5
      },
      {
        type: 'note',
        value: 10
      },
      {
        type: 'note',
        value: 20
      },
      {
        type: 'note',
        value: 50
      },
      {
        type: 'note',
        value: 100
      }
    ]
  },
  {
    country_code: 'EUR',
    denominations: [
      {
        type: 'note',
        value: 5
      },
      {
        type: 'note',
        value: 10
      },
      {
        type: 'note',
        value: 20
      },
      {
        type: 'note',
        value: 50
      },
      {
        type: 'note',
        value: 100
      },
      {
        type: 'note',
        value: 200
      }
    ]
  }
];

const getSuggestedAmount = (due, currencyCode) => {
  let currentDenominationList,
    suggestedAmountList = [],
    highestCurrency;

  for (const currency of countryDenominations) {
    if (currency.country_code === currencyCode) {
      currentDenominationList = currency.denominations;
      break;
    }
  }
  if (currentDenominationList !== undefined) {
    let immediateGreaterDenominationIndex;
    for (let index = 0; index < currentDenominationList.length; index++) {
      if (due <= currentDenominationList[index].value) {
        immediateGreaterDenominationIndex = index;
        suggestedAmountList.push(currentDenominationList[index].value);
        if (index !== currentDenominationList.length - 1) {
          if (currentDenominationList[index + 1].value > due) {
            suggestedAmountList.push(currentDenominationList[index + 1].value);
          }
        }
        break;
      }
    }

    highestCurrency = currentDenominationList[currentDenominationList.length - 1];
    if (immediateGreaterDenominationIndex === undefined) {
      const highestNoteNeeded = Math.floor(due / highestCurrency.value);
      suggestedAmountList.push(
        Number(Number(highestNoteNeeded + 1) * Number(highestCurrency.value))
      );
    }
    let summation = 0;
    // get the summation of all
    for (let index = 0; index < immediateGreaterDenominationIndex; index++) {
      summation += currentDenominationList[index].value;
    }
    if (summation > due) {
      suggestedAmountList.push(summation);
    }
    for (let index = 0; index < immediateGreaterDenominationIndex; index++) {
      if (
        currentDenominationList[index].value +
          currentDenominationList[immediateGreaterDenominationIndex].value >=
        due
      ) {
        suggestedAmountList.push(
          currentDenominationList[index].value +
            currentDenominationList[immediateGreaterDenominationIndex].value
        );
        if (
          currentDenominationList[index].value +
            currentDenominationList[immediateGreaterDenominationIndex - 1].value >=
          due
        ) {
          suggestedAmountList.push(
            currentDenominationList[index].value +
              currentDenominationList[immediateGreaterDenominationIndex - 1].value
          );
        }
      }
    }

    if (suggestedAmountList[1] === undefined) {
      // if 50 deno is available
      // if yes add with first
      suggestedAmountList[1] = suggestedAmountList[0] + 50;
    }
  } else {
    suggestedAmountList.push(due + 10);
    suggestedAmountList.push(due + 20);
  }

  return [suggestedAmountList[0], suggestedAmountList[1]];
};

export function getFinalSuggestedAmountList(dueAmount, currencyCode) {
  const amounts = getSuggestedAmount(dueAmount, currencyCode);
  let finalAmount = amounts.sort((a, b) => {
    return a - b;
  });
  return finalAmount;
}
