import { RESET_USER_DATA, SET_USER_DATA, SET_USER_PHONE_NUMBER } from '../../constants/users';

const initialState = {
  user: '',
  userPhoneNumber: ''
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        user: action.data
      };
    case RESET_USER_DATA:
      return {
        ...state,
        user: action.data
      };
    case SET_USER_PHONE_NUMBER:
      return {
        ...state,
        userPhoneNumber: action.data
      };
    default:
      return state;
  }
};

export default userReducer;
