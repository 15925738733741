import CONSTANTS from '../../../constants';

export function activitiesStats(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.ANALYTICS.ACTIVITIES) {
    return {
      data: action.data ? action.data.data : null,
      total_stats: action.data ? action.data.sum : null,
      loading: action.loading
    };
  }
  return state;
}
