import React, { Component } from 'react';
import { ArrowLeft } from 'react-feather';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { Button, Row } from 'reactstrap';
import { search } from 'ss-search';
import '../../assets/css/selectLocation.scss';
import CONSTANTS from '../../constants';
import ViewHelper from '../../helpers/ViewHelper';
import SyncHelper from '../../pos/src/utils/SyncHelper';
import {
  getAllInventoryItems,
  setLocation,
  setOnSelectClicked
} from '../../redux/actions/inventory';
import {
  getOutletsFor,
  setIsMasterLocation,
  setSelectedLocation
} from '../../redux/actions/locations/index';
import LocationService from '../../services/LocationService';

class SelectLocation extends Component {
  constructor(props) {
    super(props);
    this.state = { searchTerm: '', userLocations: [] };
  }

  async componentDidMount() {
    this.props.setIsMasterLocation(false);
    let locations = await LocationService.getLocations('');
    this.setState({ userLocations: locations });
    if (locations.length === 1) {
      this.onChange({ location: locations[0] });
    }
  }

  handleInputChange = (searchTerm) => {
    this.setState({ searchTerm });
    return searchTerm;
  };

  async loadOptions(term, callback) {
    if (term.length < 3) return callback([]);
    try {
      let locations = [];
      if (!window.navigator.onLine) {
        const locallocations = await SyncHelper.getLocalLocations();
        locations = search(locallocations, ['name'], term);
      } else {
        locations = await LocationService.getLocations(term, this.props.showOutlets);
      }
      callback(ViewHelper.transformedLocations(locations));
    } catch (e) {
      alert('Could not load locations');
    }
  }

  onChange(selected) {
    this.props.setLocation(selected);
    selected?.parent_id !== null
      ? this.props.getAllInventoryItems(selected?.location?.parent_id)
      : this.props.getAllInventoryItems(selected?.location?.loc_id);
    this.props.setOnSelectClicked(true);
    if (selected?.location?.is_master_venue || selected?.location?.is_parent) {
      this.props.setIsMasterLocation(true);
      this.props.onSelect(selected.location);
    } else if (selected) {
      if (this.props.onSelect) {
        if (this.props.openOrderType === 'true') {
          this.props.setCustomerInfoModal({ ['orderType']: true });
          this.props.history.push('/locations/pos');
        }
        this.props.onSelect(selected.location);
      } else this.onSelect(selected.location);
    }
  }

  onBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  goBack() {
    if (this.props.onBack) {
      this.props.onBack();
    }
  }

  onSelect(location) {
    LocationService.setLocation(location);
    this.redirectToURL(location);
  }

  redirectToURL(location) {
    this.props.history.push(`${CONSTANTS.BASE_URL}locations/${location.loc_id}/order_types`);
  }

  render() {
    const {
      props: { hideBack = false, showAll = false }
    } = this;
    return (
      <React.Fragment>
        <div className="d-flex flex-row">
          {!hideBack && (
            <ArrowLeft
              className="primary font-weight-bold align-self-auto mr-1"
              size="40"
              onClick={this.goBack.bind(this)}
            />
          )}
          <div className="position-relative has-icon-left full-width">
            <AsyncSelect
              placeholder="Please enter restaurant name"
              autoFocus
              isClearable
              cacheOptions
              onBlur={this.onBlur.bind(this)}
              onChange={this.onChange.bind(this)}
              loadOptions={this.loadOptions.bind(this)}
              onInputChange={this.handleInputChange.bind(this)}
              noOptionsMessage={() => 'No restaurants present.'}
              styles={{
                control: (provided) => {
                  return { ...provided, borderRadius: '1.5rem' };
                },
                dropdownIndicator: (provided) => {
                  return { ...provided, display: 'none' };
                },
                indicatorSeparator: (provided) => {
                  return { ...provided, display: 'none' };
                }
              }}
            />
          </div>
        </div>
        <div>
          <Row style={{ margin: 10 }}>
            {showAll && (
              <Button.Ripple
                style={{ margin: 5 }}
                outline
                color="primary"
                key={0}
                onClick={() =>
                  this.onChange({
                    location: {
                      loc_id: 0
                    }
                  })
                }>
                {' '}
                All{' '}
              </Button.Ripple>
            )}
            {this.state.userLocations.map((location) => (
              <Button.Ripple
                style={{ margin: 5 }}
                outline
                color="primary"
                key={location.loc_id}
                onClick={() => {
                  this.onChange({ location });
                }}>
                {' '}
                {location.name}{' '}
              </Button.Ripple>
            ))}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    outlets: state.locations && state?.locations?.outlet?.data,
    locationType: state.locations && state.locations.locationType,
    customizer: state.customizer ? state.customizer : null,
    orderType: state.cartReducer
      ? state.cartReducer.orderType
        ? state.cartReducer.orderType
        : null
      : null,
    location: state.posReducer ? state.posReducer.location : null,
    userPos: state.posReducer ? state.posReducer : null
  };
}

export default connect(mapStateToProps, {
  setIsMasterLocation,
  getOutletsFor,
  setSelectedLocation,
  getAllInventoryItems,
  setOnSelectClicked,
  setLocation
})(withRouter(SelectLocation));
