import { combineReducers } from 'redux';
import { childLocations, locationType, outlet } from './ChildLocationsReducer';
import { deliveries, deliveryProcessors, departments, location, outlets } from './LocationReducer';

const locationsReducer = combineReducers({
  location,
  outlets,
  deliveryProcessors,
  deliveries,
  childLocations,
  outlet,
  departments,
  locationType
});
export default locationsReducer;
