import { combineReducers } from 'redux';
import { allCards, allManuals, allSmsPlans, allSubscriptions, smsPlan } from './SmsReducer';

const smsReducer = combineReducers({
  allSmsPlans,
  smsPlan,
  allCards,
  allSubscriptions,
  allManuals
});

export default smsReducer;
