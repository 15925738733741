import React from 'react';
import Item from './Item';

const ItemList = (props) => {
  return (
    <div
      style={{ paddingTop: 16 }}
      id={`${props.category?.category_id}`}
      className="scroll-item-pos">
      {props.category && props.category?.Menus.length > 0 && (
        <p className="category-name">{props.category?.name}</p>
      )}
      <div tabIndex={props.index} className="item-parent">
        {props.category?.Menus.filter((item) =>
          item.menu_name.includes(props.searchText || '')
        ).map((item, itemIndex) => {
          item.menu_status =
            props.location &&
            props.location.stockStatuses &&
            props.location.stockStatuses[item.menu_id]
              ? props.location.stockStatuses[item.menu_id].status_id
              : item.menu_status;

          if (item.menu_status === 0) {
            return null;
          }

          return (
            <Item
              cart={props.cart}
              promotions={props.promotions}
              customer={props.customer}
              category={props.category}
              orderType={props.orderType}
              item={item}
              location={props.location}
              itemIndex={itemIndex}
              index={props.index}
              cursor={props.cursor}
              selectItem={props.selectItem}
              currencyCode={props.currencyCode}
              decimalPlace={props.decimalPlace}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ItemList;
