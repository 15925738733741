import Icon, { CarOutlined } from '@ant-design/icons';
import { faHandHolding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import SpinnerComponent from '../../../components/@vuexy/spinner/Fallback-spinner';
import CONSTANTS from '../../../constants';
import { addData, getStoreData, initRestaurantDB } from '../../../pos/src/utils/DBHelper';
import LocationService from '../../../services/LocationService';
import OrderTypeService from '../../../services/OrderTypeService';
import { StyledModalOrderType } from '../../../styles/GlobalStyles';
import { setCheckout, setOrderTableNo, setOrderType } from '../redux/actions/cart';
import { setOrderTypeList } from '../redux/actions/pos';

import { ReactComponent as CurbSide } from '../../../assets/img/static/curbside-dark-theme.svg';
import { ReactComponent as Delivery } from '../../../assets/img/static/delivery-dark-theme.svg';
import { ReactComponent as Dinein } from '../../../assets/img/static/dine-in-dark-theme.svg';

const DineInIcon = (props) => <Icon component={Dinein} {...props} />;
const DeliveryIcon = (props) => <Icon component={Delivery} {...props} />;
const CurbSideIcon = (props) => <Icon component={CurbSide} {...props} />;

const SelectOrderType = (props) => {
  const [orderTypeList, setOrderTypeList] = useState([]);
  const [landspaceDevice, setLandspaceDevice] = useState(false);
  const [delivery, setDelivery] = useState('');
  const connection = window.navigator.onLine;
  let theme = props.customizer?.customizer?.theme;
  let { user } = props;
  let refreshOrderTypeData = async (loc_id) => {
    await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, loc_id);
    let localOrderTypes = await getStoreData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
      CONSTANTS.INDEXDB.STORE.ORDER_TYPES
    );
    if (localOrderTypes.length > 0) {
      setOrderTypeList(localOrderTypes[1].data);
    } else {
      let orderTypes = null;
      if (props.location) {
        orderTypes = props.location.OrderTypes
          ? props.location.OrderTypes
          : props.location.orderTypes;
      } else {
        orderTypes = await OrderTypeService.getOrderTypesPOS(loc_id);
      }
      const date = moment.utc().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
      await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.ORDER_TYPES, {
        id: 0,
        lastSync: date
      });
      await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.ORDER_TYPES, {
        id: 1,
        data: orderTypes
      });
      setOrderTypeList(orderTypes);
      props.setOrderTypeList(orderTypes);
    }
  };

  useEffect(() => {
    if (props.open) {
      const location = LocationService.getLocation();
      props.location?.loc_id
        ? refreshOrderTypeData(props.location.loc_id)
        : refreshOrderTypeData(location.loc_id);
    }
  }, []);

  useEffect(() => {
    if (props.open) {
      const location = LocationService.getLocation();
      props.location?.loc_id
        ? refreshOrderTypeData(props.location.loc_id)
        : refreshOrderTypeData(location.loc_id);
    }
  }, [props]);

  useEffect(() => {
    if (!connection) {
      const location = LocationService.getLocation();
      if (location || props.location?.loc_id) {
        props.location?.loc_id
          ? refreshOrderTypeData(props.location.loc_id)
          : refreshOrderTypeData(location.loc_id);
      }
    }
  }, [connection]);

  const getOrderTypeImageUrl = (orderType) => {
    switch (orderType) {
      case 'delivery':
        return <DeliveryIcon style={{ fontSize: '25px' }} />;
        break;
      case 'dine_in':
        return <DineInIcon style={{ fontSize: '25px' }} />;
        break;
      case 'pick_up':
        return <CarOutlined style={{ fontSize: '25px' }} />;
        break;
      case 'curbside_pickup':
        return <CurbSideIcon style={{ fontSize: '35px' }} />;
        break;
      case 'advance_order':
        return <FontAwesomeIcon icon={faHandHolding} size={'lg'} color="#c2c6dc" />;
        break;
      default:
        return;
    }
  };

  const getStyleClassName = (status, index) => {
    let className = '';
    if (status === true) {
      index > 2 ? (className = 'mt-24 selected') : (className = 'selected');
    } else {
      index > 2 ? (className = 'mt-24') : (className = '');
    }
    return className;
  };

  const selectOrderType = (type) => {
    if (type.active === true) {
      setDelivery(type.order_type);
      let checkoutData = props.checkout;
      checkoutData.order_type = type.order_type;
      checkoutData.order_type_id = type.id;
      checkoutData.order_type_label = type.label;
      props.setOrderType(type);
      props.setCheckout(checkoutData);
      if (type.order_type !== 'dine_in') {
        props.setOrderTableNo('');
      }
      if (
        type.order_type === 'delivery' ||
        type.order_type === 'pick_up' ||
        type.order_type === 'curbside_pickup'
      ) {
        props.handleClose('orderType', type.order_type);
      } else if (type.order_type === 'dine_in') {
        props.handleClose('orderType', 'dine_in');
      } else {
        props.handleClose('orderType');
      }
    } else {
      Swal.fire('Opps!', `Seems like this restaurant does not support ${type.label}`, 'warning');
    }
  };

  function capitalize(word) {
    let splitWord = word.split(' ');
    if (splitWord.length > 1) {
      let finalWord = '';
      // finalWord = finalWord[0];
      for (let i = 0; i < splitWord.length; i++) {
        let word;
        if (i === 0) {
          word = splitWord[i];
          word = word[0].toUpperCase() + word.substring(1).toLowerCase();
          finalWord = finalWord + word;
        } else {
          word = splitWord[i];
          word = word.toLowerCase();
          finalWord = finalWord + ` ${word}`;
        }
      }
      return finalWord;
    } else {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    }
  }

  const updateOrientationStatus = () => {
    const mql = window.matchMedia('(orientation: portrait)');
    if (!mql.matches) {
      setLandspaceDevice(true);
    } else {
      setLandspaceDevice(false);
    }
  };
  useEffect(() => {
    updateOrientationStatus();
    if ('onorientationchange' in window) {
      window.addEventListener(
        'orientationchange',
        function () {
          updateOrientationStatus();
        },
        false
      );
    }
  }, []);

  return (
    <StyledModalOrderType
      theme={theme}
      visible={props.open}
      onClose={props.closeOrderTypeModal}
      isLandspace={landspaceDevice}
      enterAnimation={'slideDown'}
      leaveAnimation={'slideDown'}
      duration={350}>
      <div className="customer-modal">
        <div className="customer-modal__header">
          <h2>Select Order Type:</h2>
          <button onClick={props.closeOrderTypeModal}>Cancel</button>
        </div>
        <div className="customer-modal__contents">
          <div className="customer-modal__contents__content-group">
            {orderTypeList.length > 0 ? (
              orderTypeList.map((type, index) => {
                return (
                  <button
                    key={type.id}
                    onClick={() => selectOrderType(type)}
                    className={`${getStyleClassName(
                      props.orderType?.id === type.id ? true : false,
                      index
                    )}`}>
                    {getOrderTypeImageUrl(type.order_type)}
                    <p style={{ textTransform: 'none !important' }}>{`${capitalize(
                      type.label
                    )}`}</p>
                  </button>
                );
              })
            ) : (
              <SpinnerComponent />
            )}
            {/*<button*/}
            {/*    onClick={handleAdvanceOrderClick}*/}
            {/*    className={`mt-24`}*/}
            {/*>*/}
            {/*    <img src={getOrderTypeImageUrl('advance_order')} alt=""/>*/}
            {/*    <p style={{textTransform: "none !important"}}>{`${capitalize('Advance Orders')}`}</p>*/}
            {/*</button>*/}
          </div>
          {}
          {/*<button className={"proceed"}*/}
          {/* onClick={closeOrderTypeModal}>{delivery === 'delivery' || props.orderType?.order_type === 'delivery' ? 'Add Delivery Address' : 'Add Order Type'}</button>*/}
        </div>
      </div>
    </StyledModalOrderType>
  );
};

function mapStateToProps(state) {
  return {
    customizer: state.customizer ? state.customizer : null,
    orderType: state.cartReducer
      ? state.cartReducer.orderType
        ? state.cartReducer.orderType
        : null
      : null,
    location: state.posReducer ? state.posReducer.location : null,
    userPos: state.posReducer ? state.posReducer : null,
    checkout: state.cartReducer ? state.cartReducer.checkout : ''
  };
}

export default connect(mapStateToProps, {
  setOrderType,
  setOrderTypeList,
  setOrderTableNo,
  setCheckout
})(SelectOrderType);
