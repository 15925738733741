import { setupListeners } from '@reduxjs/toolkit/query';
import localforage from 'localforage';
import { applyMiddleware, compose, createStore } from 'redux';
import createDebounce from 'redux-debounced';
import { persistReducer, persistStore } from 'redux-persist';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import { PrinterStationService } from '../../services/PrinterStationService';
import { erpApi } from '../../services/erp';
import rootReducer from '../reducers/rootReducer';

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: [
    'locations',
    'tables',
    'users',
    'promotions',
    'orders',
    'currentStatus',
    'posReducer',
    'cartReducer',
    'userReducer',
    'notificationReducer',
    'loginReducer'
  ]
};

const presistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [
  ReduxPromise,
  thunk,
  erpApi.middleware,
  PrinterStationService.middleware,
  createDebounce()
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(presistedReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

setupListeners(store.dispatch);

const persistor = persistStore(store);

export { persistor, store };
