class MyCustomMarker {
  constructor(params) {
    const overlay = new window.google.maps.OverlayView();

    if (params.position)
      overlay.position = new window.google.maps.LatLng(params.position.lat, params.position.lng);

    const content = document.createElement('div');
    content.classList.add('marker');
    if (params.isSelected) {
      content.classList.add('active');
    }
    content.textContent = params.label;
    content.style.position = 'absolute';
    content.style.transform = 'translate(-50%, -100%)';

    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.cursor = 'pointer';
    container.style.color = '#000';
    container.appendChild(content);

    overlay.container = container;

    overlay.container.onclick = params.onClick;

    overlay.onAdd = this.onAdd;
    overlay.onRemove = this.onRemove;
    overlay.draw = this.draw;

    window.google.maps.OverlayView.preventMapHitsFrom(container);
    return overlay;
  }

  onAdd() {
    this.getPanes().floatPane.appendChild(this.container);
  }

  onRemove() {
    this.container.remove();
  }

  draw() {
    const pos = this.getProjection().fromLatLngToDivPixel(this.position);
    this.container.style.left = pos.x + 'px';
    this.container.style.top = pos.y + 'px';
  }
}

export default MyCustomMarker;
