import { combineReducers } from 'redux';
import { allPromotions, errorPromotion, promotion } from './PromotionsReducer';

const promotionsReducer = combineReducers({
  allPromotions,
  promotion,
  errorPromotion
});

export default promotionsReducer;
