import CONSTANTS from '../../../constants';
import LoginService from '../../../services/LoginService';

export default {
  getPromotions,
  getPromotionByCodeIfApplicable
};

async function getPromotions(loc_id, customer_token = '') {
  let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.PROMOTIONS.DEFAULT(loc_id)}`;
  let headers = { ...CONSTANTS.REQUEST_HEADERS };
  if (customer_token) {
    headers['x-access-token'] = customer_token;
    url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.PROMOTIONS.USER_DEFAULT(loc_id)}`;
  }

  let promotions = await fetch(url, {
    method: 'GET',
    headers: headers
  });
  promotions = await promotions.json();
  if (promotions) return promotions;

  throw new Error('Could not fetch promotions');
}

async function getPromotionByCodeIfApplicable(code, loc_id) {
  let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.PROMOTIONS.CHECK(code, loc_id)}`;
  const token = LoginService.getToken();
  let headers = { ...CONSTANTS.REQUEST_HEADERS };
  if (token) {
    headers['x-access-token'] = '8cfd1dd7a4798e400c32c58cbad4d2a4bed5ebba';
    url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.PROMOTIONS.USER_CHECK(code, loc_id)}`;
  }

  let promotions = await fetch(url, {
    method: 'GET',
    headers: headers
  });

  promotions = await promotions.json();
  if (promotions) return promotions;

  throw new Error('Could not save reviews');
}
