import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../helpers/customBaseQuery';

export const PrinterStationService = createApi({
  reducerPath: 'printer_stations',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['PrinterStations'],
  endpoints: (builder) => ({
    getAllPrinterStations: builder.query({
      query: (params) => `/locations/${params.loc_id}/printer_stations`,
      providesTags: ['PrinterStations']
    }),
    savePrinterStations: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.loc_id}/printer_stations/save`,
        method: 'POST',
        body: params.reqBody
      }),
      invalidatesTags: ['PrinterStations']
    }),
    getAllCategories: builder.query({
      query: (params) => `/locations/${params.loc_id}/categories/minimal`
    })
  })
});

export const {
  useGetAllPrinterStationsQuery,
  useSavePrinterStationsMutation,
  useGetAllCategoriesQuery
} = PrinterStationService;
