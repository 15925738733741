import React, { useState } from 'react';
import { Menu, MenuItem, ProSidebar, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { connect } from 'react-redux';

import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router-dom';
import analyticsIcon from '../../../assets/img/static/analytics.svg';
import dotsIcon from '../../../assets/img/static/dots.svg';
import informationIcon from '../../../assets/img/static/information-icon.svg';
import logoutIcon from '../../../assets/img/static/logout-icon.svg';
import menuIcon from '../../../assets/img/static/menu-icon.svg';
import settingIcon from '../../../assets/img/static/menu-settings-icon.svg';
import orderMenuIcon from '../../../assets/img/static/orders-list-icon.svg';
import posIcon from '../../../assets/img/static/pos-terminal.svg';
import promotionIcon from '../../../assets/img/static/promotion.svg';
import restaurantManageIcon from '../../../assets/img/static/restaurant-settings.svg';
import ridersIcon from '../../../assets/img/static/riders.svg';
import smsMarketingIcon from '../../../assets/img/static/sms-marketing.svg';
import userAccountIcon from '../../../assets/img/static/user-account-icon.svg';
import { changeMode } from '../../../redux/actions/customizer';
import { StyledSidebar } from '../../../styles/GlobalStyles';

const SideBar = (props) => {
  const history = useHistory();
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  let theme = props.customizer?.theme ? props.customizer.theme : 'light';
  let { changeMode } = props;
  const toggleMenuCollapsed = () => {
    setMenuCollapsed(!menuCollapsed);
  };
  const handleMenuClick = (path) => {
    history.push(`${path}`);
  };
  const [darkModeChecked, setDarkModeChecked] = useState(true);
  const toggleDarkMode = () => {
    if (darkModeChecked === false) {
      changeMode('dark');
      setDarkModeChecked(!darkModeChecked);
    } else {
      changeMode('light');
      setDarkModeChecked(!darkModeChecked);
    }
  };
  return (
    <StyledSidebar theme={theme}>
      <ProSidebar collapsed={menuCollapsed}>
        <Menu iconShape="square">
          <MenuItem onClick={toggleMenuCollapsed}>
            {/*{!menuCollapsed ? <img src={dotsIcon} alt=""/> : <img src={rightArrowIcon} alt=""/>}*/}
            <img src={dotsIcon} alt="" />
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleMenuClick('/locations/pos/order-list');
            }}>
            <img src={orderMenuIcon} alt="" /> <span>Orders</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClick('/locations/pos');
            }}>
            <img src={posIcon} alt="" /> <span>POS</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClick('/locations/menu');
            }}>
            <img src={menuIcon} alt="" /> <span>Menu</span>
          </MenuItem>
          <SubMenu title="Analytics" icon={<img src={analyticsIcon} alt="" />}>
            <MenuItem
              onClick={() => {
                handleMenuClick('/locations/overall_stats');
              }}>
              Overall <br />
              Stats
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/locations/customer_stats');
              }}>
              Customer <br />
              Stats
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/locations/item_stats');
              }}>
              Item <br />
              Stats
            </MenuItem>
          </SubMenu>
          <MenuItem
            onClick={() => {
              handleMenuClick('/locations/promotions');
            }}>
            <img src={promotionIcon} alt="" /> <span>Promotion</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClick('/locations/loyalties');
            }}>
            <img src={promotionIcon} alt="" /> <span>Loyalties</span>
          </MenuItem>
          <SubMenu
            title={
              <span>
                SMS <br /> Marketing
              </span>
            }
            icon={<img src={smsMarketingIcon} alt="" />}>
            <MenuItem
              onClick={() => {
                handleMenuClick('/locations/sms/campaigns');
              }}>
              SMS <br />
              Campaigns
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/locations/sms/plans');
              }}>
              Purchase <br />
              SMS B...
            </MenuItem>
          </SubMenu>
          <SubMenu
            title={
              <span>
                Restaurant <br /> Settings
              </span>
            }
            icon={<img src={restaurantManageIcon} alt="" />}>
            <MenuItem
              onClick={() => {
                handleMenuClick('/locations/outlets');
              }}>
              Outlets
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/locations/branding');
              }}>
              Branding
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/tables');
              }}>
              Tables
            </MenuItem>
          </SubMenu>
          <SubMenu title={`Settings`} icon={<img src={settingIcon} alt="" />}>
            <MenuItem
              onClick={() => {
                handleMenuClick('/locations');
              }}>
              Restaurants
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/locations/payment_processor');
              }}>
              Payment <br />
              Settings
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/currencies');
              }}>
              Currencies
            </MenuItem>
          </SubMenu>
          <SubMenu
            title={
              <span>
                Riders & <br /> Delivery
              </span>
            }
            icon={<img src={ridersIcon} alt="" />}>
            <MenuItem
              onClick={() => {
                handleMenuClick('/locations/rider_compensation');
              }}>
              Riders <br />
              Compensation
            </MenuItem>
          </SubMenu>
          <SubMenu title={<span>App Data</span>} icon={<img src={informationIcon} alt="" />}>
            <MenuItem
              onClick={() => {
                handleMenuClick('/feedbacks');
              }}>
              Feedbacks
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/reviews');
              }}>
              Reviews
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/appdata/users');
              }}>
              Users
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/appdata/reports');
              }}>
              Reports
            </MenuItem>
          </SubMenu>
          <SubMenu
            title={
              <span>
                Account & <br /> Roles
              </span>
            }
            icon={<img src={userAccountIcon} alt="" />}>
            <MenuItem
              onClick={() => {
                handleMenuClick('/staff');
              }}>
              Riders <br />
              Accounts
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClick('/roles');
              }}>
              Riders <br />
              Roles
            </MenuItem>
          </SubMenu>
          <MenuItem
            onClick={() => {
              handleMenuClick('/logout');
            }}>
            <img src={logoutIcon} alt="" /> <span>Logout</span>
          </MenuItem>
          <MenuItem className={'switch-theme'}>
            <Switch
              checked={props.customizer.theme === 'dark' ? true : false}
              onChange={toggleDarkMode}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <span>Light / Dark</span>
          </MenuItem>
        </Menu>
      </ProSidebar>
    </StyledSidebar>
  );
};

function mapStateToProps(state) {
  return {
    customizer: state.customizer ? state.customizer.customizer : null
  };
}

export default connect(mapStateToProps, { changeMode })(SideBar);
