import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CONSTANTS from '../constants';
import DeliveryChargeHelper from './DeliveryChargeHelper';

export default class GoogleHelper {
  static async getLatLongFromAddress(address) {
    let geoCodedLocation = (await geocodeByAddress(address))[0];
    return getLatLng(geoCodedLocation);
  }

  static checkForDeliveryArea(locationString, deliveryAreas) {
    if (!window.navigator.onLine) {
      let [lat, lng] = locationString.split(',');
      if (deliveryAreas && deliveryAreas.length > 0) {
        let areas = deliveryAreas.filter((area) => {
          if (area.status) {
            if (area.type === CONSTANTS.ORDERS.VARIABLES.DELIVERY_ZONE_TYPES.CIRCLE) {
              let circleInfo = area.circle;
              let km = circleInfo[1].radius / 1000;
              return this.isWithinRange(
                lat,
                lng,
                circleInfo[0].center.lat,
                circleInfo[0].center.lng,
                km
              );
            }
            if (area.type === CONSTANTS.ORDERS.VARIABLES.DELIVERY_ZONE_TYPES.SHAPE) {
              let locatonLatlng = locationString.split(',');
              return this.inside(locatonLatlng, area.vertices);
            }
            return false;
          } else {
            return false;
          }
        });
        return areas[0];
      }
    }
    let [lat, lng] = locationString.split(',');
    let location = { lat, lng };
    if (deliveryAreas && deliveryAreas.length > 0) {
      let areas = deliveryAreas.filter((area) => {
        if (area.status) {
          if (area.type === CONSTANTS.ORDERS.VARIABLES.DELIVERY_ZONE_TYPES.CIRCLE)
            return this.isInsideCircle(location, area);
          if (area.type === CONSTANTS.ORDERS.VARIABLES.DELIVERY_ZONE_TYPES.SHAPE)
            return this.isInsideShape(location, area);
          return false;
        } else {
          return false;
        }
      });
      return areas[0];
    }
  }

  static getDeliveryArea(locationString, deliveryAreas, orderTotal) {
    if (!window.navigator.onLine) {
      let [lat, lng] = locationString.split(',');
      if (deliveryAreas && deliveryAreas.length > 0) {
        let areas = deliveryAreas.filter((area) => {
          if (area.status) {
            if (area.type === CONSTANTS.ORDERS.VARIABLES.DELIVERY_ZONE_TYPES.CIRCLE) {
              let circleInfo = area.circle;
              let km = circleInfo[1].radius / 1000;
              return this.isWithinRange(
                lat,
                lng,
                circleInfo[0].center.lat,
                circleInfo[0].center.lng,
                km
              );
            }
            if (area.type === CONSTANTS.ORDERS.VARIABLES.DELIVERY_ZONE_TYPES.SHAPE) {
              let locatonLatlng = locationString.split(',');
              return this.inside(locatonLatlng, area.vertices);
            }
            return false;
          } else {
            return false;
          }
        });
        if (areas) {
          if (orderTotal) {
            areas.forEach(
              (area) =>
                (area.deliveryCharges = DeliveryChargeHelper.computeDeliveryCharges(
                  area,
                  orderTotal
                ))
            );
            areas.sort((a, b) => a.deliveryCharges - b.deliveryCharges);
          }
          return areas[0];
        }
      } else {
        return false;
      }
    } else {
      let [lat, lng] = locationString.split(',');
      let location = { lat, lng };
      if (deliveryAreas && deliveryAreas.length > 0) {
        let areas = deliveryAreas.filter((area) => {
          if (area.status) {
            if (area.type === CONSTANTS.ORDERS.VARIABLES.DELIVERY_ZONE_TYPES.CIRCLE)
              return this.isInsideCircle(location, area);
            if (area.type === CONSTANTS.ORDERS.VARIABLES.DELIVERY_ZONE_TYPES.SHAPE)
              return this.isInsideShape(location, area);
            return false;
          } else {
            return false;
          }
        });
        if (areas) {
          if (orderTotal) {
            areas.forEach(
              (area) =>
                (area.deliveryCharges = DeliveryChargeHelper.computeDeliveryCharges(
                  area,
                  orderTotal
                ))
            );
            areas.sort((a, b) => a.deliveryCharges - b.deliveryCharges);
          }
          return areas[0];
        }
      } else {
        return false;
      }
    }
  }

  static distanceBetweenTwoLatLngStrings(point1Str, point2Str) {
    const [lat1, lng1] = point1Str.split(',');
    const [lat2, lng2] = point2Str.split(',');
    const point1 = new window.google.maps.LatLng(parseFloat(lat1), parseFloat(lng1));
    const point2 = new window.google.maps.LatLng(parseFloat(lat2), parseFloat(lng2));
    return Math.round(parseFloat(this.distance(point1, point2) / 1000));
  }

  static distance(point1, point2) {
    return window.google.maps.geometry.spherical.computeDistanceBetween(
      { lat: point1.lat(), lng: point1.lng() },
      { lat: point2.lat(), lng: point2.lng() }
    );
  }

  static isInsideCircle(location, circle) {
    let circleInfo = circle.circle;
    let center = new window.google.maps.LatLng(
      parseFloat(circleInfo[0].center.lat),
      parseFloat(circleInfo[0].center.lng)
    );
    let latLng = new window.google.maps.LatLng(parseFloat(location.lat), parseFloat(location.lng));
    return this.distance(latLng, center) < circleInfo[1].radius;
  }

  static isInsideShape(location, shape) {
    if (location && shape) {
      let latLng = new window.google.maps.LatLng(
        parseFloat(location.lat),
        parseFloat(location.lng)
      );
      setTimeout(function () {
        let mapShape = new window.google.maps.Polygon({ paths: shape.vertices });
        return window.google.maps.geometry.poly.containsLocation(latLng, mapShape);
      }, 100);
    }
  }

  static isWithinRange(
    sourceLat,
    sourceLon,
    targetLat,
    targetLon,
    maxDistance // in kilometers
  ) {
    const earthRadius = 6371; // Earth's radius in kilometers
    const dLat = this.toRadians(targetLat - sourceLat);
    const dLon = this.toRadians(targetLon - sourceLon);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRadians(sourceLat)) *
        Math.cos(this.toRadians(targetLat)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c;

    return distance <= maxDistance;
  }
  static toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }
  static inside(point, vs) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

    var x = point[0],
      y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i].lat,
        yi = vs[i].lng;
      var xj = vs[j].lat,
        yj = vs[j].lng;

      var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }
}
