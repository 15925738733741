import moment from 'moment';
import CONSTANTS from '../../../constants';
import { addData, getStoreData, initRestaurantDB } from '../../../pos/src/utils/DBHelper';
import TableService from '../../../services/TableService';

export function getAllTables(locationId) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
      let tables = await TableService.getAllTables(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: false });
    }
  };
}

export function getAllTablesForPOS(locationId) {
  return async (dispatch) => {
    try {
      if (locationId === undefined || locationId === null) {
        dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: false });
      } else {
        dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
        initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, locationId);
        let localTables = await getStoreData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + locationId,
          CONSTANTS.INDEXDB.STORE.TABLES
        );
        if (localTables.length > 0) {
          let tables = localTables[1].data;
          dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables, loading: false });
        } else {
          let tables = await TableService.getAllTables(locationId);
          const date = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
          await addData(
            CONSTANTS.DB_CACHE_KEY_PREFIX + locationId,
            CONSTANTS.INDEXDB.STORE.TABLES,
            {
              id: 0,
              lastSync: date
            }
          );
          await addData(
            CONSTANTS.DB_CACHE_KEY_PREFIX + locationId,
            CONSTANTS.INDEXDB.STORE.TABLES,
            {
              id: 1,
              data: tables
            }
          );
          dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables, loading: false });
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: false });
    }
  };
}

export function createTable(locationId, table) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
      let tables = await TableService.createTable(locationId, table);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: false });
    }
  };
}

export function changeTableStatus(locationId, data) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
      let tables = await TableService.changeTableStatus(locationId, data);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: false });
    }
  };
}

export function reGenerateQr(locationId, data) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
      let tables = await TableService.generateQr(locationId, data);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: false });
    }
  };
}
