import CONSTANTS from '../constants';
import { customFetch as fetch } from '../helpers/FetchHelper';
import LoginService from './LoginService';

export default {
  getStatuses,
  getCurrencies,
  getCategories
};

async function getStatuses() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.CONFIGURATIONS.DEFAULT}/statuses`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get statuses');
}

async function getCurrencies() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.CONFIGURATIONS.DEFAULT}/currencies`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get currencies');
}

async function getCategories() {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.CONFIGURATIONS.DEFAULT}/categories`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get categories');
}
