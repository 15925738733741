import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import SelectLocation from '../../../../components/shared/SelectLocation';
import ViewHelper from '../../../../helpers/ViewHelper';
import LayoutContext from '../../../../layouts/LayoutContext';
import { collapseSidebar, toggleCartVisibility } from '../../../../redux/actions/customizer';
import {
  getChildLocations,
  getLocation,
  getOutletsForPOS,
  setLocation
} from '../../../../redux/actions/locations';
import { getAllTablesForPOS } from '../../../../redux/actions/tables';
import {
  getCustomers,
  resetCustomerLocation,
  resetExistingCustomerDetails
} from '../../../../redux/actions/users';
import LocationService from '../../../../services/LocationService';
import LoginService from '../../../../services/LoginService';
import {
  addItemToCart,
  clearCart,
  clearCheckout,
  removeItemFromCart,
  setOrderAddress,
  setOrderCustomer,
  setOrderType,
  updateQuantityFromCart
} from '../../redux/actions/cart';
import { setLocationData, setMenuOptionsData, setTokenData } from '../../redux/actions/pos';
import { registerUser, resetUserData } from '../../redux/actions/users';
import CartService from '../../utils/CartService';
import OptionHelper from '../../utils/OptionHelper';
import SyncHelper from '../../utils/SyncHelper';

import PosSkeleton from '../../../../components/@vuexy/skeleton/PosSkeleton';
import {
  getMenu,
  getMenuByCategory,
  getMenuGroupedByCategory
} from '../../../../redux/actions/categories';
import { getStatuses } from '../../../../redux/actions/configurations';
import { getFilteredPromotionList, getPromotions } from '../../../../redux/actions/promotions';
import { StyledItemModal, StyledPos } from '../style/posStyle';

import { useLocation } from 'react-router-dom';
import { setStateValue } from '../../../../redux/actions/orders';
import ItemCard from '../../components/ItemCard';
import ItemDetailData from '../../components/ItemDetailData';

import ErrorHandler from '../../../../components/shared/ErrorHandler';
import CategoryList from '../../components/CategoryList';
import ItemList from '../../components/ItemList';
import MenuDesktop from '../../components/MainMenuDesktop';
import { deleteDatabase, initMainDB, initRestaurantDB } from '../../utils/DBHelper';
import CONSTANTS from '../../utils/constants';

const HomePos = (props) => {
  const { locationType } = props;
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const [menu, setMenu] = useState([]);
  const toggleMenuCollapsed = () => {
    //setMenuCollapsed(!menuCollapsed);
  };

  let [connection, setConnection] = useState(window.navigator.onLine);

  let boxWrap = useRef();
  let timer = null;

  useEffect(() => {
    if (!props.categoriesLoading) {
      setIsMenuLoading(false);
    } else {
      setIsMenuLoading(true);
    }
  }, [props.categoriesLoading]);

  useEffect(() => {
    function changeLinkState() {
      let scroll = document.querySelectorAll('.scroll-item-pos');
      let scrollMenu = document.querySelectorAll('.scroll-menu-item');

      let index = scroll.length;
      while (--index && boxWrap?.current?.scrollTop + 150 < scroll[index]?.offsetTop) {}
      // eslint-disable-next-line
      scrollMenu.forEach((link) =>
        link !== scrollMenu[index] ? link?.classList?.remove('active') : ''
      );
      // eslint-disable-next-line
      scrollMenu[index]?.classList?.add('active');
      // eslint-disable-next-line
      const currentData = scrollMenu[index]?.childNodes;
      // eslint-disable-next-line
      const swiperWrapper = document.getElementsByClassName('swiper-wrapper');

      if (currentData !== undefined) {
        setTimeout(function () {
          swiperWrapper[0].scrollTo({
            left: scrollMenu[index]?.offsetLeft - 0,
            top: 0,
            behavior: 'smooth',
            duration: 1000
          });
          currentData[0].focus();
        }, 400);

        // eslint-disable-next-line
      }
    }

    changeLinkState();
    // eslint-disable-next-line
    boxWrap.current?.addEventListener('scroll', (e) => {
      changeLinkState();
    });
  });

  const changeSection = (categoryId) => {
    let scrollItem = document.querySelectorAll('.scroll-item-pos'),
      height = 0;
    for (let i = 0; i < scrollItem.length; i++) {
      if (scrollItem[i].id === `${categoryId}`) {
        break;
      } else {
        height += scrollItem[i].clientHeight;
      }
    }
    setTimeout(function () {
      boxWrap.current.scrollTo({
        left: 0,
        top: height + 20,
        behavior: 'smooth'
      });
    }, 400);
  };

  const [cursor, setCursor] = useState(0);
  const [currentItemMenuOptions, setCurrentItemMenuOptions] = useState(null);
  const [currentItemOptions, setCurrentOptions] = useState(null);
  const [requiredId, setRequiredId] = useState(null);
  const requiredTrigger = useRef(null);
  const RequiredColor = useRef(null);
  const [requiredItemOptions, setRequiredItemOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);

  const [addsOnModal, setAddsOnModal] = useState(false);

  const [portionSize, setPortionSize] = useState(null);

  let [quantity, setQuantity] = useState(1);
  let [options, setOptions] = useState([]);
  let [comment, setComment] = useState('');
  const [customerInfoModalOpen, setCustomerInfoModal] = useState({
    customer: false,
    table: false,
    orderType: false,
    address: false
  });

  let theme = props.customizer?.customizer?.theme;
  const changeCartVisibility = props.toggleCartVisibility;
  const scrollRef = useRef(null);

  const onWheel = (e) => {
    e.preventDefault();
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;

    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY
    });
  };

  const location = window.navigator.onLine
    ? LocationService.getLocation()
    : JSON.parse(localStorage.getItem('current_location'));
  let { token } = LoginService.getToken();
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('');

  useEffect(() => {
    props.collapseSidebar(true);
    if (connection) {
      props.getLocation(location?.loc_id);
      if (props.location?.is_outlet) {
        //props.getMenu(props.location);
        props.setLocationData(props.location);
        localStorage.setItem(
          'current_location',
          props.location ? JSON.stringify(props.location) : null
        );
      }
    }
    if (location && connection) {
      if (location.is_master_venue) {
        props.getOutletsForPOS(location);
      }
      if (token) {
        props.setTokenData(token);
      }
      if (location) {
        props.setLocationData(location);
        props.getMenu(location);
        props.getFilteredPromotionList(
          location?.is_outlet ? location?.parent_id : location?.loc_id
        );
        props.getCustomers(location?.is_outlet ? location?.parent_id : location?.loc_id);
      }
    } else if (!location && connection) {
      setActive(true);
    }
    if (props.location && window.navigator.onLine) {
      validateLocalData(props.location);
    }
  }, []);
  let data = props.categories;
  let localData = data ? (data.length ? data : []) : [];
  let [searchText, setSearchText] = useState('');
  let [results, setResults] = useState([]);
  let [menuCategory, setMenuCategory] = useState(localData);
  const [landspaceDevice, setLandspaceDevice] = useState(false);

  const updateOrientationStatus = () => {
    const mql = window.matchMedia('(orientation: portrait)');
    if (!mql.matches) {
      setLandspaceDevice(true);
    } else {
      setLandspaceDevice(false);
    }
  };

  let [active, setActive] = useState(false);

  const updateOnlineStatus = () => {
    if (offlineOrderList && offlineOrderList.length > 0) {
      setNotificationOpen(false);
      setNotificationSeverity('warning');
      setNotificationMsg('Your offline orders are in progress!');
    }
    setConnection(true);
  };

  const updateOfflineStatus = () => {
    setConnection(false);
  };

  useEffect(() => {
    if (!location?.is_master_venue) {
      props.getFilteredPromotionList(location?.is_outlet ? location?.parent_id : location?.loc_id);
      props.getStatuses();
    }
    updateOrientationStatus();
    if ('onorientationchange' in window) {
      window.addEventListener(
        'orientationchange',
        function () {
          updateOrientationStatus();
        },
        false
      );
    }
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOfflineStatus);
    return () => {
      window.removeEventListener('offline', updateOfflineStatus);
      window.removeEventListener('online', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    setResults([]);
    setMenuCategory([]);
    CartService.setDeliveryCharge(props.location?.loc_id, 0);
    if (window.navigator.onLine) {
      localStorage.setItem(
        'current_location',
        props.location ? JSON.stringify(props.location) : null
      );
    }
    let categoriesData = menuCategory;
    if (connection) {
      if (props.location && props.token) {
        props.setTokenData(token);
        if (!props.location.is_master_venue) {
          if (!categoriesData) {
            setTimeout(() => setCustomerInfoModal({ ['orderType']: true }), 1000);
            props.getMenu(props.location);
          } else {
            setIsMenuLoading(false);
          }
          // props.setMenuOptionsData(props.location?.loc_id);
          props.getFilteredPromotionList(
            props.location.is_outlet ? props.location?.parent_id : props.location?.loc_id
          );
          //props.getPromotions(props.location?.slug, props.customer);
          props.getCustomers(
            props.location.is_outlet ? props.location?.parent_id : props.location?.loc_id
          );
          props.getAllTablesForPOS(props.location?.loc_id);
        }
      }
      let datas = {
        filter: 'live_orders',
        filterKeyName: 'live_orders',
        offlineOrder: false,
        page: 0,
        sort: 'DESC'
      };
      props.setStateValue(datas);
    } else {
      let datas = {
        filter: 'offline',
        filterKeyName: 'offline',
        offlineOrder: true,
        page: 0,
        sort: 'DESC'
      };
      if (props.location) {
        props.getMenu(props.location);
      }
      setIsMenuLoading(false);
      props.setStateValue(datas);
    }
  }, [props.location?.loc_id]);

  useEffect(() => {
    if (!connection) {
      props.getMenu(props.location);
      setMenuCategory(localData);
    }
  }, [connection]);

  let itemsList = props.menuCategory
    ? props.menuCategory.length
      ? props.menuCategory.length > 0
        ? props.menuCategory
        : localData
      : localData
    : localData;
  // useEffect(() => {
  //   let category = [];
  //   setMenuCategory(localData);
  //   setResults(itemsList);
  //   let menusData = [...itemsList];
  //   for (let i = 0; i < menusData.length; i++) {
  //     let categories = menusData[i];
  //     let menus = categories.Menus;
  //     let datas = search(menus, ['menu_name', 'category_name'], searchText);
  //     categories.Menus = datas;
  //     category.push(categories);
  //   }
  //   if (category.length > 0) {
  //     setResults(category);
  //   }
  //   window.scrollTo(0, 0);
  //   if (searchText === '') {
  //     setMenuCategory(menuCategory);
  //     setResults(menuCategory);
  //   }
  // }, [searchText]);

  const handlerSearch = (newState) => {
    console.log(newState);
  };

  let list = results
    ? results.length
      ? results.length > 0
        ? results
        : itemsList
      : itemsList
    : itemsList;
  let finalItems = list.filter((p) => p.Menus.length > 0);
  const handleAddOnModalOpen = (item) => {
    const menuElem = document.getElementsByClassName('main-menu');
    menuElem[0].style.zIndex = 1;
    const mainMenu = document.getElementsByClassName('main-menu-desktop');
    mainMenu[0].style.zIndex = 1;

    if (window.innerWidth <= 768) {
      setTimeout(() => {
        let payment = document.getElementsByClassName('payment');
        let checkoutMobile = document.getElementsByClassName('checkout-mobile');
        payment[0].style.display = 'none';
        checkoutMobile[0].style.zIndex = 0;
      }, 175);
    }

    setTimeout(() => {
      setAddsOnModal(true);
      let value = [];
      if (item?.MenuOptions?.length > 0) {
        for (let i = 0; i < item?.MenuOptions?.length; i++) {
          let option = item?.MenuOptions[i];
          if (option.required === 1 || (option.min && option.max)) {
            value.push({ id: option.option_id, name: option.Option.option_name });
          }
        }
      }
      setRequiredItemOptions(value);
    }, 150);
  };

  const handleAddOnModalClose = () => {
    setAddsOnModal(false);
    setTimeout(() => {
      setCurrentOptions(null);
    }, 150);
    setComment('');
    setOptions([]);
    setPortionSize(null);
    setRequiredItemOptions([]);
    setQuantity(1);
    setSearchText('');
    setSearchClicked(false);
    const menuElem = document.getElementsByClassName('main-menu');
    menuElem[0].style.zIndex = 1030;
    const mainMenu = document.getElementsByClassName('main-menu-desktop');
    mainMenu[0].style.zIndex = 999;
    if (window.innerWidth <= 768) {
      let payment = document.getElementsByClassName('payment');
      let checkoutMobile = document.getElementsByClassName('checkout-mobile');
      payment[0].style.display = 'flex';
      checkoutMobile[0].style.zIndex = 999;
    }
  };

  const checkIfOptionIsIncluded = (optionId, optionValueId) => {
    const itemExits = options.filter((item) => {
      return item.option_id === optionId && item.option_value_id === optionValueId;
    });
    return itemExits.length > 0 ? true : false;
  };

  const selectItem = (categoryIndex, itemIndex) => {
    setCurrentOptions(finalItems[categoryIndex].Menus[itemIndex]);
    setPortionSize(finalItems[categoryIndex].Menus[itemIndex].Portion?.PortionSizes[0]);
    setCurrentItemMenuOptions(
      finalItems[categoryIndex].Menus[itemIndex].MenuOptions
        ? finalItems[categoryIndex].Menus[itemIndex].MenuOptions
        : []
    );
    setItemOptions(
      finalItems[categoryIndex].Menus[itemIndex].options
        ? finalItems[categoryIndex].Menus[itemIndex].options
        : []
    );
    handleAddOnModalOpen(finalItems[categoryIndex].Menus[itemIndex]);
  };

  const locationName = props.location ? props.location.name : '';

  let change = (name, event) => {
    if (name === 'quantity') {
      if (event.target.value === '0' || event.target.value === '') {
        setQuantity(1);
      } else {
        setQuantity(parseInt(event.target.value));
      }
    }
  };

  let increaseQty = () => {
    quantity = quantity + 1;
    setQuantity(parseInt(quantity));
  };

  let dicreaseQty = () => {
    if (quantity > 1) {
      quantity = quantity - 1;
      setQuantity(parseInt(quantity));
    } else {
      setQuantity(1);
    }
  };

  const onPortionSizeSelect = (selectedPortionSize) => {
    setPortionSize(selectedPortionSize);
  };

  let onRadioSelect = (selectedOption, selectedOptionValue) => {
    document.getElementById('required-error-' + selectedOption.option_id).innerHTML = '';
    const optionIndex = requiredItemOptions.findIndex(
      (option) => option.id === selectedOption.option_id
    );
    if (optionIndex >= 0) {
      // let requiredElementOption = document.getElementsByClassName(
      //   `Required-item-${selectedOption.option_id}`
      // );
      // requiredElementOption[0].style.display = 'none';
      const filteredRequiredOption = requiredItemOptions.filter(
        (option) => option.id !== selectedOption.option_id
      );
      setRequiredItemOptions(filteredRequiredOption);
    }

    let optionsAr = [...options];
    let updatedSelectedOptionValue = selectedOptionValue;
    _.remove(optionsAr, (option) => option.option_id === selectedOption.option_id);
    optionsAr.push(OptionHelper.optionObject(selectedOption, selectedOptionValue));

    setOptions(optionsAr);
  };

  let canAddOption = (selectedOptions, selectedOption) => {
    let max = parseInt(selectedOption.max);
    let currentOptions = _.filter(
      selectedOptions,
      (option) => option.option_id === selectedOption.option_id
    );
    if (currentOptions.length > 0) {
      return isNaN(max) || max === 0 || currentOptions[0].qty < max;
    } else {
      return null;
    }
  };

  let onSelectAdd = (selectedOption, selectedOptionValue, displayType) => {
    document.getElementById('required-error-' + selectedOption.option_id).innerHTML = '';
    if (getOptionItemQty(selectedOptionValue) >= 1) {
      if (OptionHelper.canAddOption(options, selectedOption)) {
        onAddonIncrementDecrement(displayType, selectedOption, selectedOptionValue, true);
      }
    } else {
      let optionsAr = [...options];
      if (OptionHelper.canAddOption(optionsAr, selectedOption)) {
        optionsAr.push(OptionHelper.optionObject(selectedOption, selectedOptionValue));
      }
      setOptions(optionsAr);
    }
  };

  let onAddonIncrementDecrement = (
    displayType = 'radio',
    selectedOption,
    selectedOptionValue,
    increment = true
  ) => {
    /*
     * Title: addon increment
     * 1.  Get option from options
     * 2.  Check if includes/has qty
     * 3.  If true
     * 3.1 qty ++ / qty += 1
     * 4.  If false
     * 4.1 qty = 2
     * 5. Replace new option
     */
    let optionIndex = _.findIndex(options, {
      option_id: selectedOptionValue.option_id,
      option_value_id: selectedOptionValue.option_value_id
    });
    if (optionIndex < 0) {
      Swal.fire(
        'Opps!',
        'You forgot to select addon before performing increment/decrement!',
        'warning'
      );
    } else {
      let updatedOptions = options;
      if (selectedOptionValue.value) updatedOptions[optionIndex].value = selectedOptionValue.value;
      if (increment === true) {
        if (OptionHelper.canAddOption(options, selectedOption)) {
          updatedOptions[optionIndex].qty = Number(updatedOptions[optionIndex].qty) + 1;
        }
      } else {
        if (updatedOptions[optionIndex].qty === 1) {
        } else {
          updatedOptions[optionIndex].qty = Number(updatedOptions[optionIndex].qty) - 1;
        }
      }

      let optionsAr = [...updatedOptions];
      if (displayType !== 'select') {
        _.remove(optionsAr, (option) => option.option_id === selectedOption.option_id);
      }

      if (displayType === 'select') {
        _.remove(
          optionsAr,
          (option) => option.option_value_id === selectedOptionValue.option_value_id
        );
      }

      optionsAr.push(OptionHelper.optionObject(selectedOption, updatedOptions[optionIndex]));

      setOptions(optionsAr);
    }
  };

  const getOptionItemQty = (item) => {
    let optionIndex = _.findIndex(options, {
      option_id: item.option_id,
      option_value_id: item.option_value_id
    });
    if (optionIndex >= 0) {
      return options[optionIndex].qty;
    }
  };

  let onSelectRemove = (selectedOption, selectedOptionValue) => {
    let optionsAr = [...options];
    const alreadySelected = _.findLastIndex(
      optionsAr,
      (option) => option.option_value_id === selectedOptionValue.option_value_id
    );
    if (alreadySelected > -1) _.pullAt(optionsAr, [alreadySelected]);

    setOptions(optionsAr);
  };

  let onCommentChange = (event) => {
    currentItemOptions.comment = event.target.value;
    setComment(event.target.value);
  };

  const originalPrice =
    currentItemOptions &&
    OptionHelper.optionsTotal(
      currentItemOptions,
      options,
      quantity,
      portionSize ? portionSize : currentItemOptions?.Portion?.PortionSizes[0]
    );

  let localOrderList = JSON.parse(localStorage.getItem('offline_order_list'));
  let offlineOrderList = localOrderList ? (localOrderList.length ? localOrderList : []) : [];

  useEffect(() => {
    if (connection) {
      if (offlineOrderList && offlineOrderList.length > 0) {
        setNotificationOpen(true);
        setNotificationSeverity('warning');
        setNotificationMsg('Your offline orders are in progress!');
      }
    }
  }, [connection]);

  useEffect(() => {
    setRequiredId(`Required-${requiredItemOptions[0]?.id}`);
  }, [requiredItemOptions]);

  let handleItemOnClick = (item) => {
    const addedItem = { ...item, portionSize };
    let validationErrors = OptionHelper.validationErrors(options, currentItemMenuOptions);
    if (currentItemOptions.Portion && !portionSize) {
      validationErrors.unshift({
        message: `${currentItemOptions.Portion.label} is a required option`
      });
    }
    if (validationErrors && validationErrors.length > 0) {
      if (document.getElementById('Required-' + validationErrors[0].option_id)) {
        document.getElementById('Required-' + validationErrors[0].option_id).scrollIntoView();
        document.getElementById('required-error-' + validationErrors[0].option_id).innerHTML =
          validationErrors[0].message;
      }
      // Swal.fire('Oops!', validationErrors[0].message, 'warning');
      return;
    } else {
      addItemToCart(addedItem);
    }
    setSearchClicked(false);
    setSearchText('');
    setComment('');
  };

  function addItemToCart(item) {
    let data;
    let discounts = ViewHelper.getDiscountedItemPricePOS(
      item.menu_price,
      item.menu_id,
      item.menu_category_id,
      props.promotions
    );
    const identify = CartService.getUniqueIdentifierForOption(item, options);
    const itemIndex = _.findIndex(props.cart, { cartItem: { identify } });
    if (itemIndex !== -1) {
      const qty = quantity > 0 ? quantity : props.cart[itemIndex].count + 1;
      const newPrice = OptionHelper.optionsTotal(
        item,
        options,
        qty,
        portionSize ? portionSize : item?.Portion?.PortionSizes[0]
      );
      props.updateQuantityFromCart(qty, identify, newPrice);
    } else {
      props.removeItemFromCart(item);
      data = {
        ...item,
        discounts: discounts,
        loc_id: props.location?.loc_id,
        options: options ? (options.length ? options : []) : [],
        comment: comment ? comment : '',
        quantity: quantity,
        original_price: originalPrice,
        portionSize,
        identify: CartService.getUniqueIdentifierForOption(item, options)
      };
      props.addItemToCart(data, quantity);
    }

    handleAddOnModalClose();
  }

  function getCurrent(cart, menu_id) {
    let found = cart.length ? cart.find((item) => item.cartItem.menu_id === menu_id) : null;

    return found;
  }

  let calculatePrice = (carts) => {
    let price = 0;

    if (carts.length) {
      let len = carts.length;
      for (let i = 0; i < len; i++) {
        let calc = carts[i].count * parseFloat(carts[i].cartItem.menu_price);

        price += calc;
      }
      return price;
    } else {
      return 0;
    }
  };

  let getTax = (taxPercent) => {
    taxPercent = parseFloat(taxPercent) || 0.0;
    return calculatePrice(props.cart) * (taxPercent / 100.0);
  };

  let grantTotal = calculatePrice(props.cart) + getTax(props.location?.tax_percent);

  let handleDeleteCartItem = (data) => {
    props.removeItemFromCart(data);

    handleAddOnModalClose();
  };

  let currency_code = localStorage.getItem('currency_code');
  let decimal_place = localStorage.getItem('decimal_place');
  let current_location = localStorage.getItem('current_location');
  let local_storage_location = '';
  if (current_location && current_location !== undefined && current_location !== null) {
    local_storage_location = JSON.parse(current_location);
  }

  const currencyCode = props.currency?.currency_code
    ? props.currency?.currency_code
    : currency_code;
  const decimalPlace = props.finalLooc?.decimal_places
    ? props.finalLooc?.decimal_places
    : decimal_place;

  const queryParam = useLocation().search;
  const newOrder = new URLSearchParams(queryParam).get('newOrder');

  let itemCardVisibility = props.customizer?.customizer?.itemCardVisible;
  const toggleCartStatus = () => {
    let payment_element = document.getElementsByClassName('payment');
    if (itemCardVisibility === false) {
      if (props.cart && props.cart.length > 0) {
        payment_element[0].style.display = 'block';
      }
    } else if (itemCardVisibility === true) {
      payment_element[0].style.display = 'none';
    }
    changeCartVisibility(!itemCardVisibility);
  };

  const itemEdit = (item) => {
    setOptions(item.options);
    setQuantity(item.quantity);
    setCurrentOptions(item);
    setItemOptions(item.options);
    setCurrentItemMenuOptions(item.MenuOptions);
    handleAddOnModalOpen(item);
  };

  const isItemExistsInCart = (identify) => {
    const result = _.find(props.cart, { cartItem: { identify } });
    return result ? true : false;
  };

  const [searchClicked, setSearchClicked] = useState(false);

  let toggleLocation = () => {
    if (connection) {
      setActive(!active);
    } else if (!connection) {
      // Swal.fire('Sorry!', 'You are using offline mode now', 'warning');
      setActive(!active);
    }
  };

  const validateLocalData = async (location) => {
    try {
      let checkforSync = await SyncHelper.getAllSyncStatuses(location);
      if (checkforSync) {
        return;
      }
      // Show the loading alert
      Swal.fire({
        title: 'Fetching data',
        html: 'Please wait...',
        didOpen: () => {
          Swal.showLoading();
        }
      });

      // Determine location IDs for the main and outlet locations
      let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
      let outlet_id = location.is_outlet ? location.loc_id : null;

      // Initialize the main database first
      let result = await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, loc_id);

      // Initialize the outlet database if necessary
      if (outlet_id) {
        result = await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, outlet_id);
      }

      await initMainDB(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX);

      if (result) {
        // After initialization, sync the data in parallel
        await Promise.all([
          SyncHelper.getUsers(loc_id),
          SyncHelper.getUsersLocations(loc_id),
          SyncHelper.checkForOutlets(location),
          SyncHelper.checkForMenus(location),
          SyncHelper.checkForOrderTypes(location),
          SyncHelper.checkForPromotions(location),
          SyncHelper.checkForTables(location)
        ]);

        // Save location after syncing
        await SyncHelper.saveLocation(location);
      }
      // Close the loading alert once everything is done
      Swal.close();
    } catch (e) {
      console.error('Error during sync: ', e.message);

      // Determine location ID
      let loc_id = location.is_outlet ? location.parent_id : location.loc_id;

      try {
        // Delete the database if sync fails
        await deleteDatabase(CONSTANTS.DB_CACHE_KEY_PREFIX, loc_id);
        if (location.is_outlet) {
          await deleteDatabase(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
        }
      } catch (deleteError) {
        console.error('Error deleting database: ', deleteError.message);
      }

      // Close the loading alert and show an error message
      Swal.close();
      Swal.fire('Oops.', 'Something went wrong with data sync. Please try again', 'warning');
    }
  };

  let onSelect = async (location) => {
    localStorage.setItem('isCurrentUser', 'true');
    localStorage.setItem('current_location', location ? JSON.stringify(location) : null);
    LocationService.setLocation(location);
    setResults([]);
    setMenuCategory([]);
    props.setOrderType('');
    props.clearCart();
    props.clearCheckout();
    props.resetCustomerLocation();
    props.resetExistingCustomerDetails();
    props.setOrderAddress('');
    props.resetUserData();
    props.setOrderCustomer('');
    localStorage.removeItem('plugd_menu_cart');
    localStorage.removeItem('promo_id');
    CartService.setDeliveryCharge(location?.loc_id, 0);
    await validateLocalData(location);

    if (connection) {
      props.setLocationData(location);
      props.setTokenData(token);
      props.getLocation(location?.loc_id);
      props.getChildLocations(location?.slug);
      props.getOutletsForPOS(location);
      props.getMenu(location);
      props.getFilteredPromotionList(location.is_outlet ? location?.parent_id : location?.loc_id);
      //props.getPromotions(location?.slug, props.customer);
      //props.setMenuOptionsData(location?.loc_id);
      props.getCustomers(location?.loc_id);
      props.clearCart();
      props.clearCheckout();
      setMenuCategory(
        props.categories
          ? props.categories.length
            ? props.categories
            : localData
            ? localData.length
              ? localData
              : []
            : []
          : []
      );
      //setIsMenuLoading(false);
      setResults(menuCategory);
    } else {
      props.setLocation(location);
      props.setLocationData(location);
      props.getOutletsForPOS(location);
      props.getMenu(location);
      props.getFilteredPromotionList(location.is_outlet ? location?.parent_id : location?.loc_id);
    }
    toggleLocation();
    setTimeout(() => {
      setCustomerInfoModal({ ['orderType']: true });
    }, 1000);
  };

  const getTrimmedItemName = (name) => {
    const trimmedName = name.split(' ');
    let finalName;
    if (trimmedName.length > 3) {
      finalName = trimmedName[0] + ' ' + trimmedName[1] + ' ' + trimmedName[2] + '..';
    } else {
      finalName = name;
    }

    return finalName;
  };

  if (active === true)
    return (
      <div className="content-location-pos">
        <div
          style={{
            background: '#10163a',
            display: 'flex',
            alignItems: 'center',
            height: '63px',
            borderRadius: '0.5rem',
            paddingLeft: '1rem',
            marginBottom: '28px'
          }}>
          <h2
            style={{
              color: '#7367f0',
              fontSize: '1.3rem',
              margin: '0'
            }}>
            Select Location
          </h2>
        </div>

        <SelectLocation
          onBack={toggleLocation}
          setCustomerInfoModal={setCustomerInfoModal}
          openOrderType={newOrder}
          onSelect={onSelect}
        />
      </div>
    );

  if (props.location === '' && !local_storage_location)
    return (
      <div style={{ padding: '20px', height: 'calc(100vh - 13rem)' }}>
        <SelectLocation onBack={toggleLocation} onSelect={onSelect} />
      </div>
    );

  const renderHandler = (location, msg) => {
    return (
      <>
        <LayoutContext.Consumer>
          {(context) => (
            <MenuDesktop
              selectLocationActive={active}
              toggleLocationActive={toggleLocation}
              onLocationSelect={onSelect}
              toggleMenu={toggleMenuCollapsed}
              searchClicked={searchClicked}
              setSearchClicked={setSearchClicked}
              setSearchText={setSearchText}
              searchText={searchText}
              customerInfoModalOpen={customerInfoModalOpen}
              setCustomerInfoModal={setCustomerInfoModal}
              openOrderType={newOrder}
              module={props.module}
              toggleSidebarVisibility={context.sidebarVisibility}
            />
          )}
        </LayoutContext.Consumer>

        <div className="p-2 location">
          <div className="mb-50">
            <ErrorHandler message={msg}></ErrorHandler>
          </div>
        </div>
      </>
    );
  };
  if (props.location) {
    if (props.location.modules) {
      let modules = JSON.parse(props.location.modules);
      if (!ViewHelper.checkValidModule(modules, props.module)) {
        return renderHandler(
          props.location,
          ViewHelper.setErrorMessageForModule(props.location.name, props.module)
        );
      }
    } else {
      return renderHandler(
        props.location,
        ViewHelper.setErrorMessageForModule(props.location.name, props.module)
      );
    }
  }
  return (
    <>
      {props.loading ? (
        <div style={{ marginTop: '-64px' }}>
          <PosSkeleton />
        </div>
      ) : (
        <div style={{ background: `${theme === 'dark' ? '#10163A' : '#E9E9E9'}` }} id="Full-POS">
          {!props.location?.is_master_venue && isMenuLoading ? (
            <div style={{ marginTop: '-64px' }}>
              <PosSkeleton />
            </div>
          ) : (
            <>
              <LayoutContext.Consumer>
                {(context) => (
                  <MenuDesktop
                    page="pos"
                    selectLocationActive={active}
                    toggleLocationActive={toggleLocation}
                    onLocationSelect={onSelect}
                    toggleMenu={toggleMenuCollapsed}
                    searchClicked={searchClicked}
                    setSearchClicked={setSearchClicked}
                    handlerSearch={(text) => setSearchText(text)}
                    searchText={searchText}
                    customerInfoModalOpen={customerInfoModalOpen}
                    setCustomerInfoModal={setCustomerInfoModal}
                    openOrderType={newOrder}
                    module={props.module}
                    toggleSidebarVisibility={context.sidebarVisibility}
                  />
                )}
              </LayoutContext.Consumer>
              <StyledPos
                theme={theme}
                cartVisibility={itemCardVisibility}
                sidebarCollapsed={props.customizer?.customizer.sidebarCollapsed}
                categoryLength={finalItems ? finalItems.length : 0}>
                {/*<SideBar/>*/}
                <Container>
                  <Row>
                    <Col md={8}>
                      <div className="item-list-wrapper">
                        {currentItemOptions !== null && (
                          <StyledItemModal
                            theme={theme}
                            visible={addsOnModal}
                            onClose={handleAddOnModalClose}
                            isLandspace={landspaceDevice}
                            enterAnimation={'slideUp'}
                            leaveAnimation={'slideUp'}
                            duration={350}>
                            <ItemDetailData
                              handleAddOnModalClose={handleAddOnModalClose}
                              connection={connection}
                              currentItemOptions={currentItemOptions}
                              currentItemMenuOptions={currentItemMenuOptions}
                              dicreaseQty={dicreaseQty}
                              increaseQty={increaseQty}
                              quantity={quantity}
                              change={change}
                              isItemExistsInCart={isItemExistsInCart}
                              handleDeleteCartItem={handleDeleteCartItem}
                              RequiredColor={RequiredColor}
                              checkIfOptionIsIncluded={checkIfOptionIsIncluded}
                              onPortionSizeSelect={onPortionSizeSelect}
                              onRadioSelect={onRadioSelect}
                              currency={props.currency}
                              finalLooc={props.finalLooc}
                              onSelectAdd={onSelectAdd}
                              onSelectRemove={onSelectRemove}
                              getOptionItemQty={getOptionItemQty}
                              onAddonIncrementDecrement={onAddonIncrementDecrement}
                              onCommentChange={onCommentChange}
                              handleItemOnClick={handleItemOnClick}
                              originalPrice={originalPrice}
                              requiredId={requiredId}
                              requiredTrigger={requiredTrigger}
                            />
                          </StyledItemModal>
                        )}

                        {currencyCode !== undefined && (
                          <div className="items" ref={boxWrap}>
                            {!locationType.isMasterLoc && finalItems
                              ? finalItems.length > 0 &&
                                finalItems.map((category, index) => {
                                  const status =
                                    local_storage_location &&
                                    local_storage_location.categoryStatuses &&
                                    local_storage_location.categoryStatuses[category.category_id]
                                      ? local_storage_location.categoryStatuses[
                                          category.category_id
                                        ].status_id
                                      : category.status;
                                  if (status === 0) {
                                    return null;
                                  }
                                  return (
                                    <ItemList
                                      cart={props.cart}
                                      promotions={props.promotions}
                                      customer={props.customer}
                                      category={category}
                                      orderType={props.orderType}
                                      index={index}
                                      searchText={searchText}
                                      cursor={cursor}
                                      location={local_storage_location}
                                      selectItem={selectItem}
                                      currencyCode={currencyCode}
                                      decimalPlace={decimalPlace}
                                    />
                                  );
                                })
                              : null}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col md={4}>
                      <ItemCard
                        editItem={itemEdit}
                        className={'item-card'}
                        customerInfoModalOpen={customerInfoModalOpen}
                        setCustomerInfoModal={setCustomerInfoModal}
                      />
                    </Col>
                  </Row>
                  <Row style={{ position: 'relative' }}>
                    <Col md={12} className="m-0">
                      <CategoryList
                        theme={theme}
                        categories={itemsList}
                        changeSection={changeSection}
                        outlet={props.location ? props.location : local_storage_location}
                      />
                    </Col>
                  </Row>
                </Container>
                {props.cart.length > 0 && (
                  <div className="checkout-mobile">
                    <button onClick={toggleCartStatus}>Cart</button>
                  </div>
                )}
              </StyledPos>
            </>
          )}
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    locationType: state.locations && state.locations.locationType,
    loading: state.posReducer ? state.posReducer.loading : false,
    location: state.locations
      ? state.locations?.location?.data
        ? state.locations?.location?.data
        : state.posReducer
        ? state.posReducer.location
        : null
      : null,
    currency: state.locations ? state.locations?.location?.data?.Currency : null,
    finalLooc: state.locations ? state.locations?.location?.data : null,
    token: state.posReducer ? state.posReducer.token : null,
    categoryMenu: state.categories ? state.categories?.categoryMenu?.data : [],
    categories: state.categories ? state.categories?.categoryMenu?.data : [],
    categoriesLoading: state.categories ? state.categories?.categoryMenu?.loading : false,
    promotions: state.posReducer ? state.posReducer?.promotionsData : [],
    cart: state.cartReducer ? state.cartReducer.cart : [],
    address: state.cartReducer ? state.cartReducer.address : null,
    customizer: state.customizer ? state.customizer : null,
    allcustomers: state.users.allCustomers.data,
    tables: state.tables.alltables.data,
    customer: state.cartReducer ? state.cartReducer.customer : [],
    orderType: state.cartReducer
      ? state.cartReducer.orderType
        ? state.cartReducer.orderType
        : null
      : null
  };
}

export default connect(mapStateToProps, {
  setLocationData,
  setTokenData,
  setMenuOptionsData,
  getMenuGroupedByCategory,
  getMenuByCategory,
  getMenu,
  resetCustomerLocation,
  resetExistingCustomerDetails,
  getLocation,
  setLocation,
  getChildLocations,
  getOutletsForPOS,
  registerUser,
  addItemToCart,
  updateQuantityFromCart,
  removeItemFromCart,
  toggleCartVisibility,
  getCustomers,
  getAllTablesForPOS,
  clearCart,
  getPromotions,
  getFilteredPromotionList,
  clearCheckout,
  getStatuses,
  setStateValue,
  collapseSidebar,
  setOrderType,
  setOrderAddress,
  setOrderCustomer,
  resetUserData
})(HomePos);
