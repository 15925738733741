import CONSTANTS from '../constants';
import { customFetch as fetch } from '../helpers/FetchHelper';
import LoginService from './LoginService';

export default {
  getOrderTypes,
  updateOrderType,
  updateOrderTypes,
  getOrderTypesPOS
};

async function getOrderTypes(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDER_TYPES.DEFAULT(locationId)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) {
    localStorage.setItem('orderTypeList', JSON.stringify(response));
    // setOrderTypeList(response);
    return response;
  }

  throw new Error('Could not fetch order types');
}

async function getOrderTypesPOS(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDER_TYPES.DEFAULT_POS(locationId)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) {
    localStorage.setItem('orderTypeList', JSON.stringify(response));
    // setOrderTypeList(response);
    return response;
  }

  throw new Error('Could not fetch order types');
}

async function updateOrderTypes(loc_id, orderTypes) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDER_TYPES.DEFAULT(loc_id)}`, {
    method: 'POST',
    body: JSON.stringify(orderTypes),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;

  throw new Error('Could not update order types');
}

async function updateOrderType(orderType) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDER_TYPES.DEFAULT(orderType.location_id)}/${
      orderType.id
    }`,
    {
      method: 'PUT',
      body: JSON.stringify(orderType),
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;

  throw new Error('Could not update order type');
}
