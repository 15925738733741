import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';
import parsePhoneNumber from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { search } from 'ss-search';
import searchIconGray from '../../../assets/img/static/search-icon-gray.svg';
import CONSTANTS from '../../../constants';
import { getMenuGroupedByCategory } from '../../../redux/actions/categories';
import { toggleCartVisibility } from '../../../redux/actions/customizer';
import { getLocation } from '../../../redux/actions/locations';
import { getDummyItem } from '../../../redux/actions/menus';
import { getAll } from '../../../redux/actions/options';
import { getPromotions } from '../../../redux/actions/promotions';
import {
  getCustomers,
  getExistingCustomerLocations,
  resetExistingCustomerDetails,
  resetExistingCustomerLocations,
  setExistingCustomerDetails,
  setExistingCustomerLocations,
  showAddLocationButton
} from '../../../redux/actions/users';
import {
  clearCart,
  clearCheckout,
  setOrderAddress,
  setOrderCustomer,
  setOrderTableNo,
  setOrderType
} from '../redux/actions/cart';
import {
  setCategoriesData,
  setLocationData,
  setMenuOptionsData,
  setOrderTypeList
} from '../redux/actions/pos/index';
import {
  registerUser,
  resetUserData,
  storeUserData,
  storeUserPhoneNumber
} from '../redux/actions/users';
import { getStoreData } from '../utils/DBHelper';
import CustomerInfoCard from './CustomerInfoCard';
import CustomerLocation from './CustomerLocation';

const InputNumber = (props) => {
  const {
    searchNumber,
    registerUser,
    existingCustomerDetails,
    resetExistingCustomerDetails,
    existingCustomerLocations,
    userCreated,
    nextStep,
    handleClose,
    changeCustomerClick,
    showAddLocationButton,
    selectedOutlet,
    lastLocation,
    setLastLocation
  } = props;

  const [customer, setCustomer] = useState({
    phone_number: ''
  });
  const [customerInformation, setCustomerInformation] = useState();
  const [onChangeClick, setOnChangeClick] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const connection = window.navigator.onLine;
  useEffect(() => {
    async function getUsers() {
      let location = props._location ? props._location : props.location;
      let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
      let localUsers = await getStoreData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
        CONSTANTS.INDEXDB.STORE.USERS
      );
      if (localUsers.length > 0) {
        let usersData = localUsers.filter((row) => row.id === 1);
        let users = usersData[0].data;
        setUsersList(users);
      }
    }
    setTimeout(() => {
      getUsers();
    }, 2000);
  }, []);
  const searchCustomer = (event) => {
    let keys = ['phone_number'];
    if (event.keyCode === 11) {
      const result = search(usersList, keys, customer.phone_number);
      if (result.length) {
        setCustomerInformation(result[0]);
      } else {
        setCustomerInformation(null);
      }
    }
  };

  useEffect(() => {
    showAddLocationButton(false);
  }, []);

  const changeCustomer = async (event) => {
    if (event.target.value.length < 11) {
      resetExistingCustomerDetails(null);
    }
    if (event.target?.value?.length >= 11) {
      setCustomer({ phone_number: event.target.value });
      let keys = ['phone_number'];
      let phoneNumber = parsePhoneNumber(event?.target?.value, 'PK');
      let number =
        phoneNumber && phoneNumber.formatNational().replaceAll('-', '').replaceAll(' ', '');
      let convertedNumber = number.toString();

      if (convertedNumber[0] === '0') {
        convertedNumber = Number(convertedNumber.substring(1));
      }
      const result = search(
        usersList,
        keys,
        convertedNumber ? convertedNumber : event.target.value
      );
      if (result.length) {
        props.setExistingCustomerDetails(result[0]);
        //props.getExistingCustomerLocations(selectedOutlet.loc_id, result[0]);
        let loc_id = selectedOutlet.is_outlet ? selectedOutlet.parent_id : selectedOutlet.loc_id;
        props.setExistingCustomerLocations(loc_id, result[0]);
      } else {
        setCustomerInformation(null);
      }
      // setCustomer({ phone_number: event.target.value });
    } else {
      setCustomerInformation(null);
      setCustomer({ phone_number: event.target.value });
    }
  };

  const userRegistration = async () => {
    if (customer.phone_number === '') {
      alert('Please fill the phone number first');
    } else {
      if (connection) {
        let phoneNumber = parsePhoneNumber(customer?.phone_number, 'PK');
        props.storeUserPhoneNumber(customer?.phone_number);
        customer.phone_number = phoneNumber.number;
        const response = await registerUser(customer);
        if (response.status === 200) {
          //   let customerNumber = Number(customer.phone_number.substring(1));
          //   props.getCustomers(customerNumber);
          //   props.handleCustomerInfoOpen("customer");
        }
      } else {
        let data = {
          phone_number: customer.phone_number
        };
        props.storeUserData(data);
        props.storeUserPhoneNumber(customer?.phone_number);
        // props.handleCustomerInfoOpen("customer");
      }
    }
  };

  const searchInput = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }} className="search-input">
        <img src={searchIconGray} alt="Search Icon" />
        &nbsp;&nbsp;
        <input
          autoComplete={'off'}
          type={'number'}
          name="phone_number"
          onKeyDown={searchCustomer}
          onChange={changeCustomer}
          value={customer.phone_number}
          className="search-input"
          placeholder={'Search customer phone'}
        />
        {customer.phone_number.length >= 11 && !existingCustomerDetails && (
          <button className="add-user-btn" onClick={userRegistration}>
            <span style={{ margin: '5px' }}>
              <FontAwesomeIcon icon={faUserPlus} size={'sm'} color="#10163a" />
            </span>
          </button>
        )}
        {customer.phone_number.length >= 1 &&
          customer.phone_number.length < 11 &&
          !customerInformation && (
            <div className={searchNumber ? 'circular-progress-delivery-flow' : 'circular-progress'}>
              <CircularProgress size={20} />
            </div>
          )}
      </div>
    );
  };
  return (
    <>
      <p className={'customer-modal__form__input_group__label'}>Search Number</p>
      {searchInput()}
      {userCreated.first_name ? (
        <p style={{ padding: '20px 0 5px 0' }}>Customer Information</p>
      ) : (
        searchNumber &&
        existingCustomerDetails && <p style={{ padding: '20px 0 5px 0' }}>Customer Information</p>
      )}
      {userCreated.first_name ? (
        <CustomerInfoCard />
      ) : (
        searchNumber && existingCustomerDetails && <CustomerInfoCard />
      )}
      {searchNumber &&
        existingCustomerDetails &&
        existingCustomerLocations.length > 0 &&
        props.orderType.order_type === 'delivery' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
            {onChangeClick ? <p>Saved Locations</p> : <p>Selected Location</p>}
            {onChangeClick ? (
              <p
                onClick={() => {
                  showAddLocationButton(false);
                  setOnChangeClick(false);
                }}>
                Back
              </p>
            ) : (
              <p
                onClick={() => {
                  showAddLocationButton(true);
                  setOnChangeClick(true);
                }}>
                Change
              </p>
            )}
          </div>
        )}
      {searchNumber &&
        existingCustomerDetails &&
        existingCustomerLocations.length > 0 &&
        props.orderType.order_type === 'delivery' && (
          <CustomerLocation
            nextStep={nextStep}
            handleClose={handleClose}
            changeCustomerClick={changeCustomerClick}
            onChangeClick={onChangeClick}
            setOnChangeClick={setOnChangeClick}
            lastLocation={lastLocation}
            setLastLocation={setLastLocation}
          />
        )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    userCreated: state.userReducer ? state.userReducer.user : null,
    token: state.posReducer ? state.posReducer.token : null,
    customizer: state.customizer ? state.customizer : null,
    customer: state.cartReducer ? state.cartReducer.customer : null,
    address: state.cartReducer ? state.cartReducer.address : null,
    location: state.posReducer ? state.posReducer.location : null,
    allCustomers: state.users.allCustomers.data,
    orderInfo: state.cartReducer ? state.cartReducer : null,
    _location: state.locations
      ? state.locations?.location?.data
        ? state.locations?.location?.data
        : state.posReducer
        ? state.posReducer.location
        : null
      : null,
    _outlets: state.locations ? state.locations?.outlet?.data : null,
    existingCustomerDetails: state.users.existingCustomerDetails,
    existingCustomerLocations: state.users.existingCustomerLocations,
    orderType: state.cartReducer ? state.cartReducer.orderType : ''
  };
}

export default connect(mapStateToProps, {
  setLocationData,
  getLocation,
  getAll,
  getDummyItem,
  getMenuGroupedByCategory,
  setMenuOptionsData,
  setCategoriesData,
  getPromotions,
  getCustomers,
  setExistingCustomerDetails,
  clearCart,
  clearCheckout,
  registerUser,
  storeUserData,
  setOrderType,
  setOrderAddress,
  setOrderTableNo,
  setOrderCustomer,
  toggleCartVisibility,
  setOrderTypeList,
  storeUserPhoneNumber,
  resetExistingCustomerDetails,
  getExistingCustomerLocations,
  setExistingCustomerLocations,
  resetExistingCustomerLocations,
  resetUserData,
  showAddLocationButton
})(InputNumber);
