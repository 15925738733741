import CONSTANTS from '../../../constants';
import { addData, getStoreData, initRestaurantDB } from '../../../pos/src/utils/DBHelper';
import LocationService from '../../../services/LocationService';
import OrderService from '../../../services/OrderService';

export function getLocation(locationId) {
  return async (dispatch) => {
    try {
      if (locationId === null || locationId === undefined) {
        dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      } else {
        dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
        let location = await LocationService.getLocationFromServer(locationId);
        dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function setLocation(location) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: location, loading: true });
    } catch (e) {
      alert('Location not found');
    }
  };
}

export function getAllLocations() {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.getAllLocations();
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function getOutletsFor(locationId) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET_OUTLETS, outlets: null, loading: true });
      let outlets = await LocationService.getOutletsForLocation(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET_OUTLETS, outlets, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function getDepartmentsFor(locationId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.GET_DEPARTMENTS,
        departments: null,
        loading: true
      });
      let departments = await LocationService.getDepartmentsForLocation(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET_DEPARTMENTS, departments, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.GET_DEPARTMENTS,
        departments: null,
        loading: false
      });
    }
  };
}

export function getDeliveriesFor(locationId, type = null) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERIES,
        deliveries: null,
        loading: true
      });
      let deliveries = await LocationService.getDeliveriesForLocation(locationId, type);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERIES, deliveries, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERIES,
        deliveries: null,
        loading: false
      });
    }
  };
}

export function updateLocation(locationData) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.updateLocationFormData(locationData);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function updateTimings(loc_id, options) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.updateLocationTimings(loc_id, options);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function createLocation(locationData) {
  return async (dispatch) => {
    try {
      let location = await LocationService.createLocation(locationData);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: location, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function initializeNewLocation() {
  return async (dispatch) => {
    dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: {}, loading: false });
    dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.CHILDREN, childLocations: {} });
    dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.OUTLETS, outlets: {}, parentLocation: '' });
  };
}

export function getDeliveryProcessorInfo(locationId, orderId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERY_PROCESSORS,
        processors: null,
        loading: true
      });
      let processors = await OrderService.getDeliveryProcessorInfo(locationId, orderId);
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERY_PROCESSORS,
        processors,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERY_PROCESSORS,
        processors: null,
        loading: false
      });
    }
  };
}

export function getChildLocations(parentId, currentLocation) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.CHILDREN, childLocations: null, loading: true });
      let childLocations = await LocationService.getChildLocations(parentId, currentLocation);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.CHILDREN, childLocations, loading: false });
    } catch (error) {
      console.log('Error: ', error);
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.CHILDREN,
        childLocations: null,
        loading: false
      });
    }
  };
}

export function getOutlets(parentLocation) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.OUTLETS,
        outlet: null,
        parentLocation,
        loading: true
      });
      const outlet = await LocationService.getOutlets(parentLocation.loc_id);
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.OUTLETS,
        outlet,
        parentLocation,
        loading: false
      });
    } catch (error) {
      console.log('Error: ', error);
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.OUTLETS,
        outlet: null,
        parentLocation,
        loading: false
      });
    }
  };
}

export function getOutletsForPOS(parentLocation) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.OUTLETS,
        outlet: null,
        parentLocation,
        loading: true
      });
      await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, parentLocation.loc_id);
      let localOutlets = await getStoreData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + parentLocation.loc_id,
        CONSTANTS.INDEXDB.STORE.OUTLETS
      );
      if (localOutlets.length > 0) {
        let outlet = localOutlets[1].data;
        dispatch({
          type: CONSTANTS.ACTIONS.LOCATIONS.OUTLETS,
          outlet,
          parentLocation,
          loading: false
        });
      } else {
        const outlet = await LocationService.getOutlets(parentLocation.slug);
        await addData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + parentLocation.loc_id,
          CONSTANTS.INDEXDB.STORE.OUTLETS,
          {
            id: 1,
            data: outlet
          }
        );
        dispatch({
          type: CONSTANTS.ACTIONS.LOCATIONS.OUTLETS,
          outlet,
          parentLocation,
          loading: false
        });
      }
    } catch (error) {
      console.log('Error: ', error);
      dispatch({
        type: CONSTANTS.ACTIONS.LOCATIONS.OUTLETS,
        outlet: null,
        parentLocation,
        loading: false
      });
    }
  };
}

export function setIsMasterLocation(isMasterLoc) {
  return {
    type: CONSTANTS.ACTIONS.LOCATIONS.IS_MASTER_LOCATION,
    isMasterLoc: isMasterLoc
  };
}

export function setSelectedLocation(selectedLocation) {
  return {
    type: CONSTANTS.ACTIONS.LOCATIONS.SET_SELECTED_LOCATION,
    selectedLocation: selectedLocation
  };
}
