import CONSTANTS from '../../../constants';

export function allLoyalties(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.LOYALTIES.ALL_LOYALTIES) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}

export function allFilteredLoyalties(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.LOYALTIES.ALL_FILTERED_LOYALTIES) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}

export function loyalty(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.LOYALTIES.GET_LOYALTY) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}
