import _, { isInteger } from 'lodash';
import moment from 'moment';
import CONSTANTS from '../../../constants';
import { addData, deleteData, getStoreData, updateData } from '../../../pos/src/utils/DBHelper';
import LocationService from '../../../services/LocationService';
import LoginService from '../../../services/LoginService';
import OrderTypeService from '../../../services/OrderTypeService';
import PromotionService from '../../../services/PromotionService';
import TableService from '../../../services/TableService';
import UserService from '../../../services/UserService';
import { initRestaurantDB } from './DBHelper';
import ItemService from './ItemService';

export default {
  checkForMenus,
  checkForOutlets,
  checkForOrderTypes,
  saveLocation,
  getUsers,
  getUsersLocations,
  getLocalLocations,
  getMenuBySyncTime,
  getMenuSyncTime,
  checkForPromotions,
  checkForTables,
  getPromotionsBySyncTime,
  getTableBySyncTime,
  getLocalDBUsers,
  getLocalMenus,
  saveUserOffline,
  saveUsersLocationOffline,
  syncOfflineUsersToOnine,
  getSyncableUsersData,
  saveLiveOrders,
  getSavedLiveOrders,
  getSavedLiveOrder,
  updateStatuses,
  updateStatusesForOffline,
  getAllSyncStatuses,
  getOrderTypesBySyncTime
};
const date = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);

async function getAllSyncStatuses(location) {
  if (!location) {
    throw new Error('Location is undefined');
  }

  const loc_id = location.is_outlet ? location.parent_id : location.loc_id;

  if (!loc_id) {
    throw new Error('loc_id is undefined');
  }

  try {
    await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, loc_id);

    const storeKeys = [
      CONSTANTS.INDEXDB.STORE.USERS,
      CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
      CONSTANTS.INDEXDB.STORE.OUTLETS,
      CONSTANTS.INDEXDB.STORE.MENUS,
      CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      CONSTANTS.INDEXDB.STORE.TABLES
    ];

    const dataPromises = storeKeys.map((storeKey) =>
      getStoreData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, storeKey)
    );

    const results = await Promise.all(dataPromises);
    const isLoadedFlags = results.map((data) => data[2]?.isLoaded ?? false);

    return isLoadedFlags.every((flag) => flag === 1);
  } catch (error) {
    console.error('Error fetching synchronization statuses:', error);
    return false;
  }
}

async function saveUserOffline(user, location) {
  user.is_sync = 1;
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  let localUsers = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS
  );
  if (localUsers.length > 0) {
    let users = localUsers[1].data;
    users.push(user);
    await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 0);
    await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 1);
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
      id: 0,
      lastSync: date
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
      id: 1,
      data: users
    });
    return users;
  }
  return false;
}

async function saveUsersLocationOffline(userLocation, location) {
  userLocation.is_sync = 1;
  let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, loc_id);
  let localUsersLocation = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS
  );
  if (localUsersLocation.length > 0) {
    let usersLocations = localUsersLocation[1].data;
    usersLocations.push(userLocation);
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
      CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
      1
    );
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS, {
      id: 1,
      data: usersLocations
    });
    return usersLocations;
  }
  return [];
}

async function saveLiveOrders(orders) {
  // Validate orders object and rows property
  if (!orders || !Array.isArray(orders.rows) || orders.rows.length === 0) {
    console.error('Invalid or empty orders');
    return { success: false, error: 'Invalid or empty orders' }; // Return error object
  }

  try {
    // Delete existing data
    await deleteData(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX, CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS, 0);

    // Add new data
    await addData(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX, CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS, {
      id: 0,
      data: orders
    });

    return { success: true };
  } catch (error) {
    // Log error and return failure object
    console.error('Failed to save live orders', error);
    return { success: false, error: error.message };
  }
}

async function getSavedLiveOrders() {
  let localLiveOrders = await getStoreData(
    CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX,
    CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS
  );
  if (localLiveOrders.length > 0) {
    return localLiveOrders[0].data;
  }
  return [];
}

async function getSavedLiveOrder(order_id, loc_id) {
  let localLiveOrders = await getStoreData(
    CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX,
    CONSTANTS.INDEXDB.PLUGD.LIVE_ORDERS
  );
  if (localLiveOrders.length > 0) {
    let orders = localLiveOrders[0].data.rows;
    let order = orders.filter((row) => row.order_id === order_id && row.location_id === loc_id);
    if (order.length > 0) {
      return order[0];
    }
  }
  return null;
}

async function syncOfflineUsersToOnine(location) {
  let users = await this.getSyncableUsersData(location);
  if (users.length > 0) {
    let response = await LoginService.syncUsersList(users);
    if (response) {
      let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
      let customers = await UserService.getCustomers(loc_id);
      if (customers) {
        await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 0);
        await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 1);
        await deleteData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, 2);
        await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
          id: 0,
          lastSync: date
        });
        await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
          id: 1,
          data: customers
        });
        await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
          id: 2,
          isLoaded: 1
        });
      }

      let customers_locations = await UserService.getCustomersLocations(loc_id);
      if (customers_locations) {
        await deleteData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
          0
        );
        await deleteData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
          1
        );
        await addData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
          {
            id: 0,
            lastSync: date
          }
        );
        await addData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
          {
            id: 1,
            data: customers_locations
          }
        );
      }
    }
  }

  return [];
}

async function getSyncableUsersData(location) {
  if (!location) return [];
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  let localUsers = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS
  );
  let localUsersLocations = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS
  );
  let offlineUsers = [];
  if (localUsers.length > 0) {
    let usersData = localUsers.filter((row) => row.id === 1);
    let users = usersData[0].data;
    if (users.length > 0) {
      for (let i = 0; i < users.length; i++) {
        if (!isInteger(users[i].user_id) && users[i].is_sync === 1) {
          if (localUsersLocations.length > 0) {
            let usersLocations = localUsersLocations[1].data;
            if (usersLocations.length > 0) {
              let locations = usersLocations.filter((loc) => loc.user_id === users[i].user_id);
              users[i].usersLocations = locations.length > 0 ? locations : [];
              offlineUsers.push(users[i]);
            }
          }
        } else {
          if (localUsersLocations.length > 0) {
            let usersLocations = localUsersLocations[1].data;
            if (usersLocations.length > 0) {
              let locations = usersLocations.filter(
                (loc) => loc.user_id === users[i].user_id && loc.is_sync === 1
              );
              if (locations.length > 0) {
                users[i].loc_id = loc_id;
                users[i].usersLocations = locations;
                offlineUsers.push(users[i]);
              }
            }
          }
        }
      }
    }
  }
  return offlineUsers;
}

async function getLocalDBUsers(location) {
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  let localUsers = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS
  );
  if (localUsers.length > 0) {
    return localUsers[1].data;
  } else {
    let customers = await UserService.getCustomers(loc_id);

    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
      id: 0,
      lastSync: date
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
      id: 1,
      data: customers
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
      id: 2,
      isLoaded: 1
    });

    return customers;
  }

  return [];
}

async function getLocalLocations() {
  let localLocations = await getStoreData(
    CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX,
    CONSTANTS.INDEXDB.PLUGD.LOCATIONS
  );
  if (localLocations.length > 0) {
    return localLocations[0].data;
  }

  return [];
}

async function getPromotionsBySyncTime(location) {
  const promotions = await PromotionService.getFilteredPromotionList(location.loc_id);
  if (promotions) {
    let newdate = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      0
    );
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      1
    );
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      {
        id: 0,
        lastSync: newdate
      }
    );
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      {
        id: 1,
        data: promotions
      }
    );
  }
}

async function getTableBySyncTime(location) {
  let tables = await TableService.getAllTables(location.loc_id);
  if (tables) {
    let newdate = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.TABLES,
      0
    );
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.TABLES,
      1
    );
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.TABLES, {
      id: 0,
      lastSync: newdate
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.TABLES, {
      id: 1,
      data: tables
    });
  }
}

async function getOrderTypesBySyncTime(location) {
  let orderTypes = await OrderTypeService.getOrderTypesPOS(location.loc_id);
  if (orderTypes) {
    let newdate = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
      0
    );
    await deleteData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
      1
    );
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
      {
        id: 0,
        lastSync: newdate
      }
    );
    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
      {
        id: 1,
        data: orderTypes
      }
    );
  }
}

async function getMenuBySyncTime(location) {
  let menuSynctime = await getMenuSyncTime(location.loc_id);
  menuSynctime = moment(menuSynctime).subtract(2, 'minutes').format(CONSTANTS.DATE_TIMES.FORMAT.DB);

  let menu = await ItemService.getMenuSyncPOS(location.slug, menuSynctime);
  if (menu.length === 0) {
    return false;
  }
  let categoriesData = await ItemService.getCategoryItem(JSON.stringify(menu), location.slug);
  if (categoriesData) {
    let localMenus = await getLocalMenus(location);
    for (let categoryItem of categoriesData) {
      let index = _.findIndex(localMenus, { category_id: categoryItem.category_id });
      if (index !== -1) {
        if (menu.data.timeResult === null) {
          localMenus.splice(index, 1);
        } else {
          localMenus.splice(index, 1, categoryItem);
        }
      } else {
        localMenus.push(categoryItem);
      }
    }
    localMenus = localMenus.sort((a, b) => a.priority - b.priority);
    let newdate = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
    await updateData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.MENUS,
      0,
      {
        id: 0,
        lastSync: newdate
      }
    );
    await updateData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.MENUS,
      1,
      {
        id: 1,
        data: localMenus
      }
    );
    return localMenus;
  }
  return false;
}

async function getMenuSyncTime(location_id) {
  let localMenu = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + location_id,
    CONSTANTS.INDEXDB.STORE.MENUS
  );
  if (localMenu.length > 0) {
    return localMenu[0].lastSync;
  }
  return null;
}
async function getLocalMenus(location) {
  await initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, location.loc_id);
  let localMenu = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
    CONSTANTS.INDEXDB.STORE.MENUS
  );
  if (localMenu.length > 0) {
    return localMenu[1].data;
  } else {
    let menu = await ItemService.getMenu(location.slug);
    let categoriesData = await ItemService.getCategoryItem(JSON.stringify(menu), location.slug);
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.MENUS, {
      id: 0,
      lastSync: date
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.MENUS, {
      id: 1,
      data: categoriesData
    });
    return categoriesData;
  }
  return [];
}
async function saveLocation(location) {
  let localLocations = await getStoreData(
    CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX,
    CONSTANTS.INDEXDB.PLUGD.LOCATIONS
  );
  if (localLocations.length > 0) {
    localLocations = localLocations[0].data;
    let filterLocation = localLocations.filter((row) => row.loc_id === location.loc_id);
    if (filterLocation.length === 0) {
      localLocations.push(location);
      await updateData(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX, CONSTANTS.INDEXDB.PLUGD.LOCATIONS, 0, {
        id: 0,
        data: localLocations
      });
    }
  }
  if (localLocations.length === 0) {
    await addData(CONSTANTS.DB_MAIN_CACHE_KEY_PREFIX, CONSTANTS.INDEXDB.PLUGD.LOCATIONS, {
      id: 0,
      data: [location]
    });

    return true;
  }
  return false;
}

async function getUsers(loc_id) {
  let localUsers = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
    CONSTANTS.INDEXDB.STORE.USERS
  );
  if (localUsers.length === 0 || localUsers.length < 2) {
    let customers;
    try {
      customers = await UserService.getCustomers(loc_id);
    } catch (error) {
      console.error(`Error fetching customers: ${error}`);
      return false;
    }

    if (!customers || customers.length === 0) {
      console.warn('No customers found for location:', loc_id);
      return false;
    }

    let promises = [];

    promises.push(
      addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
        id: 0,
        lastSync: date
      }),
      addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
        id: 1,
        data: customers
      }),
      addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id, CONSTANTS.INDEXDB.STORE.USERS, {
        id: 2,
        isLoaded: 1
      })
    );

    await Promise.allSettled(promises); // Handle all promises

    return customers; // Return the newly fetched customers
  }

  return localUsers; // Return the cached local users if they exist
}

async function getUsersLocations(loc_id) {
  if (!loc_id) {
    console.error('Invalid location ID');
    return false;
  }

  try {
    let localUsers = await getStoreData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
      CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS
    );
    // Check if the data is valid and if we need to sync
    if (localUsers.length === 0 || localUsers.length < 2) {
      let customers_locations = await UserService.getCustomersLocations(loc_id);

      if (!customers_locations || customers_locations.length === 0) {
        console.warn('No customers locations retrieved');
        return false;
      }

      // Ensure atomic operations on IndexedDB by using await
      await addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
        CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
        {
          id: 0,
          lastSync: date
        }
      );
      await addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
        CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
        {
          id: 1,
          data: customers_locations
        }
      );
      await addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
        CONSTANTS.INDEXDB.STORE.USERS_LOCATIONS,
        {
          id: 2,
          isLoaded: 1
        }
      );

      return true; // Data was fetched and added
    }

    return false; // Data already exists in cache
  } catch (error) {
    console.error('Error fetching users locations:', error);
    return false;
  }
}

async function checkForMenus(location) {
  try {
    // Fetching local menu from cache/store
    let localMenu = await getStoreData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.MENUS
    );
    // Check if local menu exists and is not empty
    if (!localMenu || localMenu.length === 0 || localMenu.length < 2) {
      // Fetch new menu and category data from services
      let menu = await ItemService.getMenu(location.slug);

      // Handle the case where menu might be empty or null
      if (!menu) {
        console.error('Menu data is null or undefined.');
        return false;
      }

      let categoriesData = await ItemService.getCategoryItem(JSON.stringify(menu), location.slug);

      // Storing the sync data and categories data in cache/store
      await addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
        CONSTANTS.INDEXDB.STORE.MENUS,
        {
          id: 0,
          lastSync: date
        }
      );

      await addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
        CONSTANTS.INDEXDB.STORE.MENUS,
        {
          id: 1,
          data: categoriesData
        }
      );

      await addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
        CONSTANTS.INDEXDB.STORE.MENUS,
        {
          id: 2,
          isLoaded: 1
        }
      );

      // Returning the fetched category data
      return categoriesData;
    }

    // Local menu already exists, no need to fetch new data
    return false;
  } catch (error) {
    // Error handling
    console.error('Error in checkForMenus:', error);
    return false;
  }
}

async function checkForOutlets(location) {
  // Validate the location object
  if (!location || !location.loc_id || !location.slug) {
    throw new Error('Invalid location object');
  }

  try {
    // Fetch local outlets from the cache
    let localOutlets = await getStoreData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.OUTLETS
    );
    // Check if the localOutlets array is valid and not empty
    if (!localOutlets || localOutlets.length === 0 || localOutlets.length < 2) {
      // Fetch outlets from the API if not found in cache
      const outlet = await LocationService.getOutlets(location.slug);

      // Save the sync metadata and outlet data to the cache
      const OUTLET_SYNC_META_ID = 0;
      const OUTLET_DATA_ID = 1;

      await addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
        CONSTANTS.INDEXDB.STORE.OUTLETS,
        {
          id: OUTLET_SYNC_META_ID,
          lastSync: date // Use current timestamp for lastSync
        }
      );

      await addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
        CONSTANTS.INDEXDB.STORE.OUTLETS,
        {
          id: OUTLET_DATA_ID,
          data: outlet
        }
      );

      await addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
        CONSTANTS.INDEXDB.STORE.OUTLETS,
        {
          id: 2,
          isLoaded: 1
        }
      );

      // Return the fetched outlets
      return outlet;
    }

    // Optional: Return cached outlets if found
    return localOutlets;
  } catch (error) {
    console.error('Error in checkForOutlets:', error);
    throw new Error('Failed to check for outlets');
  }
}

async function checkForOrderTypes(location) {
  // Basic validation for location and loc_id
  if (!location || !location.loc_id) {
    throw new Error('Location or Location ID is missing');
  }

  try {
    // Fetch local order types
    let localOrderTypes = await getStoreData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.ORDER_TYPES
    );
    // If order types are already available, return them
    if (localOrderTypes.length > 2) {
      return localOrderTypes;
    }

    // Fallback to fetch order types if not available locally
    let orderTypes = '';
    if (location.orderTypes) {
      orderTypes = location.orderTypes;
    } else {
      orderTypes = await OrderTypeService.getOrderTypesPOS(location.loc_id);
    }

    // Ensure that orderTypes is valid before proceeding
    if (!orderTypes || orderTypes.length === 0) {
      throw new Error('Order types could not be fetched or are empty.');
    }

    // Store last sync and order types in database concurrently
    await Promise.all([
      addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
        CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
        { id: 0, lastSync: date }
      ),
      addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
        CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
        { id: 1, data: orderTypes }
      ),
      addData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
        CONSTANTS.INDEXDB.STORE.ORDER_TYPES,
        {
          id: 2,
          isLoaded: 1
        }
      )
    ]);

    return orderTypes;
  } catch (error) {
    console.error('Error in checkForOrderTypes:', error.message);
    return null; // Return null in case of error to indicate failure
  }
}

async function checkForPromotions(location) {
  // Handle edge case: location is null or does not have loc_id
  if (!location || !location.loc_id) {
    console.error('Invalid location provided');
    return false;
  }

  try {
    // Retrieve local promotions from the cache
    let localPromotions = await getStoreData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS
    );

    // Check if promotions exist locally and if they're recent enough
    if (localPromotions.length === 3 && localPromotions[0].lastSync) {
      const lastSync = new Date(localPromotions[0].lastSync);
      const now = new Date();

      // Check if promotions were synced within the last day (or any threshold)
      const oneDay = 24 * 60 * 60 * 1000;
      if (now - lastSync < oneDay) {
        return localPromotions[1].data || [];
      }
    }

    // Handle case where promotions need to be fetched from the server
    let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
    const promotions = await PromotionService.getFilteredPromotionList(loc_id);

    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      {
        id: 0,
        lastSync: date // Store the current date as the last sync time
      }
    );

    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      {
        id: 1,
        data: promotions || [] // Save promotions data or an empty array if none
      }
    );

    await addData(
      CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
      CONSTANTS.INDEXDB.STORE.PROMOTIONS,
      {
        id: 2,
        isLoaded: 1
      }
    );

    return promotions;
  } catch (error) {
    console.error('Error checking for promotions:', error);
    return false; // Return false on error to indicate the process failed
  }
}

async function checkForTables(location) {
  let localTables = await getStoreData(
    CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id,
    CONSTANTS.INDEXDB.STORE.TABLES
  );
  if (localTables.length === 0 || localTables.length < 2) {
    let tables = await TableService.getAllTables(location.loc_id);
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.TABLES, {
      id: 0,
      lastSync: date
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.TABLES, {
      id: 1,
      data: tables
    });
    await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + location.loc_id, CONSTANTS.INDEXDB.STORE.TABLES, {
      id: 2,
      isLoaded: 1
    });
    return tables;
  }
  return false;
}
async function updateStatuses(order_id, status_id, status_message, statuses) {
  try {
    let savedOrders = (await getSavedLiveOrders()) || { rows: [] };
    let { rows: orders = [] } = savedOrders;

    // Early exit if there are no orders
    if (orders.length === 0) {
      console.warn('No orders found');
      return;
    }

    let order = orders.find((order) => order.order_id === order_id);

    // Early exit if the order is not found
    if (!order) {
      console.warn(`Order with ID ${order_id} not found`);
      return;
    }

    order.status_id = parseInt(status_id); // Set status

    // Ensure status exists in the statuses array
    let objectStatus = statuses.find((status) => status.status_id === parseInt(status_id));
    if (!objectStatus) {
      console.warn(`Status with ID ${status_id} not found`);
      return;
    }

    // Add new status history
    let statusHistories = order.StatusHistories || [];
    let newStatus = {
      OrderStatus: objectStatus,
      object_id: order.order_id,
      date_added: moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB),
      notify: false,
      delivery_status_type: null,
      status_id: parseInt(status_id),
      comment: status_message
    };
    statusHistories.push(newStatus);

    // Handle offline statuses
    let localStatuses = localStorage.getItem('offline_statuses');
    let offlineStatuses = [];

    // If offline_statuses exist in localStorage, parse it
    if (localStatuses) {
      try {
        offlineStatuses = JSON.parse(localStatuses);
      } catch (e) {
        console.error('Error parsing offline_statuses from localStorage:', e);
        // If parsing fails, initialize as an empty array to reset
        offlineStatuses = [];
      }
    }

    // Push the new status to offlineStatuses array
    offlineStatuses.push(newStatus);
    localStorage.setItem('offline_statuses', JSON.stringify(offlineStatuses));

    // Save the updated orders
    savedOrders.rows = orders;
    await saveLiveOrders(savedOrders);
  } catch (error) {
    console.error(
      'Error updating order statuses for order_id:',
      order_id,
      'status_id:',
      status_id,
      error
    );
  }
}

async function updateStatusesForOffline(order_id, status_id, status_message, statuses) {
  try {
    let savedOrders = JSON.parse(localStorage.getItem('offline_order_list')) || [];
    if (savedOrders.length > 0) {
      let order = savedOrders.find((order) => order.koId === order_id);
      order.status_id = parseInt(status_id);
      if (!order) {
        console.warn(`Order with ID ${order_id} not found`);
        return;
      }

      let statusHistories = order.StatusHistories || [];
      let objectStatus = statuses.find((status) => status.status_id === parseInt(status_id));
      // Add new status history
      statusHistories.push({
        OrderStatus: objectStatus,
        object_id: order.koId,
        date_added: moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB),
        notify: false,
        delivery_status_type: null,
        status_id: parseInt(status_id),
        comment: status_message
      });
      // Save updated orders
      localStorage.setItem('offline_order_list', JSON.stringify(savedOrders));
    }
  } catch (error) {
    console.error('Error updating order statuses:', error);
  }
}
