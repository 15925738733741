import { combineReducers } from 'redux';
import { activitiesStats } from './ActivityStatsReducer';
import { binOrdersStats } from './BinOrdersReducer';
import { binsStats } from './BinReducer';
import { customerStats } from './CustomerStatsReducer';
import { itemsStats } from './ItemsStatsReducer';
import { ordersStats } from './OrdersStatsReducer';
import { overallStats } from './OverallStatsReducer';
import { paymentlogsStats } from './PaymentLogsStatsReducer';
import { restaurantStats } from './RestaurantsStatsReducer';

const analyticsReducer = combineReducers({
  overallStats,
  restaurantStats,
  customerStats,
  itemsStats,
  ordersStats,
  activitiesStats,
  paymentlogsStats,
  binOrdersStats,
  binsStats
});

export default analyticsReducer;
