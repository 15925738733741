import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  background: rgba(49, 49, 49, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  .customer-modal {
    width: fit-content;
    height: fit-content;
    background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#FFFFFF')};
    padding: 32px;
    display: flex;
    flex-direction: column;
    outline: unset;
    width: 475px;
    height: 450px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid rgba(225, 228, 230, 0.4);

      h2 {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        font-family: Larsseit, sans-serif !important;
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
        margin: 0;
      }
    }
    &__details {
      height: 100%;
      position: relative;
      &__detail {
        margin-bottom: 17px;
        &:first-child {
          margin-top: 24px;
        }
        &:nth-of-type(4) {
          // margin-bottom: 24px !important;
        }
        h3 {
          font-size: 16px;
          font-weight: 300;
          color: #ffffff;
          line-height: 18px;
          font-family: Larsseit, sans-serif !important;
          margin: 0;
        }
        h2 {
          font-size: 12px;
          font-weight: normal;
          color: #c2c6dc;
          line-height: 18px;
          font-family: Larsseit, sans-serif !important;
          margin-bottom: 5px;
        }
      }
      button {
        font-family: Larsseit, sans-serif !important;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: #fff;
        border: unset;
        outline: unset;
        outline: unset;
        width: 100%;
        height: 60px;
        background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
        position: absolute;
        bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    .customer-modal {
      padding: 16px;
    }
  }
  @media (max-width: 376px) {
    .customer-modal {
    }
  }
`;
