import { combineReducers } from 'redux';
import { allpayments, allriders, payment, riders, ridersearnings } from './RidersReducer';

const ridersReducer = combineReducers({
  allriders,
  allpayments,
  payment,
  riders,
  ridersearnings
});
export default ridersReducer;
