import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clearUserIcon from '../../../assets/img/static/clear-bordered.svg';
import clearUserIcon2 from '../../../assets/img/static/clear-bordered2.svg';
import { resetExistingCustomerDetails } from '../../../redux/actions/users/index';
import { clearCart, resetOrderType } from '../redux/actions/cart/index';
import { resetUserData } from '../redux/actions/users/index';

const CustomerSection = (props) => {
  const {
    existingCustomerDetails,
    resetExistingCustomerDetails,
    resetUserData,
    clearCart,
    clearOrderCustomer,
    user,
    location
  } = props;

  function onClearClick() {
    clearOrderCustomer();
  }

  const onSelectCustomerClick = () => {
    if (
      props.orderInfo?.orderType?.order_type === '' ||
      props.orderInfo?.orderType?.order_type === undefined ||
      props.orderInfo?.orderType?.order_type === null
    ) {
      props.setCustomerInfoModal({ ['orderType']: true });
    }
    if (
      props.orderInfo?.orderType?.order_type === 'delivery' ||
      props.orderInfo?.orderType?.order_type === 'pick_up' ||
      props.orderInfo?.orderType?.order_type === 'dine_in' ||
      props.orderInfo?.orderType?.order_type === 'curbside_pickup'
    ) {
      props.setCustomerInfoModal({ ['address']: true });
    } else {
      props.setCustomerInfoModal({ ['orderType']: true });
    }
  };
  return (
    <>
      {existingCustomerDetails?.first_name ? (
        <div className={'details'}>
          <div className="details__customer">
            <div
              className="details__customer__data"
              style={{
                cursor: existingCustomerDetails?.first_name && 'pointer'
              }}
              onClick={props.handleCustomerDetailModalOpen}>
              <h2 className={'title-content'}>{existingCustomerDetails?.first_name}</h2>

              <h2 className={'title-content'}>{existingCustomerDetails?.phone_number}</h2>
            </div>
          </div>
          {props.history.location.pathname !== '/locations/pos/cash-checkout' && (
            <button onClick={onClearClick} className="clear-user-btn">
              <img src={props.theme === 'dark' ? clearUserIcon : clearUserIcon2} alt="" />
            </button>
          )}
        </div>
      ) : (
        <div className={'details'}>
          <div className="details__customer" onClick={onSelectCustomerClick}>
            {props.userCart?.customer && Object.keys(props.userCart?.customer).length === 0 && (
              <h2 className={'title-content-select'}>Select customer</h2>
            )}

            <div
              className="details__customer__data"
              style={{
                cursor: props.userCart?.customer?.first_name && 'pointer'
              }}
              onClick={props.userCart?.customer?.first_name && props.handleCustomerDetailModalOpen}>
              {props.userCart?.customer?.first_name && (
                <h2 className={'title-content'}>
                  {props.userCart?.customer?.first_name
                    ? props.userCart?.customer?.first_name
                    : props.userCart?.customer?.last_name}
                </h2>
              )}

              <h2 className={'title-content'}>
                {props.userCart?.customer?.phone_number
                  ? props.userCart?.customer?.phone_number
                  : user?.phone_number}
              </h2>
            </div>
          </div>
          {props.userCart?.customer &&
            Object.keys(props.userCart?.customer).length > 0 &&
            props.history.location.pathname !== '/locations/pos/cash-checkout' && (
              <button onClick={onClearClick} className="clear-user-btn">
                <img src={props.theme === 'dark' ? clearUserIcon : clearUserIcon2} alt="" />
              </button>
            )}
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.userReducer.user && state.userReducer.user,
    existingCustomerDetails: state.users.existingCustomerDetails,
    userChanged: state.userReducer.userChanged,
    existingCustomerLocations: state.users.existingCustomerLocations,
    orderInfo: state.cartReducer ? state.cartReducer : null
  };
}

export default connect(mapStateToProps, {
  resetExistingCustomerDetails,
  resetUserData,
  resetOrderType,
  clearCart
})(withRouter(CustomerSection));
