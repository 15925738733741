import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { Col, Container, Row } from 'reactstrap';

import SpinnerComponent from '../../../components/@vuexy/spinner/Fallback-spinner';
import ViewHelper from '../../../helpers/ViewHelper';
import { clearCart, clearCheckout, editCartDataStatus } from '../redux/actions/cart';
import ItemCard from './ItemCard';
import MenuDesktop from './MainMenuDesktop';

import Icon from '@ant-design/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import CONSTANTS from '../../../constants';
import LayoutContext from '../../../layouts/LayoutContext';
import { collapseSidebar } from '../../../redux/actions/customizer';
import { getLiveOrders, setStateValue } from '../../../redux/actions/orders';
import { StyledCheckoutDone } from '../../../styles/GlobalStyles';
import {
  createOrder,
  createOrderWithoutUser,
  updateOrder,
  updateOrderWithoutUser
} from '../redux/actions/pos';
import CartService from '../utils/CartService';
import PosInvoice from './PosInvoice';

import { ReactComponent as printer } from '../../../assets/img/static/printerColoured.svg';
import { ReactComponent as sendmail } from '../../../assets/img/static/sendMailColoured.svg';

const PrinterColored = (props) => <Icon component={printer} {...props} />;
const EmailColored = (props) => <Icon component={sendmail} {...props} />;

const DEFAULT_FILTER = CONSTANTS.FILTERS.ORDERS.find((filter) => filter.default);
const DEFAULT_SORT = CONSTANTS.SORTS.ORDERS.find((sort) => sort.default);
const DEFAULT_STATE = {
  filter: DEFAULT_FILTER.key,
  sort: DEFAULT_SORT.key,
  page: 0,
  filterKeyName: ''
};

function makeid(length) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const CheckoutDone = (props) => {
  const componentRef = useRef();
  const kitchenPrintComponentRef = useRef();
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [emailReceipt, setEmailReceipt] = useState(false);

  useEffect(() => {
    props.collapseSidebar(true);
  }, []);

  let theme = props.customizer?.customizer?.theme;
  let { clearCart } = props;
  const toggleMenuCollapsed = () => {
    setMenuCollapsed(!menuCollapsed);
  };
  const history = useHistory();

  const connection = window.navigator.onLine;
  const completeCheckout = () => {
    let checkoutData = props.checkout;

    let paymentMethod = props.cart.paymentType;
    checkoutData.payment = paymentMethod.toLowerCase();
    checkoutData.send_email = emailReceipt;
    checkoutData.payment_details.method =
      paymentMethod === 'Card' ? 'Card' : paymentMethod.toLowerCase();
    if (props.user?.token) {
      if (props.cart.cartUpdate === true) {
        let datas = {
          filter: 'live_orders',
          filterKeyName: 'live_orders',
          offlineOrder: false,
          page: 0,
          sort: 'DESC'
        };
        props.setStateValue(datas);
        props.updateOrder(
          checkoutData,
          props.location,
          props.user.token,
          null,
          false,
          props.cart.orderId
        );
      } else {
        if (connection) {
          let datas = {
            filter: 'live_orders',
            filterKeyName: 'live_orders',
            offlineOrder: false,
            page: 0,
            sort: 'DESC'
          };
          props.setStateValue(datas);
          props.createOrder(checkoutData, props.location, props.user.token);
        } else {
          let data = {
            ...props.checkout,
            koId: makeid(15)
          };
          data.offline_order = true;

          let datas = {
            filter: 'offline',
            filterKeyName: 'offline',
            offlineOrder: true,
            page: 0,
            sort: 'DESC'
          };
          props.setStateValue(datas);

          let offlineOrderList = localStorage.getItem('offline_order_list');
          if (!offlineOrderList) {
            offlineOrderList = [];
            offlineOrderList.push(data);
            localStorage.setItem('offline_order_list', JSON.stringify(offlineOrderList));
          } else {
            offlineOrderList = JSON.parse(offlineOrderList);
            offlineOrderList.push(data);
            localStorage.setItem('offline_order_list', JSON.stringify(offlineOrderList));
          }
        }
      }
    } else {
      if (props.cart.cartUpdate === true) {
        let datas = {
          filter: 'live_orders',
          filterKeyName: 'live_orders',
          offlineOrder: false,
          page: 0,
          sort: 'DESC'
        };
        props.setStateValue(datas);
        props.updateOrderWithoutUser(checkoutData, props.location, null, false, props.cart.orderId);
      } else {
        if (connection) {
          let datas = {
            filter: 'live_orders',
            filterKeyName: 'live_orders',
            offlineOrder: false,
            page: 0,
            sort: 'DESC'
          };
          props.setStateValue(datas);
          props.createOrderWithoutUser(checkoutData, props.location);
        } else {
          let data = {
            ...props.checkout,
            koId: makeid(15)
          };
          data.offline_order = true;
          let datas = {
            filter: 'offline',
            filterKeyName: 'offline',
            offlineOrder: true,
            page: 0,
            sort: 'DESC'
          };
          props.setStateValue(datas);
          let offlineOrderList = localStorage.getItem('offline_order_list');
          if (!offlineOrderList) {
            offlineOrderList = [];
            offlineOrderList.push(data);
            localStorage.setItem('offline_order_list', JSON.stringify(offlineOrderList));
          } else {
            offlineOrderList = JSON.parse(offlineOrderList);
            offlineOrderList.push(data);
            localStorage.setItem('offline_order_list', JSON.stringify(offlineOrderList));
          }
        }
      }
    }
    localStorage.removeItem('promo_id');
    redirectToLiveOrders();
    // setTimeout(() => {
    //     clearCart();
    //     props.clearCheckout()
    //     localStorage.removeItem('plugd_menu_cart')
    //     history.push('/');
    // }, 1000)
  };

  const redirectToLiveOrders = () => {
    clearCart();
    props.clearCheckout();
    localStorage.removeItem('plugd_menu_cart');
    localStorage.removeItem('promo_id');
    history.push('/');
  };

  let cartData = [],
    lineItem;
  props.userCart &&
    props.userCart.length &&
    props.userCart.map((item, key) => {
      lineItem = item.cartItem;
      cartData.push(lineItem);
    });

  const amountToPay = CartService.getOrderTotalWithAllCharges(
    props.location,
    Number(localStorage.getItem('tax_percentage')),
    props.promotions,
    props.orderType?.id,
    null,
    cartData,
    false
  );
  return (
    <>
      <LayoutContext.Consumer>
        {(context) => (
          <MenuDesktop
            toggleMenu={toggleMenuCollapsed}
            toggleSidebarVisibility={context.sidebarVisibility}
          />
        )}
      </LayoutContext.Consumer>
      {props.loading ? (
        <SpinnerComponent />
      ) : (
        <StyledCheckoutDone theme={theme} paymentType={props.cart.paymentType}>
          {/*<SideBar/>*/}

          <Container>
            <Row>
              {/*<Col md={12}>*/}
              {/*    <PosInvoice/>*/}
              {/*</Col>*/}
              <Col md={8}>
                <center>
                  <div className="checkout-done">
                    <div className="amount">
                      <div>
                        <p>Amount Received</p>
                        <p>
                          {ViewHelper.toCurrency(
                            amountToPay,
                            localStorage.getItem('currency_code'),
                            props.locationData?.decimal_places
                          )}
                        </p>
                      </div>
                      {props.cart.paymentType === 'Cash' && (
                        <div>
                          <p>Change Due</p>
                          <p>
                            {Math.abs(Number(props.cart.amountReceived) - Number(amountToPay)) > 0
                              ? ViewHelper.toCurrency(
                                  Math.abs(Number(props.cart.amountReceived) - Number(amountToPay)),
                                  localStorage.getItem('currency_code'),
                                  localStorage.getItem('decimal_place')
                                )
                              : `${getSymbolFromCurrency(localStorage.getItem('currency_code'))}` +
                                ' 0 '}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className={'receipt'}>
                      {/*<Button onClick={printEPToolkit}>*/}
                      {/*    Print*/}
                      {/*</Button>*/}
                      <div
                        style={{ background: emailReceipt ? '#00CDBE' : '#6057CC' }}
                        onClick={() => setEmailReceipt(true)}>
                        <span>
                          <EmailColored style={{ fontSize: '25px' }} />
                        </span>
                        <span>Email Receipt</span>
                      </div>
                      <div style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <ReactToPrint
                          trigger={() => (
                            <div>
                              <span>
                                <PrinterColored style={{ fontSize: '25px' }} />
                              </span>
                              <span>Print Receipt</span>
                            </div>
                          )}
                          content={() => componentRef.current}
                        />
                      </div>
                    </div>
                    {/* <ReactToPrint
                      onBeforePrint={completeCheckout}
                      onAfterPrint={redirectToLiveOrders}
                      trigger={() => <button>Done</button>}
                      content={() => kitchenPrintComponentRef.current}
                    /> */}
                    <button onClick={redirectToLiveOrders}>Done</button>
                  </div>
                </center>
              </Col>
              <Col md={4}>
                <ItemCard />
              </Col>
            </Row>
          </Container>
          <div className="due-amount">
            <h2>Total Due</h2>
            <h2>
              {props.cart.paymentType === 'Cash' &&
              Math.abs(Number(props.cart.amountReceived) - Number(amountToPay)) > 0
                ? ViewHelper.toCurrency(
                    Math.abs(Number(props.cart.amountReceived) - Number(amountToPay)),
                    localStorage.getItem('currency_code'),
                    localStorage.getItem('decimal_place')
                  )
                : `${getSymbolFromCurrency(localStorage.getItem('currency_code'))}` + ' 0 '}
            </h2>
          </div>
          <div className={'invoice-component'} ref={componentRef}>
            <PosInvoice cartInvoice={true} />
          </div>
          <div className={'invoice-component'} ref={kitchenPrintComponentRef}>
            <PosInvoice kitchenInvoice={true} />
          </div>
        </StyledCheckoutDone>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    customizer: state.customizer ? state.customizer : null,
    cart: state.cartReducer ? state.cartReducer : [],
    checkout: state.cartReducer ? state.cartReducer.checkout : '',
    location: state.posReducer ? state.posReducer.location : null,
    currency: state.locations ? state.locations?.location?.data?.Currency : null,
    user: state.cartReducer
      ? state.cartReducer.customer
        ? state.cartReducer.customer
        : null
      : null,
    userCart: state.cartReducer ? state.cartReducer.cart : [],
    promotions: state.posReducer ? state.posReducer?.promotionsData : [],
    orderType: state.cartReducer ? state.cartReducer.orderType : '',
    locationData: state.locations ? state.locations?.location?.data : null
  };
}

export default connect(mapStateToProps, {
  createOrder,
  createOrderWithoutUser,
  clearCart,
  clearCheckout,
  editCartDataStatus,
  updateOrderWithoutUser,
  updateOrder,
  getLiveOrders,
  setStateValue,
  collapseSidebar
})(CheckoutDone);
