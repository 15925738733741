export const CART_PAGE = {
  ADD_ITEM_TO_CART: {
    SUCCESS: 'ADD_ITEM_TO_CART_SUCCESS'
  },
  UPDATE_QUANTITY: {
    SUCCESS: 'UPDATE_QUANTITY_SUCCESS'
  },
  REMOVE_ITEM_FROM_CART: {
    SUCCESS: 'REMOVE_ITEM_FROM_CART_SUCCESS'
  },
  CLEAR_CART: {
    SUCCESS: 'CLEAR_CART_SUCCESS'
  },
  SET_CHECKOUT: {
    SUCCESS: 'SET_CHECKOUT_SUCCESS'
  },
  CLEAR_CHECKOUT: {
    SUCCESS: 'CLEAR_CHECKOUT_SUCCESS'
  },
  ADD_CART_TOTAL_DUE: {
    SUCCESS: 'ADD_CART_TOTAL_DUE_SUCCESS'
  },
  ADD_CART_TOTAL_RECEIVED: {
    SUCCESS: 'ADD_CART_TOTAL_RECEIVED_SUCCESS'
  },
  ADD_CART_ORDER_ORDER_TYPE: {
    SUCCESS: 'ADD_CART_ORDER_ORDER_TYPE_SUCCESS'
  },
  ADD_CART_ORDER_TABLE_NO: {
    SUCCESS: 'ADD_CART_ORDER_TABLE_NO_SUCCESS'
  },
  ADD_CART_ORDER_DISCOUNT_TYPE: {
    SUCCESS: 'ADD_CART_ORDER_DISCOUNT_TYPE_SUCCESS'
  },
  ADD_CART_ORDER_DISCOUNT: {
    SUCCESS: 'ADD_CART_ORDER_DISCOUNT_SUCCESS'
  },
  ADD_CART_ORDER_PAYMENT_TYPE: {
    SUCCESS: 'ADD_CART_TOTAL_PAYMENT_SUCCESS'
  },
  ADD_CART_ORDER_CUSTOMER: {
    SUCCESS: 'ADD_CART_ORDER_CUSTOMER_SUCCESS'
  },
  SET_CART_ORDER_ORDERING_TIME: {
    SUCCESS: 'SET_CART_ORDER_ORDERING_TIME_SUCCESS'
  },
  SET_CART_ORDER_ORDERING_DATE: {
    SUCCESS: 'SET_CART_ORDER_ORDERING_DATE_SUCCESS'
  },
  CLEAR_USER: {
    SUCCESS: 'CLEAR_USER_SUCCESS'
  },
  REPLACE_CART: {
    SUCCESS: 'REPLACE_CART_SUCCESS'
  },
  UPDATE_CART: {
    SUCCESS: 'UPDATE_CART_SUCCESS'
  },
  SET_ORDER_ID: {
    SUCCESS: 'SET_ORDER_ID_SUCCESS'
  },
  SET_NOTE_VISIBILITY: {
    SUCCESS: 'SET_NOTE_VISIBILITY_SUCCESS'
  },
  SET_NOTE_DATA: {
    SUCCESS: 'SET_NOTE_DATA_SUCCESS'
  },
  SET_ADDRESS: {
    SUCCESS: 'SET_ADDRESS_SUCCESS'
  },
  UPDATE_DISCOUNT: 'UPDATE_DISCOUNT',
  RESET_ORDER_TYPE: 'RESET_ORDER_TYPE'
};
