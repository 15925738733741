import { CART_PAGE } from '../../constants/cart';

export function addItemToCart(data, quantity) {
  return {
    type: CART_PAGE.ADD_ITEM_TO_CART.SUCCESS,
    result: data,
    quantity: quantity
  };
}

export function replaceCart(data) {
  return {
    type: CART_PAGE.REPLACE_CART.SUCCESS,
    result: data
  };
}

export function removeItemFromCart(data) {
  return {
    type: CART_PAGE.REMOVE_ITEM_FROM_CART.SUCCESS,
    result: data
  };
}

export function updateQuantityFromCart(count, identify, originalPrice) {
  return {
    type: CART_PAGE.UPDATE_QUANTITY.SUCCESS,
    result: count,
    identify: identify,
    original_price: originalPrice
  };
}

export function editCartDataStatus(status) {
  return {
    type: CART_PAGE.UPDATE_CART.SUCCESS,
    result: status
  };
}

export function addCartAmountDue(dueAmount) {
  return {
    type: CART_PAGE.ADD_CART_TOTAL_DUE.SUCCESS,
    result: dueAmount
  };
}

export function setOrderId(orderId) {
  return {
    type: CART_PAGE.SET_ORDER_ID.SUCCESS,
    result: orderId
  };
}

export function addCartAmountReceived(receivedAmount) {
  return {
    type: CART_PAGE.ADD_CART_TOTAL_RECEIVED.SUCCESS,
    result: receivedAmount
  };
}

export function setOrderDate(orderDate) {
  return {
    type: CART_PAGE.SET_CART_ORDER_ORDERING_DATE.SUCCESS,
    result: orderDate
  };
}
export function setOrderType(orderType) {
  return {
    type: CART_PAGE.ADD_CART_ORDER_ORDER_TYPE.SUCCESS,
    result: orderType
  };
}
export function setOrderTableNo(orderTableNo) {
  return {
    type: CART_PAGE.ADD_CART_ORDER_TABLE_NO.SUCCESS,
    result: orderTableNo
  };
}
export function setOrderDiscountType(orderDiscountType) {
  return {
    type: CART_PAGE.ADD_CART_ORDER_DISCOUNT_TYPE.SUCCESS,
    result: orderDiscountType
  };
}
export function setOrderDiscount(orderDiscount) {
  return {
    type: CART_PAGE.ADD_CART_ORDER_DISCOUNT.SUCCESS,
    result: orderDiscount
  };
}
export function setOrderPaymentType(orderPaymentType) {
  return {
    type: CART_PAGE.ADD_CART_ORDER_PAYMENT_TYPE.SUCCESS,
    result: orderPaymentType
  };
}
export function setOrderCustomer(orderCustomer) {
  return {
    type: CART_PAGE.ADD_CART_ORDER_CUSTOMER.SUCCESS,
    result: orderCustomer
  };
}

export function setOrderComment(comment) {
  return {
    type: CART_PAGE.SET_NOTE_DATA.SUCCESS,
    result: comment
  };
}

export function setOrderAddress(data) {
  return {
    type: CART_PAGE.SET_ADDRESS.SUCCESS,
    result: data
  };
}

export function setOrderOrderingTime(orderOrderingTime) {
  return {
    type: CART_PAGE.SET_CART_ORDER_ORDERING_TIME.SUCCESS,
    result: orderOrderingTime
  };
}

export function setAddNoteVisibilityStatus(status) {
  return {
    type: CART_PAGE.SET_NOTE_VISIBILITY.SUCCESS,
    status: status
  };
}

export function clearCart() {
  return {
    type: CART_PAGE.CLEAR_CART.SUCCESS
  };
}

export function clearCartCustomer() {
  return {
    type: CART_PAGE.CLEAR_USER.SUCCESS
  };
}

export function setCheckout(data) {
  return {
    type: CART_PAGE.SET_CHECKOUT.SUCCESS,
    result: data
  };
}

export function clearCheckout() {
  return {
    type: CART_PAGE.CLEAR_CHECKOUT.SUCCESS
  };
}

export function updateCartDiscount(cart) {
  return {
    type: CART_PAGE.UPDATE_DISCOUNT,
    cart
  };
}

export function resetOrderType() {
  return {
    type: CART_PAGE.RESET_ORDER_TYPE,
    orderType: ''
  };
}
