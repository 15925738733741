import CONSTANTS from '../../../constants';

export function allPromotions(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}

export function allFilteredPromotions(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.PROMOTIONS.ALL_FILTERED_PROMOTIONS) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}

export function promotion(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}

export function errorPromotion(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.PROMOTIONS.GET_ERROR_PROMOTION) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}
