import CONSTANTS from '../../../constants';

export function allInventoryItems(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.INVENTORY.ALL_INVENTORY) {
    return {
      data: action.data,
      loading: action.loading
    };
  }
  return state;
}

export function errorBadge(state = { showErrorBadge: false }, action) {
  if (action.type === CONSTANTS.ACTIONS.ERROR_BADGE.SHOW_ERROR_BADGE) {
    return {
      showErrorBadge: action.showErrorBadge
    };
  }
  return state;
}

export function onSelect(state = { onSelectClicked: false, selectedLocation: {} }, action) {
  if (action.type === CONSTANTS.ACTIONS.ON_SELECT_CLICKED) {
    return {
      ...state,
      onSelectClicked: action.onSelectClicked
    };
  }
  if (action.type === CONSTANTS.ACTIONS.SET_LOCATION) {
    return {
      ...state,
      selectedLocation: action.selectedLocation
    };
  }
  return state;
}

export function inventoryItem(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.INVENTORY.SPECIFIC_INVENTORY_ITEM) {
    return {
      data: action.data,
      loading: action.loading
    };
  }

  if (action.type === CONSTANTS.ACTIONS.INVENTORY.GET_RECIPE_UNITS) {
    const data = { ...state.data };
    return {
      ...state,
      data: {
        ...data,
        recipeUnits: action.recipeUnits
      }
    };
  }

  if (action.type === CONSTANTS.ACTIONS.INVENTORY.GET_PURCHASE_UNITS) {
    const data = { ...state.data };
    return {
      ...state,
      data: {
        ...data,
        purchaseUnits: action.purchaseUnits
      }
    };
  }
  return state;
}
