import React, { Component } from 'react';
import { ArrowLeft } from 'react-feather';
import AsyncSelect from 'react-select/async';

import '../../../assets/css/selectLocation.scss';
import CONSTANTS from '../../../constants';
import ViewHelper from '../../../helpers/ViewHelper';
import LocationService from '../../../services/LocationService';
import { StyledDiv } from '../../../styles/GlobalStyles';

class SelectLocation extends Component {
  constructor(props) {
    super(props);
    this.state = { searchTerm: '', userLocations: [] };
  }

  async componentDidMount() {
    let locations = await LocationService.getLocations('');
    this.setState({ userLocations: locations });
    if (locations.length === 1) {
      this.onChange({ location: locations[0] });
    }
  }

  handleInputChange = (searchTerm) => {
    this.setState({ searchTerm });
    return searchTerm;
  };

  async loadOptions(term, callback) {
    if (term.length < 3) return callback([]);
    try {
      const locations = await LocationService.getLocations(term, this.props.showOutlets);
      callback(ViewHelper.transformedLocations(locations));
    } catch (e) {
      // console.log(e);
      alert('Could not load locations');
    }
  }

  onChange(selected) {
    if (selected) {
      if (this.props.onSelect) this.props.onSelect(selected.location);
      else this.onSelect(selected.location);
    }
  }

  onBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  goBack() {
    if (this.props.onBack) {
      // console.log("Go Back");
      this.props.onBack();
    }
  }

  onSelect(location) {
    LocationService.setLocation(location);
    this.redirectToURL(location);
  }

  redirectToURL(location) {
    this.props.history.push(`${CONSTANTS.BASE_URL}locations/${location.loc_id}/order_types`);
  }

  render() {
    const {
      props: { hideBack = false }
    } = this;
    return (
      <StyledDiv className="d-flex flex-row">
        {!hideBack && (
          <ArrowLeft
            className="primary font-weight-bold align-self-auto mr-1"
            size="40"
            onClick={this.goBack.bind(this)}
          />
        )}
        <div className="has-icon-left full-width input-field-div">
          <AsyncSelect
            placeholder="Please enter restaurant name"
            autoFocus
            isClearable
            className={'react-select-container'}
            cacheOptions
            onBlur={this.onBlur.bind(this)}
            onChange={this.onChange.bind(this)}
            loadOptions={this.loadOptions.bind(this)}
            onInputChange={this.handleInputChange.bind(this)}
            noOptionsMessage={() => 'No restaurants present.'}
            styles={{
              control: (provided) => {
                return { ...provided, borderRadius: '1.5rem' };
              },
              dropdownIndicator: (provided) => {
                return { ...provided, display: 'none' };
              },
              indicatorSeparator: (provided) => {
                return { ...provided, display: 'none' };
              }
            }}
          />
        </div>
      </StyledDiv>
    );
  }
}

export default SelectLocation;
