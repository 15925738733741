import moment from 'moment';
import CONSTANTS from '../../../constants';
import { setCategories } from '../../../pos/src/redux/actions/pos';
import { addData, getStoreData, initRestaurantDB } from '../../../pos/src/utils/DBHelper';
import ItemService from '../../../pos/src/utils/ItemService';
import CategoryService from '../../../services/CategoryService';

export function getMenuGroupedByCategory(loc_id, minimalData = 0, initialize = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU, data: null, loading: true });
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.GET_ERROR_CATEGORY,
        data: null,
        loading: false
      });

      let menu = await CategoryService.getMenusGroupedByCategory(loc_id, minimalData);
      if (menu.status) {
        dispatch({
          type: CONSTANTS.ACTIONS.CATEGORIES.GET_ERROR_CATEGORY,
          data: menu.message,
          loading: false
        });
      }
      let filteredResponseData = [];
      for (let category of menu) {
        category.Menus.length > 0 &&
          category.Menus.map((menu) => {
            menu.category_name = category.name;
          });
        filteredResponseData.push(category);
      }

      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU,
        data: filteredResponseData,
        loading: false
      });
      dispatch(setCategories(filteredResponseData, false));
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU, data: null, loading: false });
    }
  };
}

export function getMenu(loc) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU, data: null, loading: true });
      let loc_id = loc.is_outlet ? loc.parent_id : loc.loc_id;
      initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, loc_id);
      let localMenu = await getStoreData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
        CONSTANTS.INDEXDB.STORE.MENUS
      );
      if (localMenu.length > 0) {
        dispatch({
          type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU,
          data: localMenu[1].data,
          loading: false
        });
        dispatch(setCategories(localMenu[0].data, false));
      } else {
        let menu = await ItemService.getMenu(loc.slug);
        let categoriesData = await ItemService.getCategoryItem(JSON.stringify(menu), loc.slug);
        const date = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
        await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc.loc_id, CONSTANTS.INDEXDB.STORE.MENUS, {
          id: 0,
          lastSync: date
        });
        await addData(CONSTANTS.DB_CACHE_KEY_PREFIX + loc.loc_id, CONSTANTS.INDEXDB.STORE.MENUS, {
          id: 1,
          data: categoriesData
        });
        dispatch({
          type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU,
          data: categoriesData,
          loading: false
        });
        dispatch(setCategories(categoriesData, false));
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU, data: null, loading: false });
    }
  };
}

export function getMenuByCategory(menu_blob, loc_id) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU, data: null, loading: true });
      let categoriesData = await ItemService.getCategoryItem(menu_blob, loc_id);
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU,
        data: categoriesData,
        loading: false
      });
      dispatch(setCategories(categoriesData, false));
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU, data: null, loading: false });
    }
  };
}

export function saveCategory(loc_id, category, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY, data: null, loading: true });
      let cat = await CategoryService.saveCategory(loc_id, category);
      dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY, data: cat, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY, data: null, loading: false });
    }
  };
}

export function delCategory(loc_id, categoryId, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY, data: null, loading: true });
      let cat = await CategoryService.delCategory(loc_id, categoryId);
      dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY, data: cat, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.CATEGORIES.GET_CATEGORY, data: null, loading: false });
    }
  };
}

export function updateCategoryPriority(loc_id, categories, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({
          type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU_UPDATE,
          data: null,
          loading: true
        });
      let menu = await CategoryService.updateCategoryPriority(loc_id, categories);
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU_UPDATE,
        data: menu,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.CATEGORIES.CATEGORY_MENU_UPDATE,
        data: null,
        loading: false
      });
    }
  };
}
