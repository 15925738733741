import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import React, { useRef, useState } from 'react';
import crossIcon from '../../../assets/img/static/cross-coloured.svg';
import searchIconGray from '../../../assets/img/static/search-icon-gray.svg';
import searchIconNormalTheme from '../../../assets/img/static/search-icon-normal-theme.svg';

const MenuSearch = React.memo((props) => {
  const [childState, setChildState] = useState('');
  const inputRef = useRef(null);

  const handleChange = (event) => {
    event.preventDefault();
    const newState = event.target.value;
    setChildState(newState);
    props.handlerSearch(newState);
    // if (inputRef.current) inputRef.current.focus();
  };

  return (
    <div className="logo" onClick={props.toggleMenu}>
      <div className="search-input">
        <div className="search-field">
          {props.itemCardVisible === false ? (
            <Paper component="form" className={props.classes.root}>
              <IconButton
                ref={props.searchClickRef}
                onClick={props.changeSearchClicked}
                className={props.classes.iconButton}
                aria-label="menu">
                <img
                  name={'search-enable-disable'}
                  src={
                    props.searchClicked === true
                      ? crossIcon
                      : props.theme === 'dark'
                      ? searchIconGray
                      : searchIconNormalTheme
                  }
                  alt=""
                />
              </IconButton>
              <InputBase
                value={childState}
                inputRef={inputRef}
                onChange={(e) => handleChange(e)}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                  }
                }}
                className={props.classes.input}
                placeholder="Search Item"
                inputProps={{ 'aria-label': 'search item' }}
              />
            </Paper>
          ) : null}
        </div>
      </div>
    </div>
  );
});

export default MenuSearch;
