import { Skeleton } from '@material-ui/lab';
import React from 'react';
import '../../../assets/scss/components/app-loader.scss';

class PosSkeleton extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100">
        <div style={{ display: 'flex' }}>
          <Skeleton
            variant="rectangular"
            width={'70vw'}
            height={'calc(100vh - 185px)'}
            style={{ marginLeft: 20, marginTop: 64 }}
          />
          <Skeleton
            variant="rectangular"
            width={'30vw'}
            height={'calc(100vh - 185px)'}
            style={{ marginLeft: 20, marginTop: 64 }}
          />
        </div>
        {/*<Skeleton*/}
        {/*    variant="rectangular"*/}
        {/*    width={'calc(100% - 42px)'}*/}
        {/*    height={'calc(100% - 120px)'}*/}
        {/*    style={{marginLeft: 20}}*/}
        {/*/>*/}
        <div style={{ display: 'flex', position: 'absolute', bottom: 0, marginLeft: 20 }}>
          <Skeleton
            variant="rectangular"
            width={120}
            height={80}
            style={{ marginRight: 10, marginBottom: 20 }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={80}
            style={{ marginRight: 10, marginBottom: 20 }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={80}
            style={{ marginRight: 10, marginBottom: 20 }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={80}
            style={{ marginRight: 10, marginBottom: 20 }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={80}
            style={{ marginRight: 10, marginBottom: 20 }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={80}
            style={{ marginRight: 10, marginBottom: 20 }}
          />
        </div>
      </div>
    );
  }
}

export default PosSkeleton;
