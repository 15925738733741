import {
  SET_SOUND_COUNT,
  SET_SOUND_MODE,
  SET_SOUND_ONE_CARD,
  SET_SOUND_PLAY,
  SET_SOUND_VOLUME
} from '../../constants/notifications/index';

export function setVolume(data) {
  return {
    type: SET_SOUND_VOLUME,
    volume: data
  };
}

export function setMode(data) {
  return {
    type: SET_SOUND_MODE,
    mode: data
  };
}

export function setCount(data) {
  return {
    type: SET_SOUND_COUNT,
    count: data
  };
}

export function setOneCard(data) {
  return {
    type: SET_SOUND_ONE_CARD,
    handleOneCard: data
  };
}

export function setOnPlay(data) {
  return {
    type: SET_SOUND_PLAY,
    howler_sound_play: data
  };
}
