import React from 'react';
import * as Icon from 'react-feather';

const dataNavigationConfig = (location = null) =>
  [
    {
      id: 'order-types',
      title: 'Order Status',
      type: 'item',
      icon: <Icon.File size={20} />,
      permissions: ['admin', 'editor'],
      navLink: '/locations/order_types',
      filterBase: '/locations/order_types'
    },
    {
      id: 'menu-manager',
      title: 'Menu',
      type: 'item',
      icon: <Icon.List size={20} />,
      permissions: ['admin', 'editor'],
      navLink: '/locations/menu'
    },
    {
      id: 'promotions',
      title: 'Promotions',
      type: 'item',
      icon: <Icon.Monitor size={20} />,
      permissions: ['admin', 'editor'],
      navLink: '/locations/promotions'
    },

    {
      id: 'restaurant-settings',
      title: 'Restaurant Settings',
      type: 'collapse',
      icon: <Icon.Home size={20} />,
      children: [
        {
          id: 'outlets',
          title: 'Outlets',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/outlets'
        },
        {
          id: 'departments',
          title: 'Departments',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/departments'
        },
        {
          id: 'printer_stations',
          title: 'Printer Stations',
          type: 'item',
          icon: <Icon.Printer size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/printer_stations'
        },
        {
          id: 'branding',
          title: 'Branding',
          type: 'item',
          icon: <Icon.Layout size={20} />,
          permissions: ['admin'],
          navLink: '/locations/branding',
          filterBase: '/locations/branding'
        },
        {
          id: 'tables',
          title: 'Tables',
          type: 'item',
          icon: <Icon.Tablet size={20} />,
          permissions: ['admin'],
          navLink: '/locations/tables'
        }
      ]
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'collapse',
      icon: <Icon.Settings size={20} />,
      children: [
        {
          id: 'restaurants',
          title: 'Restaurants',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations'
        }
      ]
    },
    {
      id: 'divider'
    },
    {
      id: 'logout',
      title: 'Logout',
      type: 'item',
      icon: <Icon.Power size={20} />,
      permissions: ['admin', 'editor'],
      navLink: '/logout'
    }
  ].filter((child) => Object.keys(child).length !== 0);
export default dataNavigationConfig;
