import React from 'react';

const CategoryItem = (props) => {
  return (
    <a href="#/" onClick={() => props.changeSection(props.category.category_id)}>
      <div
        style={{ display: props.category && props.category?.Menus.length === 0 && 'none' }}
        className={'item-categories__category'}>
        <div className="category">
          <span className="icon">
            <span
              style={{ fontSize: '30px', color: '#fff' }}
              className={
                props.category?.icon_classname !== ''
                  ? `${props.category?.icon_classname}`
                  : `icon-Spoon_and_Fork_with_Knife`
              }
            />
          </span>
          <p className="category-bottom">{props.category?.name}</p>
        </div>

        <div className="mobile-category">
          <span style={{ whiteSpace: 'nowrap' }}>{props.category?.name}</span>
        </div>
      </div>
    </a>
  );
};

export default CategoryItem;
