import { unserialize } from 'php-serialize';
import CONSTANTS from '../constants';
import { customFetch as fetch } from '../helpers/FetchHelper';
import GoogleHelper from '../helpers/GoogleHelper';
import LoginService from './LoginService';

export default {
  getLocation,
  getLocationLoc,
  setLocation,
  getLocations,
  getLocationFromServer,
  updateLocation,
  createLocation,
  getTotalLocationsCount,
  getOutletsForLocation,
  updateLocationFormData,
  enableOutlets,
  getChildLocations,
  viewableLocations,
  getOutlets,
  checkSlug,
  updateLocationTimings,
  getAllLocations,
  getDeliveryProcessorInfo,
  getDeliveriesForLocation,
  getDepartmentsForLocation,
  updateURLScheme,
  getDeliveryAreas,
  getLocationAnalytics
};

async function getDeliveriesForLocation(locationId, type = null) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DELIVERIES(locationId)}${
      type !== null ? `?type=${type}` : ''
    }`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch outlets');
}

async function checkSlug(slug) {
  const { token } = LoginService.getToken();
  const url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.CHECK_SLUG}?name=${slug}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  if (response.status !== 200) return false;
  return true;
}

function getLocation(key = `${CONSTANTS.CACHE_KEY_PREFIX}_location`) {
  let location = localStorage.getItem(key);
  if (location) return JSON.parse(location);
  return null;
}

function getLocationAnalytics(key = `${CONSTANTS.CACHE_KEY_PREFIX}_location_analytics`) {
  let location = localStorage.getItem(key);
  if (location) return JSON.parse(location);
  return null;
}

function getLocationLoc(key = `${CONSTANTS.CACHE_KEY_PREFIX}_location`) {
  let data = localStorage.getItem(key);
  let location = JSON.parse(data);
  if (location && location?.loc_id) {
    location.deliveryAreas = getDeliveryAreas(location);
    return location;
  } else {
    return null;
  }
}

function setLocation(location, key = `${CONSTANTS.CACHE_KEY_PREFIX}_location`) {
  localStorage.setItem(key, JSON.stringify(location));
}

function setTotalLocationsCount(count) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location_count`, count);
}

function getTotalLocationsCount() {
  let count = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location_count`);
  if (count === null) return 5;
  return count;
}

async function getLocations(searchTerm, showOutlets = null) {
  const { token } = LoginService.getToken();
  const url =
    showOutlets === null
      ? `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}?name=${searchTerm}`
      : `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}?name=${searchTerm}&is_outlet=${showOutlets}`;
  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (searchTerm === '') {
    setTotalLocationsCount(response.length);
  }
  if (response) return response;

  throw new Error('Could not fetch locations');
}

async function getAllLocations(showOutlets = null) {
  const { token } = LoginService.getToken();
  const url =
    showOutlets === null
      ? `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.ALL_LOCATIONS}`
      : `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.ALL_LOCATIONS}?is_outlet=${showOutlets}`;
  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();

  if (response) return response;

  throw new Error('Could not fetch locations');
}

async function getOutletsForLocation(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.OUTLETS(locationId)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch outlets');
}

async function getDepartmentsForLocation(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEPARTMENTS(locationId)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch departments');
}

async function getLocationFromServer(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}/${locationId}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );

  response = await response.json();
  if (response) {
    response.deliveryAreas = getDeliveryAreas(response);
    response.options = response.options ? unserialize(response.options) : response.options;
    if (response.SpecialEvents.length > 0) {
      for (let i = 0; i < response.SpecialEvents.length; i++) {
        response.SpecialEvents[i].event_timings = response.SpecialEvents[i].event_timings
          ? await JSON.parse(response.SpecialEvents[i].event_timings)
          : response.SpecialEvents[i].event_timings;
        response.SpecialEvents[i].isServer = 1;
      }
    }

    localStorage.setItem('currency_code', response.Currency?.currency_code);
    localStorage.setItem('decimal_place', response.decimal_places);
    localStorage.setItem('tax_percentage', response.tax_percent);

    if (response.is_outlet && response.OutletStockStatuses.length > 0) {
      response.OutletStocks = _convertArrayToMap(response.OutletStockStatuses);
    }

    if (response.is_outlet && response.OutletStockStatuses.length > 0) {
      const stockStatuses = {};
      const categoryStatuses = {};
      for (let j = 0; j < response.OutletStockStatuses.length; j++) {
        if (response.OutletStockStatuses[j].is_category) {
          categoryStatuses[response.OutletStockStatuses[j].menu_id] =
            response.OutletStockStatuses[j];
        } else {
          stockStatuses[response.OutletStockStatuses[j].menu_id] = response.OutletStockStatuses[j];
        }
      }
      response.stockStatuses = stockStatuses;
      response.categoryStatuses = categoryStatuses;
    }
    return response;
  }

  throw new Error('Could not fetch location');
}

async function getDeliveryProcessorInfo(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.GET_DELIVERY_PROCESSORS(locationId)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error('Could not fetch location');
}

async function updateLocation(locationData) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}/${locationData.loc_id}`,
    {
      body: JSON.stringify(locationData),
      method: 'PATCH',
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        'X-ACCESS-TOKEN': token,
        'Content-Type': 'application/json'
      }
    }
  );
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error('Could not save location');
}

async function updateURLScheme(loc_id, scheme) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}/scheme`, {
    body: JSON.stringify({ loc_id, scheme }),
    method: 'POST',
    headers: {
      Accept: CONSTANTS.REQUEST_HEADERS.Accept,
      'X-ACCESS-TOKEN': token,
      'Content-Type': 'application/json'
    }
  });
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error('Could not save scheme');
}

async function updateLocationTimings(loc_id, options) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.UPDATE_TIMINGS(loc_id)}`,
    {
      body: options,
      method: 'PATCH',
      headers: { Accept: CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) {
    response.options = response.options ? unserialize(response.options) : response.options;
    if (response.SpecialEvents.length > 0) {
      for (let i = 0; i < response.SpecialEvents.length; i++) {
        response.SpecialEvents[i].event_timings = response.SpecialEvents[i].event_timings
          ? await JSON.parse(response.SpecialEvents[i].event_timings)
          : response.SpecialEvents[i].event_timings;
        response.SpecialEvents[i].isServer = 1;
      }
    }
    if (response.is_outlet && response.OutletStockStatuses.length > 0) {
      response.OutletStocks = _convertArrayToMap(response.OutletStockStatuses);
    }
    return response;
  }
  throw new Error('Could not update timings');
}

async function updateLocationFormData(locationData) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}/${locationData.loc_id}`,
    {
      body: locationData,
      method: 'PATCH',
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        'X-ACCESS-TOKEN': token
      }
    }
  );
  response = await response.json();
  if (response) {
    response.options = response.options ? unserialize(response.options) : response.options;
    return response;
  }
  throw new Error('Could not save location');
}

async function enableOutlets(body) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.ENABLE_OUTLETS}`, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not enable outlets for location');
}

async function createLocation(locationData) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}`, {
    body: locationData,
    method: 'POST',
    headers: {
      Accept: CONSTANTS.REQUEST_HEADERS.Accept,
      'X-ACCESS-TOKEN': token
    }
  });
  response = await response.json();
  if (response) {
    response.options = response.options ? unserialize(response.options) : response.options;
    return response;
  }
  throw new Error('Could not create new location');
}

function _convertArrayToMap(outletStock) {
  const stock = {};
  for (let i = 0; i < outletStock.length; i++) {
    const st = outletStock[i];
    stock[st.menu_id] = st;
  }
  return stock;
}

async function getChildLocations(parentId, geoLocation) {
  let childLocations = await fetch(
    `${CONSTANTS.API_URL}/customers/locations/${parentId}/children`,
    {
      method: 'GET'
    }
  );
  childLocations = await childLocations.json();
  if (childLocations) return viewableLocations(childLocations, geoLocation);
  throw new Error('Could not get nearest locations');
}

async function getOutlets(parentId) {
  let childLocations = await fetch(`${CONSTANTS.API_URL}/customers/locations/${parentId}/outlets`, {
    method: 'GET'
  });
  childLocations = await childLocations.json();
  if (childLocations) return viewableLocations(childLocations);
  throw new Error('Could not get nearest locations');
}

function viewableLocations(locations, geoLocation, diameter = null) {
  let filteredLocations = locations.filter((location) => location.location_status && location.slug);
  let newLocations = filteredLocations.map((location) => {
    let deliveryAreas = getDeliveryAreas(location);
    let distance = '';
    if (geoLocation)
      distance = GoogleHelper.distanceBetweenTwoLatLngStrings(
        geoLocation,
        `${location.latitude},${location.longitude}`
      );
    return { ...location, deliveryAreas, distance };
  });
  if (diameter !== null) {
    newLocations = newLocations.filter((loc) => loc.distance <= diameter);
  }
  newLocations.sort((a, b) => a.distance - b.distance);
  return newLocations;
}

function getDeliveryAreas(location) {
  if (location.options) {
    let serializedOptions = unserialize(location.options);
    if (serializedOptions.delivery_areas) {
      let deliveryAreas = [];
      Object.keys(serializedOptions.delivery_areas).forEach((areaKey) => {
        let area = serializedOptions.delivery_areas[areaKey];
        if (area.status) {
          let newArea = { ...area };
          newArea.shape = area.shape ? JSON.parse(area.shape) : null;
          newArea.circle = area.circle ? JSON.parse(area.circle) : null;
          newArea.vertices = area.vertices ? JSON.parse(area.vertices) : null;
          let charges = Object.keys(area.charge).map((key) => {
            let amount = area.charge[key].amount;
            let total = area.charge[key].total;
            if (amount !== '')
              return {
                amount: parseFloat(amount),
                total: parseFloat(total),
                condition: area.charge[key].condition
              };
            return null;
          });
          newArea.charges = charges.filter((charge) => charge !== null);
          deliveryAreas.push(newArea);
        }
      });
      return deliveryAreas;
    }
  }
  return [];
}
