import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import styled from 'styled-components';

export const StyledPos = styled.div`
  //height: 100vh;
  overflow: hidden;
  display: flex;
  background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#F8F8F8')};

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .item-list-wrapper {
    @media (max-width: 768px) {
      padding-top: 60px;
    }
    @media (max-width: 540px) {
      padding-top: 150px;
    }
  }

  .arrow-div-right,
  .arrow-div-left {
    width: 48px !important;
    height: 48px !important;
    background: #c2c6dc;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    img {
      width: 20px;
    }
  }

  .arrow-div-left {
    margin-right: 15px;
  }

  .arrow-div-right {
    margin-left: 15px;
  }

  .checkout-mobile {
    @media (max-width: 768px) {
      height: 50px;
      background: #313131;
      z-index: 999;
      position: fixed;
      width: 100%;
      bottom: 0;
      display: ${(props) => (props.cartVisibility === true ? 'none !important' : 'block')};
    }
  }

  .container {
    //margin-top: 64px;
    margin-right: 0;
    //max-width: calc(100% - 84px);
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    overflow-x: hidden;
    z-index: 2;

    .row {
      .col-md-8 {
        padding-right: 0;
        padding-left: 0;
        @media (max-width: 768px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      .col-md-4 {
        padding-left: 0;
        transition: 0.1s;

        @media (max-width: 768px) {
          position: fixed;
          padding-right: 0 !important;
          width: 100%;
          height: 100vh;
          margin-right: 0;
          z-index: 10;
          top: 128px;
          flex: 0 0 100%;
          max-width: 100%;
          right: ${(props) => (props.cartVisibility === true ? 0 : '100%')};
          .item-card {
            display: block;
          }
        }
      }

      &:nth-of-type(2) {
        .col-md-12 {
          div {
            width: 100%;

            div {
              &::-webkit-scrollbar {
                width: 0;
              }
            }
          }
        }
      }
    }

    .m-0 {
      @media (max-width: 768px) {
        margin-left: -14px !important;
        margin-right: -14px !important;
      }
    }

    .category-parent {
      position: fixed;
      overflow: hidden;
      width: 100%;
      height: auto;
      min-height: 0px;
      max-height: 200px;
      z-index: 2;
      background: ${(props) => (props.theme === 'dark' ? 'rgb(38, 44, 73)' : '#fff')};
      border-top: 1px solid rgb(165, 165, 165);
      bottom: 0px;

      @media (min-width: 1200px) {
        width: ${(props) =>
          props.sidebarCollapsed === true
            ? 'calc(100% - 80px) !important'
            : 'calc(100% - 260px) !important'};
      }

      @media (max-width: 768px) {
        width: calc(100% + 48px) !important;
        top: 61px !important;
        border-top: unset !important;
        border-bottom: 1px solid rgb(165, 165, 165);
        max-height: 74px !important;
        display: flex;
        align-items: baseline;
      }
    }

    @media (max-width: 768px) {
      overflow-x: unset;
      max-width: 100%;
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  .item-categories {
    background: ${(props) => (props.theme === 'dark' ? '#262c49' : '#fff')};
    width: 100% !important;
    padding: 20px 20px 10px 0px;
    display: flex;
    //border-top: 1px solid #A5A5A5;
    flex-direction: row;
    //justify-content: ${(props) => (props.categoryLength < 9 ? 'flex-start' : 'space-between')};
    height: ${(props) =>
      props.categoryLength > 0 ? 'unset' : props.categoryLength > 0 ? '140px' : '120px'};
    justify-content: left;
    align-items: center;
    column-gap: 15px;
    //overflow: auto;
    //scroll-behavior: smooth;
    //position: fixed;
    //bottom: 0;
    //width: 92%;

    ul {
      //display: flex;
      margin-left: -20px;
      margin-bottom: 0px;
      width: 100%;

      li {
        &:last-of-type {
          margin-right: 0 !important;
        }

        @media (min-width: 769px) {
          background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#fff')};
          border-radius: 3px;
          .category {
            background: #10163a;
          }

          .category-bottom {
            color: #00cdbe;
          }
        }
        @media (max-width: 768px) {
          text-align: left;
          border-radius: 25px;
          margin-top: unset;
          margin-right: 8px !important;
          background: #10163a;
        }

        &.active {
          @media (min-width: 769px) {
            border-radius: 3px;
            background: #00cdbe !important;
            .category {
              background: #00cdbe !important;
            }

            .category-bottom {
              color: #ffffff;
            }
          }
          @media (max-width: 768px) {
            border-radius: 25px;
            background: #00cdbe !important;
            .mobile-category {
              margin-bottom: 0;
              background: #00cdbe !important;
            }
          }
        }
      }

      a {
        display: flex;
        //height: 100px;
        //width: 100px;
        align-items: center;
        justify-content: center;
      }
    }

    @media (max-width: 768px) {
      width: 100% !important;
      .swiper-slide {
        width: fit-content !important;
      }
    }

    .swiper-container {
      //padding-bottom: 15px;
      //overflow: auto;
      //&:-webkit-scrollbar {
      //  width: 0;
      //}
      .swiper-wrapper {
        overflow: auto;
        padding-bottom: 10px;

        .swiper-slide {
          width: 120px !important;
          height: 80px !important;
          margin-right: 15px;
          @media (max-width: 768px) {
            height: unset !important;
            width: unset !important;
          }
        }

        &::-webkit-scrollbar {
          height: 5px;
          @media (max-width: 768px) {
            height: 0;
            width: 0;
          }
        }

        &::-webkit-scrollbar-track {
          background: #343d69;
          width: 4px;
          border-radius: 5px;
        }

        /* Handle */

        &::-webkit-scrollbar-thumb {
          background: #7367f0;
          border-radius: 5px;
        }

        /* Handle on hover */

        &::-webkit-scrollbar-thumb:hover {
          background: #7367f0;
        }
      }

      .swiper-scrollbar {
        display: none;

        .swiper-scrollbar-drag {
          display: none;
        }
      }
    }
  }

  &__category {
    width: 100px !important;
  }

  .category-bottom {
    font-family: Larsseit, sans-serif !important;
    font-size: 14px;
    font-weight: normal;
    line-height: 15px;
    color: #00cdbe;
    @media (max-width: 768px) {
      //color: #00CDBE;
      text-align: center;
      height: fit-content;
    }

    &:hover {
      color: #ffffff;
    }
  }

  .category {
    background: #10163a;
    padding: 15px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    overflow: hidden;
    //flex: 0 0 calc(25% - 15px);
    flex: 0 0 25%;
    column-gap: 15px;
    //margin-bottom: 15px;
    //border: 1px solid #3b5998;
    //max-width: 100px;
    max-height: 80px;
    //width: 100px !important;
    height: 80px !important;
    transition: 0.3s;
    border-radius: 3px;
    //row-gap: 6px;
    text-align: center;

    .icon {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: -moz-none;
      -o-user-select: none;

      svg {
        stroke: #313131;
        @media (max-width: 768px) {
          width: 24px;
          height: 24px;
        }
      }
    }

    p {
      margin-bottom: 0;
      width: fit-content;
      padding: 0 5px;
      font-family: Larsseit, sans-serif !important;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: -moz-none;
      -o-user-select: none;
      user-select: none;
    }

    .icon {
      display: block;
    }

    @media (max-width: 1280px) {
      height: 80px !important;
    }

    @media (max-width: 768px) {
      display: none !important;
      //width: 100px;
      height: 60px;
      max-width: 100px;
      max-height: 60px;
      display: flex;
      align-items: center;
      row-gap: 0px;
      .icon {
        display: none !important;
      }
    }
  }

  @media (max-width: 768px) {
    column-gap: 10px;
    &__category {
      width: fit-content !important;
    }
  }

  .mobile-category,
  mobile-category-active {
    background: #10163a !important;
    color: #ffffff !important;
    padding: 8px 12px;
    border-radius: 25px;
    min-width: 65px;
    width: 100%;
    text-align: center;
    //margin-top: 8px;

    span {
      color: #ffffff !important;
      font-family: Larsseit, sans-serif !important;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }

    @media (min-width: 769px) {
      display: none !important;
    }
  }

  .mobile-category-active {
    background: #00cdbe !important;
    color: #ffffff !important;
    padding: 8px 12px;
    border-radius: 25px;
    min-width: 65px;
    text-align: center;
    //margin-top: 8px;
    font-family: Larsseit, sans-serif !important;

    span {
      color: #ffffff !important;
      font-family: Larsseit, sans-serif !important;
    }

    @media (min-width: 769px) {
      display: none !important;
    }
  }

  .items {
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: calc(100vh - 140px);
    padding-bottom: 100px;
    overflow: auto;
    scroll-behavior: smooth;
    padding-right: 5px;
    padding-left: 34px;

    //&::-webkit-scrollbar {
    //  width: 0;
    //}
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */

    &::-webkit-scrollbar-track {
      background: #343d69;
      width: 4px;
      border-radius: 5px;
    }

    /* Handle */

    &::-webkit-scrollbar-thumb {
      background: #7367f0;
      border-radius: 5px;
    }

    /* Handle on hover */

    &::-webkit-scrollbar-thumb:hover {
      background: #7367f0;
    }

    @media (max-width: 991px) {
      //padding-top: 50px;
      padding-right: 20px;
      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .category-name {
      font-family: Larsseit, sans-serif !important;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 10px;
      margin-top: 10px;
      color: ${(props) => (props.theme === 'dark' ? '#fff' : '#2C2C2C')};
    }

    .item-parent {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &__wrapper {
        cursor: pointer;
        padding-top: calc(120 / 140 * 100%);
        position: relative;
      }
    }

    .item-disabled {
      opacity: 0.3;
      //pointer-events: none;
    }

    .item {
      // background: #6057CC;
      //flex: 0 0 calc(25% - 15px);
      //column-gap: 15px;
      //margin-bottom: 15px;
      //margin-right: 15px;
      transition: 0.3s;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: unset !important;
      max-width: 25%;
      flex-basis: calc(25%);
      padding: 6px 12px 6px 0px;
      background: unset;

      .item-parent {
        &__wrapper {
          background: #6057cc;
        }
      }

      @media (min-width: 1600px) {
        max-width: 16%;
        flex-basis: calc(16%);
      }
      @media (min-width: 1440px) {
        max-width: 20%;
        flex-basis: calc(20%);
      }
      @media (min-width: 1300px) {
        max-width: 20%;
        flex-basis: calc(20%);
      }
      @media (max-width: 1280px) {
        max-width: 20%;
        flex-basis: calc(20%);
      }
      @media (max-width: 1260px) {
        max-width: 25%;
        flex-basis: calc(25%);
      }
      @media (max-width: 1199px) {
        max-width: 20%;
        flex-basis: calc(20%);
      }
      @media (max-width: 991px) {
        max-width: 25%;
        flex-basis: calc(25%);
      }
      @media (max-width: 640px) {
        max-width: 33%;
        flex-basis: calc(33%);
      }
      @media (max-width: 426px) {
        max-width: 33%;
        flex-basis: calc(33%);
      }
      @media (max-width: 376px) {
        max-width: 50%;
        flex-basis: calc(50%);
      }

      //@media(max-width: 1020px){
      //  flex: unset;
      //  margin-right: 23px;
      //  margin-bottom: 23px;
      //}
      //@media(max-width: 1014px){
      //  margin-right: 22px !important;
      //  margin-bottom: 22px !important;
      //}
      //@media(max-width: 992px){
      //  flex: unset !important;
      //  margin-right: 18px !important;
      //  margin-bottom: 18px !important;
      //}
      //@media(max-width: 769px){
      //  margin-right: 8px !important;
      //  margin-bottom: 8px !important;
      //}
      //@media(max-width: 426px){
      //  width: 120px;
      //}
      &:nth-of-type(even) {
        .item-parent {
          &__wrapper {
            background: #8176f5;
          }
        }
      }

      .menu-name {
        color: rgba(255, 255, 255, 0.9);
        font-size: 16px;
        font-weight: normal;
        font-family: Larsseit, sans-serif !important;
        line-height: 18px;
        position: absolute;
        top: 15px;
        left: 10px;
        right: 10px;

        @media (max-width: 1920px) {
          height: 56px;
          overflow: hidden;
        }

        @media (max-width: 1366px) {
          height: 36px !important;
          overflow: hidden;
        }

        @media (max-width: 768px) {
          height: 56px;
          overflow: hidden;
        }

        @media (max-width: 530px) {
          height: 36px;
          overflow: hidden;
        }
        @media (max-width: 426px) {
          top: 5px;
        }
      }

      .menu-price {
        //display: flex;
        //align-items: center;
        //justify-content: space-between;
        p {
          color: rgba(255, 255, 255, 0.9);
          font-size: 15px;
          font-weight: 300;
          font-family: Larsseit, sans-serif !important;
          position: absolute;
          bottom: 5px;
          left: 10px;
          right: 10px;
          margin: 0;
          z-index: 2;

          &:nth-of-type(2) {
            font-size: 14px;
            bottom: 26px;
            color: #ffffff87 !important;
          }
        }
      }

      .quantity {
        font-size: 60px;
        font-weight: 500;
        font-family: Larsseit, sans-serif !important;
        color: #9c94f6;
        position: absolute;
        bottom: -15px;
        right: 10px;
        z-index: 1;

        @media (max-width: 1920px) {
          right: 10px;
          bottom: -10px;
          font-size: 44px;
        }
        @media (max-width: 1366px) {
          right: 11px;
          bottom: -7px;
          font-size: 40px;
        }
        @media (max-width: 425px) {
          right: 11px;
          bottom: -8px;
          font-size: 40px;
        }
        @media (max-width: 376px) {
          right: 10px;
          bottom: -10px;
        }
      }

      // &:hover {
      //   cursor: pointer;
      //   background: ${(props) => (props.theme === 'dark' ? '#39346a' : '#313131')} !important;
      //   color: #fff;
      //
      //   //.menu-name {
      //   //  color: #FFFFFF;
      //   //  line-height: 16px;
      //   //}
      //
      //   .menu-price {
      //     color: #FFFFFF;
      //   }
      //
      //   .quantity {
      //     color: ${(props) => (props.theme === 'dark' ? '#9C94F6' : '#D1D1D1')};
      //   }
      // }

      &:focus {
        //background: ${(props) => (props.theme === 'dark' ? '#39346a' : '#313131')} !important;
        color: #fff;

        .menu-name {
          color: #ffffff;
        }

        .menu-price {
          color: #ffffff;
        }

        .quantity {
          color: ${(props) => (props.theme === 'dark' ? '#9C94F6' : '#D1D1D1')};
        }
      }

      @media (max-width: 990px) {
        flex: 0 0 calc(33% - 15px);
      }
      @media (max-width: 780px) {
        flex: 0 0 calc(50% - 15px);
      }
      @media (max-width: 376px) {
        flex: 0 0 calc(50% - 6px);
      }
    }
  }

  .checkout-mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }

    button {
      padding: unset;
      width: 100%;
      height: 100%;
      outline: unset;
      background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
      color: #ffffff;
      font-size: 16px;
      font-weight: normal;
      font-family: Larsseit, sans-serif !important;
      line-height: 20px;
      outline: unset;
      border: unset;
    }
  }

  .swiper-pagination {
    opacity: 0;
    z-index: -9;
  }

  .swiper-scrollbar {
    position: absolute;
    bottom: 0px;

    .swiper-scrollbar-drag {
      background: #9c94f6 !important;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledItemModal = styled(Rodal)`
  background: rgba(49, 49, 49, 0.9);

  .modal-content {
    box-shadow: unset;
    height: 100%;
    background: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 80%;
  }

  .item-modal-parent {
    overflow: unset;
    width: 100%;

    .Required.active-required {
      color: #ff0202;
    }

    &__header {
      display: none;
      justify-content: space-between;
      background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#f7f7f7')};
      padding: 16px 20px;
      align-items: center;
      border-top-right-radius: 8px;

      h2 {
        color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#2C2C2C')};
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
        font-family: Larsseit, sans-serif !important;
      }

      button {
        border: unset;
        font-size: 16px;
        line-height: 20px;
        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
        background: unset;
        font-family: Larsseit, sans-serif !important;
      }
    }

    .complete-mobile {
      display: none;

      &__right-side {
        display: flex;
        align-items: center;
        column-gap: 4px;

        h2 {
          &:nth-of-type(2) {
            font-size: 14px;
            color: #ffffff87 !important;
            text-decoration: line-through;
          }
        }
      }
    }

    @media (max-width: 768px) {
      &__header {
        display: flex;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 9;
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
      }

      .complete-mobile {
        display: flex;
        position: fixed;
        bottom: 0px;
        background: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
        color: #fff;
        padding: 20px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        button {
          background: unset;
          color: #fff;
          border: unset;
          font-family: Larsseit, sans-serif !important;
          font-size: 20px;
          font-weight: normal;
          line-height: 20px;
        }

        h2 {
          color: #ffffff;
          font-size: 16px;
          font-weight: 500;
          font-family: Larsseit, sans-serif !important;
          line-height: 16px;
          margin: 0;
        }
      }
    }
  }

  .item-modal {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //width: 90%;
    width: 100%;
    background: ${(props) => (props.theme === 'dark' ? '#10163A' : '#F9F9F9')};
    border-radius: 8px;
    outline: unset;
    height: ${(props) => (props.isLandspace === true ? '80vh' : '100vh')};

    @media (max-width: 768px) {
      flex-direction: column;
      height: 100vh;
      //height: 92vh;
      //top: calc(54% + 1px);
      overflow: auto;
      width: 100%;
      border-radius: unset;
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #343d69;
        width: 4px;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #7367f0;
        border-radius: 5px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
      }
    }

    &__details {
      width: 60%;
      display: flex;
      flex-direction: column;
      overflow: auto;
      background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#F7F7F7')};

      &__image {
        position: relative;
        width: 100%;
        padding-top: calc(440 / 670 * 100%);

        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: 8px;
          overflow: hidden;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .stock-out {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        font-family: Larsseit, sans-serif !important;
        color: #ea5455;
      }

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 50px;
        overflow: unset;
        //img {
        //  height: 360px;
        //  border-radius: unset;
        //}
      }

      div {
        //padding-top: 20px;

        .item-modal-increment {
          display: flex;
          justify-content: space-between;
          padding: 48px 20px;
          border-bottom: 1px solid #dddddd;
          align-items: center;

          .item-stock {
            h2 {
              font-size: 24px;
              line-height: 30px;
              color: ${(props) => (props.theme === 'dark' ? '#FFF' : '#313131')};
              font-family: Larsseit, sans-serif !important;

              &:nth-of-type(1) {
                @media (max-width: 768px) {
                  font-size: 20px;
                }
              }

              &:nth-of-type(2) {
                font-size: 16px;
                line-height: 16px;
                color: ${(props) => (props.theme === 'dark' ? '#FFF' : '#A5A5A5')};
                margin: 0;
                font-family: Larsseit, sans-serif !important;
                @media (min-width: 769px) {
                  display: none;
                }
              }

              &:nth-of-type(3) {
                display: none;
                @media (max-width: 768px) {
                  display: block;
                  color: #313131;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18px;
                  color: ${(props) => (props.theme === 'dark' ? '#FFF' : '#313131')};
                  padding-top: 8px;
                  margin: 0;
                  font-family: Larsseit, sans-serif !important;
                }
              }
            }
          }

          .item-increment {
            .MuiInputBase-input {
              font-weight: normal;
              font-family: Larsseit, sans-serif !important;
              font-size: 16px;
              line-height: 20px;
            }

            .MuiInput-underline:after {
              border-bottom: 1px solid rgba(97, 218, 251, 0);
            }

            .MuiInput-underline:before {
              border-bottom: 1px solid rgba(97, 218, 251, 0);
            }

            .MuiInput-underline:hover:not(.Mui-disabled):before {
              border-bottom: 1px solid rgba(97, 218, 251, 0);
            }

            .MuiInput-underline:hover:not(.Mui-disabled):after {
              border-bottom: 1px solid rgba(97, 218, 251, 0);
            }

            @media (max-width: 768px) {
              display: flex;
              justify-content: space-between;
              align-items: end;
              width: 100%;
            }

            .input-field {
              width: 64px;
              height: 36px;
              justify-content: center;
              align-items: center;
              background-color: #e1e4e6;
              border-radius: 2px;
              padding: 8px;
              color: #a5a5a5;
              font-weight: 100;
              font-font: Larsseit, sans-serif !important;
              font-size: 28px;
            }

            .increase-buttons {
              @media (max-width: 768px) {
                padding-top: 20px;
              }
              //width: 60%;
              display: flex;
              justify-content: flex-end;
              column-gap: 8px;

              .remove-item {
                border: unset;
                outline: unset;
                background: #10163a;
                border-radius: 3px;
                width: 36px;
                height: 36px;
                margin-left: 8px;

                img {
                  width: 16px;
                  height: 16px;
                }
              }

              span {
                background: #e1e4e6;
                border: unset;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 28px;
                color: #10163a;
                font-weight: 300;
                cursor: pointer;
                width: 36px;
                height: 36px;
                align-items: center;
                text-align: center;
                font-font: Larsseit, sans-serif !important;

                span {
                  cursor: unset;
                  background: #e1e4e6;
                  padding: 0px 24px;
                  font-size: 16px;
                  display: flex;
                  cursor: pointer;
                  justify-content: center;
                  align-items: center;
                  border-radius: 3px;
                  font-family: Larsseit, sans-serif !important;
                }

                &:nth-of-type(2) {
                  color: #10163a;
                  font-size: 28px;
                  width: fit-content;
                  line-height: 20px;
                  font-weight: 400;
                  width: 36px;
                  cursor: pointer;
                  height: 36px;
                }
              }
            }

            .item-stock-data {
              display: none;

              h2 {
                font-size: 16px;
                line-height: 16px;
                color: #a5a5a5;
                margin: 0;
                font-family: Larsseit, sans-serif !important;
              }

              @media (max-width: 768px) {
                display: block;
              }
            }
          }

          @media (max-width: 768px) {
            align-items: flex-start;
            flex-direction: column;
          }
        }

        .item-modal-content {
          padding: 20px;
          //height: 50px;
          //overflow: auto;
          //&::-webkit-scrollbar {
          //  width: 5px;
          //}
          //
          ///* Track */
          //&::-webkit-scrollbar-track {
          //  background: #343D69;
          //  width: 4px;
          //  border-radius: 5px;
          //}
          //
          ///* Handle */
          //&::-webkit-scrollbar-thumb {
          //  background: #7367F0;
          //  border-radius: 5px;
          //}
          //
          ///* Handle on hover */
          //&::-webkit-scrollbar-thumb:hover {
          //  background: #7367F0;
          //}
          @media (max-width: 768px) {
            display: none;
          }

          h2 {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#313131')};
            font-family: Larsseit, sans-serif !important;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #343d69;
        width: 4px;
        border-radius: 5px;
      }

      /* Handle */

      &::-webkit-scrollbar-thumb {
        background: #7367f0;
        border-radius: 5px;
      }

      /* Handle on hover */

      &::-webkit-scrollbar-thumb:hover {
        background: #7367f0;
      }
    }

    &__options {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: ${(props) => (props.isLandspace === true ? '80vh' : '100vh')};
      overflow: auto;
      background: ${(props) => (props.theme === 'dark' ? '#10163A' : '')};
      position: relative;
      border-top-right-radius: 8px;
      overflow: hidden;
      border-bottom-right-radius: 8px;

      &::-webkit-scrollbar {
        width: 0;
      }

      @media (max-width: 768px) {
        width: 100%;
        height: unset;
        overflow: unset;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        &__header {
          display: none !important;
        }

        .complete {
          display: none !important;
          width: 100%;
          bottom: 0;
          border-bottom-right-radius: 0 !important;

          button {
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }
        }
      }

      &__data {
        padding: 20px 20px 0 20px;
        @media (min-width: 769px) {
          //max-height: calc(100vh - 305px);
          padding-bottom: 100px;
          overflow: auto;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #343d69;
          width: 4px;
          border-radius: 5px;
        }

        /* Handle */

        &::-webkit-scrollbar-thumb {
          background: #7367f0;
          border-radius: 5px;
        }

        /* Handle on hover */

        &::-webkit-scrollbar-thumb:hover {
          background: #7367f0;
        }

        &__parent {
          .select-option-parent {
            margin-bottom: 20px;

            .select-option-item-detail {
              border-bottom: 1px solid rgb(209 209 209 / 50%);
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding: 18px 0;
              margin-left: 0;
              cursor: pointer;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .select-option-item-name {
                display: flex;
                width: 80%;
                justify-content: flex-start;
                align-items: center;
                column-gap: 2px;

                h2 {
                  &:nth-of-type(1) {
                    color: #00cdbe;
                  }
                }
              }

              h2 {
                color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
                margin: 0;
                font-family: Larsseit, sans-serif !important;
              }

              .option-price {
                color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
                font-weight: normal;
                font-family: Larsseit, sans-serif !important;
                font-size: 16px;
              }

              .select-option-item-price {
                width: 20%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                &__content {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  justify-content: flex-end;
                  align-items: flex-end;
                  row-gap: 4px;

                  &__price {
                    h2 {
                      color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
                      font-size: 16px;
                      line-height: 18px;
                      font-weight: 400;
                      margin: 0;
                      font-family: Larsseit, sans-serif !important;
                    }
                  }

                  &__increase-buttons {
                    display: flex;
                    column-gap: 4px;

                    button {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: 24px;
                      font-family: Larsseit, sans-serif !important;
                      color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
                      line-height: 16px;
                      border: unset;
                      outline: unset;
                      border-radius: 3px;
                      background: ${(props) => (props.theme === 'dark' ? '#262C49' : '#e9e9e9')};
                      width: 32px;
                      height: 32px;
                    }

                    span {
                      font-weight: bold;
                    }
                  }
                }

                @media (max-width: 1280px) {
                  width: 60%;
                  justify-content: flex-end;
                }

                span {
                  color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};

                  &:first-child {
                    display: flex;
                    justify-content: flex-start;
                    column-gap: 8px;

                    button {
                      border-radius: 3px;
                      background: #e9e9e9;
                      padding: 8px 16px;
                      border: unset;
                      font-size: 20px;
                      width: 32px;
                      height: 32px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-family: Larsseit, sans-serif !important;

                      &:first-child {
                        color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
                      }
                    }
                  }

                  &:nth-of-type(2) {
                    width: 45%;
                    display: flex;
                    font-size: 16px;
                    font-weight: normal;
                    justify-content: flex-end;
                    font-family: Larsseit, sans-serif !important;
                    @media (max-width: 1100px) {
                      width: 35%;
                    }
                    @media (max-width: 991px) {
                      width: 20%;
                    }
                  }
                }
              }
            }
          }

          .option-header {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;

            h2 {
              color: #a5a5a5;
              font-size: 16px;
              line-height: 20px;
              font-weight: 400;
              font-family: Larsseit, sans-serif !important;
            }

            h3 {
              font-size: 12px;
              font-family: Larsseit, sans-serif !important;
              color: #a5a5a5;
              font-weight: 300;
              font-style: italic;
              line-height: 14px;
            }
          }

          .MuiFormGroup-root {
            margin-bottom: 20px;

            .MuiFormControlLabel-root {
              border-bottom: 1px solid rgb(209 209 209 / 50%);
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding: 5px 0;
              margin-left: 0;

              .MuiCheckbox-root {
                color: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#707070')};
                padding-left: 0;
              }

              .MuiTypography-root {
                width: 100%;

                .option-item-detail {
                  width: 100%;
                  display: flex;
                  padding: 15px 0;
                  justify-content: space-between;
                  align-items: center;

                  h2 {
                    color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 400;
                    margin: 0;
                    font-family: Larsseit, sans-serif !important;
                  }

                  .option-price {
                    color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
                    font-weight: normal;
                    font-family: Larsseit, sans-serif !important;
                    font-size: 16px;
                  }

                  .option-item-price {
                    width: 50%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    @media (max-width: 1280px) {
                      width: 60%;
                      justify-content: flex-end;
                    }
                    @media (max-width: 375px) {
                      width: 20%;
                    }

                    span {
                      color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};

                      &:first-child {
                        display: flex;
                        justify-content: flex-start;
                        column-gap: 8px;

                        button {
                          border-radius: 3px;
                          background: #e9e9e9;
                          padding: 8px 16px;
                          border: unset;
                          font-size: 20px;
                          width: 32px;
                          height: 32px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          font-family: Larsseit, sans-serif !important;

                          &:first-child {
                            color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#A5A5A5')};
                          }
                        }
                      }

                      &:nth-of-type(2) {
                        width: 45%;
                        display: flex;
                        font-size: 16px;
                        font-weight: normal;
                        justify-content: flex-end;
                        font-family: Larsseit, sans-serif !important;
                        @media (max-width: 1100px) {
                          width: 35%;
                        }
                        @media (max-width: 991px) {
                          width: 20%;
                        }
                      }
                    }
                  }
                }
              }

              .MuiRadio-colorSecondary.Mui-checked {
                color: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
                .MuiIconButton-label {
                  color: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#707070')};
                }
              }

              .MuiRadio-root {
                color: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#707070')};
                padding-left: 0;
              }

              .MuiIconButton-colorSecondary:hover {
                background: unset;
              }

              .MuiCheckbox-colorSecondary.Mui-checked {
                color: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#313131')};
                .MuiIconButton-label {
                  color: ${(props) => (props.theme === 'dark' ? '#00CDBE' : '#707070')};
                }
              }
            }

            //&:last-child{
            //  margin-bottom: 0;
            //}
          }

          .MuiFormControl-root {
            width: 100%;
          }
        }
      }

      .note {
        //padding: 20px 20px 20px 20px;
        @media (max-width: 768px) {
          padding: 20px 0px 100px 0px;
        }

        h2 {
          font-size: 16px;
          line-height: 24px;
          color: ${(props) => (props.theme === 'dark' ? '#A5A5A5' : '#313131')};
          font-weight: 400;
          padding-bottom: 20px;
          margin: 0;
          font-family: Larsseit, sans-serif !important;
        }

        textarea {
          width: 100%;
          height: 120px;
          border-radius: 5px;
          padding: 20px;
          font-family: Larsseit, sans-serif !important;
          border: 1px solid ${(props) => (props.theme === 'dark' ? '#ced4da' : '#ced4da')};
          background: transparent;
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};

          &:focus {
            outline: none;
          }

          @media (max-width: 768px) {
            height: 80px;
          }
        }
      }

      &__header {
        display: flex;
        justify-content: space-between;
        background: ${(props) => (props.theme === 'dark' ? '#6057CC' : '#f7f7f7')};
        padding: 16px 20px;
        align-items: center;
        border-top-right-radius: 8px;

        h2 {
          color: ${(props) => (props.theme === 'dark' ? '#FFFFFF' : '#2C2C2C')};
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
          font-family: Larsseit, sans-serif !important;
        }

        button {
          border: unset;
          font-size: 16px;
          line-height: 20px;
          color: ${(props) => (props.theme === 'dark' ? '#C2C6DC' : '#313131')};
          background: unset;
          font-family: Larsseit, sans-serif !important;
        }
      }
    }

    &__options {
      .complete {
        position: absolute;
        bottom: 0;
        background: #00cdbe;
        color: #fff;
        border-bottom-right-radius: 8px;
        padding: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        z-index: 10000;
        button {
          background: unset;
          color: #fff;
          border: unset;
          font-family: Larsseit, sans-serif !important;
          font-size: 20px;
          font-weight: normal;
          line-height: 20px;
        }

        h2 {
          color: #ffffff;
          font-size: 16px;
          font-weight: 500;
          font-family: Larsseit, sans-serif !important;
          line-height: 16px;
          margin: 0;
        }

        &__right-side {
          display: flex;
          align-items: center;
          column-gap: 4px;

          h2 {
            &:nth-of-type(2) {
              font-size: 14px;
              color: #ffffff87 !important;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
`;
