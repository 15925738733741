import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import CONSTANTS from '../constants';
import LoginService from '../services/LoginService';

const baseQuery = fetchBaseQuery({
  baseUrl: CONSTANTS.API_URL,
  prepareHeaders: (headers) => {
    const { token } = LoginService.getToken();
    if (token) {
      headers.set('X-ACCESS-TOKEN', token);
    }
    headers.set('Accept', 'application/json');
    return headers;
  }
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  if (window.navigator.onLine) {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      try {
        await LoginService.refresh();
        result = await baseQuery(args, api, extraOptions);
      } catch (e) {
        LoginService.removeLoginData();
        window.location.href = '/';
      }
    }
    return result;
  }
};
