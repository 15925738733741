import _ from 'lodash';
import CONSTANTS from '../../../constants';

export default class OptionHelper {
  // Display Type Functions
  static getOrderType(orderTypes, orderType) {
    if (orderTypes.length === 0) return { order_type: orderType }; // When order type is already present
    return orderTypes.find((ot) => ot.id === orderType);
  }

  static isRadio(option) {
    return option.display_type === CONSTANTS.INPUTS.DISPLAY_TYPES.RADIO;
  }

  static isSelect(option) {
    return option.display_type === CONSTANTS.INPUTS.DISPLAY_TYPES.SELECT;
  }

  static isCheckbox(option) {
    return option.display_type === CONSTANTS.INPUTS.DISPLAY_TYPES.CHECKBOX;
  }

  static optionObject(selectedOption, selectedOptionValue) {
    return {
      option_id: selectedOption.option_id,
      option_name: selectedOption.option_name,
      option_value_id: selectedOptionValue.option_value_id,
      option_value_name: selectedOptionValue.value,
      price: selectedOptionValue.price,
      qty: selectedOptionValue.qty ? selectedOptionValue.qty : 1,
      serverSideOption: { ...selectedOption, Option_Values: [selectedOptionValue] }
    };
  }

  static getQuantity(options, optionValue) {
    return _.filter(options, (option) => option.option_value_id === optionValue.option_value_id)
      .length;
  }

  static getFormattedQuantity(options, optionValue) {
    let quantity = this.getQuantity(options, optionValue);
    if (quantity > 0) return `${quantity}x `;
    return '';
  }

  static getErrorMessage(errors, option) {
    let error = _.find(errors, (error) => error.option_id === option.option_id);
    if (error) return error.message;
    return '';
  }

  static optionsTotal(item, options, quantity, portionSize) {
    const itemPrice = item.Portion ? portionSize.price : item.menu_price;
    let total = parseFloat(itemPrice);
    options.map((option) => (total += parseFloat(option.price) * Number(option.qty) || 0.0));
    return total * quantity;
  }

  static isChecked(selectedOptions, optionValue) {
    let checked = false;
    if (_.find(selectedOptions, (so) => so.option_value_id === optionValue.option_value_id))
      checked = true;
    return checked;
  }

  static validationErrors(selectedOptions, allOptions) {
    let validationErrors = [];
    allOptions.forEach((item) => {
      item.MenuOptionValues.forEach((option) => {
        let currentOptions = _.filter(
          selectedOptions,
          (selectedOption) => selectedOption.option_id === option.option_id
        );
        let quantity = 0;
        currentOptions.forEach((opt) => (quantity += opt.qty));
        if (item.required && parseInt(item.required, 10) && quantity === 0)
          validationErrors.push({
            option_id: option.option_id,
            message: item.Option.option_name + ' is required'
          });
        if (item.min && parseInt(item.min) !== 0 && quantity < item.min)
          validationErrors.push({
            option_id: option.option_id,
            message: `You need to select at least ${item.min} ${item.Option.option_name}`
          });
        if (item.max && parseInt(item.max) !== 0 && quantity > item.max)
          validationErrors.push({
            option_id: option.option_id,
            message: `You can select only ${item.max} ${item.Option.option_name}`
          });
      });
    });
    return validationErrors;
  }

  static clearError(errors, selectedOption) {
    let newErrors = _.clone(errors, true);
    return _.filter(newErrors, (error) => error.option_id !== selectedOption.option_id);
  }

  static canAddOption(selectedOptions, selectedOption) {
    let max = parseInt(selectedOption.max);
    let currentOptions = _.filter(
      selectedOptions,
      (option) => option.option_id === selectedOption.option_id
    );

    if (currentOptions.length === 0) {
      return true;
    }
    if (currentOptions.length > 1 && max !== 0) {
      if (currentOptions.length >= max) {
        return false;
      }
    }
    let qty = 0;
    for (let i = 0; i < currentOptions.length; i++) {
      qty += currentOptions[i].qty;
    }
    // return true if max is null or 0 or selected options are less than max
    return isNaN(max) || max === 0 || qty < max;
  }
}
