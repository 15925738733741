import React from 'react';
import ViewHelper from '../../../helpers/ViewHelper';
import CartService from '../utils/CartService';

const CurrentOrder = (props) => {
  return (
    <div key={props.key} className="order-item">
      <div className="order-item__item">
        <div className="order-item__item__items">
          <div
            className="order-item__item__items__item"
            onClick={() => props.editItem(props.lineItem)}>
            <div className="order-item__item__items__item__count">
              <h2>{props.count}x</h2>
            </div>
            <div className="order-item__item__items__item__info">
              <h2>{props.lineItem?.menu_name}</h2>
              {/*<h2>Qty: {count}</h2>*/}
              {props.lineItem?.portionSize && <p>{props.lineItem?.portionSize.label}</p>}
              {props.lineItem.options
                ? props.lineItem.options.length
                  ? props.lineItem.options.map((option, id) => (
                      <p>
                        {option.qty ? option.qty : option.quantity}x {option.option_value_name}
                      </p>
                    ))
                  : null
                : null}
              <p>{props.lineItem?.comment}</p>
            </div>
          </div>
        </div>
        <div className="order-item__item__price">
          <h2 className={'order-item__item__price'}>
            {ViewHelper.toCurrency(
              props.lineItem?.original_price
                ? props.lineItem?.original_price
                : CartService.getTotalFor(props.lineItem) * props.count,
              props.currencyCode,
              props.decimalPlace
            )}
          </h2>
          <div className="order-item__item__items__increment">
            <button onClick={() => props.handleRemoveCart(props.count, props.lineItem)}>-</button>
            <button onClick={() => props.handaleAddCart(props.count, props.lineItem)}>+</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentOrder;
