import { faBuilding, faCheckCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../assets/css/outletList.css';
import {} from '../../../redux/actions/categories';
import {
  getChildLocations,
  getLocation,
  getOutlets,
  setIsMasterLocation,
  setSelectedLocation
} from '../../../redux/actions/locations/index';
import { setLocationData, setTokenData } from '../redux/actions/pos';
// import { getChildLocations } from '../../../redux/actions/locations/index';
import SpinnerComponent from '../../../components/@vuexy/spinner/Fallback-spinner';
import { getMenuGroupedByCategory } from '../../../redux/actions/categories';
import { getFilteredPromotionList } from '../../../redux/actions/promotions';
import { getCustomers } from '../../../redux/actions/users';
import { clearCart, clearCheckout } from '../redux/actions/cart';
import { setMenuOptionsData } from '../redux/actions/pos';
import { getPromotions } from '../utils/Promotions';
class OutletsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newSelectedOutlet: this.props.selectedLocation
    };
  }
  onOutletChange(location) {
    this.setState({ newSelectedOutlet: location }, () => {
      this.props.setSelectedOutlet(location);
    });
  }

  render() {
    const { outlets, selectedLocation, categoryMenu, location } = this.props;
    if (location && !outlets && categoryMenu.length === 0)
      return <span className="fw-normal">No Outlet exists for this restaurant</span>;
    if (!outlets) return <SpinnerComponent />;
    return (
      outlets &&
      outlets?.length > 0 &&
      outlets?.map((item) => {
        return (
          <>
            <div
              className="card flex-row box-shadow h-sm-250 p-2 bg-outlet no-border cursor outlet-card"
              onClick={this.onOutletChange.bind(this, item)}>
              <div className="row ">
                <div className="col-lg-12">
                  <span className="address-name">
                    <FontAwesomeIcon icon={faBuilding} /> {item.name}
                  </span>
                  {item.distance && (
                    <>
                      &nbsp;&nbsp;-&nbsp;&nbsp;
                      <a href="#/" className="f3-light">
                        {item.distance} KM &nbsp; <FontAwesomeIcon icon={faMapMarkerAlt} />
                      </a>
                    </>
                  )}
                  <br></br>
                  <small>
                    <span className="fw-normal format-address">{item.formattedAddress}</span>
                  </small>
                </div>
              </div>
              {item?.loc_id === this.state.newSelectedOutlet?.loc_id && (
                <div className="action">
                  <span className="btn btn-link p-0 font-color-primary">
                    {' '}
                    <FontAwesomeIcon icon={faCheckCircle} size={'lg'} />
                  </span>
                </div>
              )}
            </div>
          </>
        );
      })
    );
  }
}
function mapStateToProps(state) {
  return {
    customer: state.cartReducer ? state.cartReducer.customer : [],
    outlets: state.locations && state?.locations?.outlet?.data,
    selectedLocation: state.locations && state.locations?.locationType?.selectedLocation,
    categoryMenu: state.posReducer ? state.posReducer.categories : [],
    location: state.posReducer ? state.posReducer.location : null
  };
}
export default connect(mapStateToProps, {
  setIsMasterLocation,
  setSelectedLocation,
  setLocationData,
  setTokenData,
  getLocation,
  getOutlets,
  getChildLocations,
  getMenuGroupedByCategory,
  getFilteredPromotionList,
  getPromotions,
  setMenuOptionsData,
  getCustomers,
  clearCart,
  clearCheckout
})(OutletsList);
