import socketIOClient from 'socket.io-client';
import CONSTANTS from '../constants';

export default {
  listenTo,
  turnOff,
  emitTo,
  getSocket
};

function getSocket() {
  if (!window.myCustomSocket) window.myCustomSocket = socketIOClient(CONSTANTS.SOCKET_URL);
  return window.myCustomSocket;
}

function listenTo(events) {
  let socket = getSocket();
  events.forEach((event) => {
    socket.on(event.key, (data) => {
      event.callback(data);
    });
  });
}

function emitTo(events) {
  let socket = getSocket();
  events.forEach((event) => {
    socket.emit(event.key, (data) => {
      event.callback(data);
    });
  });
}

function turnOff(events) {
  let socket = getSocket();
  events.forEach((event) => {
    console.log('Turning Off: ', event.key);
    socket.off(event.key);
  });
}
