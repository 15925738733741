import CONSTANTS from '../../../constants';

export function allnotfications(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.APPNOTIFICATIONS.ALL_NOTIFICATIONS) {
    if (action.notifications) {
      if (action.notifications.status) return { data: null, loading: action.loading };
    }
    return { data: action.notifications, loading: action.loading };
  }
  return state;
}
export function notification(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.APPNOTIFICATIONS.NOTIFICATION)
    return { data: action.notification, loading: action.loading };
  return state;
}
