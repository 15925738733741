import CONSTANTS from '../../../constants';

export function allBindiscounts(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.BINDISCOUNTS.ALL_BINDISCOUNTS) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}

export function allFilteredBindiscounts(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.BINDISCOUNTS.ALL_FILTERED_BINDISCOUNTS) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}

export function bindiscount(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.BINDISCOUNTS.GET_BINDISCOUNT) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}
