import CONSTANTS from '../../../constants';

export function users(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.GET_RIDERS)
    return { data: action.riders, loading: action.loading };
  return state;
}
export function allusers(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.ALL_USERS)
    return { data: action.users, loading: action.loading };
  return state;
}
export function uploadusers(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.UPLOAD_USERS)
    return { data: action.users, loading: action.loading };
  return state;
}
export function allreports(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.ALL_REPORTS)
    return { data: action.reports, loading: action.loading };
  return state;
}
export function allCustomers(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.GET_CUSTOMERS)
    return { data: action.customers, loading: action.loading };
  return state;
}
export function existingCustomerDetails(state = { data: null }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.SET_EXISTING_CUSTOMER_DETAILS)
    return action.existingCustomerDetails || null;
  else if (action.type == CONSTANTS.ACTIONS.USERS.RESET_EXISTING_CUSTOMER_DETAILS)
    return action.existingCustomerDetails;
  return state;
}

export function existingCustomerLocations(state = { data: null }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.SET_CUSTOMER_SAVED_LOCATIONS)
    return action.existingCustomerLocations || null;
  else if (action.type == CONSTANTS.ACTIONS.USERS.RESET_CUSTOMER_SAVED_LOCATIONS)
    return action.existingCustomerLocations;
  return state;
}

export function customerSelectedLocation(state = { data: null }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.SET_CUSTOMER_SELECTED_LOCATION)
    return action.customerSelectedLocation || null;
  else if (action.type == CONSTANTS.ACTIONS.USERS.RESET_CUSTOMER_SELECTED_LOCATION)
    return action.customerSelectedLocation;
  return state;
}

export function showAddLocationButton(state = { data: null, showButton: false }, action) {
  if (action.type === CONSTANTS.ACTIONS.USERS.SET_SHOW_ADD_LOCATION_BUTTON)
    return action.showButton || null;
  return state;
}
