import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import cartReducer from '../../pos/src/redux/reducers/cart';
import posReducer from '../../pos/src/redux/reducers/pos';
import userReducer from '../../pos/src/redux/reducers/users';
import { PrinterStationService } from '../../services/PrinterStationService';
import { erpApi } from '../../services/erp';
import access from './access';
import analytics from './analytics';
import appnotifications from './appnotifications';
import binbanks from './binbanks';
import bindiscounts from './bindiscounts';
import campaigns from './campaigns';
import categories from './categories';
import configurations from './configurations';
import currency from './currency';
import customizer from './customizer';
import feedback from './feedback';
import inventoryReducer from './inventory';
import locations from './locations/';
import loginReducer from './login';
import loyalties from './loyalties';
import menu from './menus';
import metaTags from './metatags';
import notificationReducer from './notifications';
import options from './options';
import orders from './orders/';
import otpSettings from './otp-settings';
import pages from './pages';
import promotions from './promotions';
import reviews from './reviews';
import riders from './riders';
import roles from './roles';
import sessionActivity from './session-activity';
import sms from './sms';
import staff from './staff';
import tables from './tables';
import users from './users';

const rootReducer = combineReducers({
  customizer,
  orders,
  configurations,
  locations,
  users,
  analytics,
  categories,
  options,
  promotions,
  campaigns,
  sms,
  feedback,
  currency,
  reviews,
  tables,
  staff,
  roles,
  form,
  metaTags,
  menu,
  posReducer,
  cartReducer,
  userReducer,
  riders,
  sessionActivity,
  notificationReducer,
  loyalties,
  inventoryReducer,
  appnotifications,
  otpSettings,
  bindiscounts,
  binbanks,
  loginReducer,
  access,
  pages,
  [erpApi.reducerPath]: erpApi.reducer,
  [PrinterStationService.reducerPath]: PrinterStationService.reducer
});

export default rootReducer;
