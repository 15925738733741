import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import Swal from 'sweetalert2';
import '../../../assets/css/customerLocation.scss';
import GoogleHelper from '../../../helpers/GoogleHelper';
import {
  resetCustomerLocation,
  setCustomerLocation,
  showAddLocationButton
} from '../../../redux/actions/users';
import LocationService from '../../../services/LocationService';

function CustomerLocation(props) {
  const {
    existingCustomerDetails,
    existingCustomerLocations,
    setCustomerLocation,
    nextStep,
    handleClose,
    changeCustomerClick,
    showAddLocationButton,
    resetCustomerLocation,
    onChangeClick,
    setOnChangeClick,
    lastLocation,
    setLastLocation
  } = props;

  // const [onChangeClick, setOnChangeClick] = useState(false);

  const [selectedLocationIndex, setselectedLocationIndex] = useState(
    parseInt(localStorage.getItem('location_index')) || 0
  );

  const onExistingLocationClick = (loc, index) => {
    const locationLoc = LocationService.getLocationLoc();
    if (loc.latitude !== '0' && loc.longitude !== '0' && window.navigator.onLine) {
      let locationLatLng = [loc.latitude, loc.longitude].toString();
      let areas = GoogleHelper.checkForDeliveryArea(locationLatLng, locationLoc.deliveryAreas);
      if (areas) {
        localStorage.setItem('location_index', index);
        setselectedLocationIndex(index);
        setCustomerLocation(loc);
        setLastLocation(null);
        setOnChangeClick(false);
      } else {
        Swal.fire('Opps!', 'This restaurant can not deliver in the selected area', 'warning');
      }
    } else {
      localStorage.setItem('location_index', index);
      setselectedLocationIndex(index);
      setCustomerLocation(loc);
      setLastLocation(null);
      setOnChangeClick(false);
    }
  };

  useEffect(() => {
    existingCustomerLocations.map((loc, index) => {
      if (lastLocation && lastLocation.address === loc?.address) {
        setselectedLocationIndex(index);
        setCustomerLocation(loc);
      }
    });
  }, [lastLocation]);

  const onAddNewLocationCLick = () => {
    resetCustomerLocation();
    changeCustomerClick(existingCustomerDetails);
    nextStep();
  };
  console.log(lastLocation);
  return (
    <>
      <Card className="mt-1">
        {onChangeClick ? (
          <>
            <CardBody>
              {existingCustomerLocations.map((loc, index) => (
                <div
                  onClick={() => onExistingLocationClick(loc, index)}
                  key={index}
                  className={
                    selectedLocationIndex === index ? 'selected-location' : 'existing-location'
                  }>
                  <span className="ps-2 type-title location-title">
                    <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faHome} />
                    {loc?.name}
                  </span>
                  <br></br>
                  <small>
                    <span className="ps-2 fw-normal address">{loc?.address}</span>
                  </small>
                </div>
              ))}
              <button className="new-location-button" onClick={onAddNewLocationCLick}>
                Add New Location
              </button>
            </CardBody>
          </>
        ) : (
          <CardBody>
            {console.log(lastLocation)}
            <div className="f2 fw-bold">
              <span className="ps-2 type-title location-title">
                <FontAwesomeIcon icon={faHome} style={{ marginRight: '10px' }} />
                {existingCustomerLocations.map((loc, index) => {
                  if (selectedLocationIndex === index) {
                    return loc.name;
                  }
                })}
              </span>
              <br></br>
              <small>
                <span className="ps-2 fw-normal address">
                  {existingCustomerLocations.map((loc, index) => {
                    if (selectedLocationIndex === index) {
                      return loc.address;
                    }
                  })}
                </span>
              </small>
            </div>
          </CardBody>
        )}
      </Card>
    </>
  );
}

function mapStateToProps(state) {
  return {
    existingCustomerLocations: state.users.existingCustomerLocations,
    existingCustomerDetails: state.users.existingCustomerDetails,
    location: state.locations ? state.locations?.location?.data : null
  };
}

export default connect(mapStateToProps, {
  setCustomerLocation,
  showAddLocationButton,
  showAddLocationButton,
  resetCustomerLocation
})(CustomerLocation);
