import React, { Component } from 'react';
// include styles
import { connect } from 'react-redux';
import 'rodal/lib/rodal.css';
import AddNewCustomer from './AddNewCustomer';
import MapModal from './MapModal';
class Wizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      lastLocation: null
    };
  }

  async setLastLocation(lastLocation) {
    await this.setState({ lastLocation });
  }
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };
  handleStepChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { step } = this.state;
    switch (step) {
      case 1:
        return (
          <>
            {' '}
            {this.props.connection ? (
              <AddNewCustomer
                step={this.state.step}
                open={this.props.open}
                handleClose={this.props.handleClose}
                handleStepChange={this.handleStepChange}
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                selectedOutlet={this.props._location}
                _location={this.props._location}
                lastLocation={this.state.lastLocation}
                setCustomerInfoModal={this.props.setCustomerInfoModal}
                updateSavedLocations={this.props.updateSavedLocations}
                handleCustomerInfoOpen={this.props.handleCustomerInfoOpen}
                changeCustomerClick={this.props.changeCustomerClick}
                setLastLocation={this.setLastLocation.bind(this)}
              />
            ) : (
              <AddNewCustomer
                step={this.state.step}
                open={this.props.open}
                handleClose={this.props.handleClose}
                handleStepChange={this.handleStepChange}
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                selectedOutlet={this.props._location}
                _location={this.props._location}
                lastLocation={this.state.lastLocation}
                setCustomerInfoModal={this.props.setCustomerInfoModal}
                updateSavedLocations={this.props.updateSavedLocations}
                handleCustomerInfoOpen={this.props.handleCustomerInfoOpen}
                changeCustomerClick={this.props.changeCustomerClick}
                setLastLocation={this.setLastLocation.bind(this)}
              />
            )}{' '}
          </>
        );
      case 2:
        return (
          <MapModal
            toggleModal={this.props.toggleModal}
            showModal={this.props.showModal}
            name="Add Customer location"
            itemIndex={-1}
            editLocation={false}
            isTakeAway={false}
            data={this.props._outlets}
            prevStep={this.prevStep}
            onChangeOutlet={this.props.onChangeOutlet}
            selectedOutlet={this.props._location}
            updateSavedLocations={this.props.updateSavedLocations}
            deliveryInformation={this.props.deliveryInformation}
            changeDeliveryInformation={this.props.changeDeliveryInformation}
            setUserDeliveryInformation={this.props.setUserDeliveryInformation}
            setLastLocation={this.setLastLocation.bind(this)}
          />
        );
    }
  }
}

function mapStateToProps(state) {
  return {
    customizer: state.customizer ? state.customizer : null,
    user: state.cartReducer ? state.cartReducer.customer : null,
    address: state.cartReducer ? state.cartReducer.address : '',
    userCreated: state.userReducer && state.userReducer.userCreated,
    _location: state.locations
      ? state.locations?.location?.data
        ? state.locations?.location?.data
        : state.posReducer
        ? state.posReducer.location
        : null
      : null,
    _outlets: state.locations ? state.locations?.outlet?.data : null
  };
}

export default connect(mapStateToProps, {})(Wizard);
