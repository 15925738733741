import { combineReducers } from 'redux';
import { allListMenus, allModifiers, dummyItem } from './MenuReducer';

const menuReducer = combineReducers({
  dummyItem,
  allModifiers,
  allListMenus
});

export default menuReducer;
