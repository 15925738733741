import CONSTANTS from '../../../constants';

export function overallStats(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.ANALYTICS.OVERALL) {
    if (action.data) {
      if (action.data.status) return { data: null, loading: action.loading };
    }
    const { data } = action.data;
    if (data === null) return { data: null, loading: action.loading };
    let xAxisLabel = [];
    let totalSales = [];
    let completedOrders = [];
    let cancelledOrders = [];
    for (let i = 0; i < data.length; i++) {
      xAxisLabel.push(data[i].x_axis_label);
      totalSales.push(data[i].total_sales);
      completedOrders.push(data[i].total_orders);
      cancelledOrders.push(data[i].cancelled_orders);
    }
    return {
      data: {
        xAxisLabel,
        totalSales,
        completedOrders,
        cancelledOrders
      },
      sum: action.data.sum,
      loading: action.loading
    };
  }
  return state;
}
