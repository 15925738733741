import _ from 'lodash';
import inflection from 'lodash-inflection';
import CONSTANTS from '../constants';
import PromotionsHelper from '../pos/src/utils/PromotionsHelper';

function round(value, decimal_places) {
  decimal_places = decimal_places !== null && decimal_places !== undefined ? decimal_places : 2;
  value = value.toString();
  const [main_part, fraction_part] = value.split('.');
  if (!fraction_part) return parseFloat(main_part);
  if (fraction_part.length > decimal_places) {
    return parseFloat(value).toFixed(decimal_places);
  } else {
    return parseFloat(value);
  }
}
export default class ViewHelper {
  static toCurrency(amount, currency = 'PKR', decimalPlaces = 0) {
    if (amount) {
      return `${currency} ${parseFloat(amount).toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
      })}`;
    }
    return '';
  }
  static toCurrencyWithZero(amount, currency = 'PKR', decimalPlaces = 0) {
    return `${currency} ${parseFloat(amount).toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    })}`;
  }
  static round(amount, decimalPlaces = 2) {
    if (amount) {
      return round(amount, decimalPlaces);
    }
    return '';
  }

  static toDecimal(amount, decimalPlaces = 2) {
    if (amount) {
      return `${parseFloat(amount).toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
      })}`;
    }
    return '';
  }

  static getTableName(table) {
    if (table && !_.isEmpty(table)) return inflection.titleize(table.table_name);
    return '';
  }

  static getOrderTypeName(orderType) {
    if (orderType && !_.isEmpty(orderType))
      return orderType.label.charAt(0).toUpperCase() + orderType.label.slice(1).toLowerCase();
    return '';
  }

  static getGoogleMapsLink(location) {
    return `https://www.google.com/maps/place/${location}`;
  }

  static getMinimumOrderFor(orderTypes, type) {
    let orderType =
      orderTypes && orderTypes.length && orderTypes.find((ot) => ot.order_type === type);
    if (orderType) return parseFloat(orderType.minimum_order);
  }

  static select2Theme(theme) {
    let themeColor = localStorage.getItem('DashColor');
    if (themeColor === 'dark') {
      return {
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary25: '#10163a',
          primary: '#c2c6dc',
          neutral0: '#262c49',
          neutral80: '#c2c6dc'
        }
      };
    } else {
      return {
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary25: '#10163a',
          primary: '#c2c6dc',
          neutral0: '#fff',
          neutral80: '#333'
        }
      };
    }
  }

  static transformedLocations(locations) {
    if (locations && locations.length > 0)
      return locations.map((location) => {
        return {
          loc_id: location.loc_id,
          label: `${location.loc_id} - ${location.name} ${
            location.is_master_venue ? '- Master' : ''
          }`,
          location
        };
      });
    return [];
  }

  static transformedRoles(roles) {
    if (roles && roles.length > 0)
      return roles.map((role) => {
        return { value: role.staff_group_id, label: role.staff_group_name };
      });
    return [];
  }

  static transformedPresets(presets) {
    if (presets && presets.length > 0)
      return presets.map((preset) => {
        return { value: preset.id, label: preset.name };
      });
    return [];
  }

  static transformedStaff(staffs) {
    if (staffs && staffs.length > 0)
      return staffs.map((staff) => {
        return {
          staff_id: staff.staff_id,
          label: staff.User.email != '' ? staff.User.email : staff.User.facebook_email,
          staff
        };
      });
    return [];
  }

  static toOptions(
    options,
    configOptions = { idColumn: null, valueColumns: null, joiner: null },
    htmlOptions = { allowEmpty: false }
  ) {
    let { idColumn, valueColumns, joiner } = configOptions;
    let { allowEmpty } = htmlOptions;
    let id,
      value = '';

    let formattedOptions = options.map((option) => {
      id = idColumn ? option[idColumn] : option;
      value = valueColumns ? valueColumns.map((column) => option[column]).join(joiner) : option;
      return { id, value };
    });

    if (allowEmpty) formattedOptions = [{ id: '', value: 'Please select' }, ...formattedOptions];

    return formattedOptions;
  }

  // a little function to help us with reordering the result
  static reorder(list, startIndex, endIndex, isMenu = false) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    for (let i = 0; i < list.length; i++) {
      if (isMenu) result[i].menu_priority = i + 1;
      else result[i].priority = i + 1;
    }
    return result;
  }

  static getDiscountedItemPrice(
    price,
    itemId,
    categoryId,
    promotions,
    orderType = null,
    orderTypes
  ) {
    if (!promotions)
      return {
        discountedPrice: price,
        appliedPromo: null,
        discountAmount: 0,
        discountAmountType: 0
      };

    const discounts = {};

    // price = parseInt(price);
    let discountedPrice = price;
    let appliedPromo = null;
    let discountAmount = 0;
    let discountAmountType = null;

    let currentDiscount = price;
    let currentPromo = null;
    let currentDiscountAmount = 0;
    let currentDiscountAmountType = null;

    if (promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS]?.length > 0) {
      const allItemProms = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS];
      for (let i = 0; i < allItemProms.length; i++) {
        const dis = allItemProms[i].PromotionDiscount;
        if (
          dis.discount_amount_type ==
          CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.REWARD_TYPE.PERCENTAGE
        ) {
          const newPrice = price - (dis.discount_amount / 100) * price;
          if (newPrice < currentDiscount) {
            currentDiscount = newPrice;
            currentPromo = allItemProms[i].id;
            currentDiscountAmount = dis.discount_amount;
            currentDiscountAmountType = dis.discount_amount_type;
          }
        } else {
          const newPrice = price - dis.discount_amount;
          if (newPrice < currentDiscount) {
            currentDiscount = newPrice;
            currentPromo = allItemProms[i].id;
            currentDiscountAmount = dis.discount_amount;
            currentDiscountAmountType = dis.discount_amount_type;
          }
        }
      }
    }

    if (currentDiscount < 0) currentDiscount = 0;

    if (currentDiscount < price) {
      if (currentDiscount < discountedPrice) {
        discountedPrice = currentDiscount;
        appliedPromo = currentPromo;
        discountAmount = currentDiscountAmount;
        discountAmountType = currentDiscountAmountType;
      }
      discounts[currentPromo] = {
        discountAmount: currentDiscountAmount,
        discountAmountType: currentDiscountAmountType
      };

      currentPromo = null;
      currentDiscount = price;
      currentDiscountAmount = 0;
      currentDiscountAmountType = null;
    }

    const catObj = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES];

    if (catObj !== undefined && catObj[categoryId]) {
      const dis = catObj[categoryId];
      if (
        dis.discount_amount_type == CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.REWARD_TYPE.PERCENTAGE
      ) {
        const newPrice = price - (dis.discount_amount / 100) * price;
        if (newPrice < currentDiscount) {
          currentDiscount = newPrice;
          currentPromo = catObj[categoryId].parent_id;
          currentDiscountAmount = dis.discount_amount;
          currentDiscountAmountType = dis.discount_amount_type;
        }
      } else {
        const newPrice = price - dis.discount_amount;
        if (newPrice < currentDiscount) {
          currentDiscount = newPrice;
          currentPromo = catObj[categoryId].parent_id;
          currentDiscountAmount = dis.discount_amount;
          currentDiscountAmountType = dis.discount_amount_type;
        }
      }
    }

    if (currentDiscount < 0) currentDiscount = 0;

    if (currentDiscount < price) {
      if (currentDiscount < discountedPrice) {
        discountedPrice = currentDiscount;
        appliedPromo = currentPromo;
        discountAmount = currentDiscountAmount;
        discountAmountType = currentDiscountAmountType;
      }
      discounts[currentPromo] = {
        discountAmount: currentDiscountAmount,
        discountAmountType: currentDiscountAmountType
      };
      currentPromo = null;
      currentDiscount = price;
      currentDiscountAmount = 0;
      currentDiscountAmountType = null;
    }

    const itemObj = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS];
    if (itemObj !== undefined && itemObj[itemId]) {
      const dis = itemObj[itemId];
      if (
        dis.discount_amount_type == CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.REWARD_TYPE.PERCENTAGE
      ) {
        const newPrice = price - (dis.discount_amount / 100) * price;
        if (newPrice < currentDiscount) {
          currentDiscount = newPrice;
          currentPromo = itemObj[itemId].parent_id;
          currentDiscountAmount = dis.discount_amount;
          currentDiscountAmountType = dis.discount_amount_type;
        }
      } else {
        const newPrice = price - dis.discount_amount;
        if (newPrice < currentDiscount) {
          currentDiscount = newPrice;
          currentPromo = itemObj[itemId].parent_id;
          currentDiscountAmount = dis.discount_amount;
          currentDiscountAmountType = dis.discount_amount_type;
        }
      }
    }

    if (currentDiscount < 0) currentDiscount = 0;

    if (currentDiscount < price) {
      if (currentDiscount < discountedPrice) {
        discountedPrice = currentDiscount;
        appliedPromo = currentPromo;
        discountAmount = currentDiscountAmount;
        discountAmountType = currentDiscountAmountType;
      }
      discounts[currentPromo] = {
        discountAmount: currentDiscountAmount,
        discountAmountType: currentDiscountAmountType
      };
    }

    discounts['discountedPrice'] = discountedPrice;
    discounts['appliedPromo'] = appliedPromo;
    discounts['discountAmount'] = discountAmount;
    discounts['discountAmountType'] = discountAmountType;
    if (orderType) {
      const orderTypeloc = _.find(orderTypes, (o) => o.id === parseInt(orderType.id));
      const orderTypeObjects = PromotionsHelper.getPromotionOrderTypesObject(
        discounts.appliedPromo,
        promotions
      );
      if (orderTypeObjects.length > 0) {
        const orderTypeIds = orderTypeObjects
          .filter((o) => o.OrderType.active)
          .map((o) => o.OrderType.order_type);
        if (orderTypeIds.length > 0) {
          if (orderTypeloc) {
            if (orderTypeIds.indexOf(orderTypeloc.order_type) < 0) {
              discounts['discountedPrice'] = price;
              discounts['appliedPromo'] = null;
              discounts['discountAmount'] = 0;
              discounts['discountAmountType'] = null;
            }
          }
        } else {
          discounts['discountedPrice'] = price;
          discounts['appliedPromo'] = null;
          discounts['discountAmount'] = 0;
          discounts['discountAmountType'] = null;
        }
      } else {
        discounts['discountedPrice'] = price;
        discounts['appliedPromo'] = null;
        discounts['discountAmount'] = 0;
        discounts['discountAmountType'] = null;
      }
    }

    // if (orderType) {
    //   const orderTypeIds = PromotionsHelper.getPromotionOrderTypes(
    //     discounts.appliedPromo,
    //     promotions
    //   );
    //   if (orderTypeIds.indexOf(orderType.id) < 0) {
    //     discounts['discountedPrice'] = price;
    //     discounts['appliedPromo'] = null;
    //     discounts['discountAmount'] = 0;
    //     discounts['discountAmountType'] = null;
    //   }
    // }
    return discounts;
  }

  static getDiscountedItemPricePOS(
    price,
    itemId,
    categoryId,
    promotions,
    orderType = null,
    orderTypes
  ) {
    if (!promotions)
      return {
        discountedPrice: price,
        appliedPromo: null,
        discountAmount: 0,
        discountAmountType: 0
      };

    const discounts = {};

    // price = parseInt(price);
    let discountedPrice = price;
    let appliedPromo = null;
    let discountAmount = 0;
    let discountAmountType = null;

    let currentDiscount = price;
    let currentPromo = null;
    let currentDiscountAmount = 0;
    let currentDiscountAmountType = null;
    if (promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS]?.length > 0) {
      const allItemProms = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS];
      for (let i = 0; i < allItemProms.length; i++) {
        if (allItemProms[i].automatic === false) continue;
        const dis = allItemProms[i].PromotionDiscount;
        if (
          dis.discount_amount_type ==
          CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.REWARD_TYPE.PERCENTAGE
        ) {
          const newPrice = price - (dis.discount_amount / 100) * price;
          if (newPrice < currentDiscount) {
            currentDiscount = newPrice;
            currentPromo = allItemProms[i].id;
            currentDiscountAmount = dis.discount_amount;
            currentDiscountAmountType = dis.discount_amount_type;
          }
        } else {
          const newPrice = price - dis.discount_amount;
          if (newPrice < currentDiscount) {
            currentDiscount = newPrice;
            currentPromo = allItemProms[i].id;
            currentDiscountAmount = dis.discount_amount;
            currentDiscountAmountType = dis.discount_amount_type;
          }
        }
      }
    }

    if (currentDiscount < 0) currentDiscount = 0;

    if (currentDiscount < price) {
      if (currentDiscount < discountedPrice) {
        discountedPrice = currentDiscount;
        appliedPromo = currentPromo;
        discountAmount = currentDiscountAmount;
        discountAmountType = currentDiscountAmountType;
      }
      discounts[currentPromo] = {
        discountAmount: currentDiscountAmount,
        discountAmountType: currentDiscountAmountType
      };

      currentPromo = null;
      currentDiscount = price;
      currentDiscountAmount = 0;
      currentDiscountAmountType = null;
    }

    const catObj = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES];

    if (catObj !== undefined && catObj[categoryId]) {
      const dis = catObj[categoryId];
      if (
        dis.discount_amount_type == CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.REWARD_TYPE.PERCENTAGE
      ) {
        const newPrice = price - (dis.discount_amount / 100) * price;
        if (newPrice < currentDiscount) {
          currentDiscount = newPrice;
          currentPromo = catObj[categoryId].parent_id;
          currentDiscountAmount = dis.discount_amount;
          currentDiscountAmountType = dis.discount_amount_type;
        }
      } else {
        const newPrice = price - dis.discount_amount;
        if (newPrice < currentDiscount) {
          currentDiscount = newPrice;
          currentPromo = catObj[categoryId].parent_id;
          currentDiscountAmount = dis.discount_amount;
          currentDiscountAmountType = dis.discount_amount_type;
        }
      }
    }

    if (currentDiscount < 0) currentDiscount = 0;

    if (currentDiscount < price) {
      if (currentDiscount < discountedPrice) {
        discountedPrice = currentDiscount;
        appliedPromo = currentPromo;
        discountAmount = currentDiscountAmount;
        discountAmountType = currentDiscountAmountType;
      }
      discounts[currentPromo] = {
        discountAmount: currentDiscountAmount,
        discountAmountType: currentDiscountAmountType
      };
      currentPromo = null;
      currentDiscount = price;
      currentDiscountAmount = 0;
      currentDiscountAmountType = null;
    }

    const itemObj = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS];
    if (itemObj !== undefined && itemObj[itemId]) {
      const dis = itemObj[itemId];
      if (
        dis.discount_amount_type == CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.REWARD_TYPE.PERCENTAGE
      ) {
        const newPrice = price - (dis.discount_amount / 100) * price;
        if (newPrice < currentDiscount) {
          currentDiscount = newPrice;
          currentPromo = itemObj[itemId].parent_id;
          currentDiscountAmount = dis.discount_amount;
          currentDiscountAmountType = dis.discount_amount_type;
        }
      } else {
        const newPrice = price - dis.discount_amount;
        if (newPrice < currentDiscount) {
          currentDiscount = newPrice;
          currentPromo = itemObj[itemId].parent_id;
          currentDiscountAmount = dis.discount_amount;
          currentDiscountAmountType = dis.discount_amount_type;
        }
      }
    }

    if (currentDiscount < 0) currentDiscount = 0;

    if (currentDiscount < price) {
      if (currentDiscount < discountedPrice) {
        discountedPrice = currentDiscount;
        appliedPromo = currentPromo;
        discountAmount = currentDiscountAmount;
        discountAmountType = currentDiscountAmountType;
      }
      discounts[currentPromo] = {
        discountAmount: currentDiscountAmount,
        discountAmountType: currentDiscountAmountType
      };
    }

    discounts['discountedPrice'] = discountedPrice;
    discounts['appliedPromo'] = appliedPromo;
    discounts['discountAmount'] = discountAmount;
    discounts['discountAmountType'] = discountAmountType;
    if (orderType) {
      const orderTypeloc = _.find(orderTypes, (o) => o.id === parseInt(orderType.id));
      const orderTypeObjects = PromotionsHelper.getPromotionOrderTypesObject(
        discounts.appliedPromo,
        promotions
      );
      if (orderTypeObjects.length > 0) {
        const orderTypeIds = orderTypeObjects
          .filter((o) => o.OrderType.active)
          .map((o) => o.OrderType.order_type);
        if (orderTypeIds.length > 0) {
          if (orderTypeloc) {
            if (orderTypeIds.indexOf(orderTypeloc.order_type) < 0) {
              discounts['discountedPrice'] = price;
              discounts['appliedPromo'] = null;
              discounts['discountAmount'] = 0;
              discounts['discountAmountType'] = null;
            }
          }
        } else {
          discounts['discountedPrice'] = price;
          discounts['appliedPromo'] = null;
          discounts['discountAmount'] = 0;
          discounts['discountAmountType'] = null;
        }
      } else {
        discounts['discountedPrice'] = price;
        discounts['appliedPromo'] = null;
        discounts['discountAmount'] = 0;
        discounts['discountAmountType'] = null;
      }
    }

    // if (orderType) {
    //   const orderTypeIds = PromotionsHelper.getPromotionOrderTypes(
    //     discounts.appliedPromo,
    //     promotions
    //   );
    //   if (orderTypeIds.indexOf(orderType.id) < 0) {
    //     discounts['discountedPrice'] = price;
    //     discounts['appliedPromo'] = null;
    //     discounts['discountAmount'] = 0;
    //     discounts['discountAmountType'] = null;
    //   }
    // }
    return discounts;
  }

  static capitalize(str) {
    const words = [];
    for (const s of str.split('_')) {
      const word = s.charAt(0) + s.slice(1).toLowerCase();
      words.push(word);
    }
    return words.join(' ');
  }

  static userHasPermission() {
    const permittedUserList = ['administrator'];
    const userRole = localStorage.getItem('plugd_admin_role');
    return permittedUserList.includes(userRole.toString().toLowerCase());
  }
  static checkValidModule(modules, module) {
    if (this.userHasPermission()) {
      return true;
    }
    if (modules.includes(module)) {
      return true;
    }
    return false;
  }
  static setErrorMessageForModule(name, module) {
    return `Sorry - ${name} does not have to access to ${module}.`;
  }
}
