import CONSTANTS from '../../../constants';
import InventoryService from '../../../services/InventoryService';

export function getAllInventoryItems(loc_id, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.INVENTORY.ALL_INVENTORY, data: null, loading: true });
      let inventoryItems = await InventoryService.getAllInventoryItems(loc_id);
      dispatch({
        type: CONSTANTS.ACTIONS.INVENTORY.ALL_INVENTORY,
        data: inventoryItems,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.INVENTORY.ALL_INVENTORY, data: null, loading: false });
    }
  };
}

export function getInventoryItemsByGroupId(loc_id, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({
          type: CONSTANTS.ACTIONS.INVENTORY.INVENTORY_ITEMS_BY_GROUP_ID,
          data: null,
          loading: true
        });
      let inventoryItems = await InventoryService.getAllInventoryItems(loc_id);
      dispatch({
        type: CONSTANTS.ACTIONS.INVENTORY.ALL_INVENTORY,
        data: inventoryItems,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.INVENTORY.ALL_INVENTORY, data: null, loading: false });
    }
  };
}

export function getInventoryItem(loc_id, inventory_id, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({
          type: CONSTANTS.ACTIONS.INVENTORY.SPECIFIC_INVENTORY_ITEM,
          data: null,
          loading: true
        });
      let inventoryItem = await InventoryService.getInventoryItem(loc_id, inventory_id);
      dispatch({
        type: CONSTANTS.ACTIONS.INVENTORY.SPECIFIC_INVENTORY_ITEM,
        data: inventoryItem,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.INVENTORY.SPECIFIC_INVENTORY_ITEM,
        data: null,
        loading: false
      });
    }
  };
}

export function getInventoryRecipeUnits(loc_id, inventory_id) {
  return async (dispatch) => {
    try {
      let { recipe_units: recipeUnits } = await InventoryService.getRecipesUnits(
        inventory_id,
        loc_id
      );
      dispatch({ type: CONSTANTS.ACTIONS.INVENTORY.GET_RECIPE_UNITS, recipeUnits, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.INVENTORY.GET_RECIPE_UNITS, data: null, loading: false });
    }
  };
}

export function getInventoryPurchaseUnits(loc_id, inventory_id) {
  return async (dispatch) => {
    try {
      let { purchase_units: purchaseUnits } = await InventoryService.getPurchaseUnits(
        inventory_id,
        loc_id
      );
      dispatch({
        type: CONSTANTS.ACTIONS.INVENTORY.GET_PURCHASE_UNITS,
        purchaseUnits,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.INVENTORY.GET_RECIPE_UNITS, data: null, loading: false });
    }
  };
}

export function setShowErrorBadge(showErrorBadge) {
  return {
    type: CONSTANTS.ACTIONS.ERROR_BADGE.SHOW_ERROR_BADGE,
    showErrorBadge: showErrorBadge
  };
}

export function setOnSelectClicked(onSelectClicked) {
  return {
    type: CONSTANTS.ACTIONS.ON_SELECT_CLICKED,
    onSelectClicked: onSelectClicked
  };
}

export function setLocation(selectedLocation) {
  return {
    type: CONSTANTS.ACTIONS.SET_LOCATION,
    selectedLocation: selectedLocation
  };
}
