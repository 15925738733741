import CONSTANTS from '../../../constants';

export function childLocations(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.CHILDREN)
    return { data: action.childLocations, loading: action.loading };
  return state;
}

export function outlet(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.OUTLETS) {
    const outlets = action.outlet;
    if (outlets) {
      for (let i = 0; i < outlets.length; i++) {
        const { OutletStockStatuses } = outlets[i];
        const stockStatuses = {};
        const categoryStatuses = {};
        for (let j = 0; j < OutletStockStatuses.length; j++) {
          if (OutletStockStatuses[j].is_category) {
            categoryStatuses[OutletStockStatuses[j].menu_id] = OutletStockStatuses[j];
          } else {
            stockStatuses[OutletStockStatuses[j].menu_id] = OutletStockStatuses[j];
          }
        }
        outlets[i].stockStatuses = stockStatuses;
        outlets[i].categoryStatuses = categoryStatuses;
        delete outlets[i].OutletStockStatuses;
      }
    }
    return { data: outlets, loading: action.loading };
  }
  return state;
}

export function locationType(
  state = { data: null, isMasterLoc: false, selectedLocation: {} },
  action
) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.IS_MASTER_LOCATION) {
    return { ...state, isMasterLoc: action.isMasterLoc };
  } else if (action.type === CONSTANTS.ACTIONS.LOCATIONS.SET_SELECTED_LOCATION) {
    return { ...state, selectedLocation: action.selectedLocation };
  }
  return state;
}
