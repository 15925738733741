import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import CategoryItem from './CategoryItem';

const CategoryList = (props) => {
  const [categories, setCategories] = useState(props.categories);
  let sliderParams = {
    slidesPerView: 8,
    spaceBetween: 15,
    speed: 300,
    breakpoints: {
      1920: {
        slidesPerView: 14,
        spaceBetween: 15
      },
      1680: {
        slidesPerView: 11,
        spaceBetween: 15
      },
      1440: {
        slidesPerView: 9,
        spaceBetween: 15
      },
      1366: {
        slidesPerView: 8,
        spaceBetween: 15
      },
      1280: {
        slidesPerView: 7,
        spaceBetween: 15
      },
      1024: {
        slidesPerView: 7,
        spaceBetween: 15
      },
      991: {
        slidesPerView: 7,
        spaceBetween: 15
      },
      769: {
        slidesPerView: 6,
        spaceBetween: 15
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 15
      },
      767: {
        slidesPerView: 'auto',
        spaceBetween: 15
      },
      640: {
        slidesPerView: 'auto',
        spaceBetween: 15
      },
      425: {
        slidesPerView: 'auto',
        spaceBetween: 15
      },
      320: {
        slidesPerView: 'auto',
        spaceBetween: 15
      }
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: (index, className) => {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      }
    },
    freeMode: true,
    // mousewheel: true,
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: false,
      draggable: true
    },
    allowTouchMove: false,
    simulateTouch: true
  };
  const getMenuStatusForItem = (item) => {
    if (props.outlet.outletStatus) {
      const outlet_status = props.outlet && props.outlet.stockStatuses[item.menu_id];
      if (outlet_status) {
        return outlet_status.status_id;
      }
    }
    return item.menu_status;
  };
  if (props.outlet && props.outlet.is_outlet) {
    if (categories) {
      for (let item of categories) {
        for (let menu of item.Menus) {
          menu.menu_status = getMenuStatusForItem(menu, props.outlet);
        }
      }
    }
  }
  const menuByCategory = (menus, menu_category_id) => {
    return menus.filter(
      (p) => p.menu_category_id === menu_category_id && (p.menu_status === 1 || p.menu_status === 2)
    );
  };

  useEffect(() => {
    if (props.categories && props.categories.length > 0) {
      let newCategories = [];
      for (let i = 0; i < props.categories.length; i++) {
        let category = props.categories[i];
        if (props.outlet) {
          const {
            outlet: { categoryStatuses }
          } = props;
          if (
            categoryStatuses &&
            categoryStatuses[category.category_id] &&
            categoryStatuses[category.category_id].status_id === 0
          ) {
            continue;
          }
          if (menuByCategory(category.Menus, category.category_id).length === 0) {
            continue;
          }
          if (category.status === 0) {
            continue;
          }
          newCategories.push(category);
        } else {
          newCategories.push(category);
        }
      }
      setCategories(newCategories);
    }
  }, [props.categories]);
  return (
    <div
      className={'category-parent'}
      style={{
        zIndex: 2,
        background: props.theme === 'dark' ? '#262c49' : '#fff',
        borderTop: '1px solid #A5A5A5',
        position: 'fixed',
        bottom: 0,
        width: window.innerWidth > 1280 ? '100%' : '100%'
      }}>
      <div id={'item-categories'} className="item-categories">
        <ul>
          {categories && categories.length > 0 && (
            <Swiper {...sliderParams}>
              {categories
                ? categories.length > 0
                  ? categories.map((category, index) => {
                      return (
                        <li
                          key={category.category_id}
                          style={{
                            display: category?.Menus.length === 0 && 'none',
                            marginRight: index === categories.length - 1 && '0 !important'
                          }}
                          className="scroll-menu-item">
                          {category?.Menus.length > 0 && (
                            <CategoryItem
                              array1={categories}
                              category={category}
                              index={index}
                              changeSection={props.changeSection}
                            />
                          )}
                        </li>
                      );
                    })
                  : null
                : null}
            </Swiper>
          )}
        </ul>
      </div>
    </div>
  );
};

export default CategoryList;
