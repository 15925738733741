import CONSTANTS from '../../../constants';

export function itemsStats(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.ANALYTICS.ITEMS) {
    if (action.data) {
      if (action.data.status) return { data: null, loading: action.loading };
    }
    return { data: action.data, loading: action.loading };
  }
  return state;
}
