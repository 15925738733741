import React from 'react';
import { connect } from 'react-redux';
import ViewHelper from '../../../helpers/ViewHelper';
import '../../../index.scss';

const PosInvoice = (props) => {
  let calculatePrice = (carts) => {
    let price = 0;

    if (carts.length) {
      let len = carts.length;
      for (let i = 0; i < len; i++) {
        let calc = parseFloat(carts[i].cartItem?.original_price);

        price += calc;
      }
      return price;
    } else {
      return 0;
    }
  };

  let calculatePriceWithDis = (carts) => {
    let price = 0;

    if (carts.length) {
      let len = carts.length;
      for (let i = 0; i < len; i++) {
        let calc = parseFloat(carts[i].cartItem?.original_price);

        price += calc;
      }
      return price - props.checkout?.total_discount;
    } else {
      return 0;
    }
  };

  let getTax = (taxPercent) => {
    taxPercent = parseFloat(taxPercent) || 0.0;
    return calculatePrice(props.cart) * (taxPercent / 100.0);
  };
  let grantTotal = calculatePrice(props.cart) + getTax(props.location?.tax_percent);
  let amountDue = calculatePriceWithDis(props.cart) + getTax(props.location?.tax_percent);

  const getMenuOptions = (order_menu_id) => {
    let result = [];
    props.order.OrderOptions.filter((option) => {
      if (option.order_menu_id === order_menu_id) result.push(option);
    });
    return result;
  };
  const current_location = JSON.parse(localStorage.getItem('current_location'));
  let currency_code = localStorage.getItem('currency_code');
  let decimal_place = localStorage.getItem('decimal_place');

  return (
    <div className={'invoice-parent'}>
      {props.cartInvoice === true ? (
        <div className="invoice">
          <div style={{ textAlign: 'center' }}>
            <span>{props.location?.name}</span>
            <br />
            <span>{props.location?.formattedAddress}</span>
            <br />
            <span>Mobile No: {props.location?.location_telephone}</span>
            <br />
          </div>
          <div>
            <span>
              Customer:
              {props.user
                ? props.user.first_name + ' ' + props.user.last_name
                  ? props.user.last_name
                  : ''
                : 'N/A'}
            </span>
            <br />
            <span>
              Phone:{' '}
              {props.checkout?.delivery_phone
                ? props.checkout?.delivery_phone
                : props.user
                ? props.user.phone_number
                : 'N/A'}
            </span>
            <br />
            <span>Address: {props.checkout?.delivery_address}</span>
            <br />
          </div>
          <span>Date: {props.orderDate} </span>
          <br />
          <span>Time: {props.orderTime}</span>
          <div
            style={{
              display: 'flex',
              borderTop: '2px dotted rgba(0,0,0,0.3)'
            }}>
            <>
              <span style={{ width: '10%' }}>Qty</span>
              <br />
              <span style={{ width: '40%' }}>Item Name</span>
              <br />
              <span style={{ width: '25%', display: 'flex', justifyContent: 'flex-start' }}>
                Price
              </span>
              <br />
              <span style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                T.Price
              </span>
            </>
          </div>
          <div style={{ borderBottom: '2px dotted rgba(0,0,0,0.3)' }}>
            {props.cart &&
              props.cart.map((item) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <span style={{ width: '10%' }}>{item.count}</span>
                    <br />
                    <span style={{ width: '40%' }}>
                      {item.cartItem.menu_name} <br />
                      {item.cartItem.portionSize && <em>{item.cartItem.portionSize.label}</em>}
                      {item.cartItem.options.map((option) => {
                        return (
                          <span>
                            {option.qty}x {option.option_value_name} <br />
                          </span>
                        );
                      })}
                    </span>
                    <br />
                    <span style={{ width: '25%', display: 'flex', justifyContent: 'flex-start' }}>
                      {ViewHelper.toCurrency(
                        item.cartItem.menu_price,
                        props.currency?.currency_code,
                        props.location?.decimal_places
                      )}
                    </span>
                    <br />
                    <span style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                      {ViewHelper.toCurrency(
                        item.cartItem.original_price,
                        props.currency?.currency_code,
                        props.location?.decimal_places
                      )}
                    </span>
                  </div>
                );
              })}
          </div>
          <div
            style={{
              margin: '1px 0',
              borderBottom: '2px dotted rgba(0,0,0,0.3)',
              borderTop: '2px dotted rgba(0,0,0,0.3)'
            }}>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '60%' }}>
                <strong>Sub Total</strong>
              </span>
              <span style={{ width: '40%' }}>
                {ViewHelper.toCurrency(
                  calculatePrice(props.cart),
                  props.currency?.currency_code,
                  props.location?.decimal_places
                )}
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '60%' }}>
                <strong>Tax(+)</strong>
              </span>
              <span style={{ width: '40%' }}>
                {ViewHelper.toCurrency(
                  getTax(props.location?.tax_percent),
                  props.currency?.currency_code,
                  props.location?.decimal_places
                )}
              </span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <span style={{ width: '60%' }}>
                <strong>Discount(-)</strong>
              </span>
              <span style={{ width: '40%' }}>
                {ViewHelper.toCurrency(
                  props.checkout?.total_discount,
                  props.currency?.currency_code,
                  props.location?.decimal_places
                )}
              </span>
            </div>
            {props.checkout?.delivery_charges > 0 ? (
              <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '60%' }}>
                  <strong>Delivery Charges</strong>
                </span>
                <span style={{ width: '40%' }}>
                  {ViewHelper.toCurrency(
                    props.checkout?.delivery_charges,
                    props.currency?.currency_code,
                    props.location?.decimal_places
                  )}
                </span>
              </div>
            ) : null}
            <div style={{ display: 'flex', width: '100%' }}>
              <span style={{ width: '60%' }}>
                <strong>Payment Type</strong>
              </span>
              <span style={{ width: '40%' }}>{props.paymentType}</span>
            </div>
          </div>
          <div
            style={{
              margin: '1px 0',
              borderBottom: '2px dotted rgba(0,0,0,0.3)'
            }}>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '60%' }}>
                <strong>TOTAL Amount</strong>
              </span>
              <span style={{ width: '40%' }}>
                {ViewHelper.toCurrency(
                  amountDue,
                  props.currency?.currency_code,
                  props.location?.decimal_places
                )}
              </span>
            </div>
          </div>
        </div>
      ) : props.kitchenInvoice === true ? (
        <div className="invoice">
          <div style={{ textAlign: 'center' }}>
            <span>Table: {props.table?.name ? props.table.name : props.table.table_name}</span>
            <br />
            <span>Order type: {props.orderType?.label}</span>
            <br />
          </div>
          <div
            style={{
              display: 'flex',
              borderTop: '2px dotted rgba(0,0,0,0.3)'
            }}>
            <>
              <span style={{ width: '10%' }}>Qty</span>
              <br />
              <span style={{ width: '40%' }}>Item Name</span>
            </>
          </div>
          <div style={{ borderBottom: '2px dotted rgba(0,0,0,0.3)' }}>
            {props.cart &&
              props.cart.map((item) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <span style={{ width: '10%' }}>{item.count}</span>
                    <br />
                    <span style={{ width: '40%' }}>
                      {item.cartItem.menu_name}
                      <br />
                      {item.cartItem.portionSize && (
                        <span>
                          {item.cartItem.portionSize.label}
                          <br />
                        </span>
                      )}
                      {item.cartItem.options.map((option) => {
                        return (
                          <span>
                            {option.qty}x {option.option_value_name} <br />
                          </span>
                        );
                      })}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      ) : props.offlineInvoice === true ? (
        <div className="invoice">
          <div style={{ textAlign: 'center' }}>
            <span>{current_location?.name}</span>
            <br />
            <span>{current_location?.formattedAddress}</span>
            <br />
            <span>Mobile No: {current_location?.location_telephone}</span>
            <br />
          </div>
          <div>
            <span>
              Customer:{' '}
              {props.order?.customer?.first_name
                ? props.order?.customer?.first_name + ' ' + props.order?.customer?.last_name
                  ? props.order?.customer?.last_name
                  : ''
                : 'N/A'}
            </span>
            <br />
            <span>
              Phone:{' '}
              {props.checkout?.delivery_phone
                ? props.checkout?.delivery_phone
                : props.order?.customer?.phone_number}
            </span>
            <br />
            <span>Address: {props.checkout?.delivery_address}</span>
            <br />
          </div>
          <span>Date: {props.order?.order_time} </span>
          <br />
          <span>Time: {props.order?.order_date}</span>
          <div
            style={{
              display: 'flex',
              borderTop: '2px dotted rgba(0,0,0,0.3)'
            }}>
            <>
              <span style={{ width: '10%' }}>Qty</span>
              <br />
              <span style={{ width: '40%' }}>Item Name</span>
              <br />
              <span style={{ width: '20%', display: 'flex', justifyContent: 'flex-start' }}>
                Price
              </span>
              <br />
              <span style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
                T.Price
              </span>
            </>
          </div>
          <div style={{ borderBottom: '2px dotted rgba(0,0,0,0.3)' }}>
            {props.order.order_data &&
              props.order.order_data.map((item) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <span style={{ width: '10%' }}>{item.quantity}</span>
                    <br />
                    <span style={{ width: '40%' }}>
                      {item.menu_name} <br />
                      {item.options.map((option) => {
                        return (
                          <span>
                            {option.qty}x {option.option_value_name} <br />
                          </span>
                        );
                      })}
                    </span>
                    <br />
                    <span style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                      {ViewHelper.toCurrency(item.menu_price, currency_code, decimal_place)}
                    </span>
                    <br />
                    <span style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                      {ViewHelper.toCurrency(item.original_price, currency_code, decimal_place)}
                    </span>
                  </div>
                );
              })}
          </div>
          <div
            style={{
              margin: '1px 0',
              borderBottom: '2px dotted rgba(0,0,0,0.3)',
              borderTop: '2px dotted rgba(0,0,0,0.3)'
            }}>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '60%' }}>
                <strong>Sub Total</strong>
              </span>
              <span style={{ width: '40%' }}>
                {ViewHelper.toCurrency(
                  props.order?.totalPrice +
                    props.order?.total_discount -
                    props.order?.delivery_charges,
                  currency_code,
                  decimal_place
                )}
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '60%' }}>
                <strong>Tax(+)</strong>
              </span>
              <span style={{ width: '40%' }}>
                {ViewHelper.toCurrency(props.order?.tax, currency_code, decimal_place)}
              </span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <span style={{ width: '60%' }}>
                <strong>Discount(-)</strong>
              </span>
              <span style={{ width: '40%' }}>
                {ViewHelper.toCurrency(props.order?.total_discount, currency_code, decimal_place)}
              </span>
            </div>
            {props.order?.delivery_charges > 0 ? (
              <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '60%' }}>
                  <strong>Delivery Charges</strong>
                </span>
                <span style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                  {ViewHelper.toCurrency(
                    props.order?.delivery_charges,
                    currency_code,
                    decimal_place
                  )}
                </span>
              </div>
            ) : null}
            <div style={{ display: 'flex', width: '100%' }}>
              <span style={{ width: '60%' }}>
                <strong>Payment Type</strong>
              </span>
              <span style={{ width: '40%' }}>{props.order?.payment_details?.method}</span>
            </div>
          </div>
          <div
            style={{
              margin: '1px 0',
              borderBottom: '2px dotted rgba(0,0,0,0.3)'
            }}>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '60%' }}>
                <strong>TOTAL Amount</strong>
              </span>
              <span style={{ width: '40%' }}>
                {ViewHelper.toCurrency(
                  props.order?.totalPrice + props.order?.tax,
                  props.currency?.currency_code,
                  props.location?.decimal_places
                )}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="invoice">
          <div style={{ textAlign: 'center' }}>
            <span>{props.order.Location?.name}</span>
            <br />
            <span>{props.order.Location?.formattedAddress}</span>
            <br />
            <span>Mobile No: {props.order.Location?.location_telephone}</span>
            <br />
          </div>
          <div>
            <span>
              Customer:{' '}
              {props.order.first_name
                ? props.order.first_name + ' ' + props.order.last_name
                  ? props.order.last_name
                  : ''
                : 'N/A'}
            </span>
            <br />
            <span>
              Phone:{' '}
              {props.checkout?.delivery_phone
                ? props.checkout?.delivery_phone
                : props.order?.delivery_phone
                ? props.order?.delivery_phone
                : props.order?.car_number}
            </span>
            <br />
            <span>
              Address:{' '}
              {props.checkout?.delivery_address
                ? props.checkout?.delivery_address
                : props.order?.delivery_address}
            </span>
            <br />
          </div>
          <span>Date: {props.order.order_date} </span>
          <br />
          <span>
            Time: {props.order.order_time}
            {/*{moment(props.order.order_time).format('LTS')}*/}
          </span>
          <div
            style={{
              display: 'flex',
              borderTop: '2px dotted rgba(0,0,0,0.3)'
            }}>
            <>
              <span style={{ width: '10%' }}>Qty</span>
              <br />
              <span style={{ width: '40%' }}>Item Name</span>
              <br />
              <span style={{ width: '25%', display: 'flex', justifyContent: 'flex-start' }}>
                Price
              </span>
              <br />
              <span style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                T.Price
              </span>
            </>
          </div>
          <div style={{ borderBottom: '2px dotted rgba(0,0,0,0.3)' }}>
            {props.order &&
              props.order.OrderMenus.map((item) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <span style={{ width: '10%' }}>{item.quantity}</span>
                    <br />
                    <span style={{ width: '40%' }}>
                      {item.name}
                      <br />
                      {item.portion_size_label && (
                        <span>
                          {item.portion_size_label}
                          <br />
                        </span>
                      )}
                      {getMenuOptions(item.order_menu_id).length > 0 &&
                        getMenuOptions(item.order_menu_id).map((option) => {
                          return (
                            <span>
                              {option.quantity}x {option.order_option_name} <br />
                            </span>
                          );
                        })}
                    </span>
                    <br />
                    <span style={{ width: '25%', display: 'flex', justifyContent: 'flex-start' }}>
                      {ViewHelper.toCurrency(
                        item.price,
                        props.currency?.currency_code,
                        props.location?.decimal_places
                      )}
                    </span>
                    <br />
                    <span style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                      {ViewHelper.toCurrency(
                        item.subtotal,
                        props.currency?.currency_code,
                        props.location?.decimal_places
                      )}
                    </span>
                  </div>
                );
              })}
          </div>
          <div
            style={{
              margin: '1px 0',
              borderBottom: '2px dotted rgba(0,0,0,0.3)',
              borderTop: '2px dotted rgba(0,0,0,0.3)'
            }}>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '60%' }}>
                <strong>Sub Total</strong>
              </span>
              <span style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                {ViewHelper.toCurrency(
                  props.order.OrderTotals[0].value +
                    props.order.OrderTotals[1].value -
                    props.order?.delivery_charges,
                  props.currency?.currency_code,
                  props.location?.decimal_places
                )}
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '60%' }}>
                <strong>Tax(+)</strong>
              </span>
              <span style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                {ViewHelper.toCurrency(
                  props.order.OrderTotals[2].value,
                  props.currency?.currency_code,
                  props.location?.decimal_places
                )}
              </span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <span style={{ width: '60%' }}>
                <strong>Discount(-)</strong>
              </span>
              <span style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                {ViewHelper.toCurrency(
                  props.order.OrderTotals[1].value,
                  props.currency?.currency_code,
                  props.location?.decimal_places
                )}
              </span>
            </div>
            {props.order?.delivery_charges > 0 ? (
              <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '60%' }}>
                  <strong>Delivery Charges</strong>
                </span>
                <span style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                  {ViewHelper.toCurrency(
                    props.order?.delivery_charges,
                    props.currency?.currency_code,
                    props.location?.decimal_places
                  )}
                </span>
              </div>
            ) : null}
            <div style={{ display: 'flex', width: '100%' }}>
              <span style={{ width: '60%' }}>
                <strong>Payment Type</strong>
              </span>
              <span style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                {props.order.Payments[0]?.method}
              </span>
            </div>
          </div>
          <div
            style={{
              margin: '1px 0',
              borderBottom: '2px dotted rgba(0,0,0,0.3)'
            }}>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '60%' }}>
                <strong>TOTAL Amount</strong>
              </span>
              <span style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                {ViewHelper.toCurrency(
                  props.order?.OrderTotals[3]?.value,
                  props.currency?.currency_code,
                  props.location?.decimal_places
                )}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cart: state.cartReducer ? state.cartReducer.cart : [],
    checkout: state.cartReducer ? state.cartReducer.checkout : '',
    currency: state.locations ? state.locations?.location?.data?.Currency : null,
    table: state.cartReducer ? state.cartReducer.table : '',
    orderType: state.cartReducer ? state.cartReducer.orderType : '',
    paymentType: state.cartReducer ? state.cartReducer.paymentType : '',
    orderDate: state.cartReducer ? state.cartReducer.orderDate : '',
    orderTime: state.cartReducer ? state.cartReducer.orderTime : '',
    user: state.cartReducer
      ? state.cartReducer.customer
        ? state.cartReducer.customer
        : null
      : null,
    location: state.locations ? state.locations?.location?.data : null
  };
}

export default connect(mapStateToProps, {})(PosInvoice);
