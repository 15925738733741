import CONSTANTS from '../../../constants';

export function location(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.GET)
    return { data: action.location, loading: action.loading };
  return state;
}

export function outlets(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.GET_OUTLETS)
    return { data: action.outlets, loading: action.loading };
  return state;
}

export function departments(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.GET_DEPARTMENTS)
    return { data: action.departments, loading: action.loading };
  return state;
}

export function deliveryProcessors(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERY_PROCESSORS)
    return { data: action.processors, loading: action.loading };
  return state;
}

export function deliveries(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERIES)
    return { data: action.deliveries, loading: action.loading };
  return state;
}
