import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { StyledModal } from '../container/style/CustomerDetailStyle';

const CustomerDetails = (props) => {
  let theme = props.customizer?.customizer?.theme;
  const { existingCustomerDetails, orderInfo } = props;
  return (
    <StyledModal
      theme={theme}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      {existingCustomerDetails ? (
        orderInfo?.orderType?.order_type === 'delivery' ? (
          <div className="customer-modal">
            <div className="customer-modal__header">
              <h2>Customer Details</h2>
            </div>
            <div className="customer-modal__details">
              <div className="customer-modal__details__detail">
                <h3>Customer name:</h3>
                <h2>
                  {existingCustomerDetails.first_name} {existingCustomerDetails.last_name}
                </h2>
              </div>
              <div className="customer-modal__details__detail">
                <h3>Phone number:</h3>
                <h2>{existingCustomerDetails?.phone_number}</h2>
              </div>
              <div className="customer-modal__details__detail">
                <h3>Email:</h3>
                <h2>{existingCustomerDetails?.email}</h2>
              </div>
              <button onClick={props.onClose}>Back</button>
            </div>
          </div>
        ) : (
          <div className="customer-modal">
            <div className="customer-modal__header">
              <h2>Customer Details</h2>
            </div>
            <div className="customer-modal__details">
              <div className="customer-modal__details__detail">
                <h3>Customer name:</h3>
                <h2>
                  {props.user?.first_name} {props.user?.last_name}
                </h2>
              </div>
              <div className="customer-modal__details__detail">
                <h3>Phone number:</h3>
                <h2>{props.user?.phone_number}</h2>
              </div>
              <div className="customer-modal__details__detail">
                <h3>Email:</h3>
                <h2>{props.user?.email}</h2>
              </div>
              {props.user?.last_app_use_date && (
                <div className="customer-modal__details__detail">
                  <h3>Last visit:</h3>
                  <h2>{moment(props.user?.last_app_use_date).format('MMMM Do YYYY, h:mm:ss a')}</h2>
                </div>
              )}
              <button onClick={props.onClose}>Back</button>
            </div>
          </div>
        )
      ) : (
        <div className="customer-modal">
          <div className="customer-modal__header">
            <h2>Customer Details</h2>
          </div>
          <div className="customer-modal__details">
            <div className="customer-modal__details__detail">
              <h3>Customer name:</h3>
              <h2>
                {props.customer?.first_name} {props.customer?.last_name}
              </h2>
            </div>
            <div className="customer-modal__details__detail">
              <h3>Phone number:</h3>
              <h2>{props.customer?.phone_number}</h2>
            </div>
            <div className="customer-modal__details__detail">
              <h3>Email:</h3>
              <h2>{props.customer?.email}</h2>
            </div>
            {props.customer?.last_app_use_date && (
              <div className="customer-modal__details__detail">
                <h3>Last visit:</h3>
                <h2>
                  {moment(props.customer?.last_app_use_date).format('MMMM Do YYYY, h:mm:ss a')}
                </h2>
              </div>
            )}
            <button onClick={props.onClose}>Back</button>
          </div>
        </div>
      )}
    </StyledModal>
  );
};

function mapStateToProps(state) {
  return {
    existingCustomerDetails: state.users.existingCustomerDetails,
    customizer: state.customizer ? state.customizer : null,
    user: state.userReducer && state.userReducer.user,
    orderInfo: state.cartReducer ? state.cartReducer : null,

    customer: state.cartReducer
      ? state.cartReducer.customer
        ? state.cartReducer.customer
        : null
      : null
  };
}

export default connect(mapStateToProps, {})(CustomerDetails);
