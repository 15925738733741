import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
function CustomerInfoCard(props) {
  const { existingCustomerDetails, user } = props;

  return (
    <Card className="mt-1">
      <CardBody>
        <div className="mb-2">
          <h6>Customer Name:</h6>

          <div>
            {existingCustomerDetails ? existingCustomerDetails.first_name : user?.first_name}
          </div>
        </div>

        <div className="mb-2">
          <h6>Customer Email:</h6>

          <div>{existingCustomerDetails ? existingCustomerDetails.email : user?.email}</div>
        </div>

        <div className="mb-2">
          <h6>Customer Number:</h6>

          <div className="f2 mb-1 mr-1">
            {existingCustomerDetails ? existingCustomerDetails.phone_number : user?.phone_number}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

function mapStateToProps(state) {
  return {
    user: state.userReducer ? state.userReducer.user : null,
    existingCustomerDetails: state.users.existingCustomerDetails,
    existingCustomerLocations: state.users.existingCustomerLocations
  };
}

export default connect(mapStateToProps, {})(CustomerInfoCard);
