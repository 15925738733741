import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import allOrderIcon from '../../../assets/img/static/all-order-dark-theme.svg';
import calenderDarkIcon from '../../../assets/img/static/calender-dark-theme.svg';
import closedOrders from '../../../assets/img/static/closed-order-dark-theme.svg';
import curbsideOrders from '../../../assets/img/static/curbside-dark-theme.svg';
import currencyDarkIcon from '../../../assets/img/static/currency-dark-theme.svg';
import currencyIcon from '../../../assets/img/static/currency.svg';
import deliveryOrders from '../../../assets/img/static/delivery-order-dark-theme.svg';
import dineInOrders from '../../../assets/img/static/dine-in-dark-theme.svg';
import dropdownArrow from '../../../assets/img/static/down-arrow.svg';
import editPen from '../../../assets/img/static/edit-pen.svg';
import forkDarkIcon from '../../../assets/img/static/fork-dark-theme.svg';
import forkIcon from '../../../assets/img/static/fork.svg';
import informationIcon from '../../../assets/img/static/information-icon-2.svg';
import liveOrders from '../../../assets/img/static/live-orders-dark-theme.svg';
import locationDarkIcon from '../../../assets/img/static/location-dark-theme.svg';
import locationIcon from '../../../assets/img/static/location.svg';
import timeDarkIcon from '../../../assets/img/static/time-theme.svg';
import timeIcon from '../../../assets/img/static/time.svg';
import userDarkIcon from '../../../assets/img/static/user-dark-theme.svg';
import userIcon from '../../../assets/img/static/user.svg';
import SpinnerComponent from '../../../components/@vuexy/spinner/Fallback-spinner';
import CONSTANTS from '../../../constants';
import ViewHelper from '../../../helpers/ViewHelper';
import LocationService from '../../../services/LocationService';
import LoginService from '../../../services/LoginService';
import { StyledOrderList } from '../../../styles/GlobalStyles';
import { tokenData } from '../container/pos/data/location';
import { getLiveOrders, setLocationData, setTokenData } from '../redux/actions/pos';
import MenuDesktop from './MainMenuDesktop';
import SideBar from './SideBar';
const useStyles = makeStyles(() => ({
  formControl: {
    '& .MuiInputBase-root': {
      color: '#262C49',
      borderColor: '#C2C6DC',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '5px',
      justifyContent: 'center',
      background: '#C2C6DC'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '14px 16px',
      fontFamily: 'Larsseit, sans-serif'
    }
  },
  select: {
    width: 'auto',
    fontSize: '12px',
    '&:focus': {
      backgroundColor: 'transparent'
    },
    fontFamily: 'Larsseit, sans-serif'
  },
  selectIcon: {
    position: 'relative',
    // color: "#6EC177",
    fontSize: '14px',
    fontFamily: 'Larsseit, sans-serif'
  },
  mainPaper: {
    borderRadius: 5,
    fontFamily: 'Larsseit, sans-serif',
    '@media(max-width: 768px)': {
      left: '0 !important',
      borderRadius: 0,
      minWidth: '100% !important'
    }
  },
  paper: {
    borderRadius: 5,
    fontFamily: 'Larsseit, sans-serif'
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: 'Larsseit, sans-serif',
    '& li': {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: '12px',
      padding: '14px 16px',
      background: '#C2C6DC',
      fontFamily: 'Larsseit, sans-serif'
    },
    '& li.Mui-selected': {
      color: '#262C49',
      background: '#C2C6DC',
      fontFamily: 'Larsseit, sans-serif'
    },
    '& li.Mui-selected:hover': {
      background: '#C2C6DC',
      color: '#262C49',
      fontFamily: 'Larsseit, sans-serif'
    }
  }
}));

const OrderList = (props) => {
  const classes = useStyles();
  let theme = props.customizer?.customizer?.theme;
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
      right: 0
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
      right: 0
    },
    getContentAnchorEl: null
  };
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  let history = useHistory();
  const [filterKeyName, setFilterKeyName] = useState('');
  const [sortKeyName, setSortKeyName] = useState('');
  const [orderAccept, setOrderAccept] = useState(false);
  const [acceptedOrder, setAcceptedOrder] = useState(null);
  const [showDropDownItems, setShowDropDownItems] = useState(false);
  const [showFilterDropDownItems, setShowFilterDropDownItems] = useState(false);
  const [sortOrderValue, setSortOrderValue] = useState('');
  const [filterOrderValue, setFilterOrderValue] = useState('');

  const changeFilterOrderValue = ({ value }) => {
    // // console.log(value)
    setFilterOrderValue(value);
  };
  const changeSortOrderValue = ({ value }) => {
    // // console.log(value)
    setSortOrderValue(value);
  };

  const toggleShowDropDownItems = () => {
    setShowDropDownItems(!showDropDownItems);
  };

  const toggleFilterDropDownItems = () => {
    setShowFilterDropDownItems(!showFilterDropDownItems);
  };

  const changeOrderAcceptStatus = (index) => {
    setAcceptedOrder(index);
  };
  const changeFilterKeyName = (value) => {
    // console.log(value);
    setFilterKeyName(value);
  };
  const changeSortKeyName = (value) => {
    setSortKeyName(value);
  };
  useEffect(() => {
    const location = LocationService.getLocation();
    let { token } = LoginService.getToken();
    if (token) {
      props.setTokenData(token);
    }
    if (location) {
      props.setLocationData(location);
    }
  }, []);

  useEffect(() => {
    let params = {
      filter: 'live_orders',
      sort: 'DESC',
      page: '0'
    };
    props.getLiveOrders(params, tokenData);
  }, []);

  function getOrderTypeName(orderType) {
    if (orderType && !_.isEmpty(orderType))
      return orderType.label.charAt(0).toUpperCase() + orderType.label.slice(1).toLowerCase();
    return '';
  }

  function toFormattedDateTime(dateTime) {
    if (!dateTime) return '';
    return moment(dateTime).format(`${CONSTANTS.DATE_TIMES.FORMAT.DISPLAY}`);
  }

  const toggleMenuCollapsed = () => {
    setMenuCollapsed(!menuCollapsed);
    // console.log('Collapsed Called');
  };
  let handleDetails = (row) => {
    // console.log(row)
    history.push(`/locations/pos/order-detail/${row.order_id}`);
  };
  const orderListData = () => (
    <Grid spacing={3} container style={{ marginTop: 5 }}>
      {props.liveOrderList?.rows
        ? props.liveOrderList.rows.length
          ? props.liveOrderList.rows.map((row, key) => {
              const orderTotal =
                row.OrderTotals[2].code === 'order_total'
                  ? row.OrderTotals[2].value
                  : row.OrderTotals[3].value;
              return (
                <Grid item md={6} xs={12}>
                  <Accordion expanded={true} key={key}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                      <div className="header-content">
                        <h2
                          className={key === acceptedOrder && 'accepted'}
                          onClick={() => handleDetails(row)}>
                          # {row?.order_id}
                        </h2>
                        {key !== acceptedOrder && (
                          <div className="header-content__buttons">
                            <Button onClick={() => changeOrderAcceptStatus(key)}>Accept</Button>
                            <Button className={'reject'}>Reject</Button>
                          </div>
                        )}
                        {key === acceptedOrder && (
                          <div className="header-content__complete">
                            <Tooltip
                              title={`Modified at: ${toFormattedDateTime(row?.date_modified)}`}
                              placement="top-start">
                              <img src={informationIcon} alt="" />
                            </Tooltip>
                            <FormControl>
                              <Select
                                value={'Complete'}
                                // onChange={handleChange}
                                disableUnderline
                                IconComponent={ExpandMoreRoundedIcon}
                                MenuProps={menuProps}>
                                {orderStatusData.map((item) => {
                                  return (
                                    <MenuItem key={item.key} value={item.value}>
                                      {item.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <Button>Request Rider</Button>
                          </div>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails onClick={() => handleDetails(row)}>
                      <div className="order-info">
                        <div className="order-info__info">
                          <img src={theme === 'dark' ? userDarkIcon : userIcon} alt="" />
                          <h2>
                            {row?.first_name} {row?.last_name}
                          </h2>
                        </div>
                        <div className="order-info__info">
                          <img src={theme === 'dark' ? calenderDarkIcon : timeIcon} alt="" />
                          <h2>{toFormattedDateTime(row?.date_added)}</h2>
                        </div>
                      </div>
                      <div className="order-info">
                        <div className="order-info__info">
                          <img src={theme === 'dark' ? forkDarkIcon : forkIcon} alt="" />
                          <h2>Delivery</h2>
                        </div>
                        <div className="order-info__info">
                          <img src={theme === 'dark' ? timeDarkIcon : editPen} alt="" />
                          <h2>{toFormattedDateTime(row?.date_modified)}</h2>
                        </div>
                      </div>
                      <div className="order-info">
                        <div className="order-info__info">
                          <img src={theme === 'dark' ? locationDarkIcon : locationIcon} alt="" />
                          <h2>{row?.Location?.name}</h2>
                        </div>
                        <div className="order-info__info">
                          <img src={theme === 'dark' ? currencyDarkIcon : currencyIcon} alt="" />
                          <h2>
                            {ViewHelper.toCurrency(
                              orderTotal,
                              row?.Location?.Currency?.currency_code,
                              row?.Location?.decimal_places
                            )}
                          </h2>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })
          : null
        : null}
    </Grid>
  );

  const orderTypeList = [
    { id: 1, title: 'All orders', img: allOrderIcon },
    { id: 2, title: 'Live Orders', img: liveOrders },
    { id: 3, title: 'Closed Orders', img: closedOrders },
    { id: 4, title: 'Delivery orders', img: deliveryOrders },
    { id: 5, title: 'Dine In orders', img: dineInOrders },
    { id: 6, title: 'Curbside orders', img: curbsideOrders }
  ];

  const orderFilterList = [
    'Live Orders',
    'Closed Orders',
    'Delivery orders',
    'Dine In orders',
    'Curbside orders',
    'Pickup orders',
    'All orders'
  ];

  const orderFilterData = [
    { id: 1, key: 'Most Recent', value: 'Most Recent' },
    { id: 2, key: 'Most Oldest First', value: 'Most Oldest First' }
  ];
  const orderStatusData = [
    { id: 1, key: 'Complete', value: 'Complete' },
    { id: 2, key: 'Placed', value: 'Placed' },
    { id: 2, key: 'On the way', value: 'On the way' }
  ];

  const sortOptions = ['Most Recent', 'Older First'];

  return (
    <>
      <MenuDesktop toggleMenu={toggleMenuCollapsed} />
      {props.loading ? (
        <SpinnerComponent />
      ) : (
        <StyledOrderList
          theme={theme}
          sortItemsVisibility={showDropDownItems}
          filterItemsVisibility={showFilterDropDownItems}>
          <SideBar />
          <Container>
            <Row>
              <Col md={12}>
                <div className="sorting">
                  <div className="filter-orders">
                    {orderTypeList.map((order) => {
                      return (
                        <div
                          onClick={() => changeFilterKeyName(order.id)}
                          key={order.id}
                          className={
                            filterKeyName.toString() === order.id.toString()
                              ? 'filter-orders__order active-order'
                              : 'filter-orders__order'
                          }>
                          <img src={order.img} alt="" />
                          <h2>{order.title}</h2>
                        </div>
                      );
                    })}
                  </div>
                  <div className="sort-orders">
                    <div className="sort-orders__filter">
                      <h2>Filter</h2>
                      <Dropdown
                        arrowOpen={
                          <span className={'arrow-open'}>
                            <img src={dropdownArrow} alt="" />
                          </span>
                        }
                        arrowClosed={
                          <span className={'arrow-close'}>
                            <img src={dropdownArrow} alt="" />
                          </span>
                        }
                        options={orderFilterList}
                        value={filterOrderValue}
                        onChange={changeFilterOrderValue}
                        placeholder="Filter: "
                      />
                    </div>
                    <div className="sort-orders__dropdown">
                      <h2>Sort</h2>
                      <Dropdown
                        arrowOpen={
                          <span className={'arrow-open'}>
                            <img src={dropdownArrow} alt="" />
                          </span>
                        }
                        arrowClosed={
                          <span className={'arrow-close'}>
                            <img src={dropdownArrow} alt="" />
                          </span>
                        }
                        options={sortOptions}
                        value={sortOrderValue}
                        onChange={changeSortOrderValue}
                        placeholder="Sort: "
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>{orderListData()}</Col>
            </Row>
          </Container>
        </StyledOrderList>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.posReducer ? state.posReducer.loading : false,
    location: state.posReducer ? state.posReducer.location : null,
    token: state.posReducer ? state.posReducer.token : null,
    liveOrderList: state.posReducer ? state.posReducer.liveOrderList : null,
    customizer: state.customizer ? state.customizer : null
  };
}

export default connect(mapStateToProps, {
  setLocationData,
  setTokenData,
  getLiveOrders
})(OrderList);
