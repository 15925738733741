import CONSTANTS from '../constants';
import { customFetch as fetch } from '../helpers/FetchHelper';
import LoginService from './LoginService';

const createRawInventoryItem = async (payload, location_id) => {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.INVENTORY.CREATE(location_id)}`,
    {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  if (response.status === 500) {
    throw new Error(response.text());
  }
  return response;
};

const getRecipesUnits = async (inventory_id, location_id) => {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.INVENTORY.GET_RECIPE_UNITS(location_id, inventory_id)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  if (response.status === 500) {
    throw new Error(response.text());
  }
  return response.json();
};

const getPurchaseUnits = async (inventory_id, location_id) => {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.INVENTORY.GET_PURCHASE_UNITS(location_id, inventory_id)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  if (response.status === 500) {
    throw new Error(response.text());
  }
  return response.json();
};

const getAllInventoryItems = async (location_id) => {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.INVENTORY.GET_ALL(location_id)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  if (response.status === 500) {
    throw new Error(response.text());
  }
  return response.json();
};

const getInventoryItem = async (location_id, inventory_id) => {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.INVENTORY.GET_ONE(location_id, inventory_id)}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );

  if (response.status === 500) {
    throw new Error(response.text());
  }

  return response.json();
};

const updateInventoryItem = async (location_id, inventory_id, payload) => {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.INVENTORY.UPDATE(location_id, inventory_id)}`,
    {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  if (response.status === 500) {
    throw new Error(response.text());
  }
  return response;
};

export default {
  createRawInventoryItem,
  getAllInventoryItems,
  getInventoryItem,
  updateInventoryItem,
  getRecipesUnits,
  getPurchaseUnits
};
