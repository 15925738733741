import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = (props) => {
  let {
    accept,
    reject,
    isOpen,
    title = 'Are you sure you want to delete this item?',
    header = 'Confirm Delete Action',
    rejectTitle = 'No'
  } = props;

  useEffect(() => {
    accept = props.accept;
    reject = props.reject;
    isOpen = props.isOpen;
    title = props.title ? props.title : 'Are you sure you want to delete this item?';
  }, [props]);

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={reject} className="modal-dialog-centered">
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>
          <span className="font-medium-1">{title}</span>
        </ModalBody>

        <ModalFooter>
          {reject && (
            <Button color="dark" onClick={reject}>
              {rejectTitle}
            </Button>
          )}{' '}
          {accept && (
            <Button color="danger" onClick={accept}>
              Yes
            </Button>
          )}{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default ConfirmationModal;
