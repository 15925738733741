import { CART_PAGE } from '../../constants/cart';

let initialState = {
  cart: [],
  cartLoading: false,
  cartErr: null,
  checkout: [],
  amountDue: 0,
  amountReceived: 0,
  table: '',
  orderType: '',
  customer: {},
  address: '',
  discountType: '',
  discountAmount: '',
  paymentType: 'Cash',
  orderDate: '',
  orderTime: '',
  cartUpdate: false,
  orderId: null,
  noteVisibility: false,
  comment: ''
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_PAGE.ADD_ITEM_TO_CART.SUCCESS:
      let cartItem = action.result; // action.result = {whole cart item}
      let quantity = action.quantity;

      let found = state.cart.length
        ? state.cart.find((item) => item.cartItem.identify === cartItem.identify)
        : null;

      let newCart = [];

      if (found) {
        newCart = [...state.cart];
        let idx = state.cart.findIndex((item) => item.cartItem.identify === cartItem.identify);

        //newCart[idx].count += 1
        newCart[idx].count = quantity;
        newCart[idx].cartItem = cartItem;
      } else {
        let addCart = {
          count: quantity,
          cartItem: cartItem
        };

        newCart = [...state.cart];

        newCart.push(addCart);
      }

      return {
        ...state,
        cart: newCart
      };
    case CART_PAGE.REPLACE_CART.SUCCESS:
      return {
        ...state,
        cart: action.result
      };

    case CART_PAGE.REMOVE_ITEM_FROM_CART.SUCCESS:
      let removeItem = action.result; // action.result = { count, id }

      let updated = [...state.cart];

      let isAvailable = updated.length
        ? updated.find((item) => item.cartItem.identify === removeItem.identify)
        : null;

      if (isAvailable) {
        updated = updated.filter((item) => item.cartItem.identify !== removeItem.identify);
      }

      return {
        ...state,
        cart: updated
      };

    case CART_PAGE.UPDATE_QUANTITY.SUCCESS:
      let qty = action.result;
      let identify = action.identify;
      let original_price = action.original_price;

      let items = [...state.cart];

      let isItem = items.length ? items.find((item) => item.cartItem.identify === identify) : null;

      if (isItem) {
        if (qty > 0) {
          let inx = items.findIndex((item) => item.cartItem.identify === identify);

          items[inx].count = parseInt(qty);
          items[inx].cartItem.original_price = original_price;
          items[inx].cartItem.quantity = parseInt(qty);
        }
      }

      return {
        ...state,
        cart: items
      };

    case CART_PAGE.CLEAR_CART.SUCCESS:
      return {
        ...state,
        cart: [],
        amountDue: 0,
        amountReceived: 0,
        table: '',
        orderType: '',
        customer: {},
        address: '',
        discountType: '',
        discountAmount: '',
        paymentType: 'Cash',
        orderDate: '',
        orderTime: '',
        comment: '',
        orderId: null,
        cartUpdate: false,
        noteVisibility: false
      };
    case CART_PAGE.SET_ADDRESS.SUCCESS:
      return {
        ...state,
        address: action.result
      };
    case CART_PAGE.SET_CHECKOUT.SUCCESS:
      return {
        ...state,
        checkout: action.result
      };

    case CART_PAGE.CLEAR_CHECKOUT.SUCCESS:
      return {
        ...state,
        checkout: []
      };
    case CART_PAGE.CLEAR_USER.SUCCESS:
      return {
        ...state,
        customer: {}
      };
    case CART_PAGE.ADD_CART_TOTAL_DUE.SUCCESS:
      return {
        ...state,
        amountDue: action.result
      };
    case CART_PAGE.ADD_CART_TOTAL_RECEIVED.SUCCESS:
      return {
        ...state,
        amountReceived: action.result
      };
    case CART_PAGE.ADD_CART_ORDER_ORDER_TYPE.SUCCESS:
      return {
        ...state,
        orderType: action.result
      };
    case CART_PAGE.ADD_CART_ORDER_TABLE_NO.SUCCESS:
      return {
        ...state,
        table: action.result
      };
    case CART_PAGE.ADD_CART_ORDER_DISCOUNT_TYPE.SUCCESS:
      return {
        ...state,
        discountType: action.result
      };
    case CART_PAGE.ADD_CART_ORDER_DISCOUNT.SUCCESS:
      return {
        ...state,
        discountAmount: action.result
      };
    case CART_PAGE.ADD_CART_ORDER_PAYMENT_TYPE.SUCCESS:
      return {
        ...state,
        paymentType: action.result
      };
    case CART_PAGE.ADD_CART_ORDER_CUSTOMER.SUCCESS:
      return {
        ...state,
        customer: action.result
      };
    case CART_PAGE.SET_CART_ORDER_ORDERING_TIME.SUCCESS:
      return {
        ...state,
        orderTime: action.result
      };
    case CART_PAGE.SET_CART_ORDER_ORDERING_DATE.SUCCESS:
      return {
        ...state,
        orderDate: action.result
      };
    case CART_PAGE.UPDATE_CART.SUCCESS:
      return {
        ...state,
        cartUpdate: action.result
      };
    case CART_PAGE.SET_ORDER_ID.SUCCESS:
      return {
        ...state,
        orderId: action.result
      };
    case CART_PAGE.SET_NOTE_VISIBILITY.SUCCESS:
      return {
        ...state,
        noteVisibility: action.status
      };
    case CART_PAGE.SET_NOTE_DATA.SUCCESS:
      return {
        ...state,
        comment: action.result
      };
    case CART_PAGE.UPDATE_DISCOUNT:
      return {
        ...state,
        cart: action.cart
      };
    default:
      return state;
  }
};

export default cartReducer;
