import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { ContextLayout } from '../../helpers/context/Layout';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from './ErrorHandler';

const PublicRoute = ({ component: Component, fullLayout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return (
        <ContextLayout.Consumer>
          {(context) => {
            let LayoutTag =
              fullLayout === true
                ? context.fullLayout
                : context.state.activeLayout === 'horizontal'
                ? context.horizontalLayout
                : context.VerticalLayout;
            return (
              <LayoutTag {...props}>
                <Suspense fallback={<SpinnerComponent />}>
                  <ErrorHandler>
                    <Component {...props} />
                  </ErrorHandler>
                </Suspense>
              </LayoutTag>
            );
          }}
        </ContextLayout.Consumer>
      );
    }}
  />
);

export default PublicRoute;
