import CONSTANTS from '../../../constants';
import ConfigurationService from '../../../services/ConfigurationService';

export function getStatuses() {
  return async (dispatch) => {
    try {
      let statuses = await ConfigurationService.getStatuses();
      localStorage.setItem('statuses', JSON.stringify(statuses));
      dispatch({
        type: CONSTANTS.ACTIONS.CONFIGURATIONS.STATUSES,
        statuses: statuses,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.CONFIGURATIONS.STATUSES, statuses: null, loading: false });
    }
  };
}

export function getCurrencies() {
  return async (dispatch) => {
    try {
      let currencies = await ConfigurationService.getCurrencies();
      dispatch({
        type: CONSTANTS.ACTIONS.CONFIGURATIONS.CURRENCIES,
        currencies: currencies,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.CONFIGURATIONS.CURRENCIES,
        currencies: null,
        loading: false
      });
    }
  };
}

export function getCategories() {
  return async (dispatch) => {
    try {
      let categories = await ConfigurationService.getCategories();
      dispatch({ type: CONSTANTS.ACTIONS.CONFIGURATIONS.CATEGORIES, categories, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSTANTS.ACTIONS.CONFIGURATIONS.CATEGORIES,
        categories: null,
        loading: false
      });
    }
  };
}
