import React from 'react';
const CheftIcon = () => (
  <img
    alt="chef-icon"
    src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAADFElEQVRIidWSTWxUVRTHf+femU41UzpW0EpIysfEtplEE1OCLZ2BEFbduYAYExbgBly4gNAFiRsWBJuwMBrDBl2a4MJEE3QB2E4/oNImBKyl9ENgBWq/HDrY1/fucTFvmqmdqaM7zuqdc//v9z/n3AvPe0g1or6B6S4x2g3SVqjoLSP0dLYnv/9PBmNjYzVzC7HjTjgiSCtoDtEnqLxZ7mdFbgvaCNSDPEb0qvjufDr9+sw6g5GRkWjeS/wAHCjDmlCRMy7qZwEink2DnlOkpYx2AWP2Zd7eeQcgUqzmV+pPh/BxQbrVd8NB4D2zNbX7vIh38+Ce1tkSyDdXh8ezNUE0bZUhz7N/Rl9wbc7pJ8Bb6tx5oGvNBNmhqV+B7WKlOb1n1/3yG904BgdnmgJxD4D5TEeyAcCUnL8IYHwX/B84wAr6SvjpF2urKxLkO0XfD0S+6h2YORWvrR1ta9uarwS7cmUyFq+Xd9TKqPXFqNG9Dj0LgPLlOgOCyGnsyhvAbmNcNu/lC74VIp6QD4EecYozyqpY+fblxPJHRd3qitLppnng60Kmc8B85WUAIjmBn4FlYBZYLPDJplIpb/0EgCDNioLIx5n2ZM9G/Ez7rovAxWLef2PypKpcEENzqa70klG0BUCUyQ27LxcqvwDgaC0tR9aKaEZA4VL/0NS7Cv0ics9XfRDYlfnX4i738GGNxONskph5SQO3Q4SUU7Nf0YMhpaWyAXoC5HNgi8Jh4LCqYgEbRJldhHgiVAYKCKogaBHwG8gHpcR1r6RvaOqxwKsiXFC0ASUFshmoB+pCWQ5YBP0DYQwnvyN0A48yHcmmigaqavpvTAcAxvmbOjtbcv9soFxcvqy2cdv0MkCmI7lmK2sueXBwohEK8y7VLbtq4ACHDqFhszIwcG9rRQNnIl+Ewqf2WV28WoPe0fsNQB4wzkQuVTQAOgAC7O5EpLaq9QCQ8/8KlPYw27uRwS0ASzC+5C19Wi3fxmKfWeFumP5U0cA4OQpcBzxB3stmJ7f8G/zHkYnNFJ60B1wzTo5V29jzEX8DBLciVqquVsgAAAAASUVORK5CYII=`}
    width="20"
    height={'20'}
  ></img>
);

export default CheftIcon;
