import moment from 'moment';
import CONSTANTS from '../../../constants';
import { promotionsData } from '../../../pos/src/redux/actions/pos';
import { addData, getStoreData, initRestaurantDB } from '../../../pos/src/utils/DBHelper';
import PromotionsService from '../../../pos/src/utils/PromotionsService';
import PromotionService from '../../../services/PromotionService';

export function getPromotions(loc_id, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS, data: null, loading: true });
      let promotions = await PromotionService.getPromotions(loc_id);

      let promoAr = [];
      for (let i = 0; i < promotions.length; i++) {
        let promotionsData = await PromotionService.getPromotion(loc_id, promotions[i].id);
        promoAr.push(promotionsData);
      }
      if (promoAr.length > 0) localStorage.setItem('promotionList', JSON.stringify(promoAr));
      //dispatch(promotionsData(promoAr))

      dispatch({
        type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS,
        data: promotions,
        loading: false
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS, data: null, loading: false });
    }
  };
}

export function getFilteredPromotionList(loc_id, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS, data: null, loading: true });
      if (loc_id === undefined || loc_id === null) {
        dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS, data: null, loading: true });
      } else {
        initRestaurantDB(CONSTANTS.DB_CACHE_KEY_PREFIX, loc_id);
        let localPromotions = await getStoreData(
          CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
          CONSTANTS.INDEXDB.STORE.PROMOTIONS
        );
        if (localPromotions.length > 0) {
          let promotions = localPromotions[1].data;
          dispatch(promotionsData(promotions));

          dispatch({
            type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS,
            data: promotions,
            loading: false
          });
        } else {
          let promotions = await PromotionService.getFilteredPromotionList(loc_id);
          const date = moment().format(CONSTANTS.DATE_TIMES.FORMAT.DB);
          await addData(
            CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
            CONSTANTS.INDEXDB.STORE.PROMOTIONS,
            {
              id: 0,
              lastSync: date
            }
          );
          await addData(
            CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
            CONSTANTS.INDEXDB.STORE.PROMOTIONS,
            {
              id: 1,
              data: promotions
            }
          );
          dispatch(promotionsData(promotions));

          dispatch({
            type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS,
            data: promotions,
            loading: false
          });
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS, data: null, loading: false });
    }
  };
}

export function getCustomerPromotions(loc_id, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS, data: null, loading: true });
      let promotions = await PromotionsService.getPromotions(loc_id);
      dispatch({
        type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS,
        data: promotions,
        loading: false
      });
    } catch (error) {
      console.log('Error: ', error);
      dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS, data: null, loading: false });
    }
  };
}

export function savePromotion(loc_id, promotion, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: null, loading: true });
      let prom = await PromotionService.savePromotion(loc_id, promotion);
      dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: prom, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: null, loading: false });
    }
  };
}

export function updatePromotion(loc_id, promotion, promotion_id, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: null, loading: true });
      let prom = await PromotionService.updatePromotion(loc_id, promotion, promotion_id);
      dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: prom, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: null, loading: false });
    }
  };
}

export function togglePromotion(loc_id, promotionId, initialize = false) {
  return async (dispatch) => {
    try {
      if (initialize)
        dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: null, loading: true });
      let prom = await PromotionService.togglePromotion(loc_id, promotionId);
      dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: prom, loading: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: null, loading: false });
    }
  };
}
