import React from 'react';
import { Br, Cut, Line, Printer, render, Row, Text } from 'react-thermal-printer';
import ViewHelper from './ViewHelper';

export default {
  getOrderInvoiceData: async (orderData, location) => {
    let calculatePrice = (items) => {
      let price = 0;
      for (const item of items) {
        price += parseFloat(item.subtotal);
      }
      return price;
    };

    const getMenuOptions = (menu_id) => {
      return orderData.OrderOptions.filter((item) => (item.menu_id = menu_id));
    };

    const subtotal = calculatePrice(orderData.OrderMenus);
    const taxAmount = orderData.OrderTotals.find((totals) => totals.code === 'tax').value;
    const discount = orderData.OrderTotals.find((totals) => totals.code === 'discount').value;
    const deliveryCharges = orderData.delivery_charges || 0;
    const locationAddressLines = splitIntoLines(location.formattedAddress, 42);

    const invoice = (
      <Printer type="epson" width={42}>
        <Text align="center" bold={true}>
          {location.name}
        </Text>
        {locationAddressLines.map((address) => (
          <Text align="center">{address}</Text>
        ))}
        <Text align="center">{location.location_telephone}</Text>
        <Br />
        <Text>Order ID: {orderData.order_id}</Text>
        {orderData.first_name && orderData.last_name && (
          <Text>{`Customer: ${orderData.first_name} ${orderData.last_name}`}</Text>
        )}
        {orderData.delivery_phone && orderData.delivery_address && (
          <>
            <Text>{`Phone: ${orderData.delivery_phone}`}</Text>
            <Text>{`Address: ${orderData.delivery_address}`}</Text>
          </>
        )}
        <Text>Order Type: {orderData.OrderType.label}</Text>
        {orderData.OrderType.order_type === 'dine_in' && (
          <Text>Table: {orderData.Table.table_name || ''}</Text>
        )}
        <Text>{`Date: ${orderData.order_date}`}</Text>
        <Text>{`Time: ${orderData.order_time}`}</Text>
        <Line />
        {orderData.OrderMenus.map((item, index) => (
          <>
            {index !== 0 && <Br />}
            <Row left={`x${item.quantity}  ${item.name}`} right={`${item.subtotal}`}></Row>
            {item.portion_size_label && <Text>{`    ${item.portion_size_label}`}</Text>}
            {getMenuOptions(item.menu_id).length > 0 &&
              getMenuOptions(item.menu_id).map((opt) => (
                <Text>{`    x${opt.quantity} ${opt.order_option_name}`}</Text>
              ))}
          </>
        ))}
        <Line />
        <Row
          left={<Text bold={true}>{`Sub Total`}</Text>}
          right={`${ViewHelper.round(subtotal, location.decimal_places)}`}></Row>
        <Row
          left={<Text bold={true}>{`${location.tax_percent}% Tax (+)`}</Text>}
          right={`${ViewHelper.round(taxAmount, location.decimal_places)}`}></Row>
        {discount > 0 && (
          <Row
            left={<Text bold={true}>{`Discount (-)`}</Text>}
            right={`${ViewHelper.round(discount, location.decimal_places)}`}></Row>
        )}
        {deliveryCharges > 0 && (
          <Row
            left={<Text bold={true}>{`Delivery Charges (-)`}</Text>}
            right={`${ViewHelper.round(deliveryCharges, location.decimal_places)}`}></Row>
        )}
        <Line />
        {/* <Row
          left={<Text bold={true}>{`  Payment Type`}</Text>}
          right={`${cartData.payment_details.method}  `}></Row> */}
        <Row
          left={<Text bold={true}>{`Total Amount`}</Text>}
          right={`Rs ${ViewHelper.round(
            subtotal + taxAmount - discount + deliveryCharges,
            location.decimal_places
          )}`}></Row>
        <Line />
        <Cut />
      </Printer>
    );

    return await render(invoice);
  },
  getCartInvoiceData: async (cartData, location, orderId) => {
    let calculatePrice = (items) => {
      let price = 0;
      for (const item of items) {
        price += parseFloat(item.original_price);
      }
      return price;
    };

    let getTax = (taxPercent, subTotal) => {
      taxPercent = parseFloat(taxPercent) || 0.0;
      return subTotal * (taxPercent / 100.0);
    };

    const subtotal = calculatePrice(cartData.order_data);
    const taxAmount = getTax(location.tax_percent, subtotal);
    const discount = cartData.total_discount || 0;
    const deliveryCharges = cartData.delivery_charges || 0;
    const locationAddressLines = splitIntoLines(location.formattedAddress, 42);

    const invoice = (
      <Printer width={42} type="epson">
        <Text align="center" bold={true}>
          {location.name}
        </Text>
        {locationAddressLines.map((address) => (
          <Text align="center">{address}</Text>
        ))}
        <Text align="center">{location.location_telephone}</Text>
        <Br />
        <Text>Order ID: {orderId}</Text>
        {cartData.customer.first_name && cartData.customer.last_name && (
          <Text>{`Customer: ${cartData.customer.first_name} ${cartData.customer.last_name}`}</Text>
        )}
        {cartData.delivery_phone && cartData.delivery_address && (
          <>
            <Text>{`Phone: ${cartData.delivery_phone}`}</Text>
            <Text>{`Address: ${cartData.delivery_address}`}</Text>
          </>
        )}
        <Text>Order Type: {cartData.order_type_label}</Text>
        {cartData.order_type === 'dine_in' && <Text>Table: {cartData.table.table_name || ''}</Text>}
        <Text>{`Date: ${cartData.order_date}`}</Text>
        <Text>{`Time: ${cartData.order_time}`}</Text>
        <Line />
        {cartData.order_data.map((item, index) => (
          <>
            {index !== 0 && <Br />}
            <Row
              left={`x${item.quantity}  ${item.menu_name}`}
              right={`${item.original_price}`}></Row>
            {item.portionSize && <Text>{`    ${item.portionSize.label}`}</Text>}
            {item.options &&
              item.options.length > 0 &&
              item.options.map((opt) => <Text>{`    x${opt.qty} ${opt.option_value_name}`}</Text>)}
          </>
        ))}
        <Line />
        <Row
          left={<Text bold={true}>{`Sub Total`}</Text>}
          right={`${ViewHelper.round(subtotal, location.decimal_places)}`}></Row>
        <Row
          left={<Text bold={true}>{`${location.tax_percent}% Tax (+)`}</Text>}
          right={`${ViewHelper.round(taxAmount, location.decimal_places)}`}></Row>
        {discount > 0 && (
          <Row
            left={<Text bold={true}>{`Discount (-)`}</Text>}
            right={`${ViewHelper.round(discount, location.decimal_places)}`}></Row>
        )}
        {deliveryCharges > 0 && (
          <Row
            left={<Text bold={true}>{`Delivery Charges (-)`}</Text>}
            right={`${ViewHelper.round(deliveryCharges, location.decimal_places)}`}></Row>
        )}
        <Line />
        {/* <Row
          left={<Text bold={true}>{`  Payment Type`}</Text>}
          right={`${cartData.payment_details.method}  `}></Row> */}
        <Row
          left={<Text bold={true}>{`Total Amount`}</Text>}
          right={`Rs ${ViewHelper.round(
            subtotal + taxAmount - discount + deliveryCharges,
            location.decimal_places
          )}`}></Row>
        <Line />
        <Cut />
      </Printer>
    );

    return await render(invoice);
  },

  getKitchenInvoiceData: async (cartData, orderId, stationLabel) => {
    const invoice = (
      <Printer type="epson" width={42}>
        <Text align="center">Order ID: {orderId}</Text>
        <Text align="center">Order Type: {cartData.order_type_label}</Text>
        {cartData.order_type === 'dine_in' && (
          <Text align="center">Table: {cartData.table.table_name || ''}</Text>
        )}
        <Text align="center">{`Date: ${cartData.order_date}`}</Text>
        <Text align="center">{`Time: ${cartData.order_time}`}</Text>
        <Text align="center">{`Print Station: ${stationLabel}`}</Text>
        <Line />
        {cartData.order_data.map((item, index) => (
          <>
            {index !== 0 && <Br />}
            <Text>{` x${item.quantity}  ${item.menu_name}`}</Text>
            {item.portionSize && <Text>{`     ${item.portionSize.label}`}</Text>}
            {item.options &&
              item.options.length > 0 &&
              item.options.map((opt) => <Text>{`     x${opt.qty} ${opt.option_value_name}`}</Text>)}
          </>
        ))}
        <Line />
        <Cut />
      </Printer>
    );

    return await render(invoice);
  }
};

function splitIntoLines(input, len) {
  var i;
  var output = [];
  var lineSoFar = '';
  var temp;
  var words = input.split(' ');
  for (i = 0; i < words.length; ) {
    // check if adding this word would exceed the len
    temp = addWordOntoLine(lineSoFar, words[i]);
    if (temp.length > len) {
      if (lineSoFar.length == 0) {
        lineSoFar = temp; // force to put at least one word in each line
        i++; // skip past this word now
      }
      output.push(lineSoFar); // put line into output
      lineSoFar = ''; // init back to empty
    } else {
      lineSoFar = temp; // take the new word
      i++; // skip past this word now
    }
  }
  if (lineSoFar.length > 0) {
    output.push(lineSoFar);
  }
  return output;
}

function addWordOntoLine(line, word) {
  if (line.length != 0) {
    line += ' ';
  }
  return (line += word);
}
