import { combineReducers } from 'redux';
import {
  allCustomers,
  allreports,
  allusers,
  customerSelectedLocation,
  existingCustomerDetails,
  existingCustomerLocations,
  showAddLocationButton,
  uploadusers,
  users
} from './UserReducer';

const usersReducer = combineReducers({
  showAddLocationButton,
  users,
  allusers,
  allreports,
  allCustomers,
  existingCustomerDetails,
  existingCustomerLocations,
  customerSelectedLocation,
  uploadusers
});

export default usersReducer;
