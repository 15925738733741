import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import removeIcon from '../../../assets/img/static/remove-icon-colored.svg';
import ImageHelper from '../../../helpers/ImageHelper';
import ViewHelper from '../../../helpers/ViewHelper';

const ItemDetailData = (props) => {
  const renderPortionSizes = () => {
    const portion = props.currentItemOptions.Portion;
    const portionSizes = portion.PortionSizes;

    return (
      <div className="item-modal__options__data__parent">
        <div className="option-header" id={`Required-${portion.portion_id}`}>
          <h2>{portion.label}</h2>
          <h3 ref={props.RequiredColor} className="Required">
            Required
          </h3>
        </div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="radio-buttons-group"
            defaultValue={portionSizes[0].label}>
            {portionSizes.map((item, itemIndex) => {
              return (
                <FormControlLabel
                  key={item.portion_size_id}
                  value={item.label}
                  control={<Radio checkedIcon={<CheckCircleIcon />} />}
                  onChange={() => props.onPortionSizeSelect(item)}
                  label={
                    <div className={'option-item-detail'}>
                      <h2>{item.label}</h2>
                      <span className="option-price">
                        {ViewHelper.toCurrency(
                          item.price,
                          props.currency?.currency_code,
                          props.finalLooc?.decimal_places
                        )}
                      </span>
                    </div>
                  }
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  return (
    <div className="item-modal-parent">
      <div className="item-modal-parent__header">
        <h2>Select Modifier</h2>
        <button onClick={props.handleAddOnModalClose}>Cancel</button>
        {/*<button style={{color: "#FF4646"}} onClick={handleAddOnModalClose}>Remove Item</button>*/}
      </div>

      <div className="item-modal">
        <div className="item-modal__details">
          {props.connection && props.currentItemOptions?.menu_photo ? (
            <div className={'item-modal__details__image'}>
              <img
                src={ImageHelper.getImageURL(
                  props.currentItemOptions?.menu_photo,
                  '?func=crop&w=686&h=500'
                )}
                alt={props.currentItemOptions?.menu_name ? props.currentItemOptions?.menu_name : ''}
              />
            </div>
          ) : null}

          {/*<img src={burgerImage} alt=""/>*/}
          <div>
            <div className="item-modal-increment">
              <div className="item-stock">
                <h2>{props.currentItemOptions?.menu_name}</h2>

                {props.currentItemOptions?.menu_description && (
                  <h2>{props.currentItemOptions?.menu_description}</h2>
                )}
              </div>
              <div className="item-increment">
                <div className="increase-buttons">
                  {props.currentItemOptions?.menu_status === 2 && (
                    <h2 className={'stock-out'}>Out of stock</h2>
                  )}
                  {props.currentItemOptions?.menu_status === 1 && (
                    <>
                      <span onClick={props.dicreaseQty}>-</span>
                      <TextField
                        className="input-field"
                        type="number"
                        defaultValue={props.quantity}
                        value={props.quantity}
                        onChange={props.change.bind(null, 'quantity')}
                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                        disabled={true}
                      />
                      <span onClick={props.increaseQty}>+</span>
                      {props.isItemExistsInCart(props.currentItemOptions?.identify) === true && (
                        <button
                          className="remove-item"
                          onClick={() => props.handleDeleteCartItem(props.currentItemOptions)}>
                          <img src={removeIcon} alt="" />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {props.currentItemOptions?.menu_description && (
              <div className="item-modal-content">
                <h2>{props.currentItemOptions?.menu_description}</h2>
              </div>
            )}
          </div>
        </div>
        <div className="item-modal__options">
          <div className="item-modal__options__header">
            {props.currentItemMenuOptions.length > 0 ? (
              <h2>Select Modifier</h2>
            ) : (
              <h2>{props.currentItemOptions?.menu_name}</h2>
            )}
            <button onClick={props.handleAddOnModalClose}>Cancel</button>
            {/*<button style={{color: "#FF4646"}} onClick={handleAddOnModalClose}>Remove Item</button>*/}
          </div>

          <div className="item-modal__options__data">
            {props.currentItemOptions.Portion && renderPortionSizes()}
            {props.currentItemMenuOptions.map((options) => {
              if (options.status === 0) return null;
              return (
                <div key={options?.option_id} className="item-modal__options__data__parent">
                  <div
                    className="option-header"
                    id={
                      options?.required === 1
                        ? `Required-${options?.option_id}`
                        : options.min && parseInt(options.min) !== 0
                        ? `Required-${options?.option_id}`
                        : options.max && parseInt(options.max) !== 0
                        ? `Required-${options?.option_id}`
                        : 'Optional'
                    }>
                    <h2>
                      {options?.Option?.option_name}
                      <br />
                      <a className="required-error" id={`required-error-${options?.option_id}`}></a>
                    </h2>
                    <h3 ref={props.RequiredColor} className="Required">
                      {options?.required === 1
                        ? 'Required'
                        : options.min && parseInt(options.min) !== 0
                        ? 'Required'
                        : options.max && parseInt(options.max) !== 0
                        ? 'Required'
                        : 'Optional'}
                    </h3>
                  </div>
                  {console.log(options)}
                  {/* {options?.required === 1 && (
                    <p
                      className={`Required-item-${options?.option_id}`}
                      style={{ display: 'none', color: '#EA5455', fontSize: '14px', margin: '0' }}>
                      This item is required
                    </p>
                  )} */}
                  {options?.Option?.display_type === 'radio' && (
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="gender" name="radio-buttons-group">
                        {options?.OptionValues?.map((item, itemIndex) => {
                          if (item.status === 0) return null;
                          let MenuOptionValueNew = options?.MenuOptionValues.find(
                            (p) => p.option_value_id === item?.option_value_id
                          );
                          item.price = MenuOptionValueNew?.new_price
                            ? MenuOptionValueNew?.new_price
                            : item?.price;

                          return (
                            <FormControlLabel
                              key={item.id}
                              value={item.value}
                              control={<Radio checkedIcon={<CheckCircleIcon />} />}
                              checked={
                                props.checkIfOptionIsIncluded(
                                  item?.option_id,
                                  item?.option_value_id
                                ) === true
                                  ? true
                                  : false
                              }
                              onChange={() => props.onRadioSelect(options?.Option, item)}
                              label={
                                <div className={'option-item-detail'}>
                                  <h2>{item?.value.replaceAll('?', '')}</h2>
                                  <span className="option-price">
                                    {ViewHelper.toCurrency(
                                      MenuOptionValueNew?.new_price
                                        ? MenuOptionValueNew?.new_price
                                        : item?.price,
                                      props.currency?.currency_code,
                                      props.finalLooc?.decimal_places
                                    )}
                                  </span>
                                </div>
                              }
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {options.Option?.display_type === 'checkbox' && (
                    <FormControl component="fieldset">
                      <FormGroup>
                        {options?.OptionValues?.map((item, itemIndex) => {
                          if (item.status === 0) return null;
                          let MenuOptionValueNew = options?.MenuOptionValues.find(
                            (p) => p.option_value_id === item?.option_value_id
                          );
                          item.price = MenuOptionValueNew?.new_price
                            ? MenuOptionValueNew?.new_price
                            : item?.price;
                          return (
                            <FormControlLabel
                              key={item?.id}
                              control={<Checkbox name="gilad" />}
                              checked={
                                props.checkIfOptionIsIncluded(
                                  item.option_id,
                                  item.option_value_id
                                ) === true
                                  ? true
                                  : false
                              }
                              onChange={(event) => {
                                event.target.checked
                                  ? props.onSelectAdd(options?.Option, item)
                                  : props.onSelectRemove(options?.Option, item);
                              }}
                              label={
                                <div className={'option-item-detail'}>
                                  <h2>{item?.value.replaceAll('?', '')}</h2>
                                  <div className={'option-item-price'}>
                                    <span>
                                      {item?.price
                                        ? `${ViewHelper.toCurrency(
                                            MenuOptionValueNew?.new_price
                                              ? MenuOptionValueNew?.new_price
                                              : item?.price,
                                            props.currency?.currency_code,
                                            props.finalLooc?.decimal_places
                                          )}`
                                        : '-'}
                                    </span>
                                  </div>
                                </div>
                              }
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  )}
                  {options?.Option?.display_type === 'select' && (
                    <div className={'select-option-parent'}>
                      {options?.OptionValues?.map((item, itemIndex) => {
                        if (item.status === 0) return null;
                        let MenuOptionValueNew = options?.MenuOptionValues.find(
                          (p) => p.option_value_id === item?.option_value_id
                        );
                        item.price = MenuOptionValueNew?.new_price
                          ? MenuOptionValueNew?.new_price
                          : item?.price;

                        return (
                          <div className={'select-option-item-detail'}>
                            <div
                              className="select-option-item-name"
                              onClick={() =>
                                props.onSelectAdd(options, item, options.Option.display_type)
                              }>
                              <h2>
                                {props.getOptionItemQty(item) >= 1
                                  ? `${props.getOptionItemQty(item)}x `
                                  : ''}
                              </h2>
                              <h2>{item?.value.replaceAll('?', '')}</h2>
                            </div>
                            <div className={'select-option-item-price'}>
                              <div className={'select-option-item-price__content'}>
                                <div
                                  className="select-option-item-price__content-price"
                                  onClick={() =>
                                    props.onSelectAdd(
                                      options?.Option,
                                      item,
                                      options?.Option?.display_type
                                    )
                                  }>
                                  <h2>
                                    {item?.price
                                      ? `${ViewHelper.toCurrency(
                                          MenuOptionValueNew?.new_price
                                            ? MenuOptionValueNew?.new_price
                                            : item?.price,
                                          props.currency?.currency_code,
                                          props.finalLooc?.decimal_places
                                        )}`
                                      : '-'}
                                  </h2>
                                </div>
                                {props.getOptionItemQty(item) >= 1 ? (
                                  <div
                                    className={
                                      'select-option-item-price__content__increase-buttons'
                                    }
                                    style={{ zIndex: '9999' }}>
                                    <button
                                      onClick={() => {
                                        props.getOptionItemQty(item) === 1
                                          ? props.onSelectRemove(options?.Option, item)
                                          : props.onAddonIncrementDecrement(
                                              options?.Option?.display_type,
                                              options,
                                              item,
                                              false
                                            );
                                      }}>
                                      -
                                    </button>
                                    <button
                                      onClick={() =>
                                        props.onAddonIncrementDecrement(
                                          options?.Option?.display_type,
                                          options,
                                          item,
                                          true
                                        )
                                      }>
                                      +
                                    </button>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
            <div className="note">
              <h2>Add note</h2>
              <textarea
                defaultValue={
                  props.currentItemOptions?.comment !== undefined
                    ? props.currentItemOptions?.comment
                    : ''
                }
                onChange={props.onCommentChange}
                name="comment"
                id=""
                cols="30"
                rows="10"
              />
            </div>
          </div>
          {props.currentItemOptions?.menu_status === 2 && (
            <div className="complete" onClick={props.handleAddOnModalClose}>
              <button>Back</button>
            </div>
          )}
          {props.currentItemOptions?.menu_status === 1 && (
            <div
              className="complete"
              onClick={() => props.handleItemOnClick(props.currentItemOptions)}>
              <button>Done</button>
              <div className="complete__right-side">
                <h2>
                  {ViewHelper.toCurrency(
                    props.originalPrice,
                    props.currency?.currency_code,
                    props.finalLooc?.decimal_places
                  )}
                </h2>
              </div>
            </div>
          )}
          {props.currentItemOptions?.menu_status === 0 && (
            <div
              className="complete"
              onClick={() => props.handleItemOnClick(props.currentItemOptions)}>
              <button>Done</button>
              <div className="complete__right-side">
                <h2>
                  {ViewHelper.toCurrency(
                    props.originalPrice,
                    props.currency?.currency_code,
                    props.finalLooc?.decimal_places
                  )}
                </h2>
              </div>
            </div>
          )}
          <a
            href={`#${props.requiredId}`}
            ref={props.requiredTrigger}
            style={{ opacity: '0', zIndex: -1 }}>
            Click
          </a>
        </div>
      </div>
      {props.currentItemOptions?.menu_status === 2 && (
        <div className="complete-mobile" onClick={props.handleAddOnModalClose}>
          <button>Back</button>
        </div>
      )}
      {props.currentItemOptions?.menu_status === 1 && (
        <div
          className="complete-mobile"
          onClick={() => props.handleItemOnClick(props.currentItemOptions)}>
          <button>Done</button>
          <div className="complete-mobile__right-side">
            <h2>
              {ViewHelper.toCurrency(
                props.originalPrice,
                props.currency?.currency_code,
                props.finalLooc?.decimal_places
              )}
            </h2>
          </div>
        </div>
      )}
      {props.currentItemOptions?.menu_status === 0 && (
        <div
          className="complete-mobile"
          onClick={() => props.handleItemOnClick(props.currentItemOptions)}>
          <button>Done</button>
          <div className="complete-mobile__right-side">
            <h2>
              {ViewHelper.toCurrency(
                props.originalPrice,
                props.currency?.currency_code,
                props.finalLooc?.decimal_places
              )}
            </h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemDetailData;
