import { combineReducers } from 'redux';
import { options, option, menuState } from './AllOptionReducer';

const optionsReducer = combineReducers({
  options,
  option,
  menuState
});

export default optionsReducer;
