import LoginService from '../services/LoginService';

export async function customFetch(url, options) {
  if (window.navigator.onLine) {
    let response = await fetch(url, options);
    if (response.status === 401) {
      try {
        const token = await LoginService.refresh();
        response = await fetch(url, {
          ...options,
          headers: { ...options.headers, 'X-ACCESS-TOKEN': token }
        });
      } catch (e) {
        console.log(e);
        LoginService.removeLoginData();
        window.location.href = '/';
      }
    }
    return response;
  }
}
