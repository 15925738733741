import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getPromotions,
  getPromotion,
  updatePromotion,
  savePromotion,
  togglePromotion,
  getFilteredPromotionList
};

async function getPromotions(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PROMOTION.DEFAULT(loc_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;

  throw new Error('Could not fetch promotions');
}

async function getPromotion(loc_id, id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.PROMOTION.DEFAULT(loc_id)}/${id}`,
    {
      method: 'GET',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;

  throw new Error('Could not fetch promotions');
}

async function getFilteredPromotionList(loc_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PROMOTION.FILTERED(loc_id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;

  throw new Error('Could not fetch promotions');
}

async function updatePromotion(loc_id, promotion, promotion_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.PROMOTION.DEFAULT(loc_id)}/${promotion_id}`,
    {
      method: 'PUT',
      body: promotion,
      headers: {
        Accept: CONSTANTS.REQUEST_HEADERS.Accept,
        'X-ACCESS-TOKEN': token
      }
    }
  );
  response = await response.json();
  if (response) return response;

  throw new Error('Could not update categories');
}

async function savePromotion(loc_id, promotion) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PROMOTION.DEFAULT(loc_id)}`, {
    method: 'POST',
    body: promotion,
    headers: { Accept: CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;

  throw new Error('Could not save category');
}

async function togglePromotion(loc_id, promotion_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.PROMOTION.DEFAULT(loc_id)}/${promotion_id}`,
    {
      method: 'PATCH',
      headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    }
  );
  response = await response.json();
  if (response) return response;

  throw new Error('Could not delete category');
}
