import CONSTANTS from '../../../constants';

export function allcurrencies(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.CURRENCIES.ALL_CURRENCIES)
    return { data: action.currencies, loading: action.loading };
  return state;
}
export function allcountries(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.COUNTRIES.ALL_COUNTRIES)
    return { data: action.countries, loading: action.loading };
  return state;
}
