import { Workbox } from 'workbox-window';

export default function swDev() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swVersion = Date.now(); // version control for cache-busting
      const swUrl = `/worker.js?version=${swVersion}`;

      // Check if the user is online before attempting registration
      if (navigator.onLine) {
        navigator.serviceWorker
          .register(swUrl)
          .then((registration) => {
            console.log('Service Worker registered with scope:', registration);

            // Use Workbox to manage service worker lifecycle
            const wb = new Workbox(swUrl, { debug: false });

            // Register Workbox service worker
            wb.register();

            // Listen for service worker update found
            wb.addEventListener('updatefound', () => {
              const installingWorker = registration.installing;
              console.log('A new service worker is being installed:', installingWorker);

              installingWorker.addEventListener('statechange', () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    console.log('New content is available; please refresh.');
                    // Here you could show a prompt to the user to refresh
                  } else {
                    console.log('Content cached for offline use.');
                  }
                }
              });
            });

            // Ensure the service worker is updated if needed
            registration.update().catch((error) => {
              console.error('Service Worker update failed:', error);
            });
          })
          .catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      } else {
        console.warn('You are offline, service worker not registered.');
      }

      // Listen for when the service worker is already controlling the page
      navigator.serviceWorker.ready.then((registration) => {
        console.log('Service Worker is controlling the page:', registration);
      });
    });

    // Detect when the user comes back online
    window.addEventListener('online', () => {
      console.log('Back online, checking for service worker updates.');
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          registration.update();
        }
      });
    });
  }
}
