let ENV = {
  PROD: {
    WEB_URL: 'https://now.menu',
    API_HOST: 'https://plugd.app',
    API_ROOT: '/plugdapi/WebServicesV3/',
    PANEL_HOST: 'https://panel.plugd.app',
    BASE_URL: '/',
    FB_APP_ID: '1372007656237188',
    FIREBASE_CONFIG: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }
  },
  STAGE: {
    WEB_URL: 'https://dev.now.menu',
    API_HOST: 'https://dev.plugd.app',
    API_ROOT: '/plugdapi/WebServicesV3/',
    PANEL_HOST: 'https://paneldev.plugd.app',
    BASE_URL: '/',
    FB_APP_ID: '1372007656237188',
    FIREBASE_CONFIG: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }
  },
  STAGING: {
    WEB_URL: 'https://staging.plugd.app/plugdWeb',
    API_URL: 'https://staging.plugd.app:4000',
    SOCKET_URL: 'https://staging.plugd.app:3000',
    BASE_URL: '/',
    FB_APP_ID: '1372007656237188',
    FIREBASE_CONFIG: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }
  },
  DEV: {
    WEB_URL: 'http://localhost:3000',
    API_HOST: 'http://localhost',
    API_ROOT: '/plugdapi/WebServicesV3/',
    PANEL_HOST: 'https://paneldev.plugd.app',
    BASE_URL: '/',
    FB_APP_ID: '162127988411616',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyBJqJ2ZMxk7uDsCYupP4fqtlW0qawd4BbI',
      authDomain: 'reactlearning-202119.firebaseapp.com',
      databaseURL: 'https://reactlearning-202119.firebaseio.com',
      projectId: 'reactlearning-202119',
      storageBucket: 'reactlearning-202119.appspot.com',
      messagingSenderId: '956188906543',
      appId: '1:956188906543:web:697552464d001bfb0c62da',
      measurementId: 'G-WS99RCFG0Z'
    }
  }
};

let CONSTANTS = {
  BASE_URL: `${ENV[process.env.REACT_APP_STAGE].BASE_URL}`,
  API_HOST: `${ENV[process.env.REACT_APP_STAGE].API_HOST}`,
  API_URL: `${ENV[process.env.REACT_APP_STAGE].API_HOST}${
    ENV[process.env.REACT_APP_STAGE].API_ROOT
  }`,
  WEB_URL: `${ENV[process.env.REACT_APP_STAGE].WEB_URL}`,
  NODE_API_URL: process.env.REACT_APP_NODE_API_URL,
  CLOUD_IMAGE_URL: process.env.REACT_APP_CLOUD_IMAGE_URL,
  DB_CACHE_KEY_PREFIX: 'plugd_location_',
  DB_MAIN_CACHE_KEY_PREFIX: 'plugd_main',
  LOCATION_IMAGE_URL: `${ENV[process.env.REACT_APP_STAGE].API_HOST}:4000/images/`,
  FIREBASE_CONFIG: ENV[process.env.REACT_APP_STAGE].FIREBASE_CONFIG,
  TRACKING_CODE: process.env.REACT_APP_ANALYTICS_TRACKING_CODE,
  APP_SECRET: process.env.REACT_APP_SECRET,
  ADYEN_CONFIG: {
    PAYMENT_METHODS: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        name: 'Credit or debit card'
      }
    },
    RESULT_CODES: {
      SUCCESS: 'Authorised',
      FAILURE: 'Refused'
    }
  },
  GOOGLE_MAPS: {
    API_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY
  },
  CACHE_KEY_PREFIX: 'plugd_menu',
  APP_TITLE: 'Plugd',
  NAVBAR_HEIGHT: 80,
  CLUTTER: 0,
  REQUEST_HEADERS: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  URLS: {
    LOCATIONS: {
      GET: (locationId) => `customers/locations/${locationId}/details`,
      UPDATE_MENU: (locationId) => `customers/locations/${locationId}/menu`,
      LIST: 'get_nearest_restaurants',
      CHILDREN: 'customers/locations',
      SETTINGS: (locationId) => `customers/locations/${locationId}/branding`
    },
    CATEGORIES: {
      GET: 'get_categories'
    },
    ITEMS: {
      GET: 'get_simplified_menus',
      POPULAR_ITEMS: 'getTopRated',
      ITEM: {
        GET: 'item_reviews'
      }
    },
    LOGIN: {
      FB: 'fb_login_api',
      PHONE: 'phone_login',
      PROFILE: 'update_profile',
      CHECK: 'customers/users/check'
    },
    ORDERS: {
      CREATE: (locationId) => `customers/orders/${locationId}/place_order`,
      // CREATE: 'place_order',
      STATUS: 'order_status',
      SETTLE: 'request_bill'
    },
    PAYMENTS: {
      DEFAULT: 'customers/payments'
    },
    USER_LOCATIONS: {
      DEFAULT: 'customers/user_locations',
      BULK_INSERT: 'customers/user_locations/bulk_create'
    },
    CONFIGURATIONS: {
      DEFAULT: 'customers/configurations'
    },
    NOTIFICATIONS: {
      DEFAULT: 'customers/notifications'
    },
    REVIEWS: {
      DEFAULT: 'customers/reviews'
    },
    DELIVERY: {
      GET: (orderId) => `customers/delivery/${orderId}`
    }
  },
  ACTIONS: {
    LOCATIONS: {
      LIST: 'GET_LOCATIONS',
      CHILDREN: 'CHILD_LOCATIONS',
      OUTLETS: 'OUTLETS',
      GET: 'GET_LOCATION',
      SETTINGS: 'GET_SETTINGS'
    },
    // PROMOTIONS: {
    //   LIST: 'GET_ALL_PROMOTIONS',
    //   GET_PROMOTION: 'GET_PROMOTION',
    // },
    PROMOTIONS: {
      LIST: 'GET_ALL_PROMOTIONS',
      GET_PROMOTION: 'GET_PROMOTION'
    },
    CATEGORIES: {
      LIST: 'CATEGORIES',
      SELECT: 'SELECT_CATEGORY'
    },
    NOTIFICATIONS: {
      LIST: 'GET_NOTIFICATIONS'
    },
    ITEMS: {
      LIST: 'ITEMS',
      POPULAR_LIST: 'POPULAR_ITEMS',
      GET: 'GET_ITEM',
      GET_DETAILS: 'GET_DETAILS'
    },
    CART: {
      UPDATE: 'UPDATE_CART'
    },
    ORDERS: {
      CREATE: 'CREATE_ORDER',
      STATUS: 'ORDER_STATUS'
    },
    PAYMENTS: {
      PROCESSOR: {
        GET: 'get_processor'
      }
    },
    ALERTS: {
      DURATION: 5000,
      SUCCESS: 'alert_success',
      ERROR: 'alert_error',
      WARNING: 'alert_warning',
      CLEAR: 'alert_clear',
      TOAST_SETTINGS: 'toast_settings'
    },
    CONFIGURATIONS: {
      STATUSES: 'GET_STATUSES'
    }
  },
  ERRORS: {
    DEFAULT:
      'Oops, Something went wrong. Please refresh the page and try again. If problem persists please contact us @ developer@plugd.app.',
    LOCATION_NOT_FOUND: 'Invalid restaurant link. Please enter the correct URL.',
    MINIMUM_ORDER_ERROR: (minimumAmount) => {
      return `Your order is below the minimum amount of ${minimumAmount}`;
    },
    TIME_NOT_ALLOWED: (restaurant, orderType) => {
      return `${restaurant} does not allow ${orderType} order at this time. Please select another time.`;
    },
    ADDITIONAL_FIELDS: {
      REQUIRED: {
        DELIVERY_PHONE: 'Please enter a phone number',
        DELIVERY_LOCATION: 'Please select a location',
        DELIVERY_ADDRESS: 'Please enter an address',
        CAR_NUMBER: 'Please enter your car number',
        CAR_MODEL: 'Please enter your car model'
      },
      NOT_ALLOWED: {
        DELIVERY_LOCATION: (restaurant) => {
          return `${restaurant} can not deliver in your area.`;
        }
      }
    }
  },
  SETTINGS: {
    RATING: {
      SCALE_FACTOR: 1.0,
      LOCATION_TIMEOUT: 30000
    },
    STATUS_POLLING_INTERVAL: 10000
  },
  INPUTS: {
    DISPLAY_TYPES: {
      RADIO: 'radio',
      SELECT: 'select',
      CHECKBOX: 'checkbox'
    }
  },
  LOGIN: {
    FB: {
      PROVIDER_ID: 'facebook.com',
      APP_ID: ENV[process.env.REACT_APP_STAGE].FB_APP_ID,
      STATUS: {
        UNKNOWN: 'unknown'
      }
    },
    VARIABLES: {
      USER_TYPES: {
        NEW: 'new',
        OLD: 'old',
        EXISTING: 'existing'
      },
      RELATIONSHIP_STATUS: {
        UNKNOWN: 'unknown'
      },
      GENDERS: {
        MALE: 'MALE',
        FEMALE: 'FEMALE'
      },
      DEFAULT_PASSWORD: 'password',
      DEFAULT_IMAGE: 'user.jpg'
    }
  },
  PROMOTIONS: {
    VARIABLES: {
      TYPE: {
        AUTOMATIC: 1,
        MANUAL: 0
      },
      APPLICABLE_TIME_TYPE: {
        ALL_TIME: 0,
        SPECIFIC_DATES: 1,
        WEEK_DAYS: 2
      },
      DISCOUNTS: {
        TYPE: {
          BASKET: 0,
          ALL_ITEMS: 1,
          SPECIFIC_ITEMS: 2
        },
        TYPE_STR: {
          BASKET: 'basket',
          ALL_ITEMS: 'all_items',
          CATEGORIES: 'categories',
          ITEMS: 'items'
        },
        AMOUNT_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1
        },
        REWARD_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1
        },
        ITEM_TYPES: {
          CATEGORY: 0,
          ITEM: 1
        }
      }
    }
  },
  ORDERS: {
    VARIABLES: {
      QR_ERROR:
        'Invalid Restaurant or QR Code. Please contact restaurant manager to resolve this issue.',
      TOTALS: {
        TAX: 'tax',
        SUB_TOTAL: 'cart_total',
        DISCOUNT: 'discount',
        TOTAL: 'order_total'
      },
      ORDER_TYPES: {
        DELIVERY: 'delivery',
        DINE_IN: 'dine_in',
        PICK_UP: 'pick_up',
        CURBSIDE_PICKUP: 'curbside_pickup'
      },
      ORDER_TYPE_STATUSES: {
        DELIVERY: [11, 12, 13, 22, 15, 19],
        DINE_IN: [11, 12, 13, 14, 15, 19],
        PICK_UP: [11, 12, 13, 21, 15, 19],
        CURBSIDE_PICKUP: [11, 12, 13, 21, 15, 19]
      },
      PAYMENT_MODES: {
        CASH: 'cash',
        CARD: 'card'
      },
      PAYMENT_PROVIDERS: {
        ADYEN: 'Adyen',
        CYBERSOURCE: 'CyberSource'
      },
      PAYMENT_STATUSES: {
        PAID: 'paid',
        NOT_PAID: 'not_paid',
        BILL_REQUESTED: 'bill_requested',
        COMPLETE_REFUND: 'complete_refund',
        PARTIAL_REFUND: 'partial_refund',
        HOLD: 'hold'
      },
      STATUSES: {
        PLACED: 'Placed',
        SEEN: 'Seen',
        BEING_PREPARED: 'Being Prepared',
        READY: 'Ready For Pickup',
        ON_THE_WAY: 'On The Way',
        COMPLETE: 'Complete',
        CANCELLED: 'Cancelled'
      },
      PAYMENT_METHODS: {
        scheme: 'Card',
        cash: 'Cash',
        default: 'Cash'
      },
      TIME_OPTIONS: {
        NOW: 'Now',
        TODAY: 'Today',
        TOMORROW: 'Tomorrow'
      },
      DEFAULT_LOCATION: {
        lat: 8.7832,
        lng: 124.5085
      },
      DELIVERY_ZONE_TYPES: {
        CIRCLE: 'circle',
        SHAPE: 'shape'
      }
    }
  },
  NOTIFICATIONS: {
    VARIABLES: {
      TEXTS: {
        order: (locationName) => {
          return `How was your meal at <span class='font-italic text-bold'>${locationName}</span> ? Rate what you had and add it to your foodie profile.`;
        }
      },
      SHOW_STATUS: {
        SHOW: 'show',
        UNSHOW: 'unshow'
      },
      OPENED_STATUS: {
        OPENED: 'opened',
        UN_OPENED: 'unopened'
      },
      SEEN_STATUS: {
        SEEN: 'seen',
        UNSEEN: 'unseen'
      },
      TYPES: {
        ORDER_REVIEW: 'order'
      }
    }
  },
  RATING_COLORS: {
    0: '#F90403',
    1: '#F90403',
    2: '#FFA400',
    3: '#92D050',
    4: '#00EAD9',
    5: '#00EAD9'
  },
  DEFAULT_RATING_COLOR: '#ffc148',
  DEFAULT_RATING_SPACE: '0',
  DEFAULT_RATING_SIZE: '20px',
  MENU: {
    POPULAR_CATEGORY: 'popular'
  },
  DATES: {
    DAY_NUMBERS: [1, 2, 3, 4, 5, 6, 7],
    FORMAT: {
      DISPLAY: 'Do MMM, YYYY',
      DB: 'YYYY MM DD'
    }
  },
  TIMES: {
    FORMAT: {
      DISPLAY: 'hh:mm A',
      DB: 'HH:mm:ss'
    }
  },
  DATE_TIMES: {
    FORMAT: {
      DISPLAY: 'DD MMM YYYY hh:mm A',
      NEW_DISPLAY: 'DD MMM hh:mm A',
      DB: 'YYYY-MM-DD HH:mm:ss'
    }
  },
  EVENTS: {
    GENERAL: {
      GROUP_NAME: 'General',
      DEVICE_BACK_BUTTON: 'Pressed Device Back Button',
      BROWSER_BACK_BUTTON: 'Pressed Browser Back Button',
      CAMERA_PERMISSION_PROMPT: 'Camera Permission Prompt',
      CAMERA_PERMISSION_ACCEPTED: 'Camera Permission Accepted',
      CAMERA_PERMISSION_REJECTED: 'Camera Permission Rejected',
      GPS_PERMISSION_PROMPT: 'GPS Permission Prompt',
      GPS_PERMISSION_ACCEPTED: 'GPS Permission Accepted',
      GPS_PERMISSION_REJECTED: 'GPS Permission Rejected',
      GPS_FETCH_SUCCESS: 'GPS Fetch Successful',
      GPS_FETCH_ERROR: 'GPS Fetch Un Successful'
    },
    MASTER: {
      GROUP_NAME: 'Master',
      OPEN: 'Opened Master Page',
      SELECT: 'Clicked Restaurant Link',
      FILTER: 'Clicked Restaurant Filter'
    },
    MENU: {
      GROUP_NAME: 'Menu',
      OPEN: 'Opened Menu Page',
      ITEM_SELECT: 'Clicked Item On Main Menu',
      CATEGORY: 'Clicked CategoryItem',
      POPULAR_ITEM_SELECT: 'Clicked Item From Popular Tab',
      QUICK_ADD: 'Quick Add Item',
      QUICK_REMOVE: 'Quick Remove Item',
      ITEM_OPTIONS: 'Opened Option Page From Menu',
      VIEW_CART: 'Clicked View Cart',
      PHONE: 'Clicked Restaurant Phone',
      LOCATION: 'Clicked Restaurant Location',
      OPENING_HOURS: 'Clicked Restaurant Timings',
      STATUS: 'Clicked Order Status Bar'
    },
    ITEM: {
      GROUP_NAME: 'Item Page',
      OPEN: 'Opened Item Page',
      ADD: 'Clicked Add Item',
      REMOVE: 'Clicked Remove Item',
      VIEW_CART: 'Clicked View Cart',
      ITEM_OPTIONS: 'Opened Option Page From Item',
      BACK: 'Clicked Back Button'
    },
    ITEM_OPTIONS: {
      GROUP_NAME: 'Item Options',
      INCREMENT: 'Clicked Increase Quantity',
      DECREMENT: 'Clicked Decrease Quantity',
      ADD_ITEM: 'Clicked Add Item',
      ADD_ITEM_ERROR: 'Add Item Failure - Validation Shown',
      BACK: 'Clicked Back Button'
    },
    CONFIRM_ORDER: {
      GROUP_NAME: 'CONFIRM_ORDER',
      OPEN: 'Opened Confirm Order Page',
      ADD_MORE: 'Clicked Add More Items',
      ORDER_TYPE: 'Clicked Order Type',
      SCAN: 'Opened QR Scanner',
      SCAN_SUCCESS: 'Scan Success',
      SCAN_FAIL: 'Scan Failure',
      TYPE: 'Data Entered In Field',
      CHANGE_LOCATION: 'Clicked Change Location',
      SELECT_LOCATION: 'Selected Location',
      CHANGE_ORDER_TIME: 'Clicked Change Order Time',
      ADD_ITEM: 'Increased Item Quantity ',
      REMOVE_ITEM: 'Decreased Item Quantity ',
      SPECIAL_INSTRUCTIONS: 'Added Special Instructions',
      PLACE_ORDER: 'Clicked Place Order',
      PLACE_ORDER_SUCCESS: 'Place Order Success',
      PLACE_ORDER_FAILURE: 'Place Order Failure',
      BACK: 'Clicked back button'
    },
    LOGIN: {
      GROUP_NAME: 'Login',
      OPEN: 'Opened Login Page',
      LOGIN_WITH_FB: 'Clicked Login with Facebook',
      LOGIN_WITH_PHONE: 'Clicked Login with Phone',
      PHONE_PROMPT: 'Opened Phone Number Prompt',
      GET_CODE: 'Clicked Get Code',
      OTP: 'OTP Prompt Shown',
      OTP_SUCCESS: 'OTP Success',
      OTP_FAILURE: 'OTP Failure',
      LOGIN_FB_SUCCESS: 'Facebook Login Success',
      LOGIN_FB_ERROR: 'Facebook Login Failed',
      LOGIN_SUCCESS: 'Login Successful',
      LOGIN_FAILURE: 'Login Failure',
      BACK: 'Clicked back button'
    },
    SIGNUP: {
      GROUP_NAME: 'Sign Up',
      TYPE: 'Data Entered In Field',
      SIGNUP: 'Clicked On Sign Up',
      OPEN: 'Opened Sign Up Page',
      SIGNUP_SUCCESS: 'Sign Up Successful',
      SIGNUP_FAILURE: 'Sign Up Failed',
      SIGNUP_VALIDATION: 'Sign Up Failed - Validation Error',
      BACK: 'Clicked back button'
    },
    ORDER_TRACKER: {
      GROUP_NAME: 'Order Tracker',
      OPEN: 'Opened Order Tracker Page',
      BACK: 'Clicked back button',
      ADD_MORE: 'Clicked Add More To Your Order'
    }
  }
};

CONSTANTS.ORDERS.VARIABLES.WORKING_HOURS = {
  [CONSTANTS.ORDERS.VARIABLES.ORDER_TYPES.DELIVERY]: 'delivery',
  [CONSTANTS.ORDERS.VARIABLES.ORDER_TYPES.DINE_IN]: 'opening',
  [CONSTANTS.ORDERS.VARIABLES.ORDER_TYPES.PICK_UP]: 'collection',
  [CONSTANTS.ORDERS.VARIABLES.ORDER_TYPES.CURBSIDE_PICKUP]: 'collection'
};

export default CONSTANTS;
