import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../helpers/customBaseQuery';

export const erpApi = createApi({
  reducerPath: 'erpApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Recipe', 'Productions', 'Stock', 'RecipeId', 'Transfer', 'Adjustments'],
  endpoints: (builder) => ({
    getInventoryByGroup: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/?group_type=${params.groupType}`,
      providesTags: ['Recipe', 'Stock'],
      invalidatesTags: ['Inventory', 'Recipe', 'Stock'],
      transformResponse: (response) => {
        return response;
      }
    }),

    getUnitsById: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/${params.ingredientId}/units`
    }),
    getRecipeById: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/${params.recipeId}/recipe`,
      providesTags: ['RecipeId']
    }),
    getInventoryItemDetail: builder.query({
      query: (params) => ({
        url: `locations/${params.locId}/inventory/${params.id}`
      })
    }),
    getIntermediaryInventory: builder.query({
      query: (params) => ({
        url: `locations/${params.locId}/inventory?group_type=INTERMEDIARY`
      })
    }),
    createIntermediaryRecipe: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/intermediary`,
        method: 'POST',
        body: params.reqBody
      }),
      providesTags: ['Inventory'],
      invalidatesTags: ['Recipe']
    }),
    createMenuRecipe: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/menu`,
        method: 'POST',
        body: params.reqBody
      }),
      invalidatesTags: ['Recipe']
    }),
    createGlobalModifierRecipe: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/modifier`,
        method: 'POST',
        body: params.reqBody
      }),
      invalidatesTags: ['Recipe']
    }),
    updateIntermediaryRecipe: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/${params.itemId}/intermediary`,
        method: 'PATCH',
        body: params.reqBody
      }),
      invalidatesTags: ['RecipeId']
    }),
    updateMenuRecipe: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/${params.itemId}/menu`,
        method: 'PATCH',
        body: params.reqBody
      }),
      invalidatesTags: ['RecipeId']
    }),
    updateGlobalModifierRecipe: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/${params.itemId}/modifier`,
        method: 'PATCH',
        body: params.reqBody
      }),
      invalidatesTags: ['RecipeId']
    }),
    getPurchases: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/transactions?type=PURCHASE`,
      providesTags: ['Transaction']
    }),
    getPurchaseDetails: builder.query({
      query: (params) =>
        `/locations/${params.locId}/inventory/transactions/purchases/${params.purchaseId}`
    }),
    getPurchaseableItems: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/purchaseable`
    }),
    getOutlets: builder.query({
      query: (params) => `/locations/${params.locId}/outlets`
    }),
    getMenuItems: builder.query({
      query: (params) => ({
        url: `/customers/locations/${params.slug}/menu`,
        method: 'POST',
        providesTags: ['Recipe'],
        transformResponse: (response) => {
          return response.menu_data
            .flatMap((data) => {
              return data.Menu.map((item) => ({
                ...item,
                menu_id: item.menu_id,
                menu_name: item.menu_name,
                menu_price: item.menu_price,
                Portion: item.Portion
              }));
            })
            .filter((item) => {
              let ok = true;

              if (item.Portion && item.Portion.PortionSizes[0].recipe_id) {
                ok = false;
              }
              return ok;
            });
        }
      })
    }),
    getGlobalModifiers: builder.query({
      query: (params) => `/locations/${params.locId}/options`,
      transformResponse: (response) => {
        return response.filter((i) => i.recipe_id === null);
      }
    }),
    getStockAdjustmentDetail: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/adjustments/${params.id}`,
      providesTags: ['Adjustments']
    }),
    createPurchase: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/transactions/purchase`,
        method: 'POST',
        body: params.reqBody
      }),
      invalidatesTags: ['Stock', 'Transaction']
    }),
    createProduce: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/transactions/produce`,
        method: 'POST',
        body: params.reqBody
      }),
      invalidatesTags: ['Stock', 'Transaction', 'Productions']
    }),
    getProductions: builder.query({
      query: (params) => ({
        url: `locations/${params.locId}/inventory/transactions?type=PRODUCE`
      }),
      providesTags: ['Productions']
    }),
    getProductionDetail: builder.query({
      query: (params) => ({
        url: `locations/${params.locId}/inventory/transactions/productions/${params.id}`
      })
    }),
    getInventoryStockHistory: builder.query({
      query: (params) => ({
        url: `locations/${params.locId}/inventory/${params.id}/stocks`
      })
    }),
    deleteIntermediaryRecipe: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/${params.itemId}/intermediary`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Recipe']
    }),
    deleteMenuRecipe: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/${params.itemId}/menu`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Recipe']
    }),
    deleteGlobalModifierRecipe: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/${params.itemId}/modifier`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Recipe']
    }),
    getStockAdjustments: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/adjustments`,
      providesTags: ['Adjustments']
    }),
    getStockCountDetail: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/count/${params.id}`
    }),
    createStockAdjustment: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/adjustments/new`,
        method: 'POST',
        body: params.reqBody
      }),
      invalidatesTags: ['Stock', 'Adjustments']
    }),
    updateStockAdjustmentStatus: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/adjustments/status/${params.id}`,
        method: 'PATCH',
        body: params.reqBody
      }),
      invalidatesTags: ['Stock', 'Adjustments']
    }),
    createStockTransfer: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/transfer`,
        method: 'POST',
        body: params.reqBody
      }),
      invalidatesTags: ['Stock', 'Transfer']
    }),
    getStockTransfers: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/transfer`,
      providesTags: ['Transfer']
    }),
    getStockCounts: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/count`,
      providesTags: ['StockCounts']
    }),
    getStockTransferDetails: builder.query({
      query: (params) => `/locations/${params.locId}/inventory/transfer/${params.id}`,
      providesTags: ['Transfer']
    }),
    changeStockTransferStatus: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/transfer/${params.id}`,
        method: 'PATCH',
        body: params.reqBody
      }),
      invalidatesTags: ['Transfer']
    }),
    createStockCount: builder.mutation({
      query: (params) => ({
        url: `/locations/${params.locId}/inventory/count`,
        method: 'POST',
        body: params.reqBody
      }),
      invalidatesTags: ['StockCounts', 'Adjustments']
    })
  })
});

export const {
  endpoints,
  useGetInventoryByGroupQuery,
  useLazyGetUnitsByIdQuery,
  useLazyGetRecipeByIdQuery,
  useGetPurchaseDetailsQuery,
  useGetPurchasesQuery,
  useCreateIntermediaryRecipeMutation,
  useCreateMenuRecipeMutation,
  useUpdateIntermediaryRecipeMutation,
  useUpdateMenuRecipeMutation,
  useGetPurchaseableItemsQuery,
  useGetOutletsQuery,
  useGetMenuItemsQuery,
  useCreatePurchaseMutation,
  useGetProductionsQuery,
  useGetIntermediaryInventoryQuery,
  useCreateProduceMutation,
  useGetProductionDetailQuery,
  useGetInventoryStockHistoryQuery,
  useDeleteIntermediaryRecipeMutation,
  useDeleteMenuRecipeMutation,
  useGetStockAdjustmentsQuery,
  useLazyGetInventoryByGroupQuery,
  useCreateStockAdjustmentMutation,
  useCreateStockTransferMutation,
  useGetStockAdjustmentDetailQuery,
  useGetStockCountDetailQuery,
  useGetGlobalModifiersQuery,
  useCreateGlobalModifierRecipeMutation,
  useUpdateGlobalModifierRecipeMutation,
  useDeleteGlobalModifierRecipeMutation,
  useGetStockTransfersQuery,
  useGetStockCountsQuery,
  useGetStockTransferDetailsQuery,
  useChangeStockTransferStatusMutation,
  useCreateStockCountMutation,
  useLazyGetStockAdjustmentDetailQuery,
  useLazyGetInventoryItemDetailQuery,
  useUpdateStockAdjustmentStatusMutation
} = erpApi;
