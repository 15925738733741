import _ from 'lodash';
import OptionHelper from './OptionHelper';
import PromotionsHelper from './PromotionsHelper';
import CONSTANTS from './constants';

export default {
  addItem,
  removeItem,
  removeItemWithOptions,
  remove,
  addOptions,
  setCart,
  setDeliveryCharge,
  getDeliveryCharge,
  removeDeliveryCharge,
  getCart,
  getQuantityFor,
  getCount,
  getTotal,
  getOrderTotal,
  getOrderTotalWithAllCharges,
  getTax,
  getTotalFor,
  getTotalAfterDiscountFor,
  getLineItems,
  getGroupedOptions,
  getUniqueIdentifierFor,
  getUniqueIdentifierForOption,
  transformedCart,
  setCreatedOrder,
  getCreatedOrder,
  removeCreatedOrder,
  setOrderData,
  getOrderData,
  getDiscount,
  clearOrderData,
  getPromoForCart,
  getTaxValue,
  getTaxValueWithDelivery
};

function addItem(item, quantity = 1) {
  let cart = this.getCart();
  let updatedCart = cart ? _.clone(cart, true) : [];
  for (let i = 0; i < quantity; i++) updatedCart.push({ ...item });
  this.setCart(updatedCart);
}

function removeItemWithOptions(item) {
  let cart = this.getCart();
  let alreadyInCart = _.findLastIndex(
    cart,
    (cartItem) => this.getUniqueIdentifierFor(cartItem) === this.getUniqueIdentifierFor(item)
  );
  this.remove(cart, alreadyInCart);
}

function removeItem(item) {
  let cart = this.getCart();
  let alreadyInCart = _.findLastIndex(cart, (cartItem) => cartItem.menu_id === item.menu_id);
  this.remove(cart, alreadyInCart);
}

function remove(cart, alreadyInCart) {
  if (alreadyInCart > -1) {
    let updatedCart = cart ? _.clone(cart, true) : [];
    _.pullAt(updatedCart, [alreadyInCart]);
    this.setCart(updatedCart);
  }
}

function addOptions(item, options, comment = '', quantity = 1) {
  this.removeItem(item);
  item.options = options;
  item.comment = comment;
  this.addItem(item, quantity);
}

function getQuantityFor(item, loc_id) {
  return _.filter(
    this.getCart(),
    (cartItem) =>
      parseInt(cartItem.menu_id, 10) === parseInt(item.menu_id, 10) &&
      parseInt(cartItem.loc_id) === parseInt(loc_id)
  ).length;
}

function getCount(locationId) {
  return this.getCart(locationId).length;
}

function getTotal(locationId) {
  return _.sumBy(this.getCart(locationId), (item) => {
    return this.getTotalFor(item);
  });
}

function getDiscount(
  location,
  items,
  promotions = null,
  orderType = null,
  fixedPromo = null,
  isManualPromo = false
) {
  if (orderType) orderType = parseInt(orderType);
  let promoIds = PromotionsHelper.getAllApplicablePromoIdsFor(items);
  let originalSum = 0;
  items.map((item) => {
    originalSum += item.original_price ? item.original_price : this.getTotalFor(item);
  });
  let minSum = originalSum;
  let promoId = null;
  for (let i = 0; i < promoIds.length; i++) {
    if (fixedPromo) {
      if (promoIds[i] != fixedPromo.toString()) continue;
    }
    if (orderType && location) {
      let typesLoc = location.OrderTypes === undefined ? location.orderTypes : location.OrderTypes;
      const orderTypeloc = _.find(typesLoc, (o) => o.id === orderType);
      const orderTypeObjects = PromotionsHelper.getOrderTypesByPromoId(promoIds[i], promotions);
      const orderTypeIds = orderTypeObjects
        .filter((o) => o.OrderType.active)
        .map((o) => o.OrderType.order_type);
      if (orderTypeIds.indexOf(orderTypeloc.order_type) < 0) continue;
      // const orderTypeIds = prom.PromotionOrderTypes.map((o) => o.order_type_id);
      // if (orderTypeIds.indexOf(orderType) < 0) continue;
    }
    let currentSum = 0;
    let currentDis = 0;
    const maxDis = PromotionsHelper.getMaxDisFor(promoIds[i], promotions);
    for (let j = 0; j < items.length; j++) {
      const originalPrice = items[j].original_price;
      let discountedPrice = originalPrice;
      if (items[j].discounts && items[j].discounts[promoIds[i]])
        discountedPrice = PromotionsHelper.getDiscountedPriceFor(
          originalPrice,
          items[j].discounts[promoIds[i]]
        );
      if (discountedPrice < 0) discountedPrice = 0;
      currentSum += discountedPrice;
      currentDis += originalPrice - discountedPrice;
    }
    if (currentDis > maxDis && maxDis != 0) {
      currentSum = originalSum - maxDis;
    }

    if (currentSum < minSum) {
      minSum = currentSum;
      promoId = promoIds[i];
    }
  }

  if (promotions) {
    const basketPromos = promotions[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET];
    for (let i = 0; i < basketPromos?.length; i++) {
      if (basketPromos[i].automatic === false && !isManualPromo) {
        continue;
      }
      if (fixedPromo) {
        if (basketPromos[i].id != fixedPromo.toString()) continue;
      }
      const prom = basketPromos[i];
      if (orderType && location) {
        let typesLoc =
          location.OrderTypes === undefined ? location.orderTypes : location.OrderTypes;
        const orderTypeloc = _.find(typesLoc, (o) => o.id === orderType);
        const orderTypeObjects = PromotionsHelper.getOrderTypesByPromoId(prom.id, promotions);
        const orderTypeIds = orderTypeObjects
          .filter((o) => o.OrderType.active)
          .map((o) => o.OrderType.order_type);
        if (orderTypeIds.indexOf(orderTypeloc.order_type) < 0) continue;
        // const orderTypeIds = prom.PromotionOrderTypes.map((o) => o.order_type_id);
        // if (orderTypeIds.indexOf(orderType) < 0) continue;
      }
      const dis = prom.PromotionDiscount;
      const applicableLevel = PromotionsHelper.getApplicableDiscountLevel(
        originalSum,
        dis.PromotionDiscountLevels
      );
      if (!applicableLevel) continue;
      if (
        applicableLevel.reward_type ==
        CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.REWARD_TYPE.PERCENTAGE
      ) {
        let dis_amount = (applicableLevel.reward / 100) * originalSum;
        if (dis_amount > prom.max_discount_amount && prom.max_discount_amount != 0)
          dis_amount = prom.max_discount_amount;
        const newPrice = originalSum - dis_amount;
        if (newPrice < minSum) {
          minSum = newPrice;
          promoId = prom.id;
        }
      } else {
        let dis_amount = applicableLevel.reward;
        if (dis_amount > prom.max_discount_amount && prom.max_discount_amount != 0)
          dis_amount = prom.max_discount_amount;
        const newPrice = originalSum - dis_amount;
        if (newPrice < minSum) {
          minSum = newPrice;
          promoId = prom.id;
        }
      }
    }
  }
  setPromoForCart(promoId);
  return { discount: originalSum - minSum, promoId };
}

function getTax(locationId, taxPercent) {
  taxPercent = parseFloat(taxPercent) || 0.0;
  return this.getTotal(locationId) * (taxPercent / 100.0);
}

function getTaxValue(items, taxPercent) {
  let total = 0,
    taxRate;
  items.length > 0 &&
    items.map((item) => {
      total += item.original_price ? item.original_price : this.getTotalFor(item) * item.quantity;
    });
  taxPercent = parseFloat(taxPercent) || 0.0;
  taxRate = total * (taxPercent / 100.0);
  return { taxRate, total };
}

function getTaxValueWithDelivery(items, deliveryCharges, discount, taxPercent) {
  let total = 0,
    taxRate;
  items.length > 0 &&
    items.map((item) => {
      total += item.original_price ? item.original_price : this.getTotalFor(item) * item.quantity;
    });
  let netTotal = total + deliveryCharges;
  taxPercent = parseFloat(taxPercent) || 0.0;
  taxRate = netTotal * (taxPercent / 100.0);
  return { taxRate, total: netTotal };
}

function getOrderTotal(locationId, taxPercent) {
  return this.getTotal(locationId) + this.getTax(locationId, taxPercent);
}

function getOrderTotalWithAllCharges(
  location,
  taxPercent,
  promotions = null,
  orderType = null,
  fixedPromo = null,
  items,
  isManualPromo
) {
  let discount = this.getDiscount(
    location,
    items,
    promotions,
    orderType,
    fixedPromo,
    isManualPromo
  ).discount;
  let deliveryCharges = this.getDeliveryCharge(location?.loc_id);
  const taxData = this.getTaxValueWithDelivery(items, deliveryCharges, discount, taxPercent);
  // return this.getOrderTotal(locationId, taxPercent) + this.getDeliveryCharge(locationId) - this.getDiscount(locationId,items, promotions, orderType, fixedPromo).discount;
  return taxData.taxRate + taxData.total - discount;
}

function getTotalFor(item) {
  let optionsSum = 0.0;
  if (item.options) optionsSum = _.sumBy(item.options, (option) => parseFloat(option.price) || 0.0);
  return parseFloat(item.menu_price) + parseFloat(optionsSum);
}

function getTotalAfterDiscountFor(item) {
  let optionsSum = 0.0;
  if (item.options) optionsSum = _.sumBy(item.options, (option) => parseFloat(option.price) || 0.0);
  if (item.discounts) return parseFloat(item.discounts.discountedPrice) + parseFloat(optionsSum);
  return parseFloat(item.menu_price) + parseFloat(optionsSum);
}

function getLineItems(cartItems) {
  // Same items with same options selected are grouped together
  let groupedItems = _.groupBy(cartItems, this.getUniqueIdentifierFor);
  let itemIdentifiers = _.keys(groupedItems);
  let lineItems = [];
  itemIdentifiers.forEach((identifier) => {
    let firstItem = groupedItems[identifier][0];
    let firstItemTotal = this.getTotalFor(firstItem);
    let firstItemTotalAfterDiscount = firstItem.discounts
      ? this.getTotalAfterDiscountFor(firstItem)
      : firstItemTotal;
    let lineItem = {
      ...firstItem,
      groupedOptions: this.getGroupedOptions(firstItem),
      identifier: identifier,
      itemCount: groupedItems[identifier][0].itemCount
    };
    lineItem.itemTotal = firstItemTotal * lineItem.itemCount;
    lineItem.itemTotalAfterDiscount = firstItemTotalAfterDiscount * lineItem.itemCount;
    lineItems.push(lineItem);
  });
  // console.log(lineItems)
  return lineItems;
}

function getGroupedOptions(item) {
  let options = [];
  if (item.options) {
    let groupedOptions = _.groupBy(item.options, (option) => option.option_value_id);
    let optionIdentifiers = _.keys(groupedOptions);
    optionIdentifiers.forEach((identifier) => {
      let firstOption = groupedOptions[identifier][0];
      let option = {
        ...firstOption,
        identifier: identifier,
        optionCount: groupedOptions[identifier].length
      };
      options.push(option);
    });
  }
  return options;
}

function getUniqueIdentifierForOption(item, options) {
  if (options && options.length > 0) {
    let sortedOptions = _.sortBy(options, (option) => option.option_value_id);
    if (!item.comment) item.comment = '';
    return `${
      item.comment
    }_${item.menu_id.toString()}_${item.portionSize?.portion_size_id.toString()}_${sortedOptions
      .map((option) => option.option_value_id)
      .join(',')}`;
  }
  if (!item.comment) item.comment = '';
  return `${
    item.comment
  }_${item.menu_id.toString()}_${item.portionSize?.portion_size_id.toString()}`;
}

function getUniqueIdentifierFor(item) {
  if (item.options && item.options.length > 0) {
    let sortedOptions = _.sortBy(item.options, (option) => option.option_value_id);
    return `${
      item.comment
    }_${item.menu_id.toString()}_${item.portionSize?.portion_size_id.toString()}_${sortedOptions
      .map((option) => option.option_value_id)
      .join(',')}`;
  }
  if (!item.comment) item.comment = '';
  return `${
    item.comment
  }_${item.menu_id.toString()}_${item.portionSize?.portion_size_id.toString()}`;
}

function getCart(locationId = null) {
  let cart = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_cart`);
  if (!cart) {
    this.setCart([]);
    cart = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_cart`);
  }
  cart = JSON.parse(cart);
  if (locationId) return _.filter(cart, (item) => item.loc_id == locationId);
  return cart;
}

function setCart(cart) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_cart`, JSON.stringify(cart));
}

function setPromoForCart(promo) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_cart_promo`, promo);
}

function getPromoForCart() {
  return localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_cart_promo`);
}

function setDeliveryCharge(locationId, amount) {
  localStorage.setItem(
    `${CONSTANTS.CACHE_KEY_PREFIX}_${locationId}_deliveryCharges`,
    JSON.stringify(amount)
  );
}

function getDeliveryCharge(locationId) {
  let charge = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_${locationId}_deliveryCharges`);
  if (charge) return JSON.parse(charge);
  return null;
}

function removeDeliveryCharge(locationId) {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_${locationId}_deliveryCharges`);
}

function transformedCart(cartItems) {
  return this.getLineItems(cartItems).map((item) => {
    let totalPrice = item.menu_price * item.itemCount;
    let options = [];
    if (item.groupedOptions && item.groupedOptions.length > 0) {
      options = item.groupedOptions.map((option) => {
        // console.log(option)
        totalPrice += option.price ? option.price * option.optionCount : 0.0;
        option.serverSideOption.Option_Values[0].optionCount = option.optionCount;
        return { ...option.serverSideOption };
      });
    }
    delete item['groupedOptions'];
    return { ...item, MenuOptions: options, total_price: totalPrice };
  });
}

function setCreatedOrder(order) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_createdOrder`, JSON.stringify(order));
}

function getCreatedOrder() {
  let flag = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_createdOrder`);
  if (flag) return JSON.parse(flag);
}

function removeCreatedOrder() {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_createdOrder`);
}

function setOrderData(state, orderTypes, location) {
  const loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  let {
    order_type_id,
    delivery_phone,
    delivery_location,
    delivery_address,
    car_number,
    car_model,
    comment
  } = state;
  let orderType = OptionHelper.getOrderType(orderTypes, order_type_id);
  localStorage.setItem(
    `${CONSTANTS.CACHE_KEY_PREFIX}_${loc_id}_orderData`,
    JSON.stringify({
      // Setting order type instead of order_type_id so that its usable across locations
      order_type: orderType ? orderType.order_type : null,
      delivery_phone,
      delivery_location,
      delivery_address,
      car_number,
      car_model,
      comment,
      selectedOutlet: state.selectedOutlet,
      selectedLocation: state.selectedLocation,
      order_time: state.order_time,
      current_time: state.current_time,
      order_date: state.order_date,
      isPickUpNow: state.isPickUpNow
    })
  );
}

function getOrderData(orderTypes, location) {
  const loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  let orderData = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_${loc_id}_orderData`);
  if (orderData) {
    orderData = JSON.parse(orderData);
    let time = new Date(orderData['order_time']);
    orderData['order_time'] = time;
    // Getting order_type_id back from order types of current location
    let orderType = orderTypes.find((ot) => ot.order_type === orderData.order_type);
    if (orderType) orderData.order_type_id = parseInt(orderType.id);
    delete orderData['order_type'];
    return orderData;
  }
}

function clearOrderData(location) {
  const loc_id = location.is_outlet ? location.parent_id : location.loc_id;
  let orderData = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_${loc_id}_orderData`);
  if (orderData) {
    orderData = JSON.parse(orderData);
    delete orderData['order_type'];
    delete orderData['order_type_id'];
    localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_orderData`, JSON.stringify(orderData));
  }
}
