import CONSTANTS from '../../../constants';
import { promotionsData, promotionsDataCheck } from '../redux/actions/pos';
import { getStoreData } from './DBHelper';
import PromotionsService from './PromotionsService';
export function getPromotions(loc_id, customer = {}) {
  return async (dispatch) => {
    try {
      let promotions = await PromotionsService.getPromotions(loc_id, customer.token);
      dispatch(promotionsData(promotions));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
}

export function checkPromo(code, location, history = {}) {
  return async (dispatch) => {
    try {
      let loc_id = location.is_outlet ? location.parent_id : location.loc_id;
      let localPromotions = await getStoreData(
        CONSTANTS.DB_CACHE_KEY_PREFIX + loc_id,
        CONSTANTS.INDEXDB.STORE.PROMOTIONS
      );
      if (localPromotions.length > 0) {
        let filterPromotion = localPromotions[1].data.filter((row) => row.code === code);
        if (filterPromotion.length > 0) {
          dispatch(promotionsDataCheck(filterPromotion[0]));
        } else {
          dispatch(promotionsDataCheck({ errorMessage: 'Promotion not found' }));
        }
      } else {
        let promotion = await PromotionsService.getPromotionByCodeIfApplicable(code, location.slug);
        dispatch(promotionsDataCheck(promotion));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
}
