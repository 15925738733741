var onConnect = function () {
  console.log('Websocket Connected');
};

var onDisconnect = function () {
  console.log('Websocket Disconnected');
  PrinterService.getSocket();
};

var url = 'ws://127.0.0.1:12212/printer';

const PrinterService = {
  getSocket: () => {
    if (!window.printerSocket) {
      let websocket = new WebSocket(url);
      websocket.onerror = (_, ev) => {
        console.log('error', ev);
      };
      websocket.onopen = onConnect;
      websocket.onclose = onDisconnect;
      window.printerSocket = websocket;
    }
    return window.printerSocket;
  },
  submit: (data) => {
    let websocket = PrinterService.getSocket();
    if (Array.isArray(data)) {
      data.forEach(function (element) {
        websocket.send(JSON.stringify(element));
      });
    } else {
      websocket.send(JSON.stringify(data));
    }
  },
  isConnected: () => {
    let websocket = PrinterService.getSocket();
    return websocket.readyState === websocket.OPEN;
  }
};

export default PrinterService;
