import { FormControl } from '@material-ui/core';
import React from 'react';
import ViewHelper from '../../../helpers/ViewHelper';
import { StyledSelect } from '../container/style/ItemCardStyle';
import CartService from '../utils/CartService';

const NoteCalculation = (props) => {
  let promo_id = localStorage.getItem('promo_id');
  promo_id = JSON.parse(promo_id);
  let deliveryCharges = 0;
  if (props.deliveryArea?.minimum_order !== '') {
    let minimumOrder = parseInt(props.deliveryArea?.minimum_order);
    if (props.totalDue > minimumOrder) {
      deliveryCharges = props.deliveryArea ? props.deliveryArea?.deliveryCharges : 0;
    }
  } else {
    deliveryCharges = props.deliveryArea ? props.deliveryArea?.deliveryCharges : 0;
  }

  console.log(props.discountSelectData);

  return (
    <>
      <div className="add-note">
        {!props.showAddNote && props.location.pathname === '/locations/pos' && (
          <button onClick={props.toggleAddNoteInput}>Add Note</button>
        )}
        {props.location.pathname === '/locations/pos'
          ? props.showAddNote && (
              <input
                className="note-input"
                onChange={props.handleComment}
                value={props.comment && props.comment}
                onBlur={props.handleClose}
                type="text"
              />
            )
          : props.userCart?.comment !== '' && (
              <input
                className="note-input"
                onChange={props.handleComment}
                onBlur={props.handleClose}
                value={props.comment && props.comment}
                type="text"
              />
            )}
      </div>
      {props.location.pathname === '/locations/pos/cash-checkout' ||
      props.location.pathname === '/locations/pos/checkout-done' ? (
        props.promo_code_from_localstorage !== null ? (
          <div className={'discount'}>
            <h2>Discount: </h2>
            <h2>{props.promo_code_from_localstorage?.type}</h2>
          </div>
        ) : (
          <div style={{ paddingBottom: '0 !important', paddingTop: '16px' }}></div>
        )
      ) : (
        <div className={'discount'}>
          {props.discountSelectData && props.discountSelectData.length === 0 ? null : (
            <h2>Discount: </h2>
          )}
          {props.discountSelectData && props.discountSelectData.length === 0 ? null : (
            <FormControl>
              <StyledSelect
                theme={props.theme}
                styles={{
                  backgroundColor:
                    props.theme === 'dark' ? '#262C49 !important' : '#E9E9E9 !important',
                  borderColor: '#262C49 !important',
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? 'var(--primary)'
                      : state.isFocused
                      ? '#262C49'
                      : '#262C49',
                    color: '#C2C6DC'
                  })
                }}
                className="React no-pointer-events"
                classNamePrefix="select"
                defaultValue={promo_id ? { label: promo_id.type, value: promo_id.type } : null}
                name="color"
                options={props.promotionsList !== undefined ? props.discountSelectData : []}
                onChange={props.handleDiscountChange}
              />
            </FormControl>
          )}
        </div>
      )}
      <div className={'sub-total'}>
        <h2> Total:</h2>

        <span>
          {ViewHelper.toCurrency(
            props.calculatePrice(props.cart),
            props.currencyCode,
            props.decimalPlace
          )}
        </span>
      </div>
      {props.deliveryArea ? (
        <div className={'discounted-total'}>
          <h2>Delivery Charges:</h2>

          <span>
            {ViewHelper.toCurrency(deliveryCharges, props.currencyCode, props.decimalPlace)}
          </span>
        </div>
      ) : null}
      <div className={'sub-total'}>
        <h2> Sub-Total:</h2>

        <span>
          {ViewHelper.toCurrency(
            props.calculatePrice(props.cart) + deliveryCharges,
            props.currencyCode,
            props.decimalPlace
          )}
        </span>
      </div>
      <div className={'discounted-total'}>
        <h2>Total Discount: </h2>

        <span>
          {props.discount > 0
            ? '-' + ViewHelper.toCurrency(props.discount, props.currencyCode, props.decimalPlace)
            : ''}
        </span>
      </div>
      <div className={'tax-total'}>
        <h2>Tax:</h2>

        <span>
          {ViewHelper.toCurrency(
            CartService.getTaxValueWithDelivery(
              props.cartData,
              deliveryCharges,
              props.discount,
              localStorage.getItem('tax_percentage')
            ).taxRate,
            props.currencyCode,
            props.decimalPlace
          )}
        </span>
      </div>
      <div className="divider"></div>
      <div className={'due-total'}>
        <h2>Total Due</h2>

        <span>{ViewHelper.toCurrency(props.totalDue, props.currencyCode, props.decimalPlace)}</span>
      </div>
    </>
  );
};

export default NoteCalculation;
