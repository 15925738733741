import { FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { search } from 'ss-search';
import editPenWhite from '../../../../assets/img/static/edit-pen-white.svg';
import printerColoured from '../../../../assets/img/static/printerColoured.svg';
import removeIconColored from '../../../../assets/img/static/remove-icon-colored.svg';
import SpinnerComponent from '../../../../components/@vuexy/spinner/Fallback-spinner';
import { getOrder } from '../../../../redux/actions/orders';
import { StyledOrderDetail } from '../../../../styles/GlobalStyles';
import MenuDesktop from '../../components/MainMenuDesktop';
import { clearCart } from '../../redux/actions/cart';

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiInputBase-root': {
      color: '#262C49',
      borderColor: '#C2C6DC',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '5px',
      justifyContent: 'center',
      background: '#6057CC'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '14px 16px',
      fontFamily: 'Larsseit, sans-serif'
    }
  },
  select: {
    width: 'auto',
    fontSize: '12px',
    '&:focus': {
      backgroundColor: 'transparent'
    },
    fontFamily: 'Larsseit, sans-serif'
  },
  selectIcon: {
    position: 'relative',
    // color: "#6EC177",
    fontSize: '14px',
    fontFamily: 'Larsseit, sans-serif'
  },
  mainPaper: {
    borderRadius: 5,
    fontFamily: 'Larsseit, sans-serif',
    '@media(max-width: 768px)': {
      borderRadius: 0,
      minWidth: '130px !important',
      left: '163px !important'
    }
  },
  paper: {
    borderRadius: 5,
    fontFamily: 'Larsseit, sans-serif'
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: 'Larsseit, sans-serif',
    '& li': {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: '12px',
      padding: '14px 16px',
      background: theme === 'dark' ? '#6057CC' : '#C2C6DC',
      fontFamily: 'Larsseit, sans-serif'
    },
    '& li.Mui-selected': {
      color: '#262C49',
      background: '#6057CC',
      fontFamily: 'Larsseit, sans-serif'
    },
    '& li.Mui-selected:hover': {
      background: '#6057CC',
      color: '#262C49',
      fontFamily: 'Larsseit, sans-serif'
    }
  }
}));

const OrderDetail = (props) => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [orderStatus, setOrderStatus] = useState('Placed');
  const history = useHistory();
  const toggleMenuCollapsed = () => {
    setMenuCollapsed(!menuCollapsed);
    console.log('Collapsed Called');
  };
  let theme = props.customizer?.customizer?.theme;
  const { id } = useParams();

  let liveOrders = props.liveOrderList ? props.liveOrderList.rows : [];
  let searchKeys = ['order_id'];
  let result = search(liveOrders, searchKeys, id);

  useEffect(() => {
    props.getOrder(props.location.loc_id, id);
  }, [id]);

  const classes = useStyles(theme);
  const orderStatusList = [
    { id: 1, key: 'Placed', value: 'Placed' },
    { id: 2, key: 'On the way', value: 'On the way' },
    { id: 3, key: 'Delivered', value: 'Delivered' }
  ];
  const menuPropsMain = {
    classes: {
      list: classes.list,
      paper: classes.mainPaper
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
      right: 0
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
      right: 0
    },
    getContentAnchorEl: null
  };

  const redirectLocation = (location, clearCart = false) => {
    if (clearCart === true) props.clearCart();
    history.push(`${location}`);
  };

  const handleStatusChange = (event) => {
    setOrderStatus(event.target.value);
  };

  return (
    <div style={{ background: `${theme === 'dark' ? '#10163A' : '#E9E9E9'}` }}>
      <MenuDesktop toggleMenu={toggleMenuCollapsed} />
      {props.loading ? (
        <SpinnerComponent />
      ) : (
        <StyledOrderDetail theme={theme}>
          {/*<SideBar/>*/}
          <Container>
            <Row>
              <Col md={12}>
                <div className="header">
                  <div className="header__left-side">
                    {/*<div className="header__left-side__details">*/}
                    {/*    <img onClick={()=> redirectLocation('/locations/pos/order-list')} src={leftArrow} alt=""/>*/}
                    {/*    <h2>Order Details</h2>*/}
                    {/*</div>*/}
                    <div
                      onClick={() => redirectLocation('/locations/pos')}
                      className="header__left-side__button">
                      <img src={editPenWhite} alt="" />
                      <h2>Edit Order</h2>
                    </div>
                    <div
                      onClick={() => redirectLocation('/locations/pos')}
                      className="header__left-side__button-receipt">
                      <img src={printerColoured} alt="" />
                      <h2>Print Receipt</h2>
                    </div>
                  </div>
                  {/*<div className="header__right-side">*/}
                  {/*    <button onClick={()=> redirectLocation('/locations/pos',true)}>New Order</button>*/}
                  {/*</div>*/}
                </div>
              </Col>
            </Row>
            {result.length > 0 && (
              <Row style={{ position: 'relative' }}>
                <Col md={4}>
                  <div className="order-detail">
                    <div className="order-detail__header">
                      <h2>Order Details</h2>
                    </div>
                    <div className="order-details">
                      <div className="order-details__contents__content">
                        <h2>Table no:</h2>
                        <p>{result[0].table_no && result[0].table_no}</p>
                      </div>
                      <div className="order-details__contents__content">
                        <h2>Customer name:</h2>
                        <p>
                          {result[0].first_name && result[0].first_name}{' '}
                          {result.last_name && result.last_name}
                        </p>
                      </div>
                      <div className="order-details__contents__content">
                        <h2>Order status:</h2>
                        <p>{result[0].OrderStatus.status_name}</p>
                      </div>
                      <div className="order-details__contents__content">
                        <h2>Payment status:</h2>
                        <p>{result[0].PaymentStatus.label}</p>
                      </div>
                      <div className="order-details__contents__content">
                        <h2>Order type:</h2>
                        <p>{result[0].OrderType.label}</p>
                      </div>
                      <div className="order-details__contents__content">
                        <h2>Location name:</h2>
                        <p>{result[0].delivery_address}</p>
                      </div>
                      <div className="order-details__contents__content">
                        <h2>Order total:</h2>
                        <p>$ {result[0].order_total}.00</p>
                      </div>
                      <div className="order-details__contents__content">
                        <h2>Time ordered:</h2>
                        <p>{moment(result[0].order_time).format('LTS')}</p>
                      </div>
                      <div className="order-details__contents__content">
                        <h2>Last updated:</h2>
                        <p>{moment(result[0].date_modified).format('MMMM Do YYYY, h:mm:ss a')}</p>
                      </div>
                      <div className="order-details__contents__content">
                        <h2>To be served by:</h2>
                        <p>{moment(result[0].date_modified).format('MMMM Do YYYY, h:mm:ss a')}</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="manage-delivery">
                    <div className="manage-delivery__header">
                      <h2>Manage Delivery</h2>
                      <button>Request rider</button>
                    </div>
                    <div className="manage-delivery__content">
                      <h2>No rider has been assigned to this order yet!</h2>
                    </div>
                  </div>
                  <div className="menu-details">
                    <div className="menu-details__header">
                      <h2>Menu Details</h2>
                    </div>
                    <div className="menu-details__contents">
                      <div className="menu-details__contents__content">
                        <h2>1x Beef Burger</h2>
                        <h2>$ 15.00</h2>
                      </div>
                      <div className="menu-details__contents__content">
                        <h2>Sub Total</h2>
                        <h2>$ {parseFloat(result[0].OrderTotals[0].value).toFixed(2)}</h2>
                      </div>
                      <div className="menu-details__contents__content">
                        <h2>Discount</h2>
                        <h2>$ {parseFloat(result[0].OrderTotals[1].value).toFixed(2)}</h2>
                      </div>
                      <div className="menu-details__contents__content">
                        <h2>Tax</h2>
                        <h2>$ {parseFloat(result[0].OrderTotals[2].value).toFixed(2)}</h2>
                      </div>
                      <div className="menu-details__contents__content">
                        <h2>Total</h2>
                        <h2>$ {parseFloat(result[0].OrderTotals[3].value).toFixed(2)}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="additional-details">
                    <div className="additional-details__header">
                      <h2>Additional Details</h2>
                    </div>
                    <div className="additional-details__contents">
                      <div className="additional-details__contents__content">
                        <h2>Order instruction:</h2>
                        <p>Testing order dine in</p>
                      </div>
                      <div className="additional-details__contents__content">
                        <h2>Phone number:</h2>
                        <p>{result[0].delivery_phone}</p>
                      </div>
                      <div className="additional-details__contents__content">
                        <h2>Delivery address:</h2>
                        <p>{result[0].delivery_address}</p>
                      </div>
                      <div className="additional-details__contents__content">
                        <h2>Delivery location:</h2>
                        <p>
                          <a
                            href={`https://www.google.com/maps/place/${result[0].delivery_location}`}>
                            {result[0].delivery_address}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="manage-status">
                    <div className="manage-status__header">
                      <h2>Manage Status</h2>
                    </div>
                    <div className="manage-status__content">
                      <div className="manage-status__content__status">
                        <div className="manage-status__content__status__value">
                          <h2>Status: </h2>
                          <h2>{orderStatus ? orderStatus : result[0].OrderStatus.status_name}</h2>
                        </div>
                        <FormControl>
                          <Select
                            value={orderStatus}
                            onChange={handleStatusChange}
                            disableUnderline
                            IconComponent={ExpandMoreRoundedIcon}
                            MenuProps={menuPropsMain}>
                            {orderStatusList.map((item) => {
                              return (
                                <MenuItem key={item.key} value={item.value}>
                                  {item.key}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <button>
                        <img src={removeIconColored} alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="status-history">
                    <div className="status-history__header">
                      <h2>Status History</h2>
                    </div>
                    <div className="status-history__contents">
                      <div className="status-history__contents__content">
                        <h2>Placed:</h2>
                        <h2>{moment(result[0].date_added).format('MMMM Do YYYY, h:mm:ss a')}</h2>
                        <p>Your order is received</p>
                      </div>
                      <div className="status-history__contents__content">
                        <h2>Complete:</h2>
                        <h2>{moment(result[0].date_modified).format('MMMM Do YYYY, h:mm:ss a')}</h2>
                        <p>Order marked completed automatically by completion time.</p>
                      </div>
                    </div>
                  </div>
                  <div className="payment-details">
                    <div className="payment-details__header">
                      <h2>Payment Details</h2>
                    </div>
                    <div className="payment-details__content">
                      <h2>Method</h2>
                      <p>{result[0].Payments[0].method}</p>
                    </div>
                  </div>
                  {/*<div className="print-receipt">*/}
                  {/*    <div className="print-receipt__item">*/}
                  {/*        <img src={printerColoured} alt=""/>*/}
                  {/*        <h2>Print Receipt</h2>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                </Col>
              </Row>
            )}
          </Container>
        </StyledOrderDetail>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    customizer: state.customizer ? state.customizer : null,
    liveOrderList: state.posReducer ? state.posReducer.liveOrderList : null,
    location: state.posReducer ? state.posReducer.location : null,
    orders: state.orders ? state.orders : null
  };
}

export default connect(mapStateToProps, {
  clearCart,
  getOrder
})(OrderDetail);
