import React, { useEffect } from 'react';
import { Edit2 } from 'react-feather';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import '../../assets/css/locationSelectHeader.scss';
import {
  getAllInventoryItems,
  setOnSelectClicked,
  setShowErrorBadge
} from '../../redux/actions/inventory';
import { useGetInventoryByGroupQuery } from '../../services/erp';
import LocationService from '../../services/LocationService';
const LocationSelectHeader = (props) => {
  const {
    allInventoryItems,
    location,
    setShowErrorBadge,
    showErrorBadge,
    locId,
    dontSetBadge,
    setDontSetBadge,
    onSelectClicked,
    getAllInventoryItems
  } = props;
  const count = LocationService.getTotalLocationsCount();

  const handleClick = () => {
    if (count > 1 && props.onIconClick) props.onIconClick();
  };

  const selectedLocation = LocationService.getLocation();

  const {
    data: inventoryItems = [],
    error,
    isLoading
  } = useGetInventoryByGroupQuery({
    locId: selectedLocation?.loc_id,
    groupType: 'INVENTORY_TYPES'
  });

  useEffect(() => {
    const selectedLocation = LocationService.getLocation();
    if (selectedLocation) {
      if (
        !selectedLocation.is_master_venue &&
        !selectedLocation.is_outlet &&
        !selectedLocation.is_department &&
        !selectedLocation.is_parent
      ) {
        getAllInventoryItems(selectedLocation.loc_id);
      } else {
        selectedLocation.parent_id !== null
          ? getAllInventoryItems(selectedLocation.parent_id)
          : getAllInventoryItems(selectedLocation.loc_id);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedLocation && selectedLocation?.is_master_venue) {
      setShowErrorBadge(false);
    }
    if (selectedLocation && !selectedLocation?.is_master_venue) {
      if (
        inventoryItems &&
        inventoryItems?.length > 0 &&
        allInventoryItems &&
        allInventoryItems?.length > 0
      ) {
        const result = inventoryItems.some((inventoryItem) => {
          const itemToCompare =
            allInventoryItems &&
            allInventoryItems?.find(
              (rowData) => rowData.id === inventoryItem.id && rowData.name === inventoryItem.name
            );
          if (itemToCompare?.inventory_threshold) {
            return (
              inventoryItem?.total_quantity - inventoryItem.consumed_quantity <
              itemToCompare?.inventory_threshold
            );
          }
        });
        result ? setShowErrorBadge(true) : setShowErrorBadge(false);
      } else {
        onSelectClicked === true && setShowErrorBadge(false);
      }
    }
  }, [inventoryItems, allInventoryItems, dontSetBadge, onSelectClicked, location]);

  return (
    <div>
      <Button
        outline
        className="round font-weight-bold success-2-border custom-padding"
        color="success"
        size="md"
        onClick={handleClick}>
        <div style={{ display: 'flex' }}>
          <div>{props.text}</div> {count > 1 ? <Edit2 size={15} /> : null}
        </div>
      </Button>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    allInventoryItems: state.inventoryReducer && state.inventoryReducer.allInventoryItems.data,
    showErrorBadge: state.inventoryReducer && state.inventoryReducer.errorBadge.showErrorBadge,
    onSelectClicked: state.inventoryReducer && state.inventoryReducer.onSelect.onSelectClicked,
    location: state.inventoryReducer && state.inventoryReducer.onSelect.selectedLocation
  };
}

export default connect(mapStateToProps, {
  getAllInventoryItems,
  setShowErrorBadge,
  setOnSelectClicked
})(LocationSelectHeader);
