import _ from 'lodash';
import CONSTANTS from '../../../constants';

export default {
  getItems,
  getCategoryItem,
  getMenu,
  getMenuSyncPOS
};

async function getMenu(loc_id) {
  let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GETMENUPOS(loc_id)}`;
  let headers = { ...CONSTANTS.REQUEST_HEADERS };

  let menu = await fetch(url, {
    method: 'POST',
    headers: headers
  });
  menu = await menu.json();
  if (menu) return menu;
  throw new Error('Could not fetch menu');
}

async function getMenuSyncPOS(loc_id, syncTime) {
  let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.MENU.GETMENUPOS(loc_id)}`;
  let headers = { ...CONSTANTS.REQUEST_HEADERS };

  let menu = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ lastSync: syncTime })
  });
  menu = await menu.json();
  if (menu) return menu;
  throw new Error('Could not fetch menu');
}

async function getItems(menuBlob, loc_id) {
  let jsonCategories = reducer(JSON.parse(menuBlob).menu_data) || [];
  let items = _.flatten(jsonCategories.map((jsonCategory) => jsonCategory.Menu));
  items.forEach((item) => {
    item.loc_id = loc_id;
    if (item.menu_photo.includes('dishPlaceholderImg')) item.menu_photo = null;
  });
  if (items) return items;

  throw new Error('Items not found');
}

async function getCategoryItem(menuBlob, loc_id) {
  let jsonCategories = reducer(JSON.parse(menuBlob).menu_data) || [];
  let items = _.flatten(jsonCategories.map((jsonCategory) => jsonCategory.Menu));
  let category = _.flatten(jsonCategories.map((jsonCategory) => jsonCategory.Category));
  items.forEach((item) => {
    let cat = category.find((p) => p.category_id === item.menu_category_id);
    item.loc_id = loc_id;
    item.category_name = cat.name;
    if (item.menu_photo.includes('dishPlaceholderImg')) item.menu_photo = null;
  });
  category.forEach((cat) => {
    cat.Menus = items.filter((p) => p.menu_category_id === cat.category_id);
  });
  if (category) return category;

  throw new Error('Items not found');
}

function reducer(data) {
  const menuData = JSON.parse(JSON.stringify(data));
  for (let i = 0; i < menuData.length; i++) {
    const { Menu: menu } = menuData[i];
    for (let j = 0; j < menu.length; j++) {
      const { MenuOptions: menuOptions } = menu[j];
      for (let k = 0; k < menuOptions.length; k++) {
        menuOptions[k] = { ...menuOptions[k], ...menuOptions[k].Option };
        let newMenuOptionValues = [];
        for (let l = 0; l < menuOptions[k].OptionValues.length; l++) {
          for (let m = 0; m < menuOptions[k].MenuOptionValues.length; m++) {
            if (
              menuOptions[k].OptionValues[l].option_value_id ===
              menuOptions[k].MenuOptionValues[m].option_value_id
            ) {
              newMenuOptionValues.push(menuOptions[k].OptionValues[l]);
            }
          }
        }
        menuOptions[k].OptionValues = newMenuOptionValues;
        for (let l = 0; l < menuOptions[k].OptionValues.length; l++) {
          let updated = false;
          for (let m = 0; m < menuOptions[k].MenuOptionValues.length; m++) {
            if (
              menuOptions[k].OptionValues[l].option_value_id ===
              menuOptions[k].MenuOptionValues[m].option_value_id
            ) {
              menuOptions[k].OptionValues[l] = {
                ...menuOptions[k].OptionValues[l],
                ...menuOptions[k].MenuOptionValues[m],
                price: menuOptions[k].MenuOptionValues[m].new_price ?? ''
              };
              updated = true;
              break;
            }
          }

          if (!updated) {
            menuOptions[k].OptionValues[l].price = menuOptions[k].OptionValues[l].price ?? '';
          }
        }
      }
    }
  }
  return menuData;
}
