import CONSTANTS from '../../../constants';

export function allSmsCampaigns(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.SMS_CAMPAIGNS.ALL_CAMPAIGNS) {
    if (action.data) {
      if (action.data.status) return { data: null, loading: action.loading };
    }
    return { data: action.data, loading: action.loading };
  }
  return state;
}
export function campaign(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.SMS_CAMPAIGNS.GET_CAMPAIGN) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}
