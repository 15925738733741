import { combineReducers } from 'redux';
import { allInventoryItems, errorBadge, inventoryItem, onSelect } from './InventoryReducer';

const inventoryReducer = combineReducers({
  allInventoryItems,
  inventoryItem,
  errorBadge,
  onSelect
});

export default inventoryReducer;
