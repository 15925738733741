import {
  CATEGORY_MENU,
  CLEAR_USER_INFO,
  CREATE_ORDER,
  GET_CATEGORY_UPDATE,
  GET_PROMOTIONS,
  LIVE_ORDERS_DETAILS,
  LIVE_ORDERS_LIST,
  MENU_OPTIONS,
  MENU_UPDATE,
  SET_CATEGORY_ICON_CLASS_LIST,
  SET_LOCATION,
  SET_ORDER_TYPE_LIST,
  SET_PROMOTIONS,
  SET_SOUND_COUNT,
  SET_SOUND_MODE,
  SET_SOUND_ONE_CARD,
  SET_SOUND_PLAY,
  SET_SOUND_VOLUME,
  SET_TOKEN,
  SET_USER_INFO
} from '../../constants/pos';
import CONSTANTS from './../../../../../constants';

const initialState = {
  loading: true,
  location: '',
  token: '',
  categories: '',
  options: [],
  order: '',
  liveOrderList: '',
  liveOrderDetails: '',
  role: '',
  promotionsData: [],
  orderTypes: [],
  categoryIconClassNameList: [],
  volume: 'notification_medium.wav',
  mode: 'Single',
  handleOneCard: false,
  howler_sound_play: false,
  count: 1
};

const posReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: action.data
      };
    case SET_CATEGORY_ICON_CLASS_LIST:
      return {
        ...state,
        categoryIconClassNameList: action.data
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.data
      };
    case CATEGORY_MENU:
      return {
        ...state,
        categories: action.data,
        loading: action.loading
      };
    case GET_CATEGORY_UPDATE:
      return {
        ...state,
        categories: action.data,
        loading: action.loading
      };
    case MENU_UPDATE:
      return {
        ...state,
        categories: action.data,
        loading: action.loading
      };
    case MENU_OPTIONS:
      return {
        ...state,
        options: action.data,
        loading: action.loading
      };
    case SET_SOUND_VOLUME:
      return {
        ...state,
        volume: action.volume
      };
    case SET_SOUND_MODE:
      return {
        ...state,
        mode: action.mode
      };
    case SET_SOUND_COUNT:
      return {
        ...state,
        count: action.count
      };
    case SET_SOUND_ONE_CARD:
      return {
        ...state,
        handleOneCard: action.handleOneCard
      };
    case SET_SOUND_PLAY:
      return {
        ...state,
        howler_sound_play: action.howler_sound_play
      };
    case GET_PROMOTIONS:
      const promotion = action.promotionsData;
      const promObj = {};
      promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET] = [];
      promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] = [];
      promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] = {};
      promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] = {};
      if (promotion.errorMessage) {
        promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET] = [];
        promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] = [];
        promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] = {};
        promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] = {};
        promObj['currentManualPromoID'] = { errorMessage: promotion.errorMessage };
      } else {
        promObj['currentManualPromoID'] = { id: promotion.id, code: promotion.code };
        const {
          PromotionDiscount: { discount_type, PromotionDiscountItems: promotionDiscountItems },
          PromotionOrderTypes
        } = promotion;
        const orderTypeIDs = PromotionOrderTypes.map((o) => o.order_type_id);
        if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.BASKET) {
          promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET].push(promotion);
        } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.ALL_ITEMS) {
          promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS].push(promotion);
        } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.SPECIFIC_ITEMS) {
          for (let i = 0; i < promotionDiscountItems.length; i++) {
            const item = promotionDiscountItems[i];
            if (item.item_type == CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.ITEM_TYPES.ITEM) {
              promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS][item.item] = {
                ...item,
                parent_id: promotion.id,
                max_discount: promotion.max_discount_amount,
                order_type_ids: orderTypeIDs,
                code: promotion.code
              };
            } else {
              promObj[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES][item.item] = {
                ...item,
                parent_id: promotion.id,
                max_discount: promotion.max_discount_amount,
                order_type_ids: orderTypeIDs,
                code: promotion.code
              };
            }
          }
        }
      }

      return {
        ...state,
        promotionsData: promObj
      };

    case SET_PROMOTIONS:
      const promotions = action.promotionsData;
      const promObjs = {};
      promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET] = [];
      promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] = [];
      promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] = {};
      promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] = {};

      for (let i = 0; i < promotions.length; i++) {
        const promotion = promotions[i];
        if (promotion.active === true) {
          const {
            PromotionDiscount: { discount_type, PromotionDiscountItems: promotionDiscountItems },
            PromotionOrderTypes
          } = promotion;
          const orderTypeIDs = PromotionOrderTypes.map((o) => o.order_type_id);
          if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.BASKET) {
            promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET].push(promotion);
          } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.ALL_ITEMS) {
            promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS].push(promotion);
          } else if (
            discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.SPECIFIC_ITEMS
          ) {
            for (let i = 0; i < promotionDiscountItems.length; i++) {
              const item = promotionDiscountItems[i];
              if (item.item_type == CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.ITEM_TYPES.ITEM) {
                promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS][item.item] = {
                  ...item,
                  parent_id: promotion.id,
                  max_discount: promotion.max_discount_amount,
                  order_type_ids: orderTypeIDs,
                  code: promotion.code,
                  order_types: promotion.PromotionOrderTypes,
                  automatic: promotion.automatic
                };
              } else {
                promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES][item.item] =
                  {
                    ...item,
                    parent_id: promotion.id,
                    max_discount: promotion.max_discount_amount,
                    order_type_ids: orderTypeIDs,
                    code: promotion.code,
                    automatic: promotion.automatic,
                    order_types: promotion.PromotionOrderTypes
                  };
              }
            }
          }
        }
      }
      return {
        ...state,
        promotionsData: promObjs
      };
    case CREATE_ORDER:
      return {
        ...state,
        order: action.data,
        loading: action.loading
      };
    case LIVE_ORDERS_LIST:
      return {
        ...state,
        liveOrderList: action.data,
        loading: action.loading
      };
    case LIVE_ORDERS_DETAILS:
      return {
        ...state,
        liveOrderDetails: action.data,
        loading: action.loading
      };
    case SET_USER_INFO:
      return {
        ...state,
        token: action.data.token,
        role: action.data.role,
        loading: action.loading
      };
    case SET_ORDER_TYPE_LIST:
      return {
        ...state,
        orderTypes: action.data,
        loading: action.loading
      };
    case CLEAR_USER_INFO:
      return {
        ...state,
        token: '',
        role: '',
        loading: action.loading
      };
    default:
      return state;
  }
};

export default posReducer;
