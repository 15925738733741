export default class DeliveryChargeHelper {
  static computeDeliveryCharges(deliveryArea, orderTotal) {
    if (deliveryArea.charges && deliveryArea.charges.length > 0) {
      let allOrdersAmount = this.getAllOrderAmount(deliveryArea.charges);
      let orderTotalAmount = this.getOrderTotalAmount(deliveryArea.charges, orderTotal);
      if (orderTotalAmount !== null) return orderTotalAmount;
      if (allOrdersAmount !== null) return allOrdersAmount;
    }
    return 0.0;
  }

  static getAllOrderAmount(allCharges) {
    let charges = allCharges.filter((charge) => charge.condition === 'all');
    if (charges && charges.length > 0) {
      charges.sort((a, b) => a.amount - b.amount);
      return charges[0].amount;
    }
    return null;
  }

  static getOrderTotalAmount(allCharges, orderTotal) {
    let charges = allCharges.filter((charge) => charge.condition === 'above');
    if (charges && charges.length > 0) {
      charges.sort((a, b) => a.total - b.total);
      if (orderTotal <= charges[0].total) return null;

      let i;
      for (i = 0; i < charges.length; i++)
        if (charges[i].total < orderTotal && charges[i + 1] && orderTotal <= charges[i + 1].total)
          break;

      return charges[i] ? charges[i].amount : charges[charges.length - 1].amount;
    }
    return null;
  }
}
