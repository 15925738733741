import React from 'react';
import * as Icon from 'react-feather';
import CheftIcon from '../components/shared/ChefIcon';

const adminNavigationConfig = (location = null, allInventoryItems) =>
  [
    {
      id: 'live-orders',
      title: 'Live Orders',
      type: 'item',
      icon: <Icon.Package size={20} />,
      permissions: ['admin', 'editor'],
      access: 'liveorders',
      navLink: '/'
    },
    {
      id: 'order-types',
      title: 'Order Status',
      type: 'item',
      icon: <Icon.File size={20} />,
      permissions: ['admin', 'editor'],
      navLink: '/locations/order_types',
      access: 'ordertypes',
      filterBase: '/locations/order_types'
    },
    {
      id: 'menu-manager',
      title: 'Menu Management',
      type: 'collapse',
      icon: <Icon.List size={20} />,
      notificationIcon: true,
      access: 'menu',
      children: [
        {
          id: 'menu',
          title: 'Menu',
          type: 'item',
          icon: <Icon.List size={20} />,
          permissions: ['admin', 'editor'],
          access: 'menu',
          navLink: '/locations/menu'
        },
        {
          id: 'upload-menu',
          title: 'Upload Menu',
          type: 'item',
          icon: <Icon.File size={20} />,
          permissions: ['admin', 'editor'],
          access: 'menu',
          navLink: '/locations/upload-menu'
        }
      ]
    },

    {
      id: 'inventory-manager',
      title: 'Inventory Management',
      type: 'collapse',
      icon: <Icon.FileText size={20} />,
      notificationIcon: true,
      access: 'inventory',
      children: [
        {
          id: 'view-inventory',
          title: 'Inventory',
          type: 'item',
          icon: <Icon.FileText size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/inventory'
        },
        {
          id: 'recipe-manager',
          title: 'Recipe',
          type: 'item',
          icon: <Icon.Book size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/recipe'
        },
        {
          id: 'inventory-stock-manager',
          title: 'Inventory Stock',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/inventory-stock',
          notificationIcon: true
        },
        {
          id: 'purchases-manager',
          title: 'Purchases',
          type: 'item',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/purchases'
        },
        {
          id: 'productions-manager',
          title: 'Productions',
          type: 'item',
          icon: <CheftIcon />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/productions'
        },
        {
          id: 'inventory-stock-adjustments',
          title: 'Stock Adjustment',
          type: 'item',
          icon: <Icon.FileMinus size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/stock-adjustment'
        },
        {
          id: 'inventory-stock-transfers',
          title: 'Stock Transfers',
          type: 'item',
          icon: <Icon.RefreshCcw size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/stock-transfers'
        },
        {
          id: 'inventory-stock-counts',
          title: 'Stock Counts',
          type: 'item',
          icon: <Icon.PlusSquare size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/stock-counts'
        }
      ].filter((child) => Object.keys(child).length !== 0)
    },
    {
      id: 'analytics',
      title: 'Analytics',
      type: 'collapse',
      icon: <Icon.BarChart2 size={16} />,
      access: 'analytics',
      children: [
        {
          id: 'overall-stats',
          title: 'Overall Stats',
          type: 'item',
          icon: <Icon.Activity size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/overall_stats'
        },
        {
          id: 'restaurant-stats',
          title: 'Restaurants Stats',
          type: 'item',
          icon: <Icon.Home size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/restaurants_stats'
        },
        {
          id: 'customer-stats',
          title: 'Customer Stats',
          type: 'item',
          icon: <Icon.User size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/customer_stats'
        },
        {
          id: 'item-stats',
          title: 'Item Stats',
          type: 'item',
          icon: <Icon.List size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/item_stats'
        },
        {
          id: 'orders-stats',
          title: 'Order Logs',
          type: 'item',
          icon: <Icon.ShoppingCart size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/orders_stats'
        },
        {
          id: 'bins-stats',
          title: 'Bank Discounts Reports',
          type: 'item',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/bin_stats'
        },
        {
          id: 'bank-discounts-stats',
          title: 'Bank Discounts Logs',
          type: 'item',
          icon: <Icon.Percent size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/bin_orders'
        },
        {
          id: 'prov-discounts-stats',
          title: 'Provisional Orders',
          type: 'item',
          icon: <Icon.List size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/provisional_stats'
        }
      ]
    },
    {
      id: 'promotion-dsicount',
      title: 'Promotions & Discounts',
      type: 'collapse',
      access: 'promotions',
      icon: <Icon.Monitor size={20} />,
      children: [
        {
          id: 'promotions',
          title: 'Promotions',
          type: 'item',
          access: 'promotions',
          icon: <Icon.Monitor size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/promotions'
        },
        {
          id: 'bankdiscounts',
          title: 'Banks Discounts',
          type: 'item',
          access: 'bin-discounts',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/bindiscounts'
        },
        {
          id: 'loyalties',
          title: 'Loyalties',
          type: 'item',
          access: 'loyalty',
          icon: <Icon.Star size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/loyalties'
        }
      ]
    },
    {
      id: 'app-notifications',
      title: 'App Notifications',
      type: 'collapse',
      access: 'notifications',
      icon: <Icon.Mail size={20} />,
      children: [
        {
          id: 'in-app-notifications',
          title: 'In App Notifications',
          type: 'item',
          icon: <Icon.MessageSquare size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/app/notifications'
        },
        {
          id: 'notifications-logs',
          title: 'Notifications Logs',
          type: 'item',
          icon: <Icon.MessageSquare size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/app/notifications/logs'
        }
      ]
    },
    {
      id: 'sms-marketing',
      title: 'Communications',
      type: 'collapse',
      access: 'sms-marketing',
      icon: <Icon.Mail size={20} />,
      children: [
        {
          id: 'sms-campaigns',
          title: 'SMS Campaigns',
          type: 'item',
          icon: <Icon.MessageSquare size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/sms/campaigns'
        },
        {
          id: 'sms-plans',
          title: 'Purchase SMS Bundle',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/sms/plans'
        },
        {
          id: 'create-sms-plans',
          title: 'Create SMS Bundle',
          type: 'item',
          icon: <Icon.Archive size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/sms/admin/plans'
        },
        {
          id: 'create-sms-manual',
          title: 'Create SMS Manual',
          type: 'item',
          icon: <Icon.Mail size={20} />,
          permissions: ['admin'],
          navLink: '/locations/sms/admin/manual'
        }
      ]
    },
    {
      id: 'restaurant-settings',
      title: 'Restaurant Settings',
      type: 'collapse',
      icon: <Icon.Home size={20} />,
      children: [
        {
          id: 'outlets',
          title: 'Outlets',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ['admin', 'editor'],
          access: 'restaurant-settings',
          navLink: '/locations/outlets'
        },
        {
          id: 'departments',
          title: 'Departments',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ['admin', 'editor'],
          access: 'departments',
          navLink: '/locations/departments'
        },
        {
          id: 'printer_stations',
          title: 'Printer Stations',
          type: 'item',
          icon: <Icon.Printer size={20} />,
          permissions: ['admin', 'editor'],
          access: 'printer-stations',
          navLink: '/locations/printer_stations'
        },
        {
          id: 'branding',
          title: 'Branding',
          type: 'item',
          icon: <Icon.Layout size={20} />,
          permissions: ['admin'],
          navLink: '/locations/branding',
          access: 'restaurant-settings',
          filterBase: '/locations/branding'
        },
        {
          id: 'pagelayouts',
          title: 'Page Layouts',
          type: 'item',
          icon: <Icon.Monitor size={20} />,
          permissions: ['admin'],
          navLink: '/locations/pagelayouts',
          access: 'restaurant-settings',
          filterBase: '/locations/pagelayouts'
        },
        {
          id: 'pages',
          title: 'Web Pages',
          type: 'item',
          icon: <Icon.Monitor size={20} />,
          permissions: ['admin', 'editor'],
          access: 'web-pages',
          navLink: '/locations/pages'
        },
        {
          id: 'tables',
          title: 'Tables',
          type: 'item',
          icon: <Icon.Tablet size={20} />,
          permissions: ['admin'],
          access: 'tables',
          navLink: '/locations/tables'
        },
        {
          id: 'metatags',
          title: 'MetaTags',
          type: 'item',
          icon: <Icon.Flag size={20} />,
          permissions: ['admin'],
          access: 'restaurant-settings',
          navLink: '/metatags'
        }
      ]
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'collapse',
      icon: <Icon.Settings size={20} />,
      children: [
        {
          id: 'appsettings',
          title: 'App Settings',
          type: 'item',
          icon: <Icon.Settings size={20} />,
          permissions: ['admin', 'editor'],
          access: 'settings',
          navLink: '/appsettings'
        },
        {
          id: 'restaurants',
          title: 'Restaurants',
          type: 'item',
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ['admin', 'editor'],
          access: 'settings',
          navLink: '/locations'
        },
        {
          id: 'payment-processor',
          title: 'Payment Settings',
          type: 'item',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          access: 'payment-settings',
          navLink: '/locations/payment_processor',
          filterBase: '/locations/payment_processor'
        },
        {
          id: 'currencies',
          title: 'Currencies',
          type: 'item',
          icon: <Icon.DollarSign size={20} />,
          permissions: ['admin'],
          navLink: '/currencies'
        },
        {
          id: 'rider-services',
          title: 'Rider Services',
          type: 'item',
          icon: <Icon.User size={20} />,
          permissions: ['admin'],
          access: 'rider-services',
          navLink: '/locations/rider_services',
          filterBase: '/locations/rider_services'
        },
        {
          id: 'notification-settings',
          title: 'Notification Settings',
          type: 'item',
          icon: <Icon.Settings size={20} />,
          navLink: '/locations/settings/notification'
        },
        {
          id: 'activities',
          title: 'Activities',
          type: 'item',
          icon: <Icon.List size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/activities'
        },
        {
          id: 'paymentlogs',
          title: 'Payment Logs',
          type: 'item',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/payment/logs'
        },
        {
          id: 'otp-settings',
          title: 'OTP Settings',
          type: 'item',
          icon: <Icon.MessageSquare size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/otp'
        },
        {
          id: 'binbanks',
          title: 'Banks',
          type: 'item',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/binbanks'
        }
      ]
    },
    {
      id: 'riders-and-delivery',
      title: 'Riders & Delivery',
      type: 'collapse',
      access: 'rider-delivery',
      icon: <Icon.Truck size={20} />,
      children: [
        {
          id: 'rider-compensation',
          title: 'Rider Compensation',
          type: 'item',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/locations/rider_compensation'
        },
        {
          id: 'rider',
          title: 'Riders Earnings',
          type: 'item',
          access: 'riderearnings',
          icon: <Icon.CreditCard size={20} />,
          permissions: ['admin', 'editor'],
          navLink: '/riders'
        }
      ]
    },
    {
      id: 'appdata',
      title: 'App Data',
      access: 'appdata',
      type: 'collapse',
      icon: <Icon.Box size={20} />,
      children: [
        {
          id: 'feedback',
          title: 'Feedbacks',
          type: 'item',
          icon: <Icon.Inbox size={20} />,
          permissions: ['admin'],
          navLink: '/feedbacks'
        },
        {
          id: 'reviews',
          title: 'Reviews',
          type: 'item',
          icon: <Icon.Star size={20} />,
          permissions: ['admin'],
          navLink: '/reviews'
        },
        {
          id: 'users',
          title: 'Users',
          type: 'item',
          icon: <Icon.Users size={20} />,
          permissions: ['admin'],
          navLink: '/appdata/users'
        },
        {
          id: 'reports',
          title: 'Reports',
          type: 'item',
          icon: <Icon.FileText size={20} />,
          permissions: ['admin'],
          navLink: '/appdata/reports'
        },
        {
          id: 'session-activity',
          title: 'Session Activity',
          type: 'item',
          icon: <Icon.Activity size={20} />,
          permissions: ['admin'],
          navLink: 'appdata/session-activity'
        }
      ]
    },
    {
      id: 'accounts',
      title: 'Accounts & Roles',
      type: 'collapse',
      access: 'accounts',
      icon: <Icon.Lock size={20} />,
      children: [
        {
          id: 'Accounts',
          title: 'Accounts',
          type: 'item',
          icon: <Icon.Users size={20} />,
          permissions: ['admin'],
          navLink: '/staff'
        },
        {
          id: 'Roles',
          title: 'Roles',
          type: 'item',
          icon: <Icon.LogIn size={20} />,
          permissions: ['admin'],
          navLink: '/roles'
        },
        {
          id: 'AccessPackages',
          title: 'Feature Packages',
          type: 'item',
          icon: <Icon.Package size={20} />,
          permissions: ['admin'],
          navLink: '/access/packages'
        },
        {
          id: 'Assign Module',
          title: 'Feature Access',
          type: 'item',
          icon: <Icon.Activity size={20} />,
          permissions: ['admin'],
          navLink: '/access/assign'
        }
      ]
    },
    {
      id: 'divider'
    },
    {
      id: 'logout',
      title: 'Logout',
      type: 'item',
      icon: <Icon.Power size={20} />,
      permissions: ['admin', 'editor'],
      access: 'logout',
      navLink: '/logout'
    }
  ].filter((child) => Object.keys(child).length !== 0);
export default adminNavigationConfig;
