import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Circle, Map, Marker } from 'google-maps-react';
import React, { Component } from 'react';
import currentLocImage from '../../../assets/img/static/current-loc.svg';
import CONSTANTS from '../../../constants';
import MyCustomMarker from './MyCustomMarker';

export default class SharedMap extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  getBounds(outlets) {
    const bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < outlets.length; i++) {
      const outlet = outlets[i];
      const marker = { lat: Number(outlet.latitude), lng: Number(outlet.longitude) };
      bounds.extend(marker);
    }
    if (this.props.defaultLocation) {
      const marker = {
        lat: Number(this.props.defaultLocation.lat),
        lng: Number(this.props.defaultLocation.lng)
      };
      bounds.extend(marker);
    }
    return bounds;
  }

  setBounds(outlets) {
    const bounds = this.getBounds(outlets);
    if (this.mapRef.current) {
      this.mapRef.current.map.fitBounds(bounds);
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.selectedOutlet) !== JSON.stringify(prevProps.selectedOutlet)) {
      this.getOutletMarkers(this.props.outlets && this.props.outlets);
    }

    if (
      JSON.stringify(this.props.defaultLocation) !== JSON.stringify(prevProps.defaultLocation) &&
      this.props.isTakeAway
    ) {
      this.setBounds(this.props.outletsForBounds);
    }
  }

  getOutletMarkers(outlets) {
    if (outlets && outlets.length === 0) return;

    const {
      props: { selectedOutlet, onOutletSelect }
    } = this;

    outlets &&
      outlets.map((outlet) => {
        const marker = { lat: Number(outlet.latitude), lng: Number(outlet.longitude) };
        const isSelected = selectedOutlet && selectedOutlet.loc_id === outlet.loc_id ? true : false;
        const markerEl = new MyCustomMarker({
          position: marker,
          label: outlet.name,
          isSelected: isSelected,
          onClick: () => onOutletSelect(outlet)
        });
        markerEl.setMap(this.mapRef.current?.map);
      });
  }

  render() {
    let restaurantLoc = '';
    if (this.props.selectedOutlet) {
      restaurantLoc = {
        lat: this.props.selectedOutlet.latitude,
        lng: this.props.selectedOutlet.longitude
      };
    }
    let location =
      this.props.defaultLocation ||
      this.props.currentLocation ||
      restaurantLoc ||
      CONSTANTS.ORDERS.VARIABLES.DEFAULT_LOCATION;
    if (this.props.selectedOutlet && this.props.isTakeAway) {
      location = {
        lat: this.props.selectedOutlet.latitude,
        lng: this.props.selectedOutlet.longitude
      };
    }
    const outlets = this.props.outlets ? this.props.outlets : [];
    const outletsForBounds = this.props.outletsForBounds ? this.props.outletsForBounds : [];
    const zoomLevel = this.props.isTakeAway ? 12 : 16;

    //console.log(this.props)

    return [
      <Map
        ref={this.mapRef}
        mapTypeControl={false}
        fullscreenControl={false}
        streetViewControl={false}
        containerStyle={{ position: 'relative' }}
        google={window.google}
        key="map"
        initialCenter={location}
        center={location}
        zoom={zoomLevel}
        gestureHandling={'greedy'}
        onDragend={this.props.isTakeAway ? console.log : this.props.onMapChange}
        onClick={this.props.isTakeAway ? console.log : this.props.onLocationSelect}
        onReady={() => {
          if (this.props.isTakeAway) {
            this.getOutletMarkers(outlets);
            this.setBounds(outletsForBounds);
          }
        }}>
        {this.props.isTakeAway && this.props.currentLocation && (
          <Circle
            radius={1200}
            center={this.props.currentLocation}
            strokeColor="transparent"
            strokeOpacity={0}
            strokeWeight={5}
            fillColor="#1E90FF"
            fillOpacity={0.2}
          />
        )}
        {this.props.isTakeAway && this.props.currentLocation && (
          <Marker
            name={'Current location'}
            position={this.props.currentLocation}
            icon={{
              url: currentLocImage,
              anchor: new window.google.maps.Point(6, 6),
              scaledSize: new window.google.maps.Size(12, 12)
            }}
          />
        )}
      </Map>,
      !this.props.isTakeAway && (
        <div className="pin-icon-container" key="pin-icon">
          <FontAwesomeIcon className="pin-icon" icon={faMapPin} size={'2x'} />
        </div>
      )
    ];
  }
}
